import React from "react";
import { ICriteria, ItermFormResDto } from "../models/SetOfCriteriaModels";
import { INFO_FORM_CRITERIA_ENABLE } from "../config/ConfigField";

type ISetOfCriteriaContext = {
    criteriaList: ICriteria[];
    setCriteriaList: React.Dispatch<React.SetStateAction<ICriteria[]>>;
    formTableAbility: typeof INFO_FORM_CRITERIA_ENABLE
    setFormTableAbility:React.Dispatch<React.SetStateAction<any>>;
    setEmpApprove: React.Dispatch<React.SetStateAction<string | null>>;
    setMaxPoint: React.Dispatch<React.SetStateAction<number>>;
    maxPoint: number,
    termFormInfo: ItermFormResDto,
    setTermFormInfo: React.Dispatch<React.SetStateAction<ItermFormResDto>>;
    stageCodeInfo: string | null,
    setStageCodeInfo: React.Dispatch<React.SetStateAction<string | null>>;
}

const initValue: ISetOfCriteriaContext = {
    criteriaList: [],
    setCriteriaList: () => {},
    formTableAbility:INFO_FORM_CRITERIA_ENABLE,
    setFormTableAbility:()=>{},
    setEmpApprove: () => {},
    setMaxPoint: () => {},
    maxPoint: 0,
    termFormInfo: {},
    setTermFormInfo: () => { },
    stageCodeInfo: "",
    setStageCodeInfo: () => { },
}

const SetOfCriteriaContext = React.createContext(initValue);

export default SetOfCriteriaContext;