import { FC, memo } from 'react';
import { ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ButtonCustomProps extends ButtonProps {
    handleAprove?: () => void;
    handleEdit?: () => void;
    handleDelete?: () => void;
    handleView?: () => void;
    elementAbility?: any
    handleAddNew?: () => void;
    isIconEditOrAccess?: boolean
}

const GroupTableButton: FC<ButtonCustomProps> = (props) => {
    const {
        handleAprove,
        handleEdit,
        handleDelete,
        handleView,
        elementAbility = {},
        handleAddNew,
        isIconEditOrAccess=true,
    } = props;

    return (
        <div className={`d-flex ${handleEdit && handleDelete && handleView ? "justify-content-start" : "justify-content-center"}`}>
            {(handleAddNew && elementAbility?.ADD) && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-new">Thêm khu vực con</Tooltip>}
                >
                    <i className="cursor-pointer px-4 fw-bold fs-4 bi bi-plus-square-fill text-primary"
                        onClick={handleAddNew}
                    />
                </OverlayTrigger>
            )}
            {(handleView && elementAbility?.VIEW) && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-approve">Xem chi tiết</Tooltip>}
                >
                    <i className="cursor-pointer px-4 fw-bold fs-4 bi bi-eye-fill text-primary"
                        onClick={handleView}
                    />
                </OverlayTrigger>
            )}
            {(handleAprove) && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-approve">Phê duyệt</Tooltip>}
                >
                    <i className="cursor-pointer px-4 fw-bold fs-4 bi bi-clipboard-check text-primary"
                        onClick={handleAprove}
                    />
                </OverlayTrigger>
            )}
            
            {(handleEdit && elementAbility?.EDIT) && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-edit">{ isIconEditOrAccess ? "Sửa" : "Truy cập" }</Tooltip>}
                >
                    <i className={`cursor-pointer px-4 fw-bold fs-4 bi ${isIconEditOrAccess ? "bi-pencil-fill" : "bi-arrow-return-left"} text-primary`}
                        onClick={handleEdit}
                    />
                </OverlayTrigger>
            )}
            {(handleDelete && elementAbility?.DELETE) && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip  id="tooltip-delete">Xóa</Tooltip>}
                >
                    <i className="cursor-pointer px-4 fw-bold fs-4 bi bi-trash-fill text-danger"
                        onClick={handleDelete}
                    />
                </OverlayTrigger>
            )}
        </div>
    );
}

export default memo(GroupTableButton);
