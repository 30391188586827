import { Children, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AUTHORITIES_PERMISSIONS, BTN_APPROVE, BTN_CANCEL, BTN_FIX, BTN_REQUEST_EDIT, BTN_SAVE, BTN_SEND_REQUEST } from '../../../Constant';
import ButtonCustom from '../../component/button-custom';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { PopupSendRequest } from '../../component/PopupSendRequest';
import { SubmitTextPopup } from '../../component/SubmitTextPopup';
import { handleError } from '../../utils/FunctionUtils';
import { updateApproveRequestEvaluate, updateStageEditRequestEvaluate, updateStageSendRequestEvaluate } from '../EvaluateServices';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../../AppContext';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { useAuth } from '../../auth';

type Props = {
    handleClose: () => void
    handleFormSubmit: () => Promise<void>
    handleFormSubmitFixed: () => Promise<void>
    lstButton: any[]
    dataForm:any,
    isAssessFull: boolean
    scoreScale: {
        [key: string]: number
    }
}

interface ConfirmDialogState {
    show: boolean;
    message: string;
    action: () => void;
}

const GroupButton = ({
    handleClose,
    handleFormSubmit,
    handleFormSubmitFixed,
    lstButton,
    dataForm,
    isAssessFull,
    scoreScale = {
        scoreEmpMark: 0,
        scoreEmpOnus: 0,
    }
}: Props) => {
    const { setPageLoading } = useLoading();
    const { lang } = useMultiLanguage();
    const navigate = useNavigate();
    const { reloadUserInfor } = useAuth();
    const dataFormRef = useRef<{ [key: string]: any } | null>(dataForm);
    dataFormRef.current = dataForm;

    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogState>({
        show: false,
        message: "",
        action: () => { }
    });
    const [openSendRequestPopup, setOpenSendRequestPopup] = useState<boolean>(false);
    const [openEditRequestPopup, setOpenEditRequestPopup] = useState<boolean>(false);
    const [openFixRequestPopup, setOpenFixRequestPopup] = useState<boolean>(false);
    const handleOpenConfirmDialog = (message: string, action: () => void) => {
        setConfirmDialog({
            show: true,
            message,
            action
        });
    };

    const handleSendRequest = async (data: any) => {
        try {
            const dataSend = {
                ...dataFormRef.current,
                textOpinions: data?.content,
                stageRequestDto: {
                    userApprove: data?.userApprove,
                    textOpinions: data?.content,
                    programImplScheduleId:dataForm?.id,
                    stageRequestId: dataForm?.stageRequestId,
                }
            }
            await updateStageSendRequestEvaluate(dataSend);
            navigate(-1);
            toast.success("Trình phê duyệt thành công");
            reloadUserInfor();
        } catch (error) {
            handleError(error)
        }

    }

    const handleRequestEdit = async (textRefuse: string) => {
        
        try {
            setPageLoading(true);
            const dataSend = {
                stageRequestId: dataForm?.stageRequestId,
                textRefuse: textRefuse,
                programImplScheduleId: dataForm?.id

            }
            await updateStageEditRequestEvaluate(dataSend)
            toast.success("Gửi yêu cầu thành công");
            setPageLoading(false);
            navigate("/approved")
        } catch (error) {
            handleError(error);
            setPageLoading(false);
        }
    }

    const handleSaveFormPerSendRequest = async () => {
        try {
            if (isAssessFull) {
                toast.warning(lang("Chưa đánh giá đủ các mục"));
                return;
            }
            await handleFormSubmit()
            setOpenSendRequestPopup(true)
        } catch (error) {
            handleError(error)
        }
    };

    const handleSave = async () => {
        try {
            await handleFormSubmit()
            handleClose()
        } catch (error) {
            handleError(error)
        }
    };
 
    const handleApprove = async () => {
        try {
            setPageLoading(true);
            const dataSend= {
                ...dataForm,
                scoreEmpMark: scoreScale.scoreEmpMark,
                scoreEmpOnus: scoreScale.scoreEmpOnus     
            }
            await updateApproveRequestEvaluate(dataForm?.id, dataSend);
            toast.success("Phê duyệt thành công");
            setPageLoading(false);
            navigate("/approved");
        } catch (error) {
            handleError(error);
            setPageLoading(false);
        }
    }

    const handleButtonClick = (action: string) => {
        switch (action) {
            case BTN_SAVE.action:
                handleSave();
                break;
            case BTN_CANCEL.action:
                handleClose();
                break;
            case BTN_SEND_REQUEST.action:
                handleSaveFormPerSendRequest();
                break;
            case BTN_APPROVE.action:
                handleOpenConfirmDialog("phê duyệt", handleApprove)
                break;
            case BTN_REQUEST_EDIT.action:
                setOpenEditRequestPopup(true)
                break;
            case BTN_FIX.action:
                handleOpenConfirmDialog("khắc phục", handleFormSubmitFixed)
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className='d-flex gap-1 justify-content-center align-items-center'>
                {
                    Children.toArray(lstButton?.map((button => (
                        <ButtonCustom
                            name={button.name}
                            className={button.className}
                            onClick={() => { handleButtonClick(button.action) }}
                            checkAuthority={button.authority}
                        >
                            {button.text}
                        </ButtonCustom>)
                    )))
                }
            </div>

            {confirmDialog.show && (
                <ConfirmDialog
                    show={confirmDialog.show}
                    message={`Bạn có muốn ${confirmDialog.message} không?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={() => {
                        confirmDialog.action();
                        setConfirmDialog({ ...confirmDialog, show: false });
                    }}
                    onCancelClick={() => setConfirmDialog({ ...confirmDialog, show: false })}
                />
            )}

            {openSendRequestPopup &&
                <PopupSendRequest
                    show={openSendRequestPopup}
                    onSubmitForm={(data) => {
                        handleSendRequest(data)
                    }}
                    onCancelClick={() => setOpenSendRequestPopup(false)}
                    permission={AUTHORITIES_PERMISSIONS.EVALUATE}
                />
            }

            {openEditRequestPopup &&
                <SubmitTextPopup
                    show={openEditRequestPopup}
                    onSubmitForm={(value) => {
                        handleRequestEdit(value?.content || "")
                    }}
                    onCancelClick={() => setOpenEditRequestPopup(false)}
                />
            }

            {openFixRequestPopup &&
                <SubmitTextPopup
                    show={openFixRequestPopup}
                    onSubmitForm={(value) => {
                        handleFormSubmitFixed()
                    }}
                    onCancelClick={() => setOpenFixRequestPopup(false)}
                />
            }


        </>
    );
};
export default GroupButton