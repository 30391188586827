export const ColumnViewListCriteria = (props: any) => {
    return [
      {
        name: "Chỉ tiêu",
        field: "termName",
        headerStyle: {
          minWidth: "300px",
        },
        cellStyle: {
          textAlign: "start",
          padding: "0 8px"
        },
        render: (row: any) => (
          !row?.shortNameParent
            ?
            <h4 className='pt-2'>{row?.shortName + ". " + row?.name}</h4>
            :
            <span>{row?.shortName + ". " + row?.name}</span>
        )
      },
    ];
  };