import { useIntl } from 'react-intl';

type Props = {
  label: string,
  name: string,
  isView?: boolean,
  value: boolean,
  handleChange: any;
  errors?: any;
  touched?: any;
};

const CheckboxField = (props: Props) => {
  const { label, name, isView, value, handleChange } = props;
  const intl = useIntl();
  return (
    <>
      <input type="checkbox"
        className={`form-check-input mt-0 checkbox-content cursor-pointer ${props.errors && props.touched ? "is-invalid" : ""}`}
        // value={value ? true : false}
        onChange={handleChange}
        disabled={isView}
        name={name}
        checked={value ? true : false}
        id={name}
      />
      <label className='spaces text-label ml-10 cursor-pointer' htmlFor={name}>
        {intl.formatMessage({ id: label })}
      </label>
      {props.touched && props.errors && <div className="invalid-feedback">{props.errors}</div>}
    </>
  );
};

export default CheckboxField;