import React, { useState, useRef, useLayoutEffect } from 'react';
import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import { isLeapYear } from '../../utils/FunctionUtils';

const FORMAT_VALUE = "__/__/____"; // định dạng dd/mm/yyyy
const INIT_INDEX = 1;
const DAY_RANGE_INDEX = [1, 2]; // vị trí con trỏ ở khu vực ngày theo định dạng dd/mm/yyyy
const MONTH_RANGE_INDEX = [3, 4, 5]; // vị trí con trỏ ở khu vực tháng theo định dạng dd/mm/yyyy
const YEAR_RANGE_INDEX = [6, 7, 8, 9, 10]; // vị trí con trỏ ở khu vực năm theo định dạng dd/mm/yyyy
export const MONTHS_HAVE_30_DAYS = [4, 6, 9, 11]; // những tháng có 30 ngày (còn lại là 31 ngày (không tính tháng 2))

interface Iprop extends FormControlProps {
    label?: string,
    touched?: boolean,
    errors?: string,
    isRequired?: boolean,
    classNameLabel?: string,
    classNameInput?: string,
    value: string,
    setValue: (value: string) => void,
};

export const validationDateFormat = (value: string) => {
    if (value) {
        if (value.includes("_")) {
            return false;
        } else {
            // value <==> dd/mm/yyyy
            let dayValue = Number(value.slice(0, 2));
            let monthValue = Number(value.slice(3, 5));
            let yearValue = Number(value.slice(6, 10));
            if (!dayValue || dayValue > (MONTHS_HAVE_30_DAYS.includes(monthValue) ? 30 : 31)) { // validate ngày
                return false;
            } else if (!monthValue || monthValue > 12) { // validate tháng
                return false;
            } else if (!yearValue || yearValue < 1000) { // validate năm
                return false;
            } else if (monthValue === 2 && dayValue > (isLeapYear(yearValue) ? 29 : 28)) { // validate trường hợp đặc biệt (tháng 2)
                return false;
            }
        }
    }
    return true;
}

const InputDateCustom = (props: Iprop) => {
    const {
        label,
        className,
        classNameLabel,
        classNameInput,
        placeholder,
        touched,
        errors,
        value,
        setValue,
        ...rest
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(INIT_INDEX);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: string = e.target.value;
        let listNumber = newValue.replace(/[^\d]+/g, '').split('');
        if (listNumber.length) {
            let result = "";
            let _index = e.target.selectionStart as number;
            for (let i = 0; i < listNumber.length; i++) {
                result += listNumber[i];
                // bổ sung ký tự "/" sau chữ số thứ 2 và thứ 4 theo định dạng dd/mm/yyyy (tương ứng với vị trí 1 và 3).
                if (i === 1 || i === 3) {
                    result += "/";
                }
                // khi đủ 8 chữ số (vị trí đạt tới 7) dừng vòng lặp.
                if (i === 7) {
                    break;
                }
            }
            if (
                (DAY_RANGE_INDEX.includes(currentIndex) && MONTH_RANGE_INDEX.includes(_index)) ||
                (MONTH_RANGE_INDEX.includes(currentIndex) && YEAR_RANGE_INDEX.includes(_index))
            ) {
                _index += 1;
            } else if (
                DAY_RANGE_INDEX.includes(currentIndex) && YEAR_RANGE_INDEX.includes(_index)
            ) {
                _index += 2;
            }
            if (result.length < FORMAT_VALUE.length) {
                result += FORMAT_VALUE.slice(result.length, FORMAT_VALUE.length);
            }

            setCurrentIndex(_index);
            setValue(result);
            setIsInvalid(!validationDateFormat(result));
        } else {
            setValue('');
            setIsInvalid(false);
        }
    }

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(currentIndex, currentIndex);
        }
    }, [currentIndex, value]);

    return (
        <div className={props?.className}>
            {props?.label && (
                <span className={`text-lable-input lable mb-1 ${classNameLabel}`}>
                    {props?.label}
                    {props?.isRequired && <span className="color-red">&nbsp;*</span>}
                </span>
            )}
            <div className="w-100">
                <Form.Group className={`position-relative flex-grow-1 ${classNameInput}`}>
                    <FormControl
                        {...rest}
                        ref={inputRef}
                        value={value}
                        onChange={handleChange}
                        placeholder={props?.placeholder ? props?.placeholder : "dd/mm/yyyy"}
                        type="text"
                        className={`
                            background-image-none
                            ${isInvalid || (touched && errors) ? "is-invalid" : ""}
                            form-control customs-input
                        `}
                    />
                    {(isInvalid || (touched && errors)) && <div className="invalid-feedback">{isInvalid ? "Sai định dạng" : errors}</div>}
                </Form.Group>
            </div>
        </div>
    )
}

export default InputDateCustom;