import { SubmitTextPopup } from '../../component/SubmitTextPopup';
import { toast } from 'react-toastify';
import { Children, useState } from 'react';
import { sendRequestEdit, sendRequest, approvalRequest } from '../services/SetOfCriteriaServices';
import { BTN_APPROVE, BTN_CANCEL, BTN_SEND_REQUEST,BTN_REQUEST_EDIT, AUTHORITIES_PERMISSIONS } from '../../../Constant';
import { TERM_FORM_BUTTON } from '../config/ConfigButton';
import { ButtonAttribute } from '../../models/models';
import { PopupSendRequest } from '../../component/PopupSendRequest';
import { handleError } from '../../utils/FunctionUtils';
import ButtonCustom from '../../component/button-custom';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { useNavigate } from 'react-router-dom';

type Props = {
    termFormData?: any
    elementAbility: ButtonAttribute
    handleClose: () => void
    departmentArea?: Array<{ id: number | null }>
    ability: string,
    empApprove?: string | null,
    errors?:any,
}

const GroupButton = ({
    termFormData = {},
    handleClose,
    departmentArea,
    elementAbility,
    ability,
    empApprove,
    errors,
}: Props) => {
    let navigate = useNavigate();
    const [openEditRequestPopup, setOpenEditRequestPopup] = useState<boolean>(false);
    const [openSendRequestPopup, setOpenSendRequestPopup] = useState<boolean>(false);
    const [openApprovelPopup, setOpenApprovelPopup] = useState<boolean>(false);
    const { id, stageRequestId } = termFormData;

    const checkErrors = (obj: Record<string, any>): boolean => {
        for (const value of Object.values(obj)) {
            if (value !== null) {
                return true;
            }
        }
        return false;
    };

    const handleSendRequest = async (data: any) => {
        const hasValidDepartment = departmentArea?.some((item) => item.id !== null);
        try {
            if (!hasValidDepartment) {
                toast.error("Chưa có khu vực nào có tiêu chí");
                setOpenSendRequestPopup(false);
                return; 
            }

            const formData = {
                termFormId: id,
                stageRequestId: stageRequestId,
                userApprove: data.userApprove,
                textOpinions: data.content
            }
            
            id && await sendRequest(formData)
            toast.success("Trình phê duyệt thành công")
            handleClose()
        } catch (error) {
            handleError(error)
        }
    };

    const handleRequestEdit = async (data: any) => {
        try {
            const formDtata = {
                stageRequestId: stageRequestId,
                termFormId: id,
                textRefuse: data.content
            }
            id && await sendRequestEdit(formDtata)
            setOpenEditRequestPopup(false)
            toast.success("Gửi yêu cầu thành công")
            navigate("/approved")
        } catch (error) {
            handleError(error)
        }
    };

    const handleApprove = async () => {
        try {
            const formData = {
                termFormId: id,
                stageRequestId: stageRequestId
            }
            id && await approvalRequest(formData)
            toast.success("Phê duyệt thành công")
            navigate("/approved")
        } catch (error) {
            handleError(error)
        }
    }

    const handleButtonClick = (action: string) => {
        if (errors && checkErrors(errors) && action !== BTN_CANCEL.action) {
            toast.warning("Vui lòng kiểm tra thông tin đầu vào!");
            return;
        };
        switch (action) {
            case BTN_CANCEL.action:
                handleClose()
                break;
            case BTN_SEND_REQUEST.action:
                setOpenSendRequestPopup(true)
                break;
            case BTN_REQUEST_EDIT.action:
                setOpenEditRequestPopup(true)
                break;
            case BTN_APPROVE.action:
                setOpenApprovelPopup(true)
                break;
            default:
                break;
        }
    };

    return (
        <>
           <div className='d-flex gap-1 justify-content-center align-items-center'>
                {
                    Children.toArray(TERM_FORM_BUTTON.map((button => {
                        return (!elementAbility?.[button.name] &&
                            <ButtonCustom
                                name={button.name}
                                className={button.className}
                                checkAuthority={{
                                    permission: AUTHORITIES_PERMISSIONS.FORM,
                                    ability: ability,
                                }}
                                onClick={() => { handleButtonClick(button.action) }}
                                >
                                {button.text}
                            </ButtonCustom>
                        )
                    })))
                }
            </div>
            {openEditRequestPopup &&
                <SubmitTextPopup
                    show={openEditRequestPopup}
                    onSubmitForm={(textRefuse) => {
                        handleRequestEdit(textRefuse)
                    }}
                    onCancelClick={() => setOpenEditRequestPopup(false)}
                />
            }
            {openSendRequestPopup &&
                <PopupSendRequest
                    show={openSendRequestPopup}
                    onSubmitForm={(data)=>{
                        handleSendRequest(data)
                    }}
                    onCancelClick={() => setOpenSendRequestPopup(false)}
                    permission={AUTHORITIES_PERMISSIONS.FORM}
                    defaultValue={empApprove}
                    defaultContent={`Đề nghị phê duyệt biểu mẫu ${termFormData.name || ""}`}
                />
            }
            {openApprovelPopup && (
                <ConfirmDialog
                    show={openApprovelPopup}
                    title="Xác nhận"
                    message={`Bạn có muốn phê duyệt không?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleApprove}
                    onCancelClick={() => setOpenApprovelPopup(false)}
                />
            )}
        </>
    );
};
export default GroupButton