import { STATUS_CODE_5S } from "../../../Constant";
import { checkUserAccount } from "../../utils/FunctionUtils";

export const TABLE_ACTION_DEFAULT = {
    VIEW: true,
    EDIT: false,
    DELETE: false,
};

export const CONFIG_TABLE_BUTTON = Object.fromEntries(
    Object.values(STATUS_CODE_5S).map((value: string) => {
        switch (value) {
            case STATUS_CODE_5S.KHOI_TAO:
                return [value, {
                    ...TABLE_ACTION_DEFAULT,
                    EDIT: true,
                    DELETE: true
                }];
            case STATUS_CODE_5S.YEU_CAU_SUA_DOI:
                return [value, {
                    ...TABLE_ACTION_DEFAULT,
                    EDIT: true,
                }];
            default:
                return [value, TABLE_ACTION_DEFAULT];
        };
    })
);

const CONFIG_TABLE_BUTTON_NOT_USER_CREATE = Object.fromEntries(
    Object.values(STATUS_CODE_5S).map((value: string) => {
        return [value, TABLE_ACTION_DEFAULT];
    })
);

export const getConfigTableButton = (userCreate: string) => {
    if (checkUserAccount(userCreate)) {
        return CONFIG_TABLE_BUTTON;
    } else {
        return {
            ...CONFIG_TABLE_BUTTON_NOT_USER_CREATE   
        };
    }
};