
import React, { ChangeEvent, useRef, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import TableCustom from '../../../app/modules/component/table-custom/TableCustom';

interface MemberImportDialogAdd {
    show: boolean;
    onClose: () => void;
    dataErrors?: any;
}

const ShowErrorsImportExcelDialog: React.FC<MemberImportDialogAdd> = ({
    show,
    onClose,
    dataErrors
}) => {

    const Column = () => {;
        return [
            {
                name: "Dòng",
                field: "row",
                headerStyle: {
                    minWidth: "100px",
                    maxWidth: "120px",
                },
                cellStyle: {
                    textAlign: "center",
                },
            },
            {
                name: "Cột",
                field: "column",
                headerStyle: {
                    minWidth: "100px",
                    maxWidth: "120px",
                    textAlign: "center"
                },
            },
            {
                name: "Lỗi",
                field: "message",
                headerStyle: {
                    minWidth: "300px",
                    maxWidth: "400px",
                },
                cellStyle: {
                    textAlign: "left",
                },
            },
        ];
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            animation
            className="custom-modal category-dialog"
            size="lg"
        >
            <Modal.Header closeButton className='spaces py-15'>
                <Modal.Title>
                    <span className='spaces m-0 text-uppercase color-primary'>Lỗi import excel</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='spaces pt-0'>
                <div className='d-flex flex-column gap-12 spaces px-0 lh-xl'>
                    <Col xs={12} className="spaces pt-16 mb-3 gap-10">
                        <TableCustom
                            data={dataErrors}
                            height={"400px"}
                            columns={Column()}
                            justFilter={true}
                            updatePageData={() => { }}
                            noPagination={true}
                            headerClasses='text-center'
                        />
                    </Col>

                    <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
                        <Button
                            onClick={onClose}
                            className="spaces button-primary-outline py-4 fs-12 h-30"
                        >
                            Hủy
                        </Button>
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShowErrorsImportExcelDialog;