/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "../../AppContext";
import moment from "moment";
import useMultiLanguage from "../../hook/useMultiLanguage";
import "./Statistical.scss";
import {
    getDataRegionalScoreStatistics,
    getListDataProgram,
} from "./services/StatisticalServices";
import { useStatisticalContext } from "./Statistical";
import TableCustom from "../component/table-custom/TableCustom";
import { GeneralSearchParamsType } from "../utils/ParamsUtils";
import { SearchBase, searchBaseDefaultValue } from "../utils/InterfaceUtils";
import { Col, Form, Row } from "react-bootstrap";
import SelectCustom from "../component/select-custom";
import { LIST_PLAN_TYPE } from "../program/consts/InitialValue";
import { heightAutocomplete } from "../component/input-field/StyleComponent";
import { useFormik } from "formik";
import InputDateCustom from "../component/input-field/InputDateCustom";
import ButtonCustom from "../component/button-custom";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from "../../Constant";
import { useAuth } from "../auth";

function RegionalScoreStatistics() {
    const { lang } = useMultiLanguage();
    const { setPageLoading } = useLoading();
    const { type, year, setLoading } = useStatisticalContext();
    const { currentUser } = useAuth();

    const [data, setData] = useState<any[]>([]);
    const [searchObject, setSearchObject] = useState<SearchBase>({  pageIndex: 1, pageSize: 1000 });
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);
    const [listProgram, setListProgram] = useState<any>([]);
    const [program, setProgram] = useState<any>(null);

    useEffect(() => {
        getListProgram();
    }, [])

    const getListProgram = async (): Promise<void> => {
        try {
            const { data } = await getListDataProgram();
            setListProgram(data.data);
            updateData(data.data[0]?.id);
            setProgram(data.data[0]?.id);
        } catch (error) {
            console.error("ERROR", error);
        };
    };

    const updateData = async (programId: any): Promise<void> => {
        try {
            setPageLoading(true);
            const { data } = await getDataRegionalScoreStatistics(programId);
            let newData = data?.data;
            setData(newData);
        } catch (error) {
            console.error("ERROR", error);
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <div className="category pt-4 bg-white area-category-edit-page wrapper-content">
            <div className="spaces flex-end align-center mb-10">
                <Row className='px-2'>
                    <Col sm="7" md="5" lg="8"></Col>
                    <Col sm="5" md="7" lg="4">
                        <SelectCustom
                            className="spaces width-100"
                            label={"Chọn chương trình"}
                            name="programId"
                            valueField={'id'}
                            displayField={'name'}
                            value={program}
                            options={listProgram}
                            styles={{ ...heightAutocomplete("32px"), menu: provided => ({ ...provided, zIndex: 9999 }) }}
                            handleChange={(value, _option, _actionMeta, name) => {
                                updateData(value);
                                setProgram(value);
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <TableCustom
                data={data}
                height={"calc(100vh - 285px)"}
                columns={Column()}
                justFilter={true}
                updatePageData={() => { }}
                noPagination={true}
                headerClasses='text-center'
                objectSearch={searchObject}
            />
        </div>
    );
}

const Column = () => {
    return [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: "40px",
                maxWidth: "50px",
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            ),
        },
        {
            name: "Tên khu vực",
            field: "result",
            headerStyle: {
                minWidth: "200px",
                maxWidth: "220px",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            name: "Điểm",
            field: "count",
            headerStyle: {
                minWidth: "100px",
                maxWidth: "120px",
                textAlign: "center"
            },
        },
    ];
};

export default RegionalScoreStatistics;
