import { FC, ReactNode } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Form, FormikValues, useFormik, FormikConfig, FormikContext } from 'formik'
import '../../component/style.scss'

interface ModalFormikProps extends ModalProps{
    handleClose: () => void
    title: string | ReactNode;
    textCancelBtn?: string
    textSubmitBtn?: string
    children: (formilk: any) => ReactNode | ReactNode
    disableBtnSave?: boolean
    disableBtnCancel?: boolean
    renderModalFooter?: ReactNode
    FormikConfig: FormikConfig<FormikValues>
    sizeCustom?: string
};

const ModalFormikCustom: FC<ModalFormikProps> = ({ children, ...props }) => {
    const {
        title,
        textCancelBtn = "Hủy",
        textSubmitBtn = "Lưu",
        handleClose,
        renderModalFooter = null,
        disableBtnSave = false,
        disableBtnCancel = false,
        sizeCustom,
        ...rest
    } = props;

    const intl = useIntl()
    const formikStateAndHelpers = useFormik(props?.FormikConfig);
    return (
        <Modal
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className={sizeCustom === 'md' ? 'modal-md' : ''}
            {...rest}
        >
            <FormikContext.Provider value={formikStateAndHelpers}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-pri '>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {typeof children === 'function' ? children(formikStateAndHelpers) : children}
                    </Modal.Body>
                    <Modal.Footer className='pt-2 pb-2 d-flex justify-content-center'>
                        {renderModalFooter ?
                            renderModalFooter :
                            <>
                                <button
                                    className="spaces button-primary py-4 fs-12 h-30"
                                    type="submit"
                                    disabled={disableBtnSave}
                                >
                                   {textSubmitBtn ? textSubmitBtn : intl.formatMessage({ id: 'BTN.SAVE' })}
                                </button>
                                <button
                                    className="spaces button-primary-outline py-4 fs-12 h-30 ml-8"
                                    type='button'
                                    onClick={handleClose}
                                    disabled={disableBtnCancel}
                                >
                                    {textCancelBtn ? textCancelBtn : intl.formatMessage({ id: 'BTN.CANCEL' })}
                                </button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </FormikContext.Provider>
        </Modal>
    )
}
export { ModalFormikCustom }
