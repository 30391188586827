import axios from "axios";
import { GeneralSearchParamsType } from "../../utils/ParamsUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";


const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchAccount = (searchObject: GeneralSearchParamsType) => {
    const url = API_PATH + "/Account/search";
    return axios.get(url, { params: searchObject });
}

export const createAccount = (data: any) => {
    const url = API_PATH + "/Account";
    return axios.post(url, data);
}

export const updateAccount = (id: any, data: any) => {
    let url = API_PATH + "/Account/" + id;
    return axios.put(url, data);
}

export const deleteAccount = (id: any) => {
    let url = API_PATH + "/" + id;
    return axios.delete(url);
}