import { FC } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { Col } from './Grid';
import { useFormik } from 'formik';
import * as yup from "yup";
import Button from './button-custom';
import { heightAutocomplete } from './input-field/StyleComponent';
import SelectCustom from './select-custom';
import { getUsers } from '../program/services/ProgramService';
import TextValidator from './input-field/TextValidator';
import { AUTHORITIES_ABILITY } from '../../Constant';

interface Props {
    show: boolean;
    onSubmitForm: (values: any) => void;
    onCancelClick: () => void;
    permission: string,
    defaultValue?: string | null,
    defaultContent?: string | null,
}

const PopupSendRequest: FC<Props> = ({ show, onSubmitForm, onCancelClick, permission, defaultValue, defaultContent }) => {
    const formik = useFormik({
        initialValues: {
            content: defaultContent || "",
            userApprove: defaultValue ||"",
        },
        validationSchema: yup.object({
            content: yup.string().trim().required("Không được bỏ trống").nullable(),
            userApprove: yup.string().trim().required("Không được bỏ trống").nullable(),
        }),
        onSubmit: onSubmitForm,
    });

    return (
        <Modal
            show={show}
            centered
            animation
            className='custom-modal'
        >
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header className="bg-pri p-3">
                    <Modal.Title className="text-white fw-normal">Gửi yêu cầu phê duyệt</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center p-4">
                    <Row className='w-100'>
                        <Col xs="12">
                            <SelectCustom
                                required
                                label='Người phê duyệt'
                                menuPlacement="auto"
                                service={getUsers}
                                params={{ authorities: `5S.${permission}.${AUTHORITIES_ABILITY.APPROVE}` }}
                                styles={heightAutocomplete("32px")}
                                valueField={'username'}
                                displayField={'displayName'}
                                optionsResponse='data'
                                handleChange={(selectedOption) => formik.setFieldValue("userApprove", selectedOption)}
                                value={formik.values.userApprove}
                                errors={formik.errors.userApprove}
                                touched={formik.touched.userApprove}
                            />
                        </Col>
                        <Col xs="12">
                            <TextValidator
                                lable="Nội dung yêu cầu"
                                name="content"
                                type='text'
                                onChange={formik.handleChange}
                                isRequired
                                value={formik.values.content}
                                errors={formik.errors.content}
                                touched={formik.touched.content}
                                as="textarea"
                                rows={3}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-3 center">
                    <Button type='submit'> Gửi </Button>
                    <Button color='secondary' onClick={onCancelClick}> Hủy </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export { PopupSendRequest };
