import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { BG, SCHEDULE_PROGRESS } from '../constants/DashboardConst';
import "../styles/style.scss";
import { useNavigate } from 'react-router-dom';
import { TIME_TYPE } from '../../program/consts/InitialValue';
import CheckStatusEvaluate from './CheckStatusEvaluate';
import { STATUS_CODE_5S } from '../../../Constant';

type Props = {
    data: any[],
    title: string,
    timeType: string,
    updateData?: (timeType: string, progress: number) => void,
};

const OngoingProgramsBox = (props: Props) => {
    const navigate = useNavigate();
    const { data, title, timeType, updateData } = props;
    const isLargeBox = timeType === TIME_TYPE.QUARTER || timeType === TIME_TYPE.YEAR;
    const isEmergency = timeType === TIME_TYPE.EMERGENCY;
    const [activeButton, setActiveButton] = useState<any>({
        happening: true,
        upcoming: false,
    });
    let activeTypeBtn = {
        className: "mx-1 button-primary",
        variant: 'primary'
    };
    let normalTypeBtn = {
        className: "mx-1 button-primary-outline",
        variant: 'outline-primary'
    };
    const handleRedirect = (data: any) => {
        navigate(`/evaluate/${data?.id}`, {
            state: {
                proImplScheduleId: data?.id,
                areaId: data?.areaId,
                termFormId: data?.termFormId,
                areaName: data?.areaName,
                programId: data?.programId,
                termAreaId: data?.termAreaId,
                isFromDashboard: true
            }
        });
    };
    return (
        <div className={`spaces ${isLargeBox ? 'h-200' : 'h-310'} pt-10 bg-white rounded mb-20 programs-box scrollbar-width-thin`}>

            <Row className='mb-2 header-box px-0'>
                <Col xs="6">
                    <h3 className='mb-0'>{title}</h3>
                </Col>
                <Col xs="6" className='d-flex flex-end pe-0'>
                    <Button size='sm'
                        onClick={() => {
                            updateData && updateData(timeType, SCHEDULE_PROGRESS.HAPPENING);
                            setActiveButton({ happening: true, upcoming: false });
                        }}
                        {...(activeButton.happening ? activeTypeBtn : normalTypeBtn)}
                    >
                        {timeType === TIME_TYPE.DAY ? "Hôm nay" : "Đến hạn"}
                    </Button>
                    <Button size='sm'
                        onClick={() => {
                            updateData && updateData(timeType, SCHEDULE_PROGRESS.UPCOMING);
                            setActiveButton({ happening: false, upcoming: true });
                        }}
                        {...(activeButton.upcoming ? activeTypeBtn : normalTypeBtn)}
                    >
                        Sắp tới
                    </Button>
                </Col>
            </Row>
            <div className='spaces h-50'></div>
            {data.length 
            ? data?.map((item: any, index: number) => (
                <Row className='mb-4'>
                    <Col xs={isLargeBox ? 6 : 8}>
                        <div className='d-flex align-items-center'>
                            <div className='rounded spaces w-30 h-30 flex-center mt-2'
                                style={{ backgroundColor: BG[item.statusCode] || BG[STATUS_CODE_5S.CHUA_DANH_GIA] }}
                            >
                                {item.statusCode === STATUS_CODE_5S.DA_DANH_GIA ? item?.totalTermAnswerScore : "-"}
                            </div>
                            <div className='ms-3'>
                                <h5 className='mb-0 fw-normal cursor-pointer' onClick={() => handleRedirect(item)}>
                                    {item?.areaName || ""}
                                    {item.type === TIME_TYPE.EMERGENCY && <span className='color-red italic fw-bold ms-2'>(Đột xuất)</span>}
                                </h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={isLargeBox ? 6 : 4} className='d-flex flex-end align-items-center mt-2'>
                        {CheckStatusEvaluate(item?.statusCode, item?.statusName)}
                    </Col>
                    <Col xs={12}>
                        <div className='d-flex'>
                            <div className='ms-12'>
                                <div className='body-normal-2 text-muted d-flex'>
                                    <div className='spaces min-w-90 me-2'> Chịu trách nhiệm </div>
                                    <div className='text-primary fw-500'>| {item?.empOnusAreaName || ""}</div>
                                </div>
                                <div className='body-normal-2 text-muted d-flex'>
                                    <div className='spaces min-w-90 me-2'> Đánh giá </div>
                                    <div className='text-primary fw-500'>| {item?.userPerform || ""}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ))
                : <div className='body-normal-2 text-muted text-center mt-5'>Không có dữ liệu</div>}
        </div>
    )
}

export default OngoingProgramsBox;