import { ERROR_MESSAGE, ICriteria, ISetOfCriteriaBasicInfo, ITermAnswer, ItermFormResDto, TermAreaDTO } from "../models/SetOfCriteriaModels";
import moment from "moment";

const currentDate = moment(new Date()).format("DD/MM/YYYY").toString();

export const FAKE_TREE_DATA = [
{
  code: "0", name: "Khu vực hành chính văn phòng", filter: [
    {
      code: "01", name: "KV hành chính nhân sự"
    },
    {
      code: "02", name: "KV kinh doanh"
    },
    {
      code: "03", name: "KV kế hoạch và đào tạo"
    },
  ]
},
{
  code: "1", name: "Khu vực hành chính văn phòng", filter: [
    {
      code: "11", name: "KV hành chính nhân sự"
    },
    {
      code: "12", name: "KV kinh doanh"
    },
    {
      code: "13", name: "KV kế hoạch và đào tạo"
    },
  ]
}
];

export const FAKE_DATA_FORM_VIEW: any[] = [
  { targets: "I. Phân loại vật dụng", radioValue: "hide", isShowSubRow: false },
  { targets: "1. Tại nơi làm việc không có các vật dụng dư thừa", radioValue: "", isShowSubRow: false, hasSubRow: true },
  { targets: "2. Tại nơi làm việc, tất cả mọi người có thể dễ dàng nhận biết phần lối đi và vị trí làm việc", radioValue: "", isShowSubRow: false, hasSubRow: true },
  { targets: "3. Nếu có các vật lạ, mọi người đều biết lý do tại sao vật đó lại ở nơi làm việc", radioValue: "", isShowSubRow: false, hasSubRow: true },
  { targets: "4. Không có các thông báo hoặc tin tức lỗi thời trên tường hoặc các bảng thông báo", radioValue: "", isShowSubRow: false, hasSubRow: true },
  { targets: "II. Sắp xếp vật dụng", radioValue: "hide", isShowSubRow: false },
  { targets: "1. Phân loại và bỏ rác đúng nơi có dán nhãn (rác tái sử dụng, rác đổ bỏ, các loại rác khác)", radioValue: "", isShowSubRow: false, hasSubRow: true },
]

export const INIT_CRITERIA_DATA: ICriteria = {
  shortName: "",
  name: "",
  description: "",
}

export const EXPLAIN_LIST = [
  { name: "Chưa đạt", code: "1" },
  { name: "Đạt", code: "2" },
];

export const MAX_LENGTH_ARRAY_CRITERIA_LADDER = 6;



export const MESSAGE_RESPONSE: ERROR_MESSAGE = {
  'errors.term_empty': 'Nhập danh sách tiêu chí',
  'errors.term_child_empty': 'Nhập đầy đủ tiêu chí cho danh mục',
  'default': 'Đã có lỗi hệ thống'
};


export const initialBasicInfoValues: ISetOfCriteriaBasicInfo = {
  id: null,
  termFormReqDto: {
    code: null,
    name: null,
    issuedDate: currentDate,
    note: null,
    userApprove: ''
  },
  lstTermAnswerDto: [
    {
      answerNumeric: 0,
      answerText: "",
    },
    {
      answerNumeric: 0,
      answerText: "",
    },
    {
      answerNumeric: 0,
      answerText: "",
    },
    {
      answerNumeric: 0,
      answerText: "",
    },
    {
      answerNumeric: 0,
      answerText: "",
    },
  ],
};


export const initialValues: TermAreaDTO = {
  termAreaDTO: {
    termId: 0,
    areaId: null,
    hasUpdateTerm: false,
  },
  lstScoreScale: [],
  lstTerm: [],
};

export const initTermAnswer: ITermAnswer = {
  answerNumeric: 0,
  answerText: "",
  sortWeight: ""
}

export const initLstTermAnswerDto: ITermAnswer[] = [
  {...initTermAnswer}
]

export const initTermFormReqDto: ItermFormResDto = {
  id: null,
  code: null,
  name: null,
  issuedDate: currentDate,
  note: null,
  userApprove: '',
  status: null,
  statusName: null,
  userCreate: null,
  textRefuse: null,
  empApproved: 0,
  orgId: null,
  stageRequestId: null,
  areaRootCode: null
}

