import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Col } from '../../component/Grid';
import InputDateCustom from '../../component/input-field/InputDateCustom';
import { heightAutocomplete } from '../../component/input-field/StyleComponent';
import TextValidator from '../../component/input-field/TextValidator';
import TableCustom from '../../component/table-custom/TableCustom';
import { CREATE_PARAMETER, StageSignature } from '../../constant';
import { ColumnAssignEvaluation } from '../consts/Columns';
import { INIT_FORM_DATA, LIST_PLAN_TYPE, TIME_TYPE } from '../consts/InitialValue';
import { iProgram } from '../models/ProgramModel';
import { copyProgram, createProgram, getProgramById, getUsers, getUsersIs5s, updateProgram } from '../services/ProgramService';
import { fetchCriteriasByUserCurrent } from '../../set-of-criteria/services/SetOfCriteriaServices';
import { useLoading } from '../../../AppContext';
import SelectCustom from '../../component/select-custom';
import { formatId, handleError, handleGetListUser, hasAuthority } from '../../utils/FunctionUtils';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import FloatingNotification from '../../component/FloatingNotification';
import GroupButton from './GroupButton';
import { PROGRAM_BUTTON } from '../config/ConfigButton';
import { DISABLE_STATUS } from '../config/ConfigField';
import { TABLE_STATUS } from '../config/ConfigTableAssignee';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, STATUS_CODE_5S } from '../../../Constant';
import ProgramInfoTab from './ProgramInfoTab';
import HistoryRequest from '../../component/History-request/HistoryRequest';
import ProgramAreaDialog from './ProgramAreaDialog';
import CustomIconButton from '../../component/CustomIconButton';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { toast } from 'react-toastify';
import { SearchBase } from '../../utils/InterfaceUtils';

type Props = {}

const ProgramForm = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setPageLoading } = useLoading();
  const { lang } = useMultiLanguage();

  const [openHistoryRequest, setOpenHistoryRequest] = useState<boolean>(false);
  const [programId, setProgramId] = useState<string | null | undefined>(id);
  const [openProgramArea, setOpenProgramArea] = useState<boolean>(false);
  const [areaDetail, setAreaDetail] = useState<any>({});
  const [lstUserAction, setLstUserAction] = useState<any[]>([]);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(id === CREATE_PARAMETER);
  const [programImplDtoSave, setProgramImplDtoSave] = useState<any[]>([]);
  const [evaluationTeamMembers, setEvaluationTeamMembers] = useState<any[]>([]);
  const [userNameEvaluationTeamMembers, setUserNameEvaluationTeamMembers] = useState<any[]>([]);
  const [listUsersIs5s, setListUsersIs5s] = useState<any[]>([]);
  const [openConfirmCopyDialog, setOpenConfirmCopyDialog] = useState<boolean>(false);
  const isCreate = programId === CREATE_PARAMETER;
  const [searchObject, setSearchObject] = useState<SearchBase>({  pageIndex: 1, pageSize: 1000 });
  
  const validationSchema = yup.object({
    code: yup.string().required("Trường này là bắt buộc").matches(/^[^\W_]+$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    name: yup.string().trim().required("Trường này là bắt buộc").matches(/^[^`~!@#$%^*]*$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    type: yup.string().nullable().required("Trường này là bắt buộc"),
    termFormId: yup.string().nullable().required("Trường này là bắt buộc"),
    empLeaderId: yup.string().nullable().required("Trường này là bắt buộc"),
    estimatedStartDate: yup.string().nullable().required("Trường này là bắt buộc"),
    estimatedEndDate: yup.string().nullable().required("Trường này là bắt buộc"),
  })

  useEffect(() => {
    getListUsersIs5s();
    setProgramId(id);
  }, [id]);

  useEffect(() => {
    init();
  }, [programId]);

  const init = async () => {
    await handleGetListUser(`5S.${"EVALUATE"}.${AUTHORITIES_ABILITY.ACTION}, 5S.${"EVALUATE"}.${AUTHORITIES_ABILITY.EMPLOYEES_BY_REGION}`, setLstUserAction);
    !isCreateNew && await fetchProgram();
  };

  const handleFormSubmit = async (formData: iProgram) => {
    try {
      setPageLoading(true);
      const _programImplDto = formData.programImplDto?.map((item: any) => ({ ...item }));
      const submitData = {
        ...formData,
        action: "SAVE",
        programImplDto: _programImplDto,
        evaluationTeamMembers: JSON.stringify(evaluationTeamMembers),
      };
      const response = isCreate ?
        (isCreateNew ? await createProgram(submitData) : await updateProgram(programId, submitData)) :
        await updateProgram(programId, submitData);

      if (isCreate && isCreateNew) {
        setIsCreateNew(false);
      }
      
      // const response = isCreate ? await createProgram(submitData) : await updateProgram(programId, submitData);
      setProgramId(response.data.data.id);
      setPageLoading(false)

      if (submitData.callback) {
        return submitData.callback();
      }
    } catch (e) {
      handleError(e, formik.setFieldError)
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_FORM_DATA,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const buttons = isCreateNew ? PROGRAM_BUTTON[StageSignature.INITIALIZATION] : PROGRAM_BUTTON[formik.values.stageCode || StageSignature.UNKNOWN];
  const fieldDisabledStage = isCreateNew ? DISABLE_STATUS[StageSignature.INITIALIZATION] : DISABLE_STATUS[formik.values.stageCode];
  const tableStatus = isCreateNew ? TABLE_STATUS[StageSignature.INITIALIZATION] : TABLE_STATUS[formik.values.stageCode];
  const checkAssessment = formik?.values?.statusCode === STATUS_CODE_5S.CHO_PHE_DUYET;

  const fetchProgram = async () => {
    try {
      setPageLoading(true);
      const { data } = await getProgramById(programId);
      setProgramImplDtoSave(data?.data?.programImplDto);
      formik.setValues({ ...INIT_FORM_DATA, ...data.data });
      const listTeamMembers = data?.data?.evaluationTeamMembers ? JSON.parse(data?.data?.evaluationTeamMembers) : [];
      if (listTeamMembers.length) {
        setEvaluationTeamMembers(listTeamMembers);
        setUserNameEvaluationTeamMembers(listTeamMembers.map((item: any) => item?.username));
        setLstUserAction(listTeamMembers.map((item: any) => {
          return {
            ...item,
            displayName: `${item?.fullName || ""} (${item?.username})`,
            empId: item?.id,
          }
        }));
      };
    } catch (e: any) {
      handleError(e)
    } finally {
      setPageLoading(false);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleShowAreas = (row: any) => {
    setOpenProgramArea(true);
    setAreaDetail(row);
  }

  const getListUsersIs5s = async () => {
    const { data } = await getUsersIs5s({});
    setListUsersIs5s(data?.data.map((item: any) => {
      return {
        ...item,
        displayName: `${item?.fullName} (${item.username})`
      }
    }));
  };

  const handleCopyTermForm = async () => {
    try {
      const { data } = await copyProgram(programId);
      formik.setValues({ ...INIT_FORM_DATA,...data?.data });
      toast.success("Sao chép thành công");
      setIsCreateNew(true);
      setProgramId("new");
      setOpenConfirmCopyDialog(false);
    } catch (error) {
      toast.error(String(error))
    }
  };

  const handleCheckStatusCode = () => {
    if ([STATUS_CODE_5S.HIEN_HANH, STATUS_CODE_5S.KET_THUC].includes(formik?.values?.statusCode)) {
      return true;
    };
    return false;
  };

  return (
    <div className='wrapper-content info-box'>
      {!isCreate &&
        <>
          <CustomIconButton
            tooltip="Sao chép"
            className="bi bi-copy copy-icon"
            onClick={() => setOpenConfirmCopyDialog(true)}
            visible={(fieldDisabledStage?.viewForm && hasAuthority(AUTHORITIES_PERMISSIONS.PROGRAM, AUTHORITIES_ABILITY.SECRETARY) || false) && handleCheckStatusCode()}
          />
          <CustomIconButton
            tooltip="Lịch sử yêu cầu"
            className="bi bi-clock-history history-icon"
            onClick={() => setOpenHistoryRequest(true)}
          />
        </>
      }

      
      <Row>
        <Col xs="12" className='mt-5 mb-6 d-flex gap-3 align-items-center justify-content-between'>
          <h3 className='spaces m-0 text-uppercase color-primary'>Chương trình đánh giá 5S</h3>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        {fieldDisabledStage?.viewForm ?
          <div className='spaces px-6'>
            <ProgramInfoTab dataForm={formik?.values} />
          </div>
          :
          <Row className='px-2'>
            <Col sm="2" md="3" lg="2" xl="3" xxl="2" xs="12">
              <TextValidator
                lable="ID"
                name="id"
                type="text"
                value={formik.values?.id ? formatId(Number(formik.values?.id)) : ''}
                onChange={formik.handleChange}
                disabled={fieldDisabledStage?.id}
                className="flex-grow-1"
              />
            </Col>
            <Col sm="2" md="3" lg="2" xl="3" xxl="2" xs="12">
              <TextValidator
                lable="Mã chương trình"
                name="code"
                type="text"
                value={formik.values?.code || ""}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.code}
                touched={formik.touched?.code}
                disabled={fieldDisabledStage?.code}
              />
            </Col>
            <Col sm="6" md="6" lg="5" xl="6" xxl="5" xs="12">
              <TextValidator
                lable="Tên chương trình"
                name="name"
                type="text"
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.name}
                touched={formik.touched?.name}
                disabled={fieldDisabledStage?.name}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="6" xxl="3" xs="12">
              <SelectCustom
                className="spaces width-100"
                label={"Loại kế hoạch"}
                name="type"
                valueField={'code'}
                displayField={'name'}
                options={LIST_PLAN_TYPE}
                styles={heightAutocomplete("32px")}
                value={formik.values?.type || null}
                handleChange={(value, _option, _actionMeta, name) => {
                  formik.setFieldValue(name, value);
                }}
                required
                errors={formik.errors?.type}
                touched={formik.touched?.type}
                isDisabled={fieldDisabledStage?.type}
              />
            </Col>

            <Col sm="2" md="3" lg="2" xl="3" xxl="2" xs="12">
              <InputDateCustom
                label="Dự kiến bắt đầu"
                value={formik.values?.estimatedStartDate || ""}
                setValue={(value: string) => formik.setFieldValue("estimatedStartDate", value)}
                errors={formik.errors?.estimatedStartDate}
                touched={formik.touched?.estimatedStartDate}
                disabled={fieldDisabledStage?.estimatedStartDate}
                isRequired
              />
            </Col>
            <Col sm="2" md="3" lg="2" xl="3" xxl="2" xs="12">
              <InputDateCustom
                label="Dự kiến kết thúc"
                value={formik.values?.estimatedEndDate || ""}
                setValue={(value: string) => formik.setFieldValue("estimatedEndDate", value)}
                errors={formik.errors?.estimatedEndDate}
                touched={formik.touched?.estimatedEndDate}
                disabled={fieldDisabledStage?.estimatedEndDate}
                isRequired
              />
            </Col>
            <Col sm="6" md="6" lg="5" xl="6" xxl="5" xs={12}>
              <SelectCustom
                className="spaces width-100"
                label={"Tiêu chí"}
                name="termFormId"
                styles={heightAutocomplete("32px")}
                value={formik.values?.termFormId || null}
                handleChange={(value, _option: any, _actionMeta, name) => {
                  formik.setFieldValue("termFormName", _option?.name);
                  formik.setFieldValue(name, value);
                }}
                required
                errors={formik.errors?.termFormId}
                touched={formik.touched?.termFormId}
                service={fetchCriteriasByUserCurrent}
                params={{ programId: programId !== CREATE_PARAMETER ? programId : null }}
                optionsResponse='data'
                valueField={'id'}
                displayField={'name'}
                isDisabled={fieldDisabledStage?.termFormId}
              />
            </Col>
            <Col sm="4" md="6" lg="3" xl="6" xxl="3" xs="12">
              <SelectCustom
                className="spaces width-100"
                label={"Trưởng đoàn đánh giá"}
                name="empLeaderId"
                service={getUsers}
                params={{ authorities: `5S.${AUTHORITIES_PERMISSIONS.PROGRAM}.${AUTHORITIES_ABILITY.LEADER}` }}
                styles={heightAutocomplete("32px")}
                value={formik.values?.empLeaderId || null}
                handleChange={(value, _option: any, _actionMeta, name) => {
                  formik.setFieldValue('empLeaderName', _option.empName);
                  formik.setFieldValue('userLeader', _option.username);
                  formik.setFieldValue(name, value);
                }}
                valueField={'empId'}
                displayField={'displayName'}
                optionsResponse='data'
                required
                errors={formik.errors?.empLeaderId}
                touched={formik.touched?.empLeaderId}
                isDisabled={fieldDisabledStage?.empLeaderId}
              />
            </Col>
            <Col sm="12" md="12" lg="12" xl="12" xxl="12" xs="12">
              <SelectCustom
                className="spaces width-100"
                label={"Thành viên đánh giá"}
                name="listTeamMembers"
                options={listUsersIs5s}
                styles={heightAutocomplete("32px")}
                value={userNameEvaluationTeamMembers || null}
                handleChange={(value, _option: any, _actionMeta, name) => {
                  setUserNameEvaluationTeamMembers([...(value as any[])]);
                  setEvaluationTeamMembers([...(_option as any[])]);
                }}
                isMulti={true}
                valueField={'username'}
                displayField={'displayName'}
                optionsResponse='data'
                isDisabled={fieldDisabledStage?.empLeaderId}
              />
            </Col>
            <Col sm="8" md="12" lg="12" xl="12" xxl="12" xs="12">
              <TextValidator
                lable="Ghi chú"
                name="note"
                type="text"
                value={formik.values?.note || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.note}
                touched={formik.touched?.note}
                disabled={fieldDisabledStage?.note}
              />
            </Col>
          </Row>
        }
        {tableStatus?.display && !checkAssessment &&
          <Row>
            <Col xs="12" lg="12" xl="12" className='mb-3 mt-3'>
              <span className={`text-lable-input lable`}>
                Phân công cán bộ đánh giá
              </span>
              <TableCustom
                id="profile-1"
                height={"calc(100vh - 450px)"}
                data={formik.values?.programImplDto || []}
                columns={ColumnAssignEvaluation({ formik: formik, disable: tableStatus?.disable, handleShowAreas, lstUserAction })}
                buttonAdd={false}
                buttonExportExcel={false}
                notDelete={false}
                justFilter={true}
                updatePageData={() => { }}
                objectSearch={searchObject}
                noPagination
              />
            </Col>
          </Row>
        }
        <Row className='justify-content-center mt-10'>
          <Col xs="12" className='mb-3 flex justify-content-center'>
            <GroupButton
              handleClose={handleClose}
              lstButton={buttons}
              setActionSubmit={(action) => formik.setFieldValue("action", action)}
              setCallback={(callback) => formik.setFieldValue("callback", callback)}
              reload={fetchProgram}
              handleSubmit={() => formik.submitForm()}
              programId={programId as string}
              defaultValue={formik.values?.userApprove || ""}
              formData={formik.values}
            />
          </Col>
        </Row>
      </Form>
      {(formik.values.statusCode === STATUS_CODE_5S.YEU_CAU_SUA_DOI) &&
        <FloatingNotification
          content={formik.values.textRefuse}
        />
      }
      {openHistoryRequest &&
        <HistoryRequest
          handleClose={() => setOpenHistoryRequest(false)}
          params={{ programId: programId as string }}
        />
      }
      {
        openProgramArea && 
        <ProgramAreaDialog
          show={openProgramArea}
          onClose={() => setOpenProgramArea(false)}
          areaDetail={areaDetail}
        />
      }
      {openConfirmCopyDialog && (
        <ConfirmDialog
          show={openConfirmCopyDialog}
          title="Xác nhận sao chép"
          message={`Bạn có chắc chắn muốn sao chép chương trình này?`}
          yes="Xác nhận"
          cancel="Hủy"
          onYesClick={handleCopyTermForm}
          onCancelClick={() => setOpenConfirmCopyDialog(false)}
        />
      )}
    </div>
  )
}

export default ProgramForm;