import axios from "axios";
import { ISearchParamsApproval } from "../models/ApprovalModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
const API_APPROVAL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] + "/stage-request";

export const fetchApproveds = (params: ISearchParamsApproval) => {
    const url = API_APPROVAL + "/search-by-approved";
    return axios.get(url, { params });
}

export const fetchPendingApprovals = (params: ISearchParamsApproval) => {
    const url = API_APPROVAL + "/search-by-pending-approve";
    return axios.get(url, { params });
}