import React, { useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { ChartsWidget1, ChartsWidget2, ChartsWidget3, ChartsWidget4, ChartsWidget5, ChartsWidget6, ChartsWidget7, ChartsWidget8, EngageWidget1, FeedsWidget2, ListsWidget1, MixedWidget1, MixedWidget2, StatisticsWidget1 } from '../../../../_metronic/partials/widgets';
import ReactApexChart from 'react-apexcharts';

interface EvaluateAnalysisDialogProps {
    show: boolean;
    onClose: () => void;
    dataAnalysis?: any;
    scoreScale?: any;
    listTermAnswer?: any;
}

const EvaluateAnalysisDialog: React.FC<EvaluateAnalysisDialogProps> = ({
    show,
    onClose,
    dataAnalysis,
    scoreScale,
    listTermAnswer
}) => {
    const [totalImage, setTotalImage] = React.useState<any>({});
    const [options, setOptions] = React.useState<any>({
        labels: [""],
        legend: {
            show: false
        },
    });
    const [series, setSeries] = React.useState<any>([]); 

    useEffect(() => {
        handeleCaculator();
    }, [])
    
    const handeleCaculator = () => {
        const keys = Object.keys(scoreScale);
        const lastKey = keys[keys.length - 1];
        const lastValue = scoreScale[lastKey];

        let imageBadCount = 0;
        let imageGoodCount = 0;

        const dataKeys = new Set(listTermAnswer?.map((item: any) => item?.answerNumeric));

        const scoreCounts: any = {};

        dataAnalysis?.programImplResultList?.forEach((item: any) => {
            if (item?.imageBad !== null) {
                imageBadCount++;
            }
            if (item?.imageGood !== null) {
                imageGoodCount++;
            }
            if (dataKeys.has(item?.termAnswerScore)) {
                const score = item.termAnswerScore.toString();
                scoreCounts[score] = (scoreCounts[score] || 0) + 1;
            }
        });

        setSeries(Object.values(scoreCounts));

        setTotalImage({
            imageBad: imageBadCount,
            imageGood: imageGoodCount,
            total: lastValue?.scoreTo
        })

        const result = listTermAnswer.filter((item: { answerNumeric: { toString: () => any; }; }) => scoreCounts.hasOwnProperty(item.answerNumeric.toString()));

        setOptions({
            ...options,
            labels: result.map((item: any) => item.answerText || "")
        })
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            animation
            className="custom-modal category-dialog"
            size="lg"
        >
            <Modal.Header closeButton className='spaces py-15'>
                <Modal.Title>
                    <span className='spaces m-0 text-uppercase color-primary'>Phân tích đánh giá</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='spaces pt-0'>
                <div className='d-flex flex-column gap-12 spaces px-0 lh-xl'>
                    <Row className='pt-5'>
                        <Col xs={6}>
                            <Row>
                                <Col xs={6}>
                                    <div className='text-center border p-3 pt-5 shadow-sm bg-white rounded h-100'>
                                        <i className="bi bi-check-circle-fill text-success fs-2"></i>
                                        <p className='text-success fs-5 m-0'>Ảnh thực hiện tốt</p>
                                        <p className='m-0 fs-1 text-dark fw-bold'>{totalImage?.imageGood || 0}</p>
                                        <p className='m-0 fs-5'>Hình ảnh</p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='text-center border p-3 pt-5 shadow-sm bg-white rounded h-100'>
                                        <i className="fa-solid fa-circle-xmark text-danger fs-2"></i>
                                        <p className='text-danger fs-5 m-0'>Ảnh cần cải tiến</p>
                                        <p className='m-0 fs-1 text-dark fw-bold'>{totalImage?.imageBad || 0}</p>
                                        <p className='m-0 fs-5'>Hình ảnh</p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='text-center border p-3 pt-5 shadow-sm bg-white rounded h-100 mt-3'>
                                        <i className="fa-solid fa-flag text-primary fs-2"></i>
                                        <p className='text-primary fs-5 m-0'>Điểm hiện tại</p>
                                        <p className='m-0 fs-1 text-dark fw-bold'>{dataAnalysis?.totalTermAnswerScore || 0}/{totalImage?.total}</p>
                                        <p className='m-0 fs-5'>Điểm</p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='text-center border p-3 pt-5 shadow-sm bg-white rounded h-100 mt-3'>
                                        <i className="fa-solid fa-file-lines text-info fs-2"></i>
                                        <p className='text-info fs-5 m-0'>Diễn giải</p>
                                        <p className='m-0 fs-1 text-dark fw-bold'>{dataAnalysis?.resultText || ""}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <ReactApexChart
                                type="pie"
                                options={options}
                                series={series}
                                height={320}
                            />
                            <p className='text-center fst-italic'>Biểu đồ đánh giá chỉ tiêu</p>
                        </Col>
                    </Row>
                    <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
                        <Button
                            onClick={onClose}
                            className="spaces button-primary-outline py-4 fs-12 h-30"
                        >
                            Đóng
                        </Button>
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EvaluateAnalysisDialog;