import CheckStatusEvaluate from "../component/CheckStatusEvaluate";
import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import { CONFIG_TABLE_BUTTON } from "../config/ConfigTableAction";
import { text } from "stream/consumers";

export const ColumnsRepeairDetail = (props: any) => {
  const { handleViewRepeair, handleDeleteRepeair, handleEditRepeair, handleShowInfoUser } = props;
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "60px",
        maxWidth: "60px",
        textAlign: "center"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "127px",
        maxWidth: "127px",
      },
      render: (row: any) => (
        <GroupTableButton 
          elementAbility={CONFIG_TABLE_BUTTON[row.statusCode]}
          handleEdit={() => handleEditRepeair(row)}
          isIconEditOrAccess={false}
        />
      ),
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "170px",
      },
      cellStyle: {
        textAlign: "center",
      },
      bodyClasses: "justify-center",
    },
    {
      name: "Khu vực đánh giá",
      field: "areaName",
      headerStyle: {
        minWidth: "200px",
        maxWidth: "350px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: "Tên chương trình",
      field: "programName",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: "Người chịu trách nhiệm",
      field: "empOnusAreaName",
      headerStyle: {
        minWidth: "140px",
        maxWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span className="hyperlink"
          onClick={() => handleShowInfoUser && handleShowInfoUser(row?.empOnusArea)}
        >{row?.empOnusAreaName}</span>
      )
    },
    {
      name: "Người đánh giá",
      field: "userPerform",
      headerStyle: {
        minWidth: "140px",
        maxWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span className="hyperlink"
          onClick={() => handleShowInfoUser && handleShowInfoUser(row?.empPerformId)}
        >{row?.userPerform}</span>
      )
    },
    {
      name: "Điểm đánh giá",
      field: "programName",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span>{row?.totalTermAnswerScore || ""} {row?.resultText ? `(${row?.resultText})` : ""}</span>
      )
    },
    {
      name: "Tổng không phù hợp",
      field: "programName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span title="Tiêu chí đã khắc phục / Tiêu chí cần khắc phục">{`${(row.numberOfCriteriaResolved || row.numberCriteriaToResolve) ? `${(row.numberOfCriteriaResolved || 0)}/${(row.numberCriteriaToResolve || 0)}` : ""}`}</span>
      )
    },
  ];
};

export const EVALUATE_STATUS = {
  NOT_EVALUATED: "006", // Chưa đánh giá
  EVALUATED: "007", // Đã đánh giá
  UNDER_EVALUATION: "010", // Đang đánh giá
}
