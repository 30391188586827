import React, { useEffect, useState } from 'react';
import { Card, Form, FormLabel } from 'react-bootstrap';

interface TreeItemProps {
    label: string;
    node: any;
    children?: React.ReactNode;
    isSelected?: boolean;
    isView?: boolean;
    isAlwayOpen?: boolean;
    childrenSelected: string | number;
    handleSelect?: (code: string) => void;
}

interface TreeNode {
    code: string;
    label: string;
    children?: TreeNode[];
    isSelected?: boolean;
}

interface Node {
    id: null | string;
    termId: null | string;
    areaId: number | string;
    nameArea: string;
    termFormId: null | string;
    employeeId: null | string;
    code: number | string;
    label: string;
    name: string;
}

interface TreeViewProps {
    data: TreeNode[];
    isView?: boolean;
    isAlwayOpen?: boolean;
    defaultSelected?: Node;
    handleChangeSelection?: (node: any) => void;
}

const TreeItem: React.FC<TreeItemProps> = ({ 
    label, 
    children, 
    isSelected, 
    isView = false, 
    isAlwayOpen = false,
    node,
    handleSelect,
    childrenSelected 
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const convertString = (inputString: string): string => {
        // Loại bỏ dấu '/' ở đầu chuỗi nếu có
        if (inputString.charAt(0) === '/') {
            inputString = inputString.substring(1);
        }

        // Tách chuỗi thành mảng các phần tử
        const parts = inputString.split('/');

        // Chuyển đổi mỗi phần tử thành chuỗi mong muốn
        const convertedString = parts.map(part => part.replace(':', '&#x276F;')).join(' > ');

        return convertedString;
    };

    return (
        <Card>
            <Card.Header
                onClick={() => {
                    !children && handleSelect?.(node);
                    !isAlwayOpen && handleToggle();
                }} 
                className={`spaces min-h-40 d-block ${childrenSelected === node?.code && "bg-light"} ${children && "has-children"}`}
            >
                {(!children && !isView) ? <Form.Check
                    type="radio"
                    name="tree-node-radio"
                    checked={isSelected}
                /> : (!isAlwayOpen) && <>
                        {isOpen 
                            ? (<i className="bi bi-caret-down-fill text-black"></i>)
                            : (<i className="bi bi-caret-right-fill text-black"></i>)}
                </>}
                <FormLabel className='spaces mt-5 ms-2 width-100'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>

                            {label}
                            {/* {node?.path &&
                                <span className="note-item-tree">
                                    {convertString(node.path)}
                                </span>} */}
                        </div>
                        {node?.id && <i className="text-green bi bi-check-circle-fill"></i>}
                    </div>
                </FormLabel>
            </Card.Header>
            {isOpen && (
                <Card.Body className='py-0'>{children}</Card.Body>
            )}
        </Card>
    );
};

const TreeView: React.FC<TreeViewProps> = ({
    data,
    isView,
    isAlwayOpen = false,
    defaultSelected = { code: '' },
    handleChangeSelection = () => {}
}) => {
    const [childrenSelected, setChildrenSelected] = useState<number | string>(defaultSelected.code);

    useEffect(() => {
        setChildrenSelected(defaultSelected.code);
        defaultSelected.code && handleChangeSelection(defaultSelected);
    }, [defaultSelected.code])

    const handleChange = (node: any) => {
        setChildrenSelected(node?.code);
        handleChangeSelection(node);
    }

    const renderTree = (nodes: TreeNode[] | undefined): React.ReactNode[] => {
        return (nodes?.map((node) => {
            if (node.label === null) {
                return node.children && renderTree(node.children);
            }
            
            return (
                <TreeItem 
                    key={node.code} 
                    label={node.label} 
                    isSelected={node?.isSelected} 
                    isView={isView} 
                    isAlwayOpen={isAlwayOpen}
                    node={node}
                    childrenSelected={childrenSelected}
                    handleSelect={(node) => handleChange(node)}
                >
                    {node.children && renderTree(node.children)}
                </TreeItem>
            );
        }) || []).filter(Boolean); 
    };


    return (
        <div>
            {renderTree(data)}
        </div>
    );
};

export default TreeView;