import axios from "axios";
import { ISetOfCriteriaBasicInfo, TermAreaDTO } from "../models/SetOfCriteriaModels";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchCriteria = (searchObject:any) => {
  const url = `${API_PATH}/term-forms/search`;
  return axios.get(url, { params: searchObject });
};

export const getAllCriteria = (searchObject: any) => {
  const url = `${API_PATH}/term-forms/all`;
  return axios.get(url, { params: searchObject });
};

export const fetchCriteriasByUserCurrent = (searchObject: any) => {
  const url = `${API_PATH}/term-forms/all/current-user`;
  return axios.get(url, { params: searchObject });
};

export const addTermForm = (form: ISetOfCriteriaBasicInfo) => {
    const url = `${API_PATH}/term-forms`;
    return axios.post(url, form);
};

export const updateTermForm = (id: any, form: ISetOfCriteriaBasicInfo) => {
    const url = `${API_PATH}/term-forms/${id}`;
    return axios.put(url, form);
};

export const deleteTermForm = (id: any) => {
  const url = `${API_PATH}/term-forms/${id}`;
  return axios.delete(url);
};

export const fetchDataTermForm = (criteriaId: number) => {
  const url = `${API_PATH}/term-forms/${criteriaId}`;
  return axios.get(url);
};

export const getAreaListByTermForm = (termId: number | string) => {
  const url = `${API_PATH}/term-area/get-areas/${termId}`;
  return axios.get(url);
}

export const getAreaListChildrenByTermForm = (id: number | null, rootCode: string | null) => {
  const url = `${API_PATH}/term-area/get-areas`;
  return axios.get(url, { params: { id, rootCode } });
}

export const getCriteriaForDepartment = (departmentId: number | string, isCopyRight: "true" | "false") => {
  const url = `${API_PATH}/term-area/get-terms-by-term-area/${isCopyRight}/${departmentId}`;
  return axios.get(url);
}

export const addCriteriaForArea = (criteria: TermAreaDTO) => {
  const url = `${API_PATH}/term-area`;
  return axios.post(url, criteria);
};

export const updateCriteriaForArea = (id: any, criteria: TermAreaDTO) => {
  const url = `${API_PATH}/term-area/${id}`;
  return axios.put(url, criteria);
};

export const sendRequest = (data:any) => {
    const url = `${API_PATH}/term-forms/update-stage-send-request`;
    return axios.put(url, data);
};

export const sendRequestEdit = (data: any) => {
    const url = `${API_PATH}/term-forms/update-stage-edit-request`;
    return axios.put(url, data);
};

export const approvalRequest = (data: any) => {
    const url = `${API_PATH}/term-forms/update-approve-request`;
    return axios.put(url, data);
};

export const copyTermForm = (id: number) => {
  const url = `${API_PATH}/term-forms/copy/${id}`;
  return axios.post(url);
};
