import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { checkIsActive, KTSVG } from '../../../../helpers'
import { useIntl } from 'react-intl'
import "../style.scss";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  onClick?: () => void
  iconClass?: string
  subMenu?: Props[]
}

const MenuItem: FC<Props> = ({ to, title, icon, fontIcon, hasArrow = false, hasBullet = false, onClick, iconClass, subMenu }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!subMenu?.length) {
      onClick && onClick();
    }
  };

  const renderMenu = () => (
    <>
      {hasBullet && (
        <span className='menu-bullet'>
          <span className='bullet bullet-dot'></span>
        </span>
      )}

      {icon && (
        <span className='menu-icon'>
          <KTSVG path={icon} className={`svg-icon-2 ${iconClass}`} />
        </span>
      )}

      {fontIcon && (
        <span className='menu-icon'>
          <i className={clsx('bi fs-3', fontIcon)}></i>
        </span>
      )}

      <span className={`menu-title`}>{title}</span>

      {hasArrow && <span className='menu-arrow'></span>}
    </>
  );

  return (
    <div className='menu-item' onClick={handleClick}>
      {subMenu?.length ?
        <>
          <button className={`btn  ${subMenu?.length ? "dropdown-toggle" : ""} text-light` } type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span className={clsx({
              "btn-menu-active": checkIsActive(pathname, to),
            })}>{title}</span>
          </button>
          {subMenu?.length > 0 &&
            <ul className="dropdown-menu">
              {
                subMenu?.map(item => {
                  return <li onClick={() => navigate(item?.to)}>
                    <a className="dropdown-item" href="#">{item?.title}</a></li>
                })
              }
            </ul>
          }
        </>
        :
        <Link
          className={clsx('menu-link', {
            'active menu-here': checkIsActive(pathname, to),
          })}
          to={to}
        >
          {renderMenu()}
        </Link>
      }
    </div>
  )
}

export { MenuItem }
