import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { iSearchProgramParams } from "../../program/models/ProgramModel";
import { STATUS_CODE_5S } from "../../../Constant";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

export type IStatisticalParams = {
  type?: number;
  year?: string;
}

const API_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const getAllDataStatistical = (): Promise<AxiosResponse<any>> => {
  let currentYear = moment().year();
  let url = `${API_URL}/thong-ke?nam=${currentYear}`;
  return axios.get(url);
};

export const getItemDataStatistical = ({type, year}: IStatisticalParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/thong-ke/get-by-type?type=${type}&nam=${year}`;
  return axios.get(url);
};

export const getDataUnratedProgramStatistics = (searchObject: iSearchProgramParams) => {
  const url = API_URL + "/program-impl-schedules/search/expired-evaluate";
  return axios.get(url, { params: searchObject });
};

export const getDataRegionalScoreStatistics = (programId: string | number) => {
  const url = API_URL + "/program-impl-schedules/area-and-total-score";
  return axios.get(url, { params: { programId: programId } });
};

export const getListDataProgram = () => {
  const url = API_URL + "/programs/by-status";
  return axios.get(url, { params: {statusCodes: `${STATUS_CODE_5S.HIEN_HANH}, ${STATUS_CODE_5S.KET_THUC}`} });
};