import React, { useEffect, useState } from 'react'
import TableCustom from '../table-custom/TableCustom'
import { HistoryRequestColumn } from './HistoryRequestColumn'
import { handleError } from '../../utils/FunctionUtils'
import { getRequestLogs } from '../../services'
import { Col, Modal, Row } from 'react-bootstrap'

type Props = {
    handleClose: () => void
    params: { programId: string } | { termFormId: string } | { areaId: string } | { programImplScheduleId: string }
}

export default function HistoryRequest({ handleClose, params }: Props) {
    const [tableData, setTableData] = useState<any[]>([]);

    const handleGetDataRequest = async (searchObject: any) => {
        try {
            const res = await getRequestLogs({
                ...searchObject,
                ...params,
                pageSize: 100
            })
            setTableData(res?.data?.data?.content)
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            <Modal
                onHide={handleClose}
                show
                centered
                animation
                size='xl'
            >
                <Modal.Header closeButton>
                    <h3>Lịch sử yêu cầu</h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="12">
                            <TableCustom
                                id="historyRequest"
                                height={"400px"}
                                data={tableData}
                                columns={HistoryRequestColumn({})}
                                buttonAdd={false}
                                buttonExportExcel={false}
                                notDelete={false}
                                fixedColumnsCount={0}
                                justFilter={true}
                                updatePageData={handleGetDataRequest}
                                noPagination={true}
                            />

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-3 center ">
                    <button className="button-primary-outline" onClick={handleClose}>
                        Đóng
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}