export const FAKE_DATA_PROGRAM = [
    {
        code: "001",
        name: "Program 1",
        startDate: "29-01-2024",
        endDate: "29-01-2024",
        items: [
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
        ]
    },
    {
        code: "002",
        name: "Program 2",
        startDate: "29-01-2024",
        endDate: "29-01-2024",
        items: [
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
        ]
    },
    {
        code: "003",
        name: "Program 3",
        startDate: "29-01-2024",
        endDate: "29-01-2024",
        items: [
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
            { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
        ]
    },
];

export const FAKE_DATA_PROGRAM_CHILDREN = [
    { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
    { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
    { evaluationTime: "29/01/2024 23:59:59", evaluationArea: "KV hành chính nhân sự", responsiblePerson: "Nguyễn Văn A" },
];

export const FAKE_TREE_DATA = [
    {
        code: "0", label: "Toàn đơn vị", children: [
            {
                code: "01", label: "Khu vực hành chính văn phòng", children: [
                    { code: "001", label: "KV hành chính nhân sự", isSelected: true },
                    { code: "002", label: "KV Kinh doanh", isSelected: false },
                    { code: "003", label: "KV Kế hoạch và đào tạo", isSelected: false },
                ]
            },
        ]
    }
];

export const FAKE_DATA_ITEM_CRITERIA = [
    { name: "Tại nơi làm việc không có các vật dụng dư thừa", description: "Môi trường làm việc sạch sẽ và gọn gàng giúp tăng hiệu suất công việc và tạo điều kiện thuận lợi cho nhân viên." },
    { name: "Mọi công cụ làm việc đều được sắp xếp gọn gàng sau khi sử dụng", description: "Việc sắp xếp gọn gàng các công cụ sau khi sử dụng giúp tránh hỏng hóc và tăng tuổi thọ của chúng." },
    { name: "Bàn làm việc luôn sạch sẽ và không có giấy tờ rải rác", description: "Bàn làm việc sạch sẽ và gọn gàng không chỉ tạo ấn tượng tốt với khách hàng mà còn giúp nhân viên tập trung vào công việc một cách hiệu quả." },
    { name: "Khu vực làm việc được bố trí sao cho thoải mái và tiện lợi nhất", description: "Việc bố trí khu vực làm việc sao cho tiện lợi và thoải mái giúp tăng cường sự thoải mái và năng suất của nhân viên." },
    { name: "Các vật dụng như hộp đựng đồ, giá đỡ được sắp xếp hợp lý và gọn gàng", description: "Sắp xếp các vật dụng như hộp đựng đồ và giá đỡ hợp lý không chỉ giúp tiết kiệm không gian mà còn làm cho môi trường làm việc trở nên gọn gàng và dễ dàng quản lý." }
];


export const FAKE_DATA_PROGRAM_ITEMS = [
    { evaluationTime: "09:30:00 2023-05-12", evaluationArea: "Phòng Kế Toán", responsiblePerson: "Nguyễn Văn A" },
    { evaluationTime: "14:15:00 2023-08-25", evaluationArea: "Phòng Nhân Sự", responsiblePerson: "Trần Thị B" },
    { evaluationTime: "10:00:00 2023-11-03", evaluationArea: "Phòng Kinh Doanh", responsiblePerson: "Lê Văn C" },
    { evaluationTime: "13:45:00 2023-09-17", evaluationArea: "Phòng Marketing", responsiblePerson: "Phạm Thị D" },
    { evaluationTime: "11:20:00 2023-07-08", evaluationArea: "Phòng Kỹ Thuật", responsiblePerson: "Hoàng Văn E" },
    { evaluationTime: "10:30:00 2023-10-29", evaluationArea: "Phòng Hành Chính", responsiblePerson: "Trần Thanh F" },
    { evaluationTime: "09:00:00 2023-06-14", evaluationArea: "Phòng Quản Lý", responsiblePerson: "Nguyễn Thị G" },
    { evaluationTime: "14:45:00 2023-12-07", evaluationArea: "Phòng Đào Tạo", responsiblePerson: "Lê Văn H" },
    { evaluationTime: "13:10:00 2023-04-21", evaluationArea: "Phòng Kế Hoạch", responsiblePerson: "Trần Thị I" },
    { evaluationTime: "11:50:00 2023-02-09", evaluationArea: "Phòng Kiểm Soát", responsiblePerson: "Phạm Văn K" }
];

export const FAKE_LIST_PROGRAMS = [
    {
        stt: 1,
        khoaBaoCao: "Khoa A",
        code: "ABCD001",
        name: "Đánh giá 5S Q2_2024",
        status: "Khởi tạo",
        startDate: "01/04/2024",
        endDate: "07/04/2024"
    },
    {
        stt: 2,
        khoaBaoCao: "Khoa B",
        code: "EFGH002",
        name: "Đánh giá 5S Q2_2024",
        status: "Chờ phê duyệt",
        startDate: "08/04/2024",
        endDate: "14/04/2024"
    },
    {
        stt: 3,
        khoaBaoCao: "Khoa C",
        code: "IJKL003",
        name: "Đánh giá 5S Q2_2024",
        status: "Đã phê duyệt",
        startDate: "15/04/2024",
        endDate: "21/04/2024"
    },
    {
        stt: 4,
        khoaBaoCao: "Khoa D",
        code: "MNOP004",
        name: "Đánh giá 5S Q2_2024",
        status: "Yêu cầu sửa đổi",
        startDate: "22/04/2024",
        endDate: "28/04/2024"
    },
    {
        stt: 5,
        khoaBaoCao: "Khoa E",
        code: "QRST005",
        name: "Đánh giá 5S Q2_2024",
        status: "Đang sửa đổi",
        startDate: "29/04/2024",
        endDate: "05/05/2024"
    },
    {
        stt: 6,
        khoaBaoCao: "Khoa F",
        code: "UVWX006",
        name: "Đánh giá 5S Q2_2024",
        status: "Khởi tạo",
        startDate: "06/05/2024",
        endDate: "12/05/2024"
    },
    {
        stt: 7,
        khoaBaoCao: "Khoa G",
        code: "YZAB007",
        name: "Đánh giá 5S Q2_2024",
        status: "Chờ phê duyệt",
        startDate: "13/05/2024",
        endDate: "19/05/2024"
    },
    {
        stt: 8,
        khoaBaoCao: "Khoa H",
        code: "CDEF008",
        name: "Đánh giá 5S Q2_2024",
        status: "Đã phê duyệt",
        startDate: "20/05/2024",
        endDate: "26/05/2024"
    },
];

export const FAKE_LIST_AREA = [
    {
        areaCode: "KV001",
        areaName: "Phòng Kế toán",
        status: { name: "Hoạt động" },
        responsible: { name: "Nguyễn Văn A" },
        note: "Ghi chú 1"
    },
    {
        areaCode: "KV002",
        areaName: "Phòng Kinh doanh",
        status: { name: "Tạm ngưng" },
        responsible: { name: "Trần Thị B" },
        note: "Ghi chú 2"
    },
    {
        areaCode: "KV003",
        areaName: "Phòng Nhân sự",
        status: { name: "Đóng cửa" },
        responsible: { name: "Phạm Văn C" },
        note: "Ghi chú 3"
    },
    {
        areaCode: "KV004",
        areaName: "Phòng Kỹ thuật",
        status: { name: "Đang sửa chữa" },
        responsible: { name: "Lê Thị D" },
        note: "Ghi chú 4"
    },
    {
        areaCode: "KV005",
        areaName: "Phòng Marketing",
        status: { name: "Hoạt động" },
        responsible: { name: "Nguyễn Văn E" },
        note: "Ghi chú 5"
    },
    {
        areaCode: "KV006",
        areaName: "Phòng Quản lý sản xuất",
        status: { name: "Tạm ngưng" },
        responsible: { name: "Trần Thị F" },
        note: "Ghi chú 6"
    },
    {
        areaCode: "KV007",
        areaName: "Phòng Tiếp thị",
        status: { name: "Đóng cửa" },
        responsible: { name: "Phạm Văn G" },
        note: "Ghi chú 7"
    },
    {
        areaCode: "KV008",
        areaName: "Phòng Kế hoạch",
        status: { name: "Đang sửa chữa" },
        responsible: { name: "Lê Thị H" },
        note: "Ghi chú 8"
    },
    {
        areaCode: "KV009",
        areaName: "Phòng Tài chính",
        status: { name: "Hoạt động" },
        responsible: { name: "Nguyễn Văn I" },
        note: "Ghi chú 9"
    },
    {
        areaCode: "KV010",
        areaName: "Phòng Hành chính",
        status: { name: "Tạm ngưng" },
        responsible: { name: "Trần Thị J" },
        note: "Ghi chú 10"
    }
];

export const FAKE_LIST_MEMMBER = [
    {
        memberName: "Nguyễn Văn An",
        memberYearOfBirth: 1990,
        gender: "Nam",
        memberAddress: "123 Đường Trần Phú, Quận 1, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0123456789",
        areaBelongs: "Phòng Kế toán",
        is5SMember: true
    },
    {
        memberName: "Trần Thị Bảo",
        memberYearOfBirth: 1985,
        gender: "Nữ",
        memberAddress: "456 Đường Lê Lợi, Quận 2, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0987654321",
        areaBelongs: "Phòng Kinh doanh",
        is5SMember: false
    },
    {
        memberName: "Phạm Văn Cường",
        memberYearOfBirth: 1992,
        gender: "Nam",
        memberAddress: "789 Đường Nguyễn Huệ, Quận 3, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0369876543",
        areaBelongs: "Phòng Nhân sự",
        is5SMember: true
    },
    {
        memberName: "Lê Thị Đào",
        memberYearOfBirth: 1988,
        gender: "Nữ",
        memberAddress: "101 Đường Trần Hưng Đạo, Quận 4, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0123987654",
        areaBelongs: "Phòng Kỹ thuật",
        is5SMember: false
    },
    {
        memberName: "Nguyễn Văn Bắc",
        memberYearOfBirth: 1995,
        gender: "Nam",
        memberAddress: "111 Đường Bùi Thị Xuân, Quận 5, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0987123456",
        areaBelongs: "Phòng Marketing",
        is5SMember: true
    },
    {
        memberName: "Trần Thị Hồng",
        memberYearOfBirth: 1987,
        gender: "Nữ",
        memberAddress: "222 Đường Nguyễn Thị Minh Khai, Quận 6, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0365432198",
        areaBelongs: "Phòng Quản lý sản xuất",
        is5SMember: false
    },
    {
        memberName: "Phạm Văn Dũng",
        memberYearOfBirth: 1993,
        gender: "Nam",
        memberAddress: "333 Đường Lý Tự Trọng, Quận 7, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0123654789",
        areaBelongs: "Phòng Tiếp thị",
        is5SMember: true
    },
    {
        memberName: "Lê Thị Hà",
        memberYearOfBirth: 1989,
        gender: "Nữ",
        memberAddress: "444 Đường Nguyễn Thái Bình, Quận 8, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0987456321",
        areaBelongs: "Phòng Kế hoạch",
        is5SMember: false
    },
    {
        memberName: "Nguyễn Văn Hoàng",
        memberYearOfBirth: 1994,
        gender: "Nam",
        memberAddress: "555 Đường Cách Mạng Tháng Tám, Quận 9, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0365214789",
        areaBelongs: "Phòng Tài chính",
        is5SMember: true
    },
    {
        memberName: "Trần Thị Kim",
        memberYearOfBirth: 1986,
        gender: "Nữ",
        memberAddress: "666 Đường Nguyễn Văn Linh, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        memberPhoneNumber: "0123546789",
        areaBelongs: "Phòng Hành chính",
        is5SMember: false
    }
];

export const FAKE_LIST_ITEM = [
    {
        status: { code: "INIT", name: "Khởi tạo" },
        evaluateDate: "2024-04-15",
        evaluateArea: "Phòng Kế toán",
        responsible: "Nguyễn Văn An"
    },
    {
        status: { code: "WAIT_APPROVAL", name: "Chờ phê duyệt" },
        evaluateDate: "2024-04-18",
        evaluateArea: "Phòng Kinh doanh",
        responsible: "Trần Thị Bảo"
    },
    {
        status: { code: "APPROVED", name: "Đã phê duyệt" },
        evaluateDate: "2024-04-20",
        evaluateArea: "Phòng Nhân sự",
        responsible: "Phạm Văn Cường"
    },
    {
        status: { code: "REQUEST_MODIFY", name: "Yêu cầu sửa đổi" },
        evaluateDate: "2024-04-22",
        evaluateArea: "Phòng Kỹ thuật",
        responsible: "Lê Thị Đào"
    },
    {
        status: { code: "IN_MODIFY", name: "Đang sửa đổi" },
        evaluateDate: "2024-04-25",
        evaluateArea: "Phòng Marketing",
        responsible: "Nguyễn Văn Bắc"
    },
    {
        status: { code: "INIT", name: "Khởi tạo" },
        evaluateDate: "2024-04-28",
        evaluateArea: "Phòng Quản lý sản xuất",
        responsible: "Trần Thị Hồng"
    },
    {
        status: { code: "APPROVED", name: "Đã phê duyệt" },
        evaluateDate: "2024-05-01",
        evaluateArea: "Phòng Tiếp thị",
        responsible: "Phạm Văn Dũng"
    },
    {
        status: { code: "WAIT_APPROVAL", name: "Chờ phê duyệt" },
        evaluateDate: "2024-05-04",
        evaluateArea: "Phòng Kế hoạch",
        responsible: "Lê Thị Hà"
    },
    {
        status: { code: "REQUEST_MODIFY", name: "Yêu cầu sửa đổi" },
        evaluateDate: "2024-05-07",
        evaluateArea: "Phòng Tài chính",
        responsible: "Nguyễn Văn Hoàng"
    },
    {
        status: { code: "IN_MODIFY", name: "Đang sửa đổi" },
        evaluateDate: "2024-05-10",
        evaluateArea: "Phòng Hành chính",
        responsible: "Trần Thị Kim"
    }
];

export const FAKE_LIST_DATA_DB = [
    {
        stt: 1,
        place: "Phòng Kế toán",
        date: "2024-04-01",
        person: "Nguyễn Văn An",
        assessor: "Trần Thị Bảo",
        score: 85
    },
    {
        stt: 2,
        place: "Phòng Kinh doanh",
        date: "2024-04-02",
        person: "Phạm Văn Cường",
        assessor: "Lê Thị Đào",
        score: 92
    },
    {
        stt: 3,
        place: "Phòng Nhân sự",
        date: "2024-04-03",
        person: "Nguyễn Văn Bắc",
        assessor: "Trần Thị Hồng",
        score: 78
    },
    {
        stt: 4,
        place: "Phòng Kỹ thuật",
        date: "2024-04-04",
        person: "Phạm Văn Dũng",
        assessor: "Lê Thị Hà",
        score: 88
    },
    {
        stt: 5,
        place: "Phòng Marketing",
        date: "2024-04-05",
        person: "Nguyễn Văn Hoàng",
        assessor: "Trần Thị Kim",
        score: 90
    },
    {
        stt: 6,
        place: "Phòng Quản lý sản xuất",
        date: "2024-04-06",
        person: "Nguyễn Thị Lan",
        assessor: "Phạm Văn Lâm",
        score: 82
    },
    {
        stt: 7,
        place: "Phòng Tiếp thị",
        date: "2024-04-07",
        person: "Trần Văn Minh",
        assessor: "Lê Thị Nga",
        score: 95
    },
    {
        stt: 8,
        place: "Phòng Kế hoạch",
        date: "2024-04-08",
        person: "Nguyễn Thị Oanh",
        assessor: "Phạm Văn Phong",
        score: 75
    },
    {
        stt: 9,
        place: "Phòng Tài chính",
        date: "2024-04-09",
        person: "Trần Văn Quân",
        assessor: "Lê Thị Quỳnh",
        score: 83
    },
    {
        stt: 10,
        place: "Phòng Hành chính",
        date: "2024-04-10",
        person: "Nguyễn Thị Ngọc",
        assessor: "Phạm Văn Sơn",
        score: 87
    }
];
