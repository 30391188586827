import axios from "axios";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
// import { ISetOfCriteriaBasicInfo, TermAreaDTO } from "../models/SetOfCriteriaModels";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const getAllRepeair = (searchObject: any) => {
  const url = `${API_PATH}/program-impl-schedules/page/by-user-onus-area`;
  return axios.get(url, { params: searchObject });
};
