import { useEffect, useState } from 'react';
import { Alert, Form, Row } from 'react-bootstrap';
import { Col } from '../../component/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { useLoading } from '../../../AppContext';
import { AUTHORITIES_ABILITY } from '../../../Constant';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import TableCustom from '../../component/table-custom/TableCustom';
import EvaluateTable from '../../evaluate/component/EvaluateTable';
import TreeView from './CustomTreeSetOfCriteria';
import { FORM_ACTION, FORM_CRITERIA_BTN, FORM_CRITERIA_BTN_INITAL } from '../config/ConfigButton';
import { ICriteria, IScoreScale, ITermAnswer, ItermFormResDto } from '../models/SetOfCriteriaModels';
import { approvalRequest, copyTermForm, fetchDataTermForm, getAreaListChildrenByTermForm, getCriteriaForDepartment } from '../services/SetOfCriteriaServices';
import { ColumnViewListCriteria } from './ColumnViewListCriteria';
import GroupButton from './GroupButton';
import { SetOfPointLevelColumnsView } from './SetOfCriteriaColumns';
import HistoryRequest from '../../component/History-request/HistoryRequest';
import CustomIconButton from '../../component/CustomIconButton';
import { StageSignature } from '../../constant';
import { initLstTermAnswerDto, initTermFormReqDto } from '../const/SetOfCriteriaConst';
import { handleError } from '../../utils/FunctionUtils';

type IProps = {
    action: string
}

function SetOfCriteriaFormView({ action }: IProps) {
    let navigate = useNavigate();
    let { id } = useParams();
    const { setPageLoading } = useLoading();
    const [departmentList, setDepartmentList] = useState<any>([]);
    const [dataTermForm, setDataTermForm] = useState<any>()
    const [noteEvaluate, setNoteEvaluate] = useState<string>("");
    const [criteriaList, setCriteriaList] = useState<ICriteria[]>([]);
    const [openConfirmApproveDialog, setOpenConfirmApproveDialog] = useState<boolean>(false);
    const [openConfirmCopyDialog, setOpenConfirmCopyDialog] = useState<boolean>(false);
    const [elementAbility, setElememtAbility] = useState<any>(FORM_CRITERIA_BTN_INITAL)
    const [scoreScaleList, setScoreScaleList] = useState<IScoreScale[]>([]);
    const [openHistoryRequest, setOpenHistoryRequest] = useState<boolean>(false);

    const lstTermAnswerDto:ITermAnswer[] = dataTermForm?.lstTermAnswerDto || initLstTermAnswerDto;
    const termFormReqDto:ItermFormResDto = dataTermForm?.termFormReqDto || initTermFormReqDto;

    useEffect(() => {
        Number(id) && handleGetDataTermForm(Number(id));
    }, [id]);

    const handleGetDataTermForm = async (termFormId: number) => {
        try {
            setPageLoading(true)
            const { data } = await fetchDataTermForm(termFormId);
            setElememtAbility(action === FORM_ACTION.VIEW
                ?
                {
                    ...FORM_CRITERIA_BTN[action as keyof typeof FORM_CRITERIA_BTN],
                    ...(data.data.stageCode === StageSignature.CURRENT && { iconButtonCopy: true })
                }
                :
                FORM_CRITERIA_BTN[data.data.stageCode as keyof typeof FORM_CRITERIA_BTN]
            )
            setDataTermForm({
                termFormReqDto: data.data.termFormResDto,
                lstTermAnswerDto: data.data.lstTermAnswer,
                stageCode: data.data.stageCode
            });
            handleGetChildrenListTermArea(data?.data?.termFormResDto?.areaRootCode)
        } catch (error) {
            handleError(error)
        } finally {
            setPageLoading(false)
        }
    };

    const handleGetChildrenListTermArea = async (rootCode: any) => {
        try {
            setPageLoading(true);
            const { data } = await getAreaListChildrenByTermForm(Number(id), rootCode || null);
            const departmentListTemp = [];
            if (data?.data) {
                for (const department of data.data) {
                    if (department.id) {
                        const { areaId, nameArea, ...rest } = department;
                        departmentListTemp.push({
                            ...rest,
                            code: areaId,
                            label: nameArea,
                            name: nameArea,
                        });
                    }
                }
            }
            setDepartmentList([{ code: "0", label: null, children: departmentListTemp }]);
        } catch (error) {
            toast.error("Lấy danh sách khu vực thất bại");
        } finally {
            setPageLoading(false);
        }
    };

    const handleReturnTotalPoint = () => {
        const criteriaListTemp = criteriaList.filter((criteria: ICriteria) => criteria?.shortNameParent);
        const maxPoint = lstTermAnswerDto?.[lstTermAnswerDto?.length - 1].answerNumeric || 1;
        return criteriaListTemp.length * maxPoint;
    };

    const getEvaluateNotes = () =>
        lstTermAnswerDto
            ?.map(({ sortWeight, answerText }) => `${sortWeight}_${answerText}`)
            ?.join("; ")
        || "";

    useEffect(() => {
        lstTermAnswerDto.length && setNoteEvaluate(getEvaluateNotes());
    }, [lstTermAnswerDto]);

    const ColumnsEvaluate = () => {
        const columnsEvaluateNew: { 
            name: string; 
            field: string; 
            render: (row: any, index: number) => JSX.Element | ""; 
        }[] = [...ColumnViewListCriteria({})]
        lstTermAnswerDto?.forEach((item: ITermAnswer, termAnswerIndex: number) => {
            columnsEvaluateNew.push({
                name: item.sortWeight || "",
                field: "",
                render: (row: any, rowIndex: number) => (
                    row?.shortNameParent ? (
                        <Form.Check
                            className="mt-2"
                            type="radio"
                            name={`checkbox_row_${row.id}_${rowIndex}`}
                            value={""}
                            checked={row.termAnswerScore === item.answerNumeric}
                        />)
                        :
                        <></>
                )
            })
        });
        columnsEvaluateNew.sort((col1, col2) => {
            return Number(col1.name) - Number(col2.name);
        })
        return columnsEvaluateNew;
    };

    const getTermsByTermArea = async (areaId: number, isCopy: "true" | "false" = "false") => {
        try {
            setPageLoading(true);
            const { data } = await getCriteriaForDepartment(areaId, isCopy);
            const criteriaListTemp: ICriteria[] = data?.data?.lstTerm?.flatMap((criteriaInfo: any) => {
                const criteriaChildren = criteriaInfo?.children || [];
                criteriaChildren.map((item: any) => item.shortNameParent = criteriaInfo?.shortName)
                return [criteriaInfo, ...criteriaChildren];
            });
            setCriteriaList(criteriaListTemp);
            setScoreScaleList(data?.data?.lstScoreScale);
        } catch (error) {
            toast.error("Lấy thông tin danh mục tiêu chí thất bại");
        } finally {
            setPageLoading(false);
        }
    };

    const handleApproval = async () => {
        try {
            id && await approvalRequest(Number(id))
            toast.success("Phê duyệt thành công")
            navigate('/set-of-criteria')
        } catch (error) {
            toast.error(String(error))
        }
    };

    const handleCopyTermForm = async () => {
        try {
            let { data } = await copyTermForm(Number(id));
            toast.success("Sao chép thành công");
            navigate(`/set-of-criteria/${data.data}`);
        } catch (error) {
            toast.error(String(error))
        }
    };

    return (
        <div className='info-box wrapper-content px-6'>
            <CustomIconButton
                tooltip="Sao chép"
                className="bi bi-copy copy-icon"
                onClick={() => setOpenConfirmCopyDialog(true)}
                visible={elementAbility.iconButtonCopy}
            />
            <CustomIconButton
                tooltip="Lịch sử yêu cầu"
                className="bi bi-clock-history history-icon"
                onClick={() => setOpenHistoryRequest(true)}
            />
            <Row>
                <Col xs="12" className='mt-5 mb-6'>
                    <h3 className='spaces m-0 text-uppercase color-primary'>Nội dung biểu mẫu 5S</h3>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-110'>Tên biểu mẫu:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.name}</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-95'>Mã biểu mẫu:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.code}</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-70'>Ngày tạo:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.issuedDate}</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-70'>Địa điểm:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.areaRootCode}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-110'>Người phê duyệt:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.userApproveName}</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='spaces min-w-95'>Trạng thái:</div>
                        <div className='text-lable-input color-primary'>{termFormReqDto.statusName}</div>
                    </div>
                </Col>
                <Col lg={12}>
                    <Alert variant="dark" className="spaces min-h-46">
                        {termFormReqDto.note}
                    </Alert>
                </Col>
            </Row>
            <div className="divider mt-2" />
            <Row className='mb-6'>
                <Col xl={3} className='treelist-criteria-container'>
                    <div className="flex justify-content-between me-4 align-items-center">
                        <div className='spaces flex flex-start flex-row color-primary align-center gap-5 mb-5'>
                            <KTSVG path="/media/icons/menu-fill.svg" className='color-primary btn-icon svg-icon-3' />
                            <span className='spaces fs-16 fw-bold pt-4'>Danh sách khu vực</span>
                        </div>
                    </div>
                    <div className='custom-treeview-criteria'>
                        <TreeView
                            data={departmentList}
                            isView={true}
                            isAlwayOpen={true}
                            defaultSelected={departmentList[0]?.children[0]}
                            handleChangeSelection={(department: any) => getTermsByTermArea(department?.id)}

                        />
                    </div>
                </Col>
                <Col xl={9} className='spaces detail-criteria-container py-15 pl-20'>
                    <div className='d-flex justify-content-between align-items-center spaces mb-15'>
                        <span className="spaces fs-18 fw-bold text-uppercase color-primary">Danh sách tiêu chí</span>
                        <i className='spaces fs-14'>
                            <span>{"("}</span>
                            <span className="color-red italic">Chú giải: </span>
                            <span className="italic">
                                {
                                    noteEvaluate
                                }{")"}
                            </span>
                        </i>
                    </div>
                    <EvaluateTable
                        data={criteriaList || []}
                        setData={() => { }}
                        handleChangeTableData={() => { }}
                        columns={ColumnsEvaluate()}
                        disabled
                    />
                    <div className="w-100">
                        <div className="d-flex align-items-center spaces py-15 gap-20">
                            <span className="spaces fs-18 fw-bold text-uppercase color-primary">Thang điểm tổng</span>
                            <i className='spaces fs-17'>(Tổng điểm chuẩn {handleReturnTotalPoint()})</i>
                        </div>
                        <div className='spaces sf-14'>
                            <TableCustom
                                id="SetOfPointLevelColumns"
                                data={scoreScaleList}
                                columns={SetOfPointLevelColumnsView({})}
                                buttonAdd={false}
                                buttonExportExcel={false}
                                notDelete={false}
                                fixedColumnsCount={0}
                                justFilter={true}
                                updatePageData={() => { }}
                                noPagination={true}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            <GroupButton
                termFormData={termFormReqDto}
                elementAbility={elementAbility}
                handleClose={() => navigate(-1)}
                ability={AUTHORITIES_ABILITY.SEARCH}
            />

            {openConfirmApproveDialog && (
                <ConfirmDialog
                    show={openConfirmApproveDialog}
                    title="Xác nhận phê duyệt"
                    message={"Bạn có chắc chắn muốn phê duyệt biểu mẫu này?"}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleApproval}
                    onCancelClick={() => setOpenConfirmApproveDialog(false)}
                />
            )}
            {openConfirmCopyDialog && (
                <ConfirmDialog
                    show={openConfirmCopyDialog}
                    title="Xác nhận sao chép"
                    message={`Bạn có chắc chắn muốn sao chép biểu mẫu này?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleCopyTermForm}
                    onCancelClick={() => setOpenConfirmCopyDialog(false)}
                />
            )}
            {openHistoryRequest &&
                <HistoryRequest
                    handleClose={() => setOpenHistoryRequest(false)}
                    params={{ termFormId: id as string }}
                />
            }
        </div>
    );
}

export default SetOfCriteriaFormView;