import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';

interface FloatingNotificationProps {
    content: any;
}

const FloatingNotification: React.FC<FloatingNotificationProps> = ({ content }) => {
    const [showModal, setShowModal] = useState(true);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const buttonElement = buttonRef.current;
        const buttonRect = buttonElement?.getBoundingClientRect();

        if (buttonRect) {
            setModalPosition({
                top: Math.min(buttonRect.top, window.innerHeight - 250),
                left: Math.min(buttonRect.left, window.innerWidth - 300),
            });
        }
    }, []);
    const handleButtonClick = () => {
        const buttonElement = buttonRef.current;
        if (buttonElement) {
            setShowModal(!showModal);
        }
    };
    window.onresize = () => {
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                ref={buttonRef}
                className="floating-button float-right"
                onClick={handleButtonClick}
            >
                <i className="spaces fas fa-comment p-0"></i>
            </Button>

            {showModal && (
                <div
                    className="modal-dialog floating-button-modal"
                    onClick={handleCloseModal}
                    style={{ top: modalPosition.top, left: modalPosition.left }}
                >
                    <div className='modal-content'>
                        <div className="modal-header floating-button-modal-header">
                            <h2>Nội dung cần chỉnh sửa</h2>
                            <span className="close fs-1 cursor-pointer" onClick={handleCloseModal}>
                                &times;
                            </span>
                        </div>
                        <div className="spaces floating-button-modal-content p-20">
                            <p>{content}</p>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default FloatingNotification;