import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TableCustom from "../../component/table-custom/TableCustom";
import { ColumnsProgram } from "../const/EvaluateConst";
import { getAllProgramImpl } from "../../program/services/ProgramService";
import AppContext from "../../../AppContext";
import { iSearchProgramParams } from "../../program/models/ProgramModel";
import InputSearch from "../../component/input-field/InputSearch";
import { SearchBase, searchBaseDefaultValue } from "../../utils/InterfaceUtils";
import { useAuth } from "../../auth";
import HeaderUserInfoModal from "../../../../_metronic/partials/layout/header-menus/HeaderUserInfoModal";
import { getMemberInfoById } from "../../category/services/CategoryServices";
import { checkColorByStatus } from "../../utils/FunctionUtils";

const EvaluatePresentTab = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser } = useAuth();
    const locationState = location.state as { currentId: number };
    const { setPageLoading } = useContext(AppContext);
    const [listProgram, setListProgram] = useState<any[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);  
    const [keyword, setKeyword] = useState<string>("");
    const [searchObject, setSearchObject] = useState<SearchBase>({
        ...searchBaseDefaultValue,
        orgId: currentUser?.orgId,
    });
    const [showInfoUserModal, setShowInfoUserModal] = useState(false);
    const [userData, setUserData] = useState<any>();

    const handleEdit = (row: any) => {   
        navigate(`/evaluate/${row.id}`, { state: {
            proImplScheduleId: row.id,
            areaId: row.areaId,
            termFormId: row.termFormId,
            areaName: row.areaName,
            programId: row.programId,
            termAreaId: row.termAreaId
        } });
    };

    const updateData = async (searchObj: iSearchProgramParams): Promise<void> => {
        try {
            setPageLoading(true);
            let { data } = await getAllProgramImpl({
                ...searchObj,
                orgId: currentUser?.orgId,
                progress: 3
            });
            let newData = data.data.content;
            let pageable = data.data.pageable;
            let newListProgram = newData.map((item: any) => ({
                ...item,
                isChecked: item.id === locationState?.currentId ? true : false,
            }));
            setListProgram(newListProgram);
            setTotalElements(pageable.totalElements);
            setNumberOfElements(pageable.pageNumber + 1);
        } catch (err) {
            console.error(err);
        } finally {
            setPageLoading(false);
        }
    };

    const handleChange = (e: any) => {
        setKeyword(e.target.value);
    };

    const handleSearch = () => {
        setSearchObject((preState) => ({
            ...preState,
            pageIndex: searchBaseDefaultValue.pageIndex,
            keyword
        }));
    };

    const handleShowInfoUser = async (id: string) => {
        try {
            setPageLoading(true);
            let res = await getMemberInfoById(id);
            let userData = res.data?.data;
            setUserData(userData);
            setShowInfoUserModal(true);
        } catch (error) {
            console.error(error);
        } finally {
            setPageLoading(false);
        }
    };

    const setRowColorClass = (row: any, rowId: string) => {
        checkColorByStatus(row?.statusCode, rowId);
        return "";
    };

    return (
      <div className="spaces mt-10">
            <div className="spaces d-flex flex-end mb-10">
                <div className='spaces min-w-400'>
                    <InputSearch
                        className='spaces h-31'
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        placeholder="Tìm kiếm tên chương trình, tên khu vực"
                        isEnter
                    />
                </div>
            </div>
            <TableCustom
                id="profile-1"
                height={"calc(100vh - 310px)"}
                data={listProgram || []}
                columns={ColumnsProgram({
                  handlePerformEvaluate: handleEdit,
                  handleShowInfoUser
                })}
                buttonAdd={false}
                buttonExportExcel={false}
                notDelete={false}
                justFilter={true}
                updatePageData={updateData}
                objectSearch={searchObject}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                notEdit={false}
                headerClasses="text-center"
                isUnclickableRowContent
                setRowColorClass={setRowColorClass}
            />
            {showInfoUserModal && (
                <HeaderUserInfoModal
                    show={showInfoUserModal}
                    onClose={() => setShowInfoUserModal(false)}
                    currentUser={userData}
                    isJustUserInfo={true}
                />
            )}
        </div>
  );
};

export default EvaluatePresentTab;
