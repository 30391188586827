import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import TextValidator from '../../../../app/modules/component/input-field/TextValidator';
import { ModalFormikCustom } from '../../../../app/modules/component/modalFormik';
import { FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { useLoading } from '../../../../app/AppContext';
import { handleError } from '../../../../app/modules/utils/FunctionUtils';
import { toast } from 'react-toastify';
import { KEY_LOCALSTORAGE } from '../../../../app/modules/auth/core/_consts';
import { localStorageItem } from '../../../../app/modules/utils/LocalStorage';

interface HeaderChangePasswordModalProps {
    show: boolean;
    onClose: () => void;
}

const forgotPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Mật khẩu cũ không được để trống'),
  password: Yup.string()
    .min(8, 'Mật khẩu mới phải có ít nhất 8 ký tự')
    .required('Mật khẩu mới không được để trống'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Mật khẩu xác nhận không khớp')
    .required('Mật khẩu xác nhận không được để trống'),
});

const changePassword = (values: any) => {
  const url = `${localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"]}/users/password/change`;
  return axios.patch(url, values);
};

const initChangePassword = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
}

const HeaderChangePasswordModal: React.FC<HeaderChangePasswordModalProps> = ({
    show,
    onClose,
}) => {
    const { setPageLoading } = useLoading();
    
    const handleChangePasswordSubmit = async (values: any) => {
      try {
        setPageLoading(true);
        await changePassword({
          oldPassword: values.oldPassword,
          password: values.password,
          confirmPassword: values.password,
        });
        toast.success("Đổi mật khẩu thành công.")
      } catch (error) {
        toast.error("Sai mật khẩu.")
      } finally {
        setPageLoading(false);
        onClose();
      }
    }

    return (
      <ModalFormikCustom
        show={show}
        sizeCustom='md'
        handleClose={onClose}
        title={"Thay đổi mật khẩu"}
        FormikConfig={{
          initialValues: initChangePassword,
          validationSchema: forgotPasswordSchema,
          onSubmit: handleChangePasswordSubmit,
        }}
      >
			{({ values, errors, touched, handleChange }: FormikProps<FormikValues>) => {
        return ((
          <>
            <Row className=''>
              <Col xs={12}>
                <div className="fw-bold spaces fs-16">Nhập mật khẩu hiện tại</div>
              </Col>
              <Col xs={12}  className='my-2'>
                <TextValidator
                  className="flex-row min-w-80"
                  classNameLabel="fw-nomal"
                  lable={"Mật khẩu hiện tại"}
                  readOnly={false}
                  isRequired
                  placeholder
                  name="oldPassword"
                  value={values.oldPassword}
                  type="password"
                  onChange={handleChange}
                  errors={errors?.oldPassword}
                  touched={touched?.oldPassword}
                />
              </Col>
              <Col xs={12}>
                <div className="fw-bold spaces fs-16 pt-12">Nhập mật khẩu mới</div>
              </Col>
              <Col xs={12}  className='my-2'>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"Mật khẩu mới"}
                  classNameLabel="fw-nomal"
                  readOnly={false}
                  isRequired
                  placeholder
                  name="password"
                  value={values.password}
                  type="password"
                  onChange={handleChange}
                  errors={errors?.password}
                  touched={touched?.password}
                />
              </Col>
              <Col xs={12}  className='my-2'>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"Nhập lại mật khẩu mới"}
                  classNameLabel="fw-nomal"
                  readOnly={false}
                  isRequired
                  placeholder
                  name="confirmPassword"
                  value={values.confirmPassword}
                  type="password"
                  onChange={handleChange}
                  errors={errors?.confirmPassword}
                  touched={touched?.confirmPassword}
                />
              </Col>
            </Row>
          </>
        ))
      }}
    </ModalFormikCustom>
  );
};

export default HeaderChangePasswordModal;