import { Children, useState } from 'react';
import { BTN_APPROVE, BTN_SEND_REQUEST, BTN_REQUEST_EDIT, BTN_CANCEL, AUTHORITIES_PERMISSIONS, BTN_EDIT, BTN_DELETE } from '../../../Constant';
import { AREA_CATEGORY_BUTTONS } from '../config/ConfigButton';
import { updateApproveRequest, updateStageEditRequest, updateStageSendRequest } from '../services/CategoryServices';
import { toast } from 'react-toastify';
import { PopupSendRequest } from '../../component/PopupSendRequest';
import { SubmitTextPopup } from '../../component/SubmitTextPopup';
import ButtonCustom from '../../component/button-custom';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { handleError } from '../../utils/FunctionUtils';

type Props = {
    areaInfo: any,
    elementAbility: any,
    updatePageData: () => void,
    handleEditRoot: () => void,
    handleDeleteRoot: () => void,
}

const GroupButton = ({
    areaInfo,
    elementAbility,
    updatePageData,
    handleEditRoot,
    handleDeleteRoot
}: Props) => {
    const [openEditRequestPopup, setOpenEditRequestPopup] = useState<boolean>(false);
    const [openSendRequestPopup, setOpenSendRequestPopup] = useState<boolean>(false);
    const [showDialogApprove, setShowDialogApprove] = useState<boolean>(false);
    const navigate = useNavigate()

    const handleSendRequest = async (data: any) => {
        try {
            await updateStageSendRequest({
                stageRequestId: areaInfo?.stageRequestId,
                areaId: areaInfo?.id,
                userApprove: data.userApprove,
                textOpinions: data.content
            });
            handleClose()
            toast.success("Trình phê duyệt thành công");
        } catch (error) {
            console.error(error);
            handleError(error);
        }
    }

    const handleEditRequest = async (textRefuse: any) => {
        try {
            await updateStageEditRequest({
                stageRequestId: areaInfo?.stageRequestId,
                areaId: areaInfo?.id,
                textRefuse: textRefuse?.content,
            });
            navigate("/approved");
            toast.success("Gửi yêu cầu chỉnh sửa thành công");
        } catch (error) {
            toast.error(error as string);
            console.error(error);
        }
    }

    const handleApproveRequest = async () => {
        try {
            await updateApproveRequest({
                stageRequestId: areaInfo?.stageRequestId,
                areaId: areaInfo?.id,
            });
            navigate("/approved")
            toast.success("Phê duyệt thành công");
        } catch (error) {
            toast.error(error as string);
            console.error(error);
        }
    }

    const handleClose = () => {
        navigate(-1)
        setOpenEditRequestPopup(false);
        setOpenSendRequestPopup(false);
        updatePageData();
    }

    const handleButtonClick = (action: string) => {
        switch (action) {
            case BTN_CANCEL.action:
                handleClose();
                break;
            case BTN_SEND_REQUEST.action:
                setOpenSendRequestPopup(true);
                break;
            case BTN_REQUEST_EDIT.action:
                setOpenEditRequestPopup(true);
                break;
            case BTN_APPROVE.action:
                setShowDialogApprove(true)
                break;
            case BTN_EDIT.action:
                handleEditRoot();
                break;
            case BTN_DELETE.action:
                handleDeleteRoot();
                break;
            default: break;
        }
    };

    return (
        <>
            <div className='d-flex gap-1 justify-content-center align-items-center'>
                {
                    Children.toArray(AREA_CATEGORY_BUTTONS.map((button => {
                        return (elementAbility?.[button.name] &&
                            <ButtonCustom
                                name={button.name}
                                className={button.className}
                                onClick={() => { handleButtonClick(button.action) }}
                                checkAuthority={button.authority}
                            >
                                {button.text}
                            </ButtonCustom>)
                    })))
                }
            </div>
            {openEditRequestPopup &&
                <SubmitTextPopup
                    show={openEditRequestPopup}
                    onSubmitForm={(textRefuse) => {
                        handleEditRequest(textRefuse)
                    }}
                    onCancelClick={() => setOpenEditRequestPopup(false)}
                />
            }
            {openSendRequestPopup &&
                <PopupSendRequest
                    show={openSendRequestPopup}
                    onSubmitForm={(data)=>{
                        handleSendRequest(data)
                    }}
                    onCancelClick={() => setOpenSendRequestPopup(false)}
                    permission={AUTHORITIES_PERMISSIONS.AREA}
                />
            }

            {showDialogApprove && (
                <ConfirmDialog
                    show={showDialogApprove}
                    title="Phê duyệt"
                    message={`Xác nhận phê duyệt khu vực`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleApproveRequest}
                    onCancelClick={() => {
                        setShowDialogApprove(false)
                    }}
                />
            )}
        </>
    );
};
export default GroupButton