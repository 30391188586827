import { FormikProps, FormikValues } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLoading } from '../../../AppContext';
import { Col } from '../../component/Grid';
import TextValidator from '../../component/input-field/TextValidator';
import { ModalFormikCustom } from '../../component/modalFormik';
import { saveRemedy } from '../EvaluateServices';
import { handleError } from "../../utils/FunctionUtils";
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { getImage, uploadImage } from '../../services';
import * as Yup from 'yup'

interface EvaluateRepairDialogProps {
  onClose: () => void;
  rowTable: any;
  setImageApprove: (value: boolean) => void;
  rowIndex: any;
  programImplResultList: any;
  setData: (value: any) => void;
  data: any;
  setShowImagePopup: (value: boolean) => void;
  setImgSrc: (value: string) => void;
  statusAction: { [key: string]: boolean };
}

export const ActionImage = ({ showRemove, handleShow, handleRemove }: {
  showRemove: boolean,
  handleShow: () => void,
  handleRemove: () => void
}) => {
  return (
    <div className='position-absolute top-0 left-0 w-100 h-100 flex-center bg-opacity-50 action-img d-none'>
      {showRemove && (
        <label>
          <i className="cursor-pointer px-4 fw-bold fs-1 bi bi-trash text-white bg-secondary rounded-2 py-1"
            onClick={handleRemove}
          />
        </label>
      )}
      <label>
        <i className="cursor-pointer px-4 fw-bold fs-1 bi bi-arrows-fullscreen text-white bg-secondary rounded-2 py-1"
          onClick={handleShow}
        />
      </label>
    </div>
  )
};

const RepeairSchema = Yup.object().shape({
  reasonText: Yup.string()
    .required('Nguyên nhân không được để trống').nullable(),
  fixedText: Yup.string()
    .required('Biện pháp không được để trống').nullable(),
});

const EvaluateRepairDialog: FC<EvaluateRepairDialogProps> = ({ 
  onClose, 
  rowTable, 
  setImageApprove, 
  rowIndex, 
  setData, 
  data, 
  setShowImagePopup, 
  setImgSrc,
  statusAction
}) => {
  const { setPageLoading } = useLoading();
  const imgRefs = useRef<any[]>([]);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState<boolean>(false);
  const [showConfirmSavePopup, setShowConfirmSavePopup] = useState<any>({
    open: false,
    data: null
  });
  const [imageUrls, setImageUrls] = useState<{ [key: number]: string }>({});
  const [nameImage, setNameImage] = useState<string | null>(data[rowIndex]?.imageImprove || null);
  const [idRow, setIdRow] = useState<number>(rowTable?.id);

  const handleCombinedSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataSubmit = {
        reasonText: values.reasonText,
        fixedText: values.fixedText,
        imageImprove: nameImage,
      };

      const dataRemedy = await saveRemedy(dataSubmit, idRow); 

      const newDataRemedy = {
        ...data[rowIndex],
        fixedText: dataRemedy?.data?.data?.fixedText,
        reasonText: dataRemedy?.data?.data?.reasonText,
        imageImprove: dataRemedy?.data?.data?.imageImprove,
        id: dataRemedy?.data?.data?.id,
      }    
      const updatedData = data.map((item: any, index: any) => index === rowIndex ? newDataRemedy : item);
      setData(updatedData);
      toast.success("Xử lý thành công");
      setImageApprove(false);
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setPageLoading(true);
        let imageDataURL = await getImage({ name: nameImage ?? "" });
        let url = URL.createObjectURL(imageDataURL.data);
        setImageUrls(url);
      } catch (error) {
        console.error('Error fetching image:', error);
      } finally {
        setPageLoading(false);
      }
    };

    if (nameImage) {
      fetchImage();
    }
  }, [nameImage]);

	const handleChangeImage = async (value: any) => {
    try {
      setPageLoading(true);
      if (value) {
        const formData = new FormData();
        formData.append("file", value);
        let { data } = await uploadImage(formData);
        setNameImage(data?.data)
        setImageUrls(URL.createObjectURL(value));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleSetImageRefs = (ref: HTMLImageElement | null, index: number, type: string) => {
    imgRefs.current[index] = {
      ...imgRefs.current[index],
      [type]: ref
    };
  };

  const handleShowImage = async (index: number, type: string) => {
    try {
      let url = imgRefs.current[index][type]?.src || "";
      setImgSrc(url as string);
      setShowImagePopup(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setPageLoading(true);
      setImageUrls({});
      setNameImage(null);
      setShowConfirmDeletePopup(false);
    } catch (error) {
      toast.error(error as string);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <ModalFormikCustom
      show
      size='lg'
      handleClose={onClose}
      disableBtnSave={statusAction.btnSave}
      title={<span>Báo cáo khắc phục: <span className='custom-title-repeair'>{rowTable?.termName}</span></span>}
      FormikConfig={{
        initialValues: rowTable,
        validationSchema: RepeairSchema,
        onSubmit: (values: any) => {
          setShowConfirmSavePopup({
            open: true,
            data: values
          })
        },
      }}
    >
      {({ values, handleChange, errors, touched }: FormikProps<FormikValues>) => (
        <>
          <Row>
            <Col xs={6} className="mb-3 px-1">
              <TextValidator
                className="min-w-80"
                lable={`Nguyên nhân`}
                isRequired
                name="reasonText"
                value={values?.reasonText}
                disabled={statusAction.reasonText}
                type="text"
                onChange={handleChange}
                errors={errors?.reasonText}
                touched={touched?.reasonText}
              />
              <TextValidator
                className="flex-row min-w-80 spaces mt-10"
                lable={`Biện pháp khắc phục`}
                isRequired
                name="fixedText"
                disabled={statusAction.fixedText}
                value={values?.fixedText}
                errors={errors?.fixedText}
                touched={touched?.fixedText}
                type="text"
                as="textarea"
                onChange={handleChange}
                rows={7}
              />
            </Col>
            <Col xs={6} className="d-flex flex-column justify-content-center align-items-center ps-5">
              {
                statusAction.actionImprove ? (
                  <label htmlFor="choose-img-file-imageImprove" className={`img-select ${imageUrls[rowIndex] ? 'd-none' : ''}`}>
                    <i className="fa-solid fa-image cursor-pointer px-4 fw-bold fs-1 text-white bg-secondary rounded-2 py-3"></i>
                  </label>
                ) : (
                  <span className={`img-select ${imageUrls[rowIndex] ? 'd-none' : ''}`}>
                    <i className="fa-solid fa-image px-4 fw-bold fs-1 text-white bg-secondary rounded-2 py-3"></i>
                  </span>
                )
              }
              <input
                type="file"
                id="choose-img-file-imageImprove"
                className="d-none"
                onChange={(e) => handleChangeImage(e.target.files?.[0])}
              />
              <div className={`spaces position-relative img-not-select ${imageUrls[rowIndex] ? '' : 'd-none'}`}>
                <img
                  ref={ref => handleSetImageRefs(ref, rowIndex, "imageImprove")}
                  loading='lazy'
                  id={nameImage || ""}
                  src={imageUrls[rowIndex] || ""}
                  alt={rowIndex || ""}
                  className="rect-img-app spaces w-100"
                />
                <ActionImage
                  showRemove={statusAction.deleteImageImporve}
                  handleShow={() => handleShowImage(rowIndex, "imageImprove")}
                  handleRemove={() => setShowConfirmDeletePopup(true)}
                />
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="mt-2">
                <em>Hình ảnh khắc phục</em>
              </div>
            </Col>
          </Row>
          {showConfirmDeletePopup && (
            <ConfirmDialog
              show={showConfirmDeletePopup}
              title="Xóa danh mục"
              message={`Bạn chắc chắn muốn xóa ảnh?`}
              yes="Xác nhận"
              cancel="Hủy"
              onYesClick={handleConfirmDelete}
              onCancelClick={() => setShowConfirmDeletePopup(false)}
            />
          )}
          {showConfirmSavePopup.open && (
            <ConfirmDialog
              show={showConfirmSavePopup.open}
              title="Xác nhận"
              message={`Bạn có chắc chắn muốn khắc phục không?`}
              yes="Xác nhận"
              cancel="Hủy"
              onYesClick={() => handleCombinedSubmit(showConfirmSavePopup.data)}
              onCancelClick={() => setShowConfirmSavePopup({
                open: false,
                data: null
              })}
            />
          )}
        </>
      )}
    </ModalFormikCustom>
  );
};

export default EvaluateRepairDialog;
