import { STATUS_CODE_5S } from "../../../Constant";
export const TABLE_ACTION_DEFAULT = {
    VIEW: true,
    EDIT: true,
    DELETE: true,
};

export const CONFIG_TABLE_BUTTON = Object.fromEntries(
    Object.values(STATUS_CODE_5S).map((value: string) => ([value, TABLE_ACTION_DEFAULT]))
);
