import { Alert, Col, Row } from "react-bootstrap"
import { ZoneModel } from "../model/CategoryModel"
import { INIT_ZONE_CATEGORY_DATA } from "../const/CategoryConst"
import HistoryRequest from "../../component/History-request/HistoryRequest"
import { useState } from "react"
import { PreviewImagePopup } from "../../evaluate/component/EvaluateTable"
import { getImage } from "../../services"
import CustomIconButton from "../../component/CustomIconButton"

type TProps = {
    rootAreaId: string
    data: ZoneModel
}
const AreaFormView = ({
    data, rootAreaId
}: TProps) => {

    const initData = { ...INIT_ZONE_CATEGORY_DATA, ...data };
    const [openHistoryRequest, setOpenHistoryRequest] = useState<boolean>(false);
    const [openViewMapRequest, setOpenViewMapRequest] = useState<boolean>(false);
    const [imageUrls, setImageUrls] = useState<string>("");

    const handleLoadImage = async (initData: any) => {
        if (!initData.image) {
            return;
        }

        const url = await handleGetUrlImage(initData?.image);
        setImageUrls(url || "");
        setOpenViewMapRequest(true);
    }

    const handleGetUrlImage = async (name: string) => {
        try {
            const imageDataURL = await getImage({ name });
            return URL.createObjectURL(imageDataURL.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='info-box py-4 px-6 mt-4 me-2 rounded root-info'>
            {rootAreaId && (
                <>
                    <CustomIconButton
                        tooltip="Lịch sử yêu cầu"
                        className="bi bi-clock-history history-icon"
                        onClick={() => setOpenHistoryRequest(true)}
                    />
                    {initData.image && (
                        <CustomIconButton
                            tooltip="Xem ảnh sơ đồ"
                            className="viewmap-icon fa fa-map-location-dot"
                            onClick={() => handleLoadImage(initData)}
                        />
                    )}
                </>
            )}
            <Row>
                <Col xs="12" className=' mb-6'>
                    <h3 className='spaces m-0 text-uppercase color-primary'>Thông tin khu vực</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <div className='d-flex gap-2 mb-3 align-items-center'>
                        <div className='spaces min-w-135'>Mã khu vực:</div>
                        <div className='text-lable-input color-primary'>{initData.code}</div>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className='d-flex gap-2 mb-3 align-items-center'>
                        <div className='spaces min-w-105'>Tên khu vực:</div>
                        <div className='text-lable-input color-primary'>{initData.name}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <div className='d-flex gap-2 mb-3 align-items-center'>
                        <div className='spaces min-w-135'>Chịu trách nhiệm:</div>
                        <div className='text-lable-input color-primary'>{initData.employeeName}</div>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className='d-flex gap-2 mb-3 align-items-center'>
                        <div className='spaces min-w-105'>Trạng thái:</div>
                        <div className='text-lable-input color-primary'>{initData.statusName}</div>
                    </div>
                </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-2'>
                <Alert variant="dark" className="spaces min-h-46">
                  {initData?.note}
                </Alert>
              </Col>
            </Row>
            {openHistoryRequest &&
                <HistoryRequest
                    handleClose={() => setOpenHistoryRequest(false)}
                    params={{ areaId: rootAreaId }}
                />
            }
            {openViewMapRequest && (
              <PreviewImagePopup
                show={openViewMapRequest}
                hide={() => setOpenViewMapRequest(false)}
                src={imageUrls || ""}
              />
            )}
        </div>
    )
}

export default AreaFormView;