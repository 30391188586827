import React, { useEffect, useState } from "react";
import { getTabList } from "./const/SystemConst";
import "./styles/styles.scss";
import TabMenu from "../component/tabs/TabMenu";
import { tab } from "../models/tabModels";

interface CategoryProps {}

const Systems: React.FC<CategoryProps> = () => {
  const [tabList, setTabList] = useState<tab[]>([]);

  useEffect(() => {
    setTabList(getTabList())
  }, [])
  return (
    <div className="vertical-tabs">
      <TabMenu
        danhsachTabs={tabList}
        vertical
      />
    </div>
  );
};

export default Systems;
