import React, { ChangeEvent, useRef, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import ButtonCustom from '../../component/button-custom';
import { RESPONSE_STATUS_CODE } from '../../utils/Constant';
import { toast } from 'react-toastify';
import { useLoading } from '../../../AppContext';
import { fileUpload } from '../services/CategoryServices';
import ShowErrorsImportExcelDialog from '../../../../_metronic/partials/modals/ShowErrorsImportExcelDialog';

interface MemberImportDialogAdd {
    show: boolean;
    onClose: () => void;
    dataUser?: any;
    setData?: any;
}

const MemberImportDialogAdd: React.FC<MemberImportDialogAdd> = ({
    show,
    onClose,
    setData,
    dataUser
}) => {
    const { setPageLoading } = useLoading();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [showErrorsDialog, setShowErrorsDialog] = useState<any>({
        show: false,
        dataErrors: []
    });
    const canUpload = useRef(true);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && Array.from(e.target.files).length > 0) {
            setSelectedFile(Array.from(e.target.files)[0]);
            canUpload.current = true
        }
    };

    const handleInputClick = (event: any) => {
        event.target.value = null;
    };

    const handleUploadFile = async () => {
        try {
            if (!selectedFile) {
                toast.warning("Vui lòng chọn file excel!");
                return;
            }
            setPageLoading(true);
            const newFile = new File([selectedFile], selectedFile.name, {
                type: selectedFile.type,
            });
            const { data } = await fileUpload(newFile);
            setData([...data?.data, ...dataUser]);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                canUpload.current = false
                toast.success("Tải lên thành công");
                onClose();
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message || "Tải lên không thành công");
            showErrorsImportExcel(error);
        } finally {
            setPageLoading(false);
        }
    };

    const showErrorsImportExcel = (error: any) => {
        if (error?.code === RESPONSE_STATUS_CODE.ERROR_IMPORT_EXCEL) {
            setShowErrorsDialog({
                show: true,
                dataErrors: error?.data
            })
        };
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            animation
            className="custom-modal category-dialog"
            size="lg"
        >
            <Modal.Header closeButton className='spaces py-15'>
                <Modal.Title>
                    <span className='spaces m-0 text-uppercase color-primary'>Import nhân viên</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='spaces pt-0'>
                <div className='d-flex flex-column gap-12 spaces px-0 lh-xl'>
                    <Col xs={12} className="spaces pt-16 mb-3 gap-10">
                        <ButtonCustom className="button-primary">
                            <input
                                type="file"
                                hidden
                                id="upload"
                                onChange={onChange}
                                onClick={handleInputClick}
                                accept={".xlsx"}
                            />
                            <label htmlFor="upload">Chọn file</label>
                        </ButtonCustom>
                    </Col>
                    {
                        selectedFile && (<Col xs={12} className="spaces p-10 d-flex justify-content-between border rounded">
                            <div className="d-flex">
                                <div>
                                    <i className="bi bi-file-earmark-excel spaces mr-4"></i>
                                    File đã chọn:
                                </div>
                                <div className="min-w-175px ms-3">
                                    {" "}
                                    {selectedFile?.name?.length < 30
                                        ? selectedFile?.name
                                        : selectedFile?.name?.slice(0, 25) + "..."}
                                </div>
                            </div>
                            <span className="ms-2 text-gray-600 min-w-90px">
                                Size:{" "}
                                {(
                                    (selectedFile?.contentSize || selectedFile.size) /
                                    1024
                                )?.toFixed(2)}{" "}
                                KB
                            </span>
                        </Col>)
                    }
                    <Col xs={12} className=" spaces pt-6 mb-3 gap-10">
                        <p className='mx-1'>
                            Khuyến cáo: Mỗi lần tải lên file excel, số lượng nhân viên không quá 100 người để dễ dàng kiểm tra nếu có lỗi.
                        </p>
                        <p className='mx-1'>
                            <a href="/media/import/Mau-import-nhan-vien.xlsx" className="hyperlink">Tải file mẫu</a>
                        </p>
                    </Col>
                    
                    <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
                        <Button
                            onClick={handleUploadFile}
                            className="spaces button-primary py-4 fs-12 h-30"
                        >
                            Tải lên
                        </Button>
                        <Button
                            onClick={onClose}
                            className="spaces button-primary-outline py-4 fs-12 h-30"
                        >
                            Hủy
                        </Button>
                    </Col>
                    {showErrorsDialog.show && <ShowErrorsImportExcelDialog
                        show={showErrorsDialog.show}
                        onClose={() => setShowErrorsDialog({
                            show: false,
                            dataErrors: []
                        })}
                        dataErrors={showErrorsDialog.dataErrors}
                    />}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MemberImportDialogAdd;