import { 
    BTN_APPROVE, 
    BTN_SEND_REQUEST, 
    BTN_REQUEST_EDIT, 
    BTN_CANCEL,
    AUTHORITIES_PERMISSIONS,
    AUTHORITIES_ABILITY,
    BTN_EDIT,
    BTN_DELETE
} from "../../../Constant";
import { StageSignature } from "../../constant";

const addAuthority = (button: any, ability: string) => {
    return {
        ...button,
        authority: {
            permission: AUTHORITIES_PERMISSIONS.AREA,
            ability
        }
    };
};

const BTN_REQUEST_EDIT_AUTHORITY = addAuthority(BTN_REQUEST_EDIT, AUTHORITIES_ABILITY.APPROVE);
const BTN_SEND_REQUEST_AUTHORITY = addAuthority(BTN_SEND_REQUEST, AUTHORITIES_ABILITY.REQUEST);
const BTN_APPROVE_AUTHORITY = addAuthority(BTN_APPROVE, AUTHORITIES_ABILITY.APPROVE);
const BTN_EDIT_AUTHORITY = addAuthority(BTN_EDIT, AUTHORITIES_ABILITY.ACTION);
const BTN_DELETE_AUTHORITY = addAuthority(BTN_DELETE, AUTHORITIES_ABILITY.ACTION);

export const AREA_CATEGORY_BUTTONS = [
    BTN_SEND_REQUEST_AUTHORITY,
    BTN_REQUEST_EDIT_AUTHORITY,
    BTN_APPROVE_AUTHORITY,
    BTN_EDIT_AUTHORITY,
    BTN_DELETE_AUTHORITY,
]

export const DEFAULT_GROUP_BUTTONS = {
    [BTN_SEND_REQUEST.name]: false,
    [BTN_REQUEST_EDIT.name]: false,
    [BTN_APPROVE.name]: false,  
    [BTN_EDIT.name]: false,
    [BTN_DELETE.name]: false,
    btnAddArea: false,
    btnAddAreaSave: false,
};

export const INITIALIZATION_GROUP_BUTTONS = {
    ...DEFAULT_GROUP_BUTTONS,
    [BTN_SEND_REQUEST.name]: true,
    [BTN_EDIT.name]: true,
    [BTN_DELETE.name]: true,
    btnAddArea:true,
    btnAddAreaSave:true,
}

export const REQUEST_FOR_MODIFICATION_GROUP_BUTTONS = {
    ...DEFAULT_GROUP_BUTTONS,
    [BTN_SEND_REQUEST.name]: true,
    [BTN_EDIT.name]: true,
    btnAddArea:true,
    btnAddAreaSave:true,
}

export const PENDING_APPROVAL_GROUP_BUTTONS = {
    ...DEFAULT_GROUP_BUTTONS,
    [BTN_REQUEST_EDIT.name]: true,
    [BTN_APPROVE.name]: true,
}

export const GROUP_BOTTON_CLOSE = {
    ...DEFAULT_GROUP_BUTTONS,
    // [BTN_CANCEL.name]: true,
}

export const PENDING_APPROVAL_USER_APPROVE_BOTTONS = {
    ...DEFAULT_GROUP_BUTTONS,
    [BTN_REQUEST_EDIT.name]: true,
    [BTN_APPROVE.name]: true
}

export const CONFIG_BUTTON = {
    [StageSignature.INITIALIZATION]:INITIALIZATION_GROUP_BUTTONS,
    [StageSignature.PENDING_APPROVAL]:DEFAULT_GROUP_BUTTONS,
    [StageSignature.PENDING_APPROVAL_USER_APPROVE]:PENDING_APPROVAL_GROUP_BUTTONS,
    [StageSignature.REQUEST_FOR_MODIFICATION]:REQUEST_FOR_MODIFICATION_GROUP_BUTTONS,
    [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]:DEFAULT_GROUP_BUTTONS,
    [StageSignature.CURRENT]:DEFAULT_GROUP_BUTTONS,
    [StageSignature.CURRENT_USER_APPROVE]:DEFAULT_GROUP_BUTTONS,
}

