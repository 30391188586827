import axios from "axios";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const getColorConfig = () => {
    const url = API_URL + "/colors/all";
    return axios.get(url);
};

export const getListStatus = () => {
    const url = API_URL + "/status/all";
    return axios.get(url);
};

export const saveStatus = (data: any) => {
    const url = API_URL + "/colors";
    if (data?.id) {
        return axios.put(`${url}/${data.id}`, data);
    } else {
        return axios.post(url, data);
    }
};

export const deleteStatus = (id: string) => {
    const url = API_URL + "/colors";
    if (id) {
        return axios.delete(`${url}/${id}`);
    }
};