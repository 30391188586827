import { useNavigate } from 'react-router-dom'
import { useNotification } from '../../../../app/AppContext'
import { NOTIFICATION_CONFIG_CODE, STATUS_READ_NOTI, URL_ROUTES } from '../../../../app/modules/constant'
import { getDataNotifications, updateStatusNotifications } from '../../../../app/modules/services'
import { INoti } from './HeaderNotificationsMenu'
import { handleError } from '../../../../app/modules/utils/FunctionUtils'
import ScoreLogs from './ScoreLogs'
import { useCallback, useEffect, useRef, useState } from 'react'

type Props = {
    listNotifications: INoti[],
    onClose?: () => void
}

function NotificationList({ listNotifications, onClose }: Props) {

    const navigate = useNavigate()
    const { setListNotifications } = useNotification()
    const [openScoreLogs, setOpenScoreLogs] = useState<boolean>(false);
    
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState<number>(1)
    const observer = useRef<IntersectionObserver | null>(null);

    const loadNotifications = useCallback(async () => {
        try {
            const res = await getDataNotifications({
                pageIndex: page,
                pageSize: 10,
            });

            setListNotifications((prevNotifications) => [...prevNotifications, ...res?.data?.data]);

            if (res?.data?.data.length < 10) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error loading notifications:', error);
        }
    }, [page, setListNotifications]);

    useEffect(() => {
        loadNotifications();
    }, [loadNotifications, page]);

    const lastNotificationRef = useCallback((node: HTMLDivElement) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [hasMore]);

    const handleViewNotifications = async (item: INoti) => {
        try {
            if (item?.status === STATUS_READ_NOTI.UN_READ) {
                await updateStatusNotifications([item?.id])
                setListNotifications((prev) => prev?.map((notification: INoti) => ({
                    ...notification,
                    status: notification?.id === item.id ? STATUS_READ_NOTI.READ : notification?.status
                })))
            }
            if (item.configCode === NOTIFICATION_CONFIG_CODE.SCORE_CHANGE) {
                setOpenScoreLogs(true);
            } else {
                item?.url === URL_ROUTES.AREAS ?
                    navigate(`/${item?.url}/${item?.idUrl}`, { state: { isFromNotification: null, urlId: item?.idUrl } })
                    : item.configCode === NOTIFICATION_CONFIG_CODE.ASSIGNMENT_FOR_CORRECTION ? 
                    navigate(`/${item?.url}/${item?.idUrl}`, { state: { isFromNotification: true, isRepaired: "0" } })
                    :
                    navigate(`/${item?.url}/${item?.idUrl}`, { state: { isFromNotification: true } });
            }
            onClose && onClose();
        } catch (error) {
            handleError(error)
        }
    };

    return (
        <>
            {Boolean(listNotifications?.length)
                    ?
                    <>
                        {listNotifications.map((item, index) => (
                            <div key={`alert${item?.id}`}
                                className={'d-flex flex-stack py-4 hover-gray px-8 cursor-pointer'}
                                onClick={() => {
                                    handleViewNotifications(item)
                                }}
                                ref={index === listNotifications.length - 1 ? lastNotificationRef : null}
                            >
                                <div className='d-flex align-items-center'>
                                    <div className='mb-0 me-2 hover-elevate-up'>
                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                            {item?.title}
                                        </div>
                                        <div className={`fs-7 ${item?.status === STATUS_READ_NOTI.UN_READ ? "text-black" : "text-muted"}`}>
                                            {item?.content}
                                        </div>
                                        <div className={`fs-7 ${item?.status === STATUS_READ_NOTI.UN_READ ? "text-notifi" : "text-muted"}`}>
                                            {item?.sentTime} từ {item?.createdBy}
                                        </div>
                                    </div>
                                </div>

                                <span className='badge badge-light fs-8'></span>
                                {
                                    item?.status === STATUS_READ_NOTI.UN_READ && (
                                        <span className=''><i className="text-notifi fa-solid fa-circle fs-9" /></span>
                                    )
                                }
                            </div>
                        ))}
                    </> :
                    <div className='py-3 text-center'>
                        Không có thông báo.
                    </div>
            }
            {openScoreLogs && (
                <ScoreLogs
                    show={openScoreLogs}
                    onClose={() => setOpenScoreLogs(false)}
                />
            )}
        </>
    )
}

export default NotificationList