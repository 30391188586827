import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import CategoryMemberTab from "../component/CategoryMemberTab";
import { MemberModel, ZoneModel } from "../model/CategoryModel";
import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import AreaCategoryTab from "../component/AreaCategoryTab";
import { CONFIG_TABLE_BUTTON } from "../config/ConfigTableAction";
import { hasAuthority } from "../../utils/FunctionUtils";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, STATUS_CODE_5S } from "../../../Constant";

export const ColumnZoneCategory = (props: any) => {
  const { rootAreaFullInfo, handleEditCategory, handleDeleteCategory, handleAddNewCategory, handleShowInfoUser } = props;

  return [
    {
      name: "",
      field: "",
      headerStyle: {
        minWidth: "40px",
        maxWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        padding: "0px",
      },
      render: (row: any, isExpand: any) => (
        <div className="expand-icon-wrapper">
          {row?.children?.length > 0 && (
            isExpand ? <i className="bi bi-chevron-down"></i> : <i className="bi bi-chevron-right"></i>
          )} 
        </div>
      ),
    },
    ...(hasAuthority(AUTHORITIES_PERMISSIONS.AREA, AUTHORITIES_ABILITY.SEARCH) ? [{
      name: "TT",
      field: "",
      headerStyle: {
        width: "80px",
      },
      render: (row: any) => (
        <GroupTableButton
          elementAbility={CONFIG_TABLE_BUTTON[rootAreaFullInfo?.stageCode]}
          handleAddNew={() => handleAddNewCategory(row)}
          handleEdit={() => handleEditCategory(row)}
          handleDelete={() => handleDeleteCategory(row)}
          isIconEditOrAccess={false}
        />
      ),
    }] : []),
    {
      name: "Mã khu vực",
      field: "code",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
      },
    },
    {
      name: "Tên khu vực",
      field: "name",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Chịu trách nhiệm",
      field: "employeeName",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span className="hyperlink"
          onClick={() => handleShowInfoUser && handleShowInfoUser(row?.empOnusArea)}
        >{row?.employeeName}</span>
      )
    },
    {
      name: "Ghi chú",
      field: "note",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
};

export const ColumnMemberCategory = (props: any) => {
  const { handleEditCategory, handleDeleteCategory } = props;

  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "40px",
        maxWidth: "50px",
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        width: "80px",
      },
      render: (row: any) => (
        <GroupTableButton 
          elementAbility={{"EDIT": true}}
          handleEdit={() => handleEditCategory(row)}
          handleDelete={() => handleDeleteCategory(row)}
          isIconEditOrAccess={false}
        />
      ),
    },
    {
      name: "Họ và tên",
      field: "fullName",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Năm sinh",
      field: "birthYear",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
        textAlign: "center"
      },
    },
    {
      name: "Giới tính",
      field: "gender",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData: any) => listGender.find(item => item.code === rowData.gender)?.name || ""
    },
    {
      name: "Địa chỉ",
      field: "address",
      headerStyle: {
        minWidth: "160px",
        maxWidth: "180px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Tên tài khoản",
      field: "username",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
};

export const ACTIVE_STATUS = {
  code: "1",
  name: "Hoạt động",
};

export const INACTIVE_STATUS = {
  code: "2",
  name: "Không hoạt động",
};

export const LIST_STATUS = [
  ACTIVE_STATUS,
  INACTIVE_STATUS
];

export const INIT_ZONE_CATEGORY_DATA: ZoneModel = {
  id: "",
  code: "",
  name: "",
  parentAreaId: "",
  active: ACTIVE_STATUS.code,
  empOnusArea: "",
  note: "",
  level: 1,
  location: "",
  statusName: "",
  path: "",
  image: "",
  employees: [],
  orgId: "",
}

export const INIT_MEMBER_CATEGORY_DATA: MemberModel = {
  code: null,
  fullName: "",
  birthYear: null,
  gender: "",
  address: "",
  phone: "",
  email: null,
  username: "",
  is5s: false,
  password: "",
  confirmPassword: "",
  orgId: "",
  roles:[],
  areas: [],
}

export const LIST_RESPONSIBLE = [
  {
    name: "Nguyễn Văn A",
  },
  {
    name: "Nguyễn Văn B",
  },
];

export const LIST_AREA = [
  {
    name: "Khu vực hành chính văn phòng",
    code: "KHCVP"
  },
  {
    name: "Khu vực nhân sự",
    code: "KHCNS"
  },
  {
    name: "Khu vực kế toán và tài chính",
    code: "KHKTTC"
  },
  {
    name: "Khu vực tiếp thị và quảng cáo",
    code: "KHTTQC"
  },
  {
    name: "Khu vực chung",
    code: "KHC"
  },
];

export const getTabList = (thongTinTab?: any) => {
  const tabList = [
    {
      eventKey: "0",
      title: "Danh mục khu vực",
      component: <AreaCategoryTab />,
    },
    {
      eventKey: "1",
      title: "Danh mục nhân viên",
      component: <CategoryMemberTab />,
    },
  ]
  return tabList
}

export const listGender = [
  { code: "MALE", name: "Nam" },
  { code: "FEMALE", name: "Nữ" },
  { code: "LGBT", name: "LGBT" },
  { code: "ETC", name: "Khác" },
];