/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import './custom.scss';
import NotificationList from './NotificationList';
import { useRef } from 'react';

export type INoti = {
    length: number;
    configCode: string;
    content: string | null;
    createdBy: string;
    createdDate: string;  // ISO 8601 format date string
    id: number;
    idUrl:string
    url:string
    modifiedBy: string;
    modifiedDate: string; // ISO 8601 format date string
    orgId: string | null;
    sentTime: string;     // DD/MM/YYYY HH:mm:ss format date string
    status: string;
    title: string;
    userReceived: string;
    userSend: string | null;
}
interface Iprops {
    listNotifications:INoti[]
}

const HeaderNotificationsMenu = (props: Iprops) => {
  const { listNotifications = [] } = props;
  const ref = useRef<any>();

  const handleClose = () => {
    ref?.current && ref.current.classList.remove('show');
  }

  return (
    <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
    ref={ref}
  >
    <div
        className='d-flex align-items-center justify-content-between bgi-no-repeat rounded-top border-bottom'
    >
        <h3 className='fw-bold px-9 mt-10 mb-6'>
            Thông báo <span className='fs-8 opacity-75 ps-3'>{listNotifications?.length} Bản ghi</span>
        </h3>
        <Link to={"/notifications"} className={`fs-6  px-5 mt-10 mb-6 text-notifi cursor-pointer`}>Xem tất cả</Link>

    </div>

    <div className='tab-content'>
      <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='scroll-y mh-400px my-5'>
              <NotificationList listNotifications={listNotifications} onClose={handleClose} />
            </div>
      </div>
    </div>
  </div>
  )
}

export default HeaderNotificationsMenu