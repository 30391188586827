import clsx from "clsx";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useState } from "react";
import "./treeViewTable.scss";

interface columnNamesType {
    name: string;
    field: string;
    sorting?: boolean;
    action?: boolean;
    headerStyle?: React.CSSProperties | object;
    cellStyle?: React.CSSProperties | object;
    isVisible?: boolean;
    colSpan?: number
    render?: (
        data: any,
        isExpand: boolean,
    ) => any;
}

type Props = {
    height?: string,
    data: any[],
    columns: columnNamesType[],
}

interface TreeItemProps {
    row: any,
    columns: columnNamesType[],
    children?: React.ReactNode,
}

const TreeViewTabelRow: React.FC<TreeItemProps> = ({
    row,
    columns,
    children
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleToggle = () => {
        setIsOpen(isOpen => !isOpen);
    };

    return (
        <>
            {row.level ? (<tr
                key={row?.id}
                className={`border-bottom border cursor-pointer bg-level-${row?.level}`}
            >
                {columns?.map((column: columnNamesType, idx: number) => {
                    return (
                        column?.render ?
                            <td
                                colSpan={column?.colSpan}
                                className={`td-vertical-center : ''}${column?.action ? " action-cell fw-bold" : ""}`}
                                style={column?.cellStyle}
                                key={idx}
                                onClick={() => row?.children?.length > 0 && idx === 0 && handleToggle()}
                            >
                                {column.render(row, isOpen)}
                            </td>
                            : <td
                                className={`td-vertical-center : ''}${column?.action ? " action-cell fw-bold" : ""} user-select-text`}
                                key={idx}
                                style={column?.cellStyle}
                                onClick={() => row?.children?.length > 0 && idx === 0 && handleToggle()}
                            >
                                {row[column?.field]}
                            </td>
                    )
                }
                )}
            </tr>) : <></>}
            {isOpen && (<>{children}</>)}
        </>
    );
};

const TreeViewTable = ({
    data,
    columns,
    height,
}: Props) => {
    const { lang } = useMultiLanguage();

    const renderRow = (rows: any) => {
        return rows?.map((row: any) => (
            <TreeViewTabelRow
                row={row} 
                columns={columns}
            >
                {row.children && renderRow(row.children)}
            </TreeViewTabelRow>
        ));
    };

    return (
        <>
            <div className="table-responsive customs-collapse-row m-0 tree-view-table" style={{height: height}}>
                <table
                    className="table-row-dashed dataTable table w-100"
                    id="kt_table_users"
                >
                    <thead
                        className={clsx("position-sticky top-0 z-index-1")}
                    >
                        <tr className="text-header-table fw-600 fw-bolder gs-0 border">
                            {columns?.map((column: columnNamesType, index: number) => {
                                return (
                                    <th
                                        key={column?.field + index}
                                        className={clsx(`p-table bg-header-table text-center`)}
                                        style={column?.headerStyle}
                                    >
                                        {column?.name}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className={""}>
                        {data[0]?.children?.length > 0
                            ? (renderRow(data))
                            : (
                                <tr>
                                    <td colSpan={columns.length} className="text-center border">
                                        {lang("TABLE.DATA.EMPTY")}
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TreeViewTable;