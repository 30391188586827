import { STATUS_CODE_5S } from "../../../Constant";


const CheckStatusEvaluate = (code: string, name: string) => {
  const STATUS_CLASSNAME = {
    [STATUS_CODE_5S.CHUA_DANH_GIA]: "status new",
    [STATUS_CODE_5S.DANG_DANH_GIA]: "status in-progress",
    [STATUS_CODE_5S.DA_DANH_GIA]: "status completed",
    other: "status other",
  }
  return (
    <div className="flex-center">
      <small className={`${STATUS_CLASSNAME[code] || STATUS_CLASSNAME["other"]} spaces min-w-110 spaces px-10 py-1 rounded text-center`}>
        {name}
      </small>
    </div>
  );
};

export default CheckStatusEvaluate;
