import React, { useEffect, useState } from 'react';
import { useLoading } from '../../../AppContext';
import TreeViewAreaCategory from './TreeViewAreaCategory';
import { getAllParentZoneCategories } from '../services/CategoryServices';
import { toast } from 'react-toastify';
import { Alert, Col, Row } from 'react-bootstrap';
import { GeneralSearchParamsType } from '../../utils/ParamsUtils';
import { ZoneModel } from '../model/CategoryModel';
import { INIT_ZONE_CATEGORY_DATA } from '../const/CategoryConst';
import CategoryZoneDialogAdd from './CategoryZoneDialogAdd';
import InputSearch from '../../component/input-field/InputSearch';
import "../styles/styles.scss";
import { DEFAULT_DISABLE } from '../config/ConfigDisabledField';
import AreaCategoryDetailInfo from './AreaCategoryDetailInfo';
import ButtonCustom from '../../component/button-custom';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from '../../../Constant';
import { useAuth } from '../../auth';
import { useLocation } from 'react-router-dom';

interface AreaCategoryTabProps {
    // Define the props for your component here
}

const AreaCategoryTab: React.FC<AreaCategoryTabProps> = () => {
    const { setPageLoading } = useLoading();
    const { currentUser } = useAuth();
    const location = useLocation()
    const [rootAreaInfo, setRootAreaInfo] = useState<ZoneModel>(INIT_ZONE_CATEGORY_DATA);
    const [areaInfo, setAreaInfo] = useState<ZoneModel>(INIT_ZONE_CATEGORY_DATA);
    const [categoryList, setCategoryList] = useState<any>([]);
    const [showDialogAddNew, setShowDialogAddNew] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>("");
    const locationState = location?.state as { urlId?: number };
    const categoryIdSelected = locationState?.urlId || rootAreaInfo?.id || "";
    useEffect(() => {
        updatePageData(true)
    }, []);

    const handleAddNewCategory = (data: any) => {
        setAreaInfo(data);
        setShowDialogAddNew(true);
    };

    const getRootAreaCategoryList = async (params: GeneralSearchParamsType, isLoadingPageFirst: boolean = false) => {
        try {
            setPageLoading(true);
            const { data } = await getAllParentZoneCategories({
                ...params,
                orgId: currentUser?.orgId
            });
            setCategoryList(data?.data?.content)
            isLoadingPageFirst && setRootAreaInfo(data?.data?.content[0]);
        } catch (error) {
            toast.error(error as string);
            console.error(error);
        } finally {
            setPageLoading(false);
        }
    };

    const updatePageData = (isFirstLoadingPage: boolean = false) => {
        getRootAreaCategoryList({}, isFirstLoadingPage);
    };

    return (
        <div className="category bg-white area-category-edit-page wrapper-content">
            <Row>
                <Col xs={3}>
                    <div className="spaces pb-10">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="spaces fs-18 fw-bold pt-20 pb-10 border-bottom">Danh sách layout</div>
                            <div className='d-flex align-items-center spaces gap-10'>
                                <ButtonCustom
                                    className="spaces button-primary py-4 fs-12 h-30 min-w-105"
                                    onClick={() => handleAddNewCategory(INIT_ZONE_CATEGORY_DATA)}
                                    checkAuthority={{
                                        permission: AUTHORITIES_PERMISSIONS.AREA,
                                        ability: AUTHORITIES_ABILITY.ACTION
                                    }}
                                >
                                    Thêm mới Layout
                                </ButtonCustom>
                            </div>
                        </div>
                        <div className='w-100'>
                            <InputSearch
                                className='spaces h-31'
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
                                handleSearch={() => getRootAreaCategoryList({ keyword: keyword }, true)}
                                placeholder="Nhập tên"
                                isEnter
                            />
                        </div>
                    </div>
                    <div>
                        <TreeViewAreaCategory
                            data={categoryList}
                            handleGetAreaInfo={setRootAreaInfo} 
                            selectedIdDefault={categoryIdSelected}
                        />
                    </div>
                </Col>
                <Col xs={9}>
                    <AreaCategoryDetailInfo
                        rootAreaInfo={rootAreaInfo}
                        updateLstRootArea={updatePageData}
                    />
                </Col>
            </Row>

            {showDialogAddNew && (
                <CategoryZoneDialogAdd
                    show={showDialogAddNew}
                    onClose={() => setShowDialogAddNew(false)}
                    data={areaInfo}
                    updateLstRootArea={()=>{updatePageData(true)}}
                    disabledFieldList={DEFAULT_DISABLE}
                    isRoot={true}
                />
            )}
        </div>
    );
};

export default AreaCategoryTab;