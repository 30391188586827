import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TableCustom from "../component/table-custom/TableCustom";
// import { getEvaluates } from "./EvaluateServices";
import { ColumnsRepeairDetail } from "./const/RepeairConst";
import AppContext from "../../AppContext";
import InputSearch from "../component/input-field/InputSearch";
import { getAllRepeair } from "./services/RepeairServices";
import { SearchBase, searchBaseDefaultValue } from "../utils/InterfaceUtils";
import { useAuth } from "../auth";
import { checkColorByStatus, handleError } from "../utils/FunctionUtils";
import { getMemberInfoById } from "../category/services/CategoryServices";
import HeaderUserInfoModal from "../../../_metronic/partials/layout/header-menus/HeaderUserInfoModal";
import TabMenu from "../component/tabs/TabMenu";
import { STATUS_CODE_5S } from "../../Constant";

const Repeair = () => {

  const { setPageLoading } = useContext(AppContext);
  const { currentUser } = useAuth();
  const [keyword, setKeyword] = useState<string>("");
  const [searchObject, setSearchObject] = useState<SearchBase>({
    ...searchBaseDefaultValue,
    orgId: currentUser?.orgId,
  });
  const [tableData, setTableData] = useState<any>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const navigate = useNavigate();
  const [showInfoUserModal, setShowInfoUserModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [ eventKey, setEventKey ] = useState<string>("0");

  const handleEdit = (row: any) => {
    navigate(`/evaluate/${row.id}`, {
      state: {
        proImplScheduleId: row.id,
        areaId: row.areaId,
        termFormId: row.termFormId,
        areaName: row.areaName,
        programId: row.programId,
        termAreaId: row.termAreaId,
        isRepaired: [STATUS_CODE_5S.DA_DANH_GIA, STATUS_CODE_5S.KHAC_PHUC].includes(row?.statusCode) ? "0" : [STATUS_CODE_5S.DA_KHAC_PHUC].includes(row?.statusCode) ? "1" : null,
      }
    });
  };

  const handleView = (row: any) => {
    console.log(row, "Chọn màn xem");
  };

  const handleDelete = (row: any) => {
    console.log(row, "Chọn màn xóa");
  };

  const handleChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    setSearchObject((preState) => ({
      ...preState,
      pageIndex: searchBaseDefaultValue.pageIndex,
      keyword
    }));
  };

  const updateData = async (searchObj: any) => {
    try {
      setPageLoading(true)
      let { data } = await getAllRepeair({
        ...searchObj,
        statusCodes: eventKey === "1" ? STATUS_CODE_5S.DA_KHAC_PHUC : [STATUS_CODE_5S.DA_DANH_GIA, STATUS_CODE_5S.KHAC_PHUC].join(",")
      });
      let newData = data.data.content;
      let pageable = data.data.pageable;
      setTableData(newData);
      setTotalPages(pageable.totalPages);
      setTotalElements(pageable.totalElements);
      setNumberOfElements(pageable.pageNumber);
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleShowInfoUser = async (id: string) => {
    try {
      setPageLoading(true);
      let res = await getMemberInfoById(id);
      let userData = res.data?.data;
      setUserData(userData);
      setShowInfoUserModal(true);
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  }

  const setRowColorClass = (row: any, rowId: string) => {
    checkColorByStatus(row?.statusCode, rowId);
    return "";
  };

  const handleChangeTab = (eventKey: string) => {
    setEventKey(eventKey);
  };

  const renderUI = () => {
    return (
      <>
        <Row className='flex flex-space-between spaces pb-6'>
          <Col xs={9}></Col>
          <Col xs={3}>
            <InputSearch
              className='spaces h-31'
              handleChange={handleChange}
              handleSearch={handleSearch}
              placeholder="Tìm kiếm đánh giá khu vực..."
              isEnter
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <TableCustom
              id="setOfCriteria"
              height={"calc(100vh - 348px)"}
              minHeight={230}
              data={tableData || []}
              columns={ColumnsRepeairDetail({
                handleEditRepeair: handleEdit,
                handleDeleteRepeair: handleDelete,
                handleViewRepeair: handleView,
                handleShowInfoUser
              })}
              buttonAdd={false}
              buttonExportExcel={false}
              notDelete={false}
              justFilter={true}
              updatePageData={updateData}
              objectSearch={searchObject}
              totalPages={totalPages}
              totalElements={totalElements}
              numberOfElements={numberOfElements}
              updateURLParams
              headerClasses="text-center"
              setRowColorClass={setRowColorClass}
            />
          </Col>
        </Row>

        {showInfoUserModal && (
          <HeaderUserInfoModal
            show={showInfoUserModal}
            onClose={() => setShowInfoUserModal(false)}
            currentUser={userData}
            isJustUserInfo={true}
          />
        )}
      </>
    )
  };

  const tabList = [
    {
      eventKey: "0",
      title: "Tiêu chí cần khắc phục",
      component: renderUI(),
    },
    {
      eventKey: "1",
      title: "Tiêu chí đã khắc phục",
      component: renderUI(),
    },
  ];


  return (
    <div className='wrapper-content'>
      <Row>
        <Col xs="12" className='my-5'>
          <h3 className='spaces m-0 text-uppercase color-primary'>Khắc phục</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <TabMenu
            danhsachTabs={tabList}
            propActiveTab={eventKey}
            handleChangeTab={((eventKey: string) => handleChangeTab(eventKey))}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="my-5">
          <h3 className='spaces m-0 text-uppercase color-primary'>THỰC HIỆN KHẮC PHỤC 5S</h3>
        </Col>
      </Row>

    </div>
  );
};

export default Repeair;
