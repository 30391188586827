import { FC, memo } from 'react';
import { Button as ButtonBootstrap, ButtonProps } from 'react-bootstrap';
import { hasAuthority } from '../../utils/FunctionUtils';
import "./style.scss";

interface ButtonCustomProps extends ButtonProps {
    name?: string,
    typeModule?: string,
    color?: 'primary' | 'secondary',
    checkAuthority?: {
        permission: string,
        ability: string,
    }
}

const Button: FC<ButtonCustomProps> = (props) => {
    const {
        name,
        checkAuthority,
        typeModule,
        className,
        color = 'primary',
        children,
        ...rest
    } = props;

    const authority = checkAuthority ? hasAuthority(checkAuthority.permission, checkAuthority.ability, typeModule) : true;

    return (
        <>
            {authority ? (
                <ButtonBootstrap 
                    {...rest}
                    variant={color}
                    className={`spaces button-${color} py-4 h-30 mx-4 ${className}`}
                >
                    {children}
                </ButtonBootstrap>
            ) : null}

        </>
    );
}

export default memo(Button);