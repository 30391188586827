import { EVALUATE_STATUS } from "./EvaluateConst";
import { StageSignature } from "../../constant";

const DISABLE_STATUS_DISABLED_FIELD_LIST = {
    id: true,
    evaluateDate: true,
    areaName: true,
    note: true,
    table: true,
    conclusion: true,
    totalScore: true,
    resultText: true,
    pictrure_kp: true,
    analysis: true,
}

export const DISABLE_STATUS = {
    [StageSignature.NOT_EVALUATED]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
        evaluateDate: false,
        note: false,
        table: false,
        conclusion: false,
        analysis: false,
    },
    [StageSignature.UNDER_EVALUATION]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
        evaluateDate: false,
        note: false,
        table: false,
        conclusion: false,
        analysis: false,
    },
    [StageSignature.PENDING_APPROVAL]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.PENDING_APPROVAL_USER_APPROVE]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.REQUEST_FOR_MODIFICATION]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
        evaluateDate: false,
        note: false,
        table: false,
        conclusion: false,
    },
    [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.EVALUATED]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.EVALUATED_USER_ONUS]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.FIX_EVALUATED]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [StageSignature.UNKNOWN]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
    },
    [EVALUATE_STATUS.UNDER_EVALUATION]: {
        ...DISABLE_STATUS_DISABLED_FIELD_LIST,
        evaluateDate: false,
        note: false,
        table: false,
        conclusion: false,
    },
};