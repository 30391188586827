export const HistoryRequestColumn = (props: any) => {
  return [
    {
      name: "Ngày trình duyệt",
      field: "requestDate",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      name: "Nội dung",
      field: "",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any, index: number) => (
        <span>{row?.textRefuse || row?.textOpinions}</span>
      )
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      name: "Tài khoản duyệt",
      field: "userApprove",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
  ];
}