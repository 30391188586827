import { ICriteria } from "../models/SetOfCriteriaModels";
import { SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import CriteriaAddNewDialog from "./CriteriaAddNewDialog";
import { INIT_CRITERIA_DATA } from "../const/SetOfCriteriaConst";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import CriteriaCategoryAddNewDialog from "./CriteriaCategoryAddNewDialog";
import SetOfCriteriaContext from "./SetOfCriteriaContext";
import useStateRef from "../../utils/useStateRef";

type TProps = {
    hasRootArea: boolean;
}
const CriteriaTable = ({
    hasRootArea,
}: TProps) => {
    const {
        criteriaList,
        setCriteriaList,
        formTableAbility
    } = useContext(SetOfCriteriaContext);

    const criteriaListRef = useRef<ICriteria[]>(criteriaList);

    const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [shouldOpenCriteriaAddNewDialog, setShouldOpenCriteriaAddNewDialog] = useState<boolean>(false);
    const [shouldOpenCriteriaCategoryAddNewDialog, setShouldOpenCriteriaCategoryAddNewDialog] = useState<boolean>(false);
    const [openCategoryDialog, setOpenCategoryDialog] = useState<boolean>(false);

    const [criteriaInfo, setCriteriaInfo] = useState<ICriteria>(INIT_CRITERIA_DATA);
    const [hasDeleteCategory, setHasDeleteCategory] = useState<boolean>(false);
    const [, setShortNameCriteria, shortNameCriteriaRef] = useStateRef<string>("1");

    // const shortNameCriteria = useMemo(() =>  {
    //    return criteriaList.length ? (criteriaList.filter(criteria => !criteria?.shortNameParent).length + 1).toString() : "1"
    // }, [criteriaList]);

    useEffect(() => {
        criteriaListRef.current = criteriaList;
        if (criteriaList?.length === 0) {
            setShortNameCriteria("1");
        };
    },[criteriaList])

    const handleAddCriteria = (criteria: ICriteria) => {
        const criteriaListTemp: SetStateAction<ICriteria[]> = [];
        criteriaList.forEach((criteriaTemp: ICriteria) => {
            if (criteriaTemp?.hasRowButton && criteria?.shortNameParent === criteriaTemp?.shortNameParent) {
                criteriaListTemp.push(criteria, { ...criteriaTemp, shortName: (parseInt(criteriaTemp.shortName) + 1).toString() });
            } else {
                criteriaListTemp.push(criteriaTemp);
            }
        })
        setCriteriaList(criteriaListTemp);
    };

    const handleUpdateCriteria = (criteria: ICriteria) => {
        const criteriaListTemp = criteriaList.map(criteriaItem => {
            return criteriaItem?.shortName === criteria?.shortName && !criteriaItem?.hasRowButton ? criteria : criteriaItem;
        })
        setCriteriaList(criteriaListTemp);
    };

    const handleDeleteCriteria = (criteriaId: string) => {
        const criteriaListTemp = criteriaList.filter(criteriaItem => {
            return hasDeleteCategory
                ? criteriaItem?.shortNameParent
                    ? criteriaItem?.shortNameParent !== criteriaId
                    : criteriaItem?.shortName !== criteriaId
                : criteriaItem?.shortName !== criteriaId;
        });

        let shortNameParentTemp = 0;
        let shortNameTemp = 0;
        const criteriaListAfterDelete = criteriaListTemp.map((criteriaItem) => {
            if (criteriaItem?.shortNameParent) {
                ++shortNameTemp;
                return {
                    ...criteriaItem,
                    shortNameParent: `${shortNameParentTemp}`,
                    shortName: criteriaItem?.hasRowButton ? `${shortNameTemp}` : `${shortNameParentTemp}.${shortNameTemp}`,
                }
            } else {
                ++shortNameParentTemp;
                shortNameTemp = 0;
                return {
                    ...criteriaItem,
                    shortName: `${shortNameParentTemp}`,
                }
            }
        })

        setCriteriaList(criteriaListAfterDelete);
        setShouldOpenConfirmDeleteDialog(false);
    };

    const handleAddCriteriaCategory = (categoryName: string) => {
        const findIndex = criteriaListRef.current.findIndex(criteria => criteria?.shortNameParent === (parseInt(shortNameCriteriaRef.current) - 1).toString() && criteria.hasRowButton);
        let listCriterial: ICriteria[] = [...criteriaListRef.current];
        const listSliceFirst = listCriterial.slice(0, findIndex + 1);
        const listSliceLast = listCriterial.slice(findIndex + 1);
        const newListSliceLast = autoIncrementShortName(listSliceLast);

        const newListCriterial = [
            ...listSliceFirst,
            {
                shortName: shortNameCriteriaRef.current,
                name: categoryName,
                description: null,
                shortNameParent: null
            },
            {
                shortName: "1",
                shortNameParent: shortNameCriteriaRef.current,
                name: categoryName,
                hasRowButton: true
            },
            ...newListSliceLast,
        ]

        setCriteriaList(newListCriterial);
        setOpenCategoryDialog(false);
        addCriteria();
    };

    const addCriteria = () => {
        setCriteriaInfo({
            ...INIT_CRITERIA_DATA,
            shortName: `${shortNameCriteriaRef.current}.1`,
            shortNameParent: shortNameCriteriaRef.current
        });
        setShouldOpenCriteriaAddNewDialog(true);
    };

    const renderRowButton = (isLastRow: boolean, criteria: ICriteria) => {
        return (
            <>
                <tr className="spaces h-32 fs-14 color-blue-600 border-y-dashed cursor-pointer bg-gray-100 ">
                    <td
                        colSpan={isLastRow ? 2 : 2}
                        className="text-center"
                        onClick={() => {
                            setCriteriaInfo({
                                ...INIT_CRITERIA_DATA,
                                shortName: `${criteria.shortNameParent}.${criteria.shortName}`,
                                shortNameParent: criteria?.shortNameParent
                            });
                            setShouldOpenCriteriaAddNewDialog(true);
                        }}
                    >
                        + Thêm tiêu chí
                    </td>
                    <td
                        colSpan={2}
                        className="text-center"
                        // hidden={!isLastRow}
                        onClick={() => {
                            handleClickBtnAddCategory(isLastRow, criteria?.shortNameParent);
                        }}
                    >
                        + Thêm danh mục
                    </td>
                </tr>
                <tr className="spaces h-1"></tr>
            </>
        )
    };

    const handleClickBtnAddCategory = (isLastRow: boolean, shortNameParent: any) => {
        let newShortNameCriteria: string | null = "1";
        if (criteriaList.length) {
            if (isLastRow) {
                newShortNameCriteria = (criteriaList.filter(criteria => !criteria?.shortNameParent).length + 1).toString();
            } else {
                const index = criteriaList.filter(criteria => !criteria?.shortNameParent).findIndex(criteria => criteria?.shortName === shortNameParent)
                newShortNameCriteria = (Number(index) + 2).toString();
            };
        };

        setShortNameCriteria(newShortNameCriteria);
        setOpenCategoryDialog(true);
    };

    const handleAddCategoryFromCriterial = (shortNameParent?: string | null) => {
        let newShortNameCriteria: string | null = "1";
        const index = criteriaList.filter(criteria => !criteria?.shortNameParent).findIndex(criteria => criteria?.shortName === shortNameParent)
        newShortNameCriteria = (Number(index) + 1).toString();
        setShortNameCriteria((parseInt(newShortNameCriteria) + 1).toString());
        setOpenCategoryDialog(true);
    };

    function autoIncrementShortName(list: ICriteria[]) {
        return list.map(item => ({
            ...item,
            shortName: (parseFloat(item.shortName) + 1).toString(),
            shortNameParent: item.shortNameParent ? (parseInt(item.shortNameParent) + 1).toString() : null,
        }));
    };

    const renderRowTable = (criteria: ICriteria, index: number) => {
        return (
            <>
                <tr key={`${criteria.shortName}`} className="spaces h-40 max-h-40 spaces fs-14 border-top">
                    <td className={`text-center ${!criteria?.shortNameParent && "fw-bolder spaces fs-16"}`}>{criteria?.shortName}</td>
                    <td className={`text-start ${!criteria?.shortNameParent && "fw-bolder spaces fs-16"}`}>{criteria?.name}</td>
                    <td className="text-start">{criteria?.description}</td>
                    {!formTableAbility.table_criteria && (
                        <td className="d-flex spaces justify-content-center align-items-center gap-4 p-4">
                            <i className="cursor-pointer bi bi-pencil-fill fs-4 text-primary px-4 fw-bold"
                                onClick={() => {
                                    setCriteriaInfo(criteria);
                                    criteria?.shortNameParent
                                        ? setShouldOpenCriteriaAddNewDialog(true)
                                        : setShouldOpenCriteriaCategoryAddNewDialog(true);
                                }}
                            />
                            <i className="cursor-pointer bi bi-trash fs-4 text-danger px-4 fw-bold"
                                onClick={() => {
                                    setCriteriaInfo(criteria);
                                    setShouldOpenConfirmDeleteDialog(true);
                                    setHasDeleteCategory(Boolean(!criteria?.shortNameParent))
                                }}
                            />
                        </td>
                    )}
                </tr>
            </>
        )
    };

    const renderAddNewCategory = () => {
        return (
            <tr className="spaces h-32 fs-14 color-blue-600 border-y-dashed cursor-pointer bg-gray-100 ">
                <td
                    colSpan={4}
                    className="text-center"
                    onClick={() => {
                        setOpenCategoryDialog(true);
                    }}
                >
                    + Thêm danh mục
                </td>
            </tr>
        )
    };

    return (
        <div className="table-responsive customs-collapse-row m-0 spaces fs-14 mt-10 ">
            <table className="dataTable table border w-100">
                <thead className="position-sticky top-0 z-index-1">
                    <tr className="text-header-table fw-bolder gs-0">
                        <th className="p-table text-center bg-header-table spaces min-w-50">STT</th>
                        <th className="p-table text-start bg-header-table spaces min-w-400">Tiêu chí</th>
                        <th className="p-table text-start bg-header-table spaces min-w-400">Mô tả</th>
                        {!formTableAbility.table_criteria && <th className="p-table text-center bg-header-table spaces min-w-120">Thao tác</th>}
                    </tr>
                </thead>
                <tbody>
                    {Boolean(criteriaList.length) ? criteriaList.map((criteria, index) => {
                        const isLastRow = criteriaList.length === index + 1;
                        if (criteria?.hasRowButton && !formTableAbility.table_criteria) {
                            return renderRowButton(isLastRow, criteria)
                        }
                        if (!criteria?.hasRowButton) {
                            return renderRowTable(criteria, index)
                        }
                        return <></>
                    })
                        :
                        <>
                            {(hasRootArea && !formTableAbility.table_criteria) ? (
                                renderAddNewCategory()
                            ) : (
                                <tr>
                                    <td colSpan={4} className="text-center">Không có bản ghi nào</td>
                                </tr>
                            )}
                        </>
                    }
                    <tr className="spaces h-1 border-bottom"></tr>
                </tbody>
            </table>
            {shouldOpenCriteriaAddNewDialog && (
                <CriteriaAddNewDialog
                    show={shouldOpenCriteriaAddNewDialog}
                    callback={(shortNameParent?: string | null) => {
                        setShouldOpenCriteriaAddNewDialog(false)
                        handleAddCategoryFromCriterial(shortNameParent);
                    }}
                    onClose={() => {
                        setShouldOpenCriteriaAddNewDialog(false)
                    }}
                    criteria={criteriaInfo}
                    handleAddCriteria={handleAddCriteria}
                    handleUpdateCriteria={(criteria: ICriteria) => handleUpdateCriteria(criteria)}
                />
            )}
            {shouldOpenCriteriaCategoryAddNewDialog && (
                <CriteriaCategoryAddNewDialog
                    show={shouldOpenCriteriaCategoryAddNewDialog}
                    handleClose={() => setShouldOpenCriteriaCategoryAddNewDialog(false)}
                    criteriaCategory={criteriaInfo}
                    handleUpdateCriteriaCategory={(criteria: ICriteria) => handleUpdateCriteria(criteria)}
                />
            )}
            {shouldOpenConfirmDeleteDialog && (
                <ConfirmDialog
                    show={shouldOpenConfirmDeleteDialog}
                    onYesClick={() => handleDeleteCriteria(criteriaInfo?.shortName)}
                    onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
                    yes="Có"
                    cancel='Hủy'
                />
            )}
            {openCategoryDialog && (
                <CriteriaCategoryAddNewDialog
                    show={openCategoryDialog}
                    callback={() => {
                        setOpenCategoryDialog(false)
                        setShouldOpenCriteriaAddNewDialog(true)
                    }}
                    handleClose={() => {
                        setOpenCategoryDialog(false)
                    }}
                    handleAddCriteriaCategory={(categoryName: string) => {
                        handleAddCriteriaCategory(categoryName)
                    }}
                />
            )}
        </div>
    )
}

export default CriteriaTable;