import { useCallback, useState } from "react";
import * as Yup from "yup";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useFormik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextValidator from "../../component/input-field/TextValidator";
import { unstable_batchedUpdates } from "react-dom";
import { ICriteria } from "../models/SetOfCriteriaModels";
import { INIT_CRITERIA_DATA } from "../const/SetOfCriteriaConst";

type TProps = {
  show: boolean;
  handleClose: () => void;
  callback?: () => void;
  criteriaCategory?: ICriteria;
  handleAddCriteriaCategory?: (categoryName: string) => void;
  handleUpdateCriteriaCategory?: (criteriaInfo: ICriteria) => void;
};

const CriteriaCategoryAddNewDialog = (props: TProps) => {
  const { 
    show, 
    criteriaCategory, 
    handleClose, 
    handleAddCriteriaCategory, 
    handleUpdateCriteriaCategory,
    callback 
  } = props;
  const { lang } = useMultiLanguage();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: ICriteria) => {
    if (criteriaCategory?.name) {
      handleUpdateCriteriaCategory?.(values)
      handleClose();
    } else {
      handleAddCriteriaCategory?.(values.name);
      callback && callback();
    }
  };

  const formikData = useFormik({
    initialValues: criteriaCategory || INIT_CRITERIA_DATA,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handlechange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    unstable_batchedUpdates(() => {
      const { value, name } = e.target;
      formikData.setFieldValue(name, value);
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      animation
      className="custom-modal category-dialog"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <span>Danh mục tiêu chí</span>
          <span className="spaces pl-20 category-dialog-titel-status">
            {criteriaCategory?.name ? "#Cập nhật" : "#Thêm mới"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formikData.handleSubmit}>
          <Row>
            <Col xs={12}>
              <TextValidator
                className="flex-row min-w-80"
                lable={"Danh mục"}
                readOnly={false}
                isRequired
                name="name"
                value={formikData.values?.name}
                type="text"
                onChange={handlechange}
                errors={formikData.errors?.name}
                touched={formikData.touched?.name}
              />
            </Col>
            <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
              <Button 
                className="spaces button-primary py-4 fs-12 h-30" 
                type="submit"
              > 
                Lưu 
              </Button>
              <Button
                onClick={handleClose}
                className="spaces button-primary-outline py-4 fs-12 h-30"
              >
                Hủy
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CriteriaCategoryAddNewDialog