import {FC, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap';

interface Props {
  show: boolean
  onCloseClick?: () => void
  onYesClick?: () => void
  onCancelClick?: () => void
  title?: string
  message?: string
  yes?: string
  cancel?: string
  close?: string
}

const ConfirmDialog: FC<Props> = (props) => {
  const {
    show,
    onCloseClick,
    onYesClick,
    onCancelClick,
    title = "Xác nhận",
    message = "Bạn có chắc muốn xóa không ?",
    yes,
    cancel,
    close
  } = props;

  useEffect(() => {
    if (show) {
      const modals = document.getElementsByClassName('custom-modal confirm-dialog');
      if (modals.length > 0) {
        const modal = modals[0];
        const modalZIndex = window.getComputedStyle(modal).zIndex;
        const modalContent: any = modal.querySelector('.modal-dialog');
        const existingBackdrop: any = modal.querySelector('.fade.modal-backdrop.show');
        if (modalContent) {
          modalContent.style.zIndex = modalZIndex;
        }
        if (!existingBackdrop) {
          const backdrop = document.createElement('div');
          backdrop.className = 'fade modal-backdrop show';
          backdrop.style.zIndex = (parseInt(modalZIndex, 10) - 1).toString();
          modal.appendChild(backdrop);
        } else {
          existingBackdrop.style.zIndex = (parseInt(modalZIndex, 10) - 1).toString();
        }
      }
    };
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      backdrop="static"
      centered
      animation
      className='custom-modal confirm-dialog'
    >
      <Modal.Header className="bg-bright-cyan p-3">
        <Modal.Title className="text-white text-uppercase">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center p-4">
        <h4 className='spaces m-0'>{message}</h4>
      </Modal.Body>
      <Modal.Footer className="p-3 center ">
        {yes && (
          <button className="button-primary" onClick={onYesClick}>
            {yes}
          </button>
        )}
        {cancel && (
          <button className="button-primary-outline" onClick={onCancelClick}>
            {cancel}
          </button>
        )}
        {close && (
          <button className="button-primary-outline" onClick={onCloseClick}>
            {close}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export {ConfirmDialog}
