import { Col, Row } from 'react-bootstrap';
import useMultiLanguage from '../../hook/useMultiLanguage';
import TabMenu from '../component/tabs/TabMenu';
import ApprovedTab from './component/ApprovedTab';
import { APPROVAL_TYPE } from './const/ApprovalConst';
import { useEffect, useState } from 'react';
import { CHECK_APPROVE } from '../../Constant';

type Props = {
    checkApprove: string,
}

const Approval = (props: Props) => {
    const { checkApprove } = props;
    const [ eventKey, setEventKey ] = useState<string>("0");

    const tabList = [
        {
            eventKey: "0",
            title: "Chờ phê duyệt",
            component: <ApprovedTab type={APPROVAL_TYPE.PENDING_APPROVAL} />,
        },
        {
            eventKey: "1",
            title: "Đã xử lý",
            component: <ApprovedTab type={APPROVAL_TYPE.APPROVED} />,
        },
    ]

    useEffect(() => {
        if (checkApprove === CHECK_APPROVE.APPROVE) {
            setEventKey("0");
        } else {
            setEventKey("1");
        }
    }, [])

    return (
        <div className='wrapper-content'>
            <Row>
                <Col xs="12" className='my-5'>
                    <h3 className='spaces m-0 text-uppercase color-primary'>Phê duyệt</h3>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <TabMenu
                        danhsachTabs={tabList}
                        propActiveTab={eventKey}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Approval;