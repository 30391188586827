import { useEffect, useState } from "react";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import TreeViewTable from "../../component/tree-view-table/TreeViewTable";
import { ColumnZoneCategory, INIT_ZONE_CATEGORY_DATA } from "../const/CategoryConst";
import CategoryZoneDialogAdd from "./CategoryZoneDialogAdd";
import { ZoneModel } from "../model/CategoryModel";
import { useLoading } from "../../../AppContext";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { deleteZoneCategories, getAllZoneCategory, getMemberInfoById } from "../services/CategoryServices";
import { DEFAULT_DISABLE, EDIT_DISABLE, VIEW_DISABLE } from "../config/ConfigDisabledField";
import { StageSignature, URL_ROUTES } from "../../constant";
import { 
    DEFAULT_GROUP_BUTTONS, 
    GROUP_BOTTON_CLOSE, 
    INITIALIZATION_GROUP_BUTTONS, 
    PENDING_APPROVAL_USER_APPROVE_BOTTONS, 
    REQUEST_FOR_MODIFICATION_GROUP_BUTTONS 
} from "../config/ConfigButton";
import GroupButton from "./GroupButton";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCustom from "../../component/button-custom";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, STATUS_CODE_5S } from "../../../Constant";
import { handleError } from "../../utils/FunctionUtils";
import AreaFormView from "./AreaFormView";
import FloatingNotification from "../../component/FloatingNotification";
import HeaderUserInfoModal from "../../../../_metronic/partials/layout/header-menus/HeaderUserInfoModal";

type TProps = {
    rootAreaInfo?: ZoneModel;
    updateLstRootArea: (isFirstLoadingPage?:boolean) => void;
}

const AreaCategoryDetailInfo = ({
    rootAreaInfo, 
    updateLstRootArea = () => { }, 
}: TProps) => {
    const navigate = useNavigate();
    const { lang } = useMultiLanguage();
    const { setPageLoading } = useLoading();
    const { id } = useParams();
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [showDialogAddNew, setShowDialogAddNew] = useState<boolean>(false);
    const [areaInfo, setAreaInfo] = useState<ZoneModel>(INIT_ZONE_CATEGORY_DATA);
    const [rootAreaId, setRootAreaId] = useState<string>("");
    const [elementAbility, setElementAbility] = useState(DEFAULT_GROUP_BUTTONS);
    const [disabledFieldList, setDisabledFieldList] = useState(DEFAULT_DISABLE);
    const [childrenAreaCategoryList, setChildrenAreaCategoryList] = useState<ZoneModel[]>([]);
    const [isRoot, setIsRoot] = useState<boolean>(false);
    const [showInfoUserModal, setShowInfoUserModal] = useState(false);
    const [userData, setUserData] = useState<any>();

    const rootAreaFullInfo = (childrenAreaCategoryList.length ? childrenAreaCategoryList[0] : INIT_ZONE_CATEGORY_DATA) as ZoneModel;

    useEffect(() => {        
        setRootAreaId(id || rootAreaInfo?.id || "");
    }, [rootAreaInfo, id]);

    useEffect(() => {
        updatePageData()
    }, [rootAreaId]);

    useEffect(() => {
        handleElementAbility(rootAreaFullInfo.stageCode || "");
    }, [childrenAreaCategoryList, areaInfo.id]);

    const getAreaCategoryList = async (rootId: string) => {
        try {
            setPageLoading(true);
            const { data } = await getAllZoneCategory(rootId);
            setChildrenAreaCategoryList(data?.data);
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setShowDialogAddNew(false);
        setShowDialogDelete(false);
    };

    const handleAddNewCategory = (row: any) => {
        setIsRoot(false);
        setShowDialogAddNew(true);
        setAreaInfo({
            ...INIT_ZONE_CATEGORY_DATA,
            parentAreaId: row?.id,
            parentAreaName: row?.name,
            orgId: row?.orgId,
        });
    }

    const handleEditCategory = (row: any) => {
        setAreaInfo(row);
        setShowDialogAddNew(true);
    };

    const handleDeleteCategory = (row: any) => {
        setAreaInfo(row);
        setShowDialogDelete(true);
    };

    const handleConfirmDelete = async () => {
        try {
            setPageLoading(true);
            areaInfo?.id && await deleteZoneCategories(areaInfo?.id);
            toast.success(lang("TOAST.DELETE.SUCCESS"));
            setShowDialogDelete(false);
            if (isRoot) {
                updateLstRootArea(true);
            } else {
                updateLstRootArea(false);
                updatePageData(); 
            }
            navigate("/" + URL_ROUTES.AREAS);
        } catch (error) {
            handleError(error)
        } finally {
            setPageLoading(false);
        }
    };

    const handleElementAbility = (stageCode: string) => {
        switch(stageCode) {
            case StageSignature.INITIALIZATION: {
                setDisabledFieldList(areaInfo.id ? EDIT_DISABLE : DEFAULT_DISABLE);
                setElementAbility(INITIALIZATION_GROUP_BUTTONS);
                break;
            }
            case StageSignature.REQUEST_FOR_MODIFICATION: {
                setDisabledFieldList(DEFAULT_DISABLE);
                setElementAbility(REQUEST_FOR_MODIFICATION_GROUP_BUTTONS);
                break;
            }
            case StageSignature.PENDING_APPROVAL_USER_APPROVE: {
                setDisabledFieldList(VIEW_DISABLE);
                setElementAbility(PENDING_APPROVAL_USER_APPROVE_BOTTONS);
                break;
            }
            case StageSignature.UNKNOWN:
            case StageSignature.PENDING_APPROVAL:
            case StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE:
            case StageSignature.CURRENT:
            case StageSignature.CURRENT_USER_APPROVE: {
                setDisabledFieldList(VIEW_DISABLE);
                setElementAbility(GROUP_BOTTON_CLOSE);
                break;
            }
            default: break;
        }
    };

    const updatePageData = () => {
        rootAreaId && getAreaCategoryList(rootAreaId);
    };

    const handleShowInfoUser = async (id: string) => {
        try {
            setPageLoading(true);
            let res = await getMemberInfoById(id);
            let userData = res.data?.data;
            setUserData(userData);
            setShowInfoUserModal(true);
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    }

    return (
        <div className={id && "category spaces mt-24 bg-white area-category-edit-page wrapper-content pt-2"}>
            <AreaFormView
                rootAreaId={rootAreaId}
                data={rootAreaFullInfo}
            />
            <div className='flex justify-center spaces py-15 mx-15 border-top'>
                <GroupButton
                    areaInfo={rootAreaFullInfo}
                    elementAbility={elementAbility}
                    updatePageData={updatePageData}
                    handleEditRoot={() => {
                        setIsRoot(true);
                        handleEditCategory(rootAreaFullInfo);
                    }}
                    handleDeleteRoot={() => {
                        setIsRoot(true);
                        handleDeleteCategory(rootAreaFullInfo);
                    }}
                />
                <div className='d-flex justify-content-end mx-2'>
                    {elementAbility?.btnAddArea &&
                        <ButtonCustom
                            name="btnAddArea"
                            className="spaces button-primary py-4 fs-12 h-30"
                            onClick={() => handleAddNewCategory(rootAreaFullInfo)}
                            checkAuthority={{
                                permission: AUTHORITIES_PERMISSIONS.AREA,
                                ability: AUTHORITIES_ABILITY.ACTION
                            }}
                        >
                            Thêm khu vực
                        </ButtonCustom>
                    }
                </div>
            </div>

            <div className="spaces pb-20">
                <TreeViewTable
                    height={"calc(100vh - 420px)"}
                    data={childrenAreaCategoryList}
                    columns={ColumnZoneCategory({
                        rootAreaFullInfo,
                        handleAddNewCategory,
                        handleEditCategory,
                        handleDeleteCategory,
                        handleShowInfoUser
                    })}
                />
            </div>

            {showDialogAddNew && (
                <CategoryZoneDialogAdd
                    rootAreaStatus={rootAreaFullInfo?.stageCode}
                    show={showDialogAddNew}
                    onClose={handleCloseDialog}
                    data={areaInfo}
                    updatePageData={updatePageData}
                    updateLstRootArea={() => {updateLstRootArea(false)}}
                    disabledFieldList={disabledFieldList}
                    isRoot={isRoot}
                />
            )}

            {(childrenAreaCategoryList[0]?.statusCode === STATUS_CODE_5S.YEU_CAU_SUA_DOI && childrenAreaCategoryList[0]?.textRefuse) &&
                <FloatingNotification content={childrenAreaCategoryList[0]?.textRefuse} />}

            {showDialogDelete && (
                <ConfirmDialog
                    show={showDialogDelete}
                    title="Xóa danh mục"
                    message={`Bạn chắc chắn muốn xóa khu vực ${areaInfo.code} ?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleConfirmDelete}
                    onCancelClick={handleCloseDialog}
                />
            )}

            {showInfoUserModal && (
                <HeaderUserInfoModal
                    show={showInfoUserModal}
                    onClose={() => setShowInfoUserModal(false)}
                    currentUser={userData}
                    isJustUserInfo={true}
                />
            )}
        </div>
    )
}

export default AreaCategoryDetailInfo;