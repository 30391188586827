export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  const basePath = getBasePath(current);
  const baseUrl = getBasePath(url);
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  if (basePath && baseUrl) {
    return basePath === baseUrl;
  }

  return false
};

function getBasePath(url: string): string {
  const segments = url.split('/').filter(segment => segment !== '');
  const basePath = `/${segments[0]}`;
  return basePath;
};
