/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "../../AppContext";
import moment from "moment";
import useMultiLanguage from "../../hook/useMultiLanguage";
import "./Statistical.scss";
import {
  getDataUnratedProgramStatistics,
} from "./services/StatisticalServices";
import { useStatisticalContext } from "./Statistical";
import TableCustom from "../component/table-custom/TableCustom";
import { GeneralSearchParamsType } from "../utils/ParamsUtils";
import { SearchBase, searchBaseDefaultValue } from "../utils/InterfaceUtils";
import { Col, Form, Row } from "react-bootstrap";
import SelectCustom from "../component/select-custom";
import { LIST_PLAN_TYPE } from "../program/consts/InitialValue";
import { heightAutocomplete } from "../component/input-field/StyleComponent";
import { useFormik } from "formik";
import InputDateCustom from "../component/input-field/InputDateCustom";
import ButtonCustom from "../component/button-custom";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from "../../Constant";
import { useAuth } from "../auth";

function UnratedProgramStatistics() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useLoading();
  const { type, year, setLoading } = useStatisticalContext();
  const { currentUser } = useAuth();

  const [data, setData] = useState<any[]>([]);
  const [searchObject, setSearchObject] = useState<SearchBase>({...searchBaseDefaultValue, orgId: currentUser?.orgId});
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);


  const updateData = async (params: GeneralSearchParamsType): Promise<void> => {
    console.log("params", params);
    
    try {
      setPageLoading(true);
      const { data } = await getDataUnratedProgramStatistics(params);
      let newData = data?.data?.content;
      let pageable = data?.data?.pageable;
      setData(newData);
      setTotalElements(pageable.totalElements);
      setNumberOfElements(pageable.pageNumber + 1);
    } catch (error) {
        console.error("ERROR", error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any) => {
    setSearchObject((preState) => ({ 
        ...preState,
        ...data,
        pageIndex: searchBaseDefaultValue.pageIndex,
    }));
  };

  const handleFormSubmit = async (formData: any) => {
    if ((formData.fromDate && !formData.toDate) || (!formData.fromDate && formData.toDate)) {
        toast.warning("Vui lòng nhập đầy đủ Từ ngày và Đến ngày");
        return;
    }
    const fromDate = formData.fromDate ? moment(formData.fromDate, "DD/MM/YYYY") : null;
    const toDate = formData.toDate ? moment(formData.toDate, "DD/MM/YYYY") : null;
    if (toDate && fromDate && toDate < fromDate) {
        toast.warning("Từ ngày phải nhỏ hơn Đến ngày");
        return;
    }
    const newParams = {
        ...formData,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
    };
    handleSearch(newParams);
  };

  const formik = useFormik({
    initialValues: {
      timeType: null,
      fromDate: null,
      toDate: null,
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <div className="category pt-4 bg-white area-category-edit-page wrapper-content">
      <div className="spaces flex-end align-center mb-10">
        <Form onSubmit={formik.handleSubmit}>
          <Row className='px-2'>
            <Col sm="1" md="1" lg="4"></Col>
            <Col sm="4" md="5" lg="3">
              <SelectCustom
                className="spaces width-100"
                label={"Loại kế hoạch"}
                name="timeType"
                valueField={'code'}
                displayField={'name'}
                options={LIST_PLAN_TYPE}
                styles={{...heightAutocomplete("32px"), menu: provided => ({ ...provided, zIndex: 9999 })}}
                value={formik.values?.timeType || null}
                handleChange={(value, _option, _actionMeta, name) => {
                  formik.setFieldValue(name, value);
                }}
              />
            </Col>
            <Col sm="3" md="2" lg="2">
              <InputDateCustom
                label="Từ ngày"
                value={formik.values?.fromDate || ""}
                setValue={(value: string) => formik.setFieldValue("fromDate", value)}
                errors={formik.errors?.fromDate}
                touched={formik.touched?.fromDate}
              />
            </Col>
            <Col sm="3" md="2" lg="2">
              <InputDateCustom
                label="Đến ngày"
                value={formik.values?.toDate || ""}
                setValue={(value: string) => formik.setFieldValue("toDate", value)}
                errors={formik.errors?.toDate}
                touched={formik.touched?.toDate}
              />
            </Col>
            <Col sm="2" md="2" lg="1" className="container-button-search">
              <ButtonCustom
                type="submit"
                className="spaces button-primary-outline py-4 fs-12 h-30"
                onClick={() => { }}
                checkAuthority={{
                  permission: AUTHORITIES_PERMISSIONS.STATISTICS,
                  ability: AUTHORITIES_ABILITY.VIEW,
                }}
              >
                Tìm kiếm
              </ButtonCustom>
            </Col>
          </Row>
        </Form>
      </div>
      <TableCustom
        data={data}
        height={"calc(100vh - 285px)"}
        columns={ColumnMemberCategory()}
        justFilter={true}
        updatePageData={updateData}
        noPagination={false}
        page={searchObject.pageIndex}
        objectSearch={searchObject}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        headerClasses='text-center'
      />
    </div>
  );
}

const ColumnMemberCategory = () => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "40px",
        maxWidth: "50px",
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "Tên chương trình",
      field: "name",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Loại kế hoạch",
      field: "type",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
        textAlign: "center"
      },
    },
    {
      name: "Số lượng chưa đánh giá",
      field: "count",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "170px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Trạng thái",
      field: "statusCode",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Tên người đánh giá",
      field: "userPerform",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Tên khu vực",
      field: "areaName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
};

export default UnratedProgramStatistics;
