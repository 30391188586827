import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Form } from "react-bootstrap";
import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import { CONFIG_TABLE_BUTTON } from "../config/ConfigTableAction";
import EvaluatePresentTab from "../component/EvaluatePresentTab";
import EvaluateUpcomingTab from "../component/EvaluateUpcomingTab";
import EvaluateReviewTab from "../component/EvaluateReviewTab";

export const getTabList = (handleEdit?: any, updateData?: any) => {
  const tabList = [
    {
      eventKey: "0",
      title: "Hôm nay",
      component: <EvaluatePresentTab />,
    },
    {
      eventKey: "1",
      title: "Sắp tới",
      component: <EvaluateUpcomingTab />,
    },
    {
      eventKey: "2",
      title: "Đã thực hiện đánh giá",
      component: <EvaluateReviewTab />,
    },
  ]
  return tabList
}

export const ColumnsProgram = (props: any) => {
  const { handlePerformEvaluate, handleShowInfoUser } = props;
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
        textAlign: "center"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "30px",
        maxWidth: "40px",
      },
      render: (row: any) => (
        <GroupTableButton 
          elementAbility={CONFIG_TABLE_BUTTON[row.statusCode]}
          handleEdit={() => handlePerformEvaluate(row)}
          isIconEditOrAccess={false}
        />
      ),
    },
    {
      name: "Khu vực đánh giá",
      field: "areaName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: "Chương trình triển khai",
      field: "name",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span key={`program-name-${row.id}`}>{row?.programResDto?.name}</span>
      ),
    },
    // {
    //   name: "Thời gian bắt đầu",
    //   field: "estimatedStartDate",
    //   headerStyle: {
    //     minWidth: "120px",
    //     textAlign: "center",
    //   },
    //   render: (row: any) => (
    //     <span key={`program-name-${row.id}`}>{row?.programResDto?.estimatedStartDate}</span>
    //   ),
    // },
    // {
    //   name: "Thời gian kết thúc",
    //   field: "estimatedEndDate",
    //   headerStyle: {
    //     minWidth: "120px",
    //     textAlign: "center",
    //   },
    // },
    {
      name: "Người chịu trách nhiệm",
      field: "empOnusAreaName",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span className="hyperlink"
          onClick={() => handleShowInfoUser && handleShowInfoUser(row?.empOnusArea) }
        >{row?.empOnusAreaName}</span>
      )
    },
    {
      name: "Người đánh giá",
      field: "userPerform",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span>{row.userPerform}</span>
      )
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
      bodyClasses: "justify-center",
      render: (row: any) =>
        <span>{row.statusName}</span>
    },
    {
      name: "Điểm đánh giá",
      field: "programName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span>{row?.totalTermAnswerScore || ""} {row?.resultText ? `(${row?.resultText})` : ""}</span>
      )
    },
    {
      name: "Ngày đánh giá",
      field: "evaluateDate",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span>{row?.evaluateDate || ""}</span>
      )
    },
  ];
};

export const ColumnsProgramReview = (props: any) => {
  const { handlePerformEvaluate, handleShowInfoUser } = props;
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
        textAlign: "center"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "30px",
        maxWidth: "40px",
      },
      render: (row: any) => (
        <GroupTableButton 
          elementAbility={CONFIG_TABLE_BUTTON[row.statusCode]}
          handleEdit={() => handlePerformEvaluate(row)}
          isIconEditOrAccess={false}
        />
      ),
    },
    {
      name: "Khu vực đánh giá",
      field: "areaName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: "Chương trình triển khai",
      field: "name",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span key={`program-name-${row.id}`}>{row?.programResDto?.name}</span>
      ),
    },
    {
      name: "Người chịu trách nhiệm",
      field: "empOnusAreaName",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span className="hyperlink"
          onClick={() => handleShowInfoUser && handleShowInfoUser(row?.empOnusArea) }
        >{row?.empOnusAreaName}</span>
      )
    },
    {
      name: "Người đánh giá",
      field: "userPerform",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span>{row.userPerform}</span>
      )
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
      bodyClasses: "justify-center",
      render: (row: any) =>
        <span>{row.statusName}</span>
    },
    {
      name: "Điểm đánh giá",
      field: "programName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => (
        <span>{row?.totalTermAnswerScore || ""} {row?.resultText ? `(${row?.resultText})` : ""}</span>
      )
    },
    {
      name: "Tổng không phù hợp",
      field: "programName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span title="Tiêu chí đã khắc phục / Tiêu chí cần khắc phục">{`${row.numberOfCriteriaResolved || 0}/${row.numberCriteriaToResolve || 0}`}</span>
      )
    },
    {
      name: "Ngày đánh giá",
      field: "evaluateDate",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "200x",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span>{row?.evaluateDate || ""}</span>
      )
    },
  ];
};

export const ColumnsProgramDetail = (props: any) => {
  const { handlePerformEvaluate } = props;
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
        textAlign: "center"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "30px",
        maxWidth: "40px",
      },
      render: (row: any) => (
        <GroupTableButton 
          elementAbility={CONFIG_TABLE_BUTTON[row.statusCode]}
          handleEdit={() => handlePerformEvaluate(row)}
          isIconEditOrAccess={false}
        />
      ),
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
      bodyClasses: "justify-center",
    },
    {
      name: "Khu vực đánh giá",
      field: "areaName",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: "Người chịu trách nhiệm",
      field: "employeeName",
      headerStyle: {
        minWidth: "160px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
  ];
};

export const ColumnsEvaluationCriteria = (props: any) => {
  const { handleCheckItemTable } = props;

  return [
    {
      name: "Tiêu chí 5S",
      field: "criteria",
    },
    {
      name: "#",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "Chỉ tiêu",
      field: "targets",
      headerStyle: {
        minWidth: "200px",
        maxWidth: "300px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "0",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      render: (row: any, index: number) => (
        <Form.Check
          className="mt-2"
          type="radio"
          value={0}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckItemTable(e.target.value, index)}
          checked={parseInt(row.rate) === 0 ? true : false}
        />
      ),
    },
    {
      name: "1",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      render: (row: any, index: number) => (
        <Form.Check
          className="mt-2"
          type="radio"
          value={1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckItemTable(e.target.value, index)}
          checked={parseInt(row.rate) === 1 ? true : false}
        />
      ),
    },
    {
      name: "2",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      render: (row: any, index: number) => (
        <Form.Check
          className="mt-2"
          type="radio"
          value={2}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckItemTable(e.target.value, index)}
          checked={parseInt(row.rate) === 2 ? true : false}
        />
      ),
    },
    {
      name: "3",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      render: (row: any, index: number) => (
        <Form.Check
          className="mt-2"
          type="radio"
          value={3}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckItemTable(e.target.value, index)}
          checked={parseInt(row.rate) === 3 ? true : false}
        />
      ),
    },
    {
      name: "4",
      field: "",
      headerStyle: {
        minWidth: "10px",
        maxWidth: "20px",
      },
      cellStyle: {
        paddingRight: "10px",
      },
      render: (row: any, index: number) => (
        <Form.Check
          className="mt-2"
          type="radio"
          value={4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckItemTable(e.target.value, index)}
          checked={parseInt(row.rate) === 4 ? true : false}
        />
      ),
    },
  ];
};

export const columnsEvaluate = (props: any) => {
  const { handleEdit, handleDelete } = props;

  return [
    {
      name: "#",
      field: "",
      headerStyle: {
        minWidth: "20px",
        maxWidth: "30px",
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "60px",
        maxWidth: "80px",
      },
      render: (row: any) => (
        <div>
          <span onClick={() => handleEdit(row)} className="pointer">
            <SVG src={toAbsoluteUrl("/media/logos/edit.svg")} />
          </span>
          <span className="spaces ml-8 pointer" onClick={() => handleDelete(row)}>
            <SVG src={toAbsoluteUrl("/media/logos/delete.svg")} />
          </span>
        </div>
      ),
    },
    {
      name: "Ngày đánh giá",
      field: "assessmentDate",
    },
    {
      name: "Bộ phận",
      field: "",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row.department?.name,
    },
    {
      name: "Người thực hiện",
      field: "",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row.implementer,
    },
    {
      name: "Bộ chỉ tiêu",
      field: "",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row.setOfCriteria?.name,
    },
    {
      name: "Trạng thái",
      field: "",
      headerStyle: {
        minWidth: "80px",
        maxWidth: "100px",
      },
      render: (row: any) => row.status?.name,
    },
    {
      name: "Tổng điểm",
      field: "totalRate",
      headerStyle: {
        minWidth: "50px",
        maxWidth: "60px",
      },
      cellStyle: {
        textAlign: "right",
      },
    },
  ];
};

export const ColumnsEvalueteFormDetail = (props: any) => {
  return [
    {
      name: "Chỉ tiêu",
      field: "termName",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        textAlign: "start",
        padding: "0 8px"
      },
      render: (row: any) => (
        !row?.shortNameParent
          ? 
          <h4 className='pt-2'>{row?.shortName + ". " + row?.termName}</h4>
          : 
          <span>{row?.shortName + ". " + row?.termName}</span>
      )
    },
  ];
};

export const LIST_CRITERIA = [
  {name: "Chưa hoàn thành", code: "1"},
  {name: "Hoàn thành", code: "2"},
  {name: "Hoàn thành tốt", code: "3"},
  {name: "Hoàn thành rất tốt", code: "4"},
]

export const LIST_DEPARTMENT = [
  {
    name: "Bộ phận 1",
  },
  {
    name: "Bộ phận 2",
  },
  {
    name: "Bộ phận 3",
  },
];

export const LITS_AREA = [
  {
    name: "Khu vực hành chính nhân sự",
  },
  {
    name: "Khu vực 1",
  },
  {
    name: "Khu vực 2",
  },
];

export const LIST_STATUS = [
  {
    name: "Đang thực hiện",
  },
  {
    name: "Hoàn Thành",
  },
];

export const LIST_PLAN = [
  {
    name: "Đánh quá Q1_2024",
  },
  {
    name: "Đánh quá Q2_2024",
  },
  {
    name: "Đánh quá Q3_2024",
  },
  {
    name: "Đánh quá Q4_2024",
  },
];

export const SET_OF_CRITERIA = [
  {
    name: "Bộ tiêu chí 5S KV1",
  },
  {
    name: "Bộ tiêu chí 5S KV2",
  },
  {
    name: "Bộ tiêu chí 5S KV3",
  },
  {
    name: "Bộ tiêu chí 5S KV4",
  },
];

export const LIST_PROGRAM = [
  {
    name: "Đánh giá 5S 2024",
    startDate: "01/01/2024",
    endDate: "31/12/2024",
    detail: [
      {
        id: "232341",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/01/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Phạm Chính Trực",
      },
      {
        id: "802948",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "29/02/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "189313",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/03/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "019381",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/04/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "313153",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/05/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "086535",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/06/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Ngọc Lan",
      },
    ],
  },
  {
    name: "Đánh giá 5S Q1_2024",
    startDate: "01/01/2024",
    endDate: "31/03/2024",
    detail: [
      {
        id: "135351",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/01/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Anh Dũng",
      },
      {
        id: "083713",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "29/02/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "635325",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/03/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
    ],
  },
  {
    name: "Đánh giá 5S Q2_2024",
    startDate: "01/04/2024",
    endDate: "30/06/2024",
    detail: [
      {
        id: "875646",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/04/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "142423",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/05/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Dương Hùng Hậu",
      },
      {
        id: "954653",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/06/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
    ],
  },
  {
    name: "Đánh giá 5S Q3_2024",
    startDate: "01/07/2024",
    endDate: "30/09/2024",
    detail: [
      {
        id: "132532",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/07/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "573426",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/08/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "142532",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/09/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Ngọc Lan",
      },
    ],
  },
  {
    name: "Đánh giá 5S Q4_2024",
    startDate: "01/10/2024",
    endDate: "31/12/2024",
    detail: [
      {
        id: "845643",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/10/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Lan",
      },
      {
        id: "124242",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "30/11/2024 23:59:59",
        evaluateArea: "KV chung",
        responsible: "Nguyễn Ngọc Thư",
      },
      {
        id: "734542",
        status: {
          code: "NEW",
          name: "Chưa đánh giá",
        },
        evaluateDate: "31/12/2024 23:59:59",
        evaluateArea: "KV hành chính nhân sự",
        responsible: "Nguyễn Ngọc Minh",
      },
    ],
  },
];

export const CRITERIA = [
  {
    criteria: "Sàng lọc",
    targets: "Tại nơi làm việc không có các vật dụng dư thừa",
    code: "SL1",
  },
  {
    criteria: "Sàng lọc",
    targets:
      "Tại nơi làm việc, tất cả mọi người có thể dễ dàng nhận biết phần lối đi và vị trí làm việc",
    code: "SL2",
  },
  {
    criteria: "Sàng lọc",
    targets:
      "Nếu có các vật lạ, mọi người đều biết lý do tại sao vật đó lại ở nơi làm việc",
    code: "SL3",
  },
  {
    criteria: "Sàng lọc",
    targets:
      "Không có các thông báo hoặc tin tức lỗi thời trên tường hoặc các bảng thông báo",
    code: "SL4",
  },
  {
    criteria: "Sàng lọc",
    targets:
      "Phân loại và bỏ rác đúng nơi có dán nhãn (rác tái sử dụng, rác đổ bỏ, các loại rác khác)",
    code: "SL5",
  },
];

export const FAKE_DATA_EVALUATE_FORM_DETAIL: any[] = [
  {
    targets: "I. Phân loại vật dụng",
    radioValue: "hide",
    isShowSubRow: false
  },
  {
    targets: "1. Tại nơi làm việc không có các vật dụng dư thừa",
    radioValue: "",
    isShowSubRow: false,
    hasSubRow: true,
    isFromEvaluation: true,
    img: {
      good: "",
      needToImprove: "",
      fix: ""
    }
  },
  {
    targets: "2. Tại nơi làm việc, tất cả mọi người có thể dễ dàng nhận biết phần lối đi và vị trí làm việc",
    radioValue: "",
    isShowSubRow: false,
    hasSubRow: true,
    isFromEvaluation: true,
    img: {
      good: "",
      needToImprove: "",
      fix: ""
    }
  },
  {
    targets: "3. Nếu có các vật lạ, mọi người đều biết lý do tại sao vật đó lại ở nơi làm việc",
    radioValue: "",
    isShowSubRow: false,
    hasSubRow: true,
    isFromEvaluation: true,
    img: {
      good: "",
      needToImprove: "",
      fix: ""
    }
  },
  {
    targets: "4. Không có các thông báo hoặc tin tức lỗi thời trên tường hoặc các bảng thông báo",
    radioValue: "",
    isShowSubRow: false,
    hasSubRow: true,
    isFromEvaluation: true,
    img: {
      good: "",
      needToImprove: "",
      fix: ""
    }
  },
  {
    targets: "II. Sắp xếp vật dụng",
    radioValue: "hide",
    isShowSubRow: false
  },
  {
    targets: "1. Phân loại và bỏ rác đúng nơi có dán nhãn (rác tái sử dụng, rác đổ bỏ, các loại rác khác)",
    radioValue: "",
    isShowSubRow: false,
    hasSubRow: true,
    isFromEvaluation: true,
    img: {
      good: "",
      needToImprove: "",
      fix: ""
    }
  },
];

export const EVALUATE_STATUS = {
  NOT_EVALUATED: "006", // Chưa đánh giá
  EVALUATED: "007", // Đã đánh giá
  UNDER_EVALUATION: "010", // Đang đánh giá
}

export const INITIAL_VALUE_EVALUATE_FORM = {
  areaId: null,
  proImplId: null,
  evaluateDate: null,
  estimatedEndDate: "",
  conclusion: "",
  empOnusId: null,
  note: "",
  totalTermAnswerScore: null,
  scoreEmpMark: 0,
  scoreEmpOnus: 0,
  statusCode: "",
  stageCode: "",
  textRefuse:"",
  resultText: "",
  type: "",
  progress: null,
  programImplResultList: [],
  userRemedy: null
}