import { useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useMultiLanguage from '../../hook/useMultiLanguage';
import { ConfirmDialog } from '../component/ConfirmDialog';
import Button from '../component/button-custom';
import InputSearch from '../component/input-field/InputSearch';
import TableCustom from '../component/table-custom/TableCustom';
import { CREATE_PARAMETER } from '../constant';
import { GeneralSearchParamsType } from '../utils/ParamsUtils';
import { deleteAccount, searchAccount } from './services/ManageAccountsServices';
import { ColumnAccountTable } from './components/ColumnAccountTable';
import AccountForm from './components/AccountForm';

type Props = {}

const ManageAccounts = (props: Props) => {
    const navigate = useNavigate();
    const { lang } = useMultiLanguage();
    const [keyword, setKeyword] = useState<string>("");
    const [listAccounts, setListAccounts] = useState<any[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<any>();
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);
    const [searchObject, setSearchObject] = useState<any>({
        keyword: "",
    });

    const updatePageData = async (searchObj: GeneralSearchParamsType) => {
        try {
            let { data } = await searchAccount(searchObj);
            let newData = data.data.content;
            let pageable = data.data.pageable;
            setListAccounts(newData);
            setTotalElements(pageable.totalElements);
            setNumberOfElements(pageable.pageNumber + 1);
        } catch (err) {
            console.error(err);
        }
    }

    const handleChange = (e: any) => {
        setKeyword(e.target.value);
    };

    const handleEdit = (rowData: any) => {
        if (rowData?.id) {
            navigate(`${rowData?.id}`);
        }
    }

    const handleDelete = (rowData: any) => {
        if (rowData?.id) {
            setSelectedId(rowData?.id);
            setOpenConfirmDialog(true);
        }
    }
    const handleConfirmDelete = async () => {
        try {
            if (selectedId !== CREATE_PARAMETER) {
                await deleteAccount(selectedId);
                setOpenConfirmDialog(false);
                toast.success(lang("TOAST.DELETE.SUCCESS"));
                handleSearch();
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleSearch = () => {
        setSearchObject({ keyword });
    };

    return (
        <div className='mt-2 p-2'>
            <Row className='bg-white'>
                <Col xs="12" className='my-5'>
                    <h3 className='spaces m-0 text-uppercase color-primary'>QUẢN LÝ TÀI KHOẢN</h3>
                </Col>
            </Row>
            <div className='d-flex spaces gap-8'>
                <Col xs={6} className='bg-white'>
                    <Row className='spaces pb-6'>
                        <Col xs={6} />
                        <Col xs="6">
                            <InputSearch className='spaces h-31' handleChange={handleChange} handleSearch={handleSearch} placeholder="Tìm kiếm theo mã, tên kế hoạch" isEnter />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TableCustom
                                id="profile-1"
                                height={"calc(100vh - 250px)"}
                                data={listAccounts || []}
                                columns={ColumnAccountTable({
                                    handleEdit, handleDelete
                                })}
                                buttonAdd={false}
                                buttonExportExcel={false}
                                notDelete={false}
                                justFilter={true}
                                updatePageData={updatePageData}
                                totalElements={totalElements}
                                numberOfElements={numberOfElements}
                                objectSearch={searchObject}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} className='bg-white'>
                    <AccountForm />
                </Col>
            </div>
            {openConfirmDialog &&
                <ConfirmDialog
                    show={openConfirmDialog}
                    onYesClick={handleConfirmDelete}
                    onCancelClick={() => setOpenConfirmDialog(false)}
                    yes="Có"
                    cancel='Hủy'
                />
            }
        </div>
    )
}

export default ManageAccounts;