import { StageSignature as STATUS } from "../../constant";

const LIST_FIELD = ["id", "code", "name", "estimatedStartDate", "estimatedEndDate", "userApprove", "type", "termFormId", "note", "viewForm", "historyRequest"];

const DISABLE_ALL = Object.fromEntries(LIST_FIELD.map(key => [key, true]));
const ENABLE_ALL = Object.fromEntries(LIST_FIELD.map(key => [key, false]));

const INITIALIZATION = {
    ...ENABLE_ALL,
    id: true,
    historyRequest: true
   
}

const PENDING_APPROVAL = {
    ...DISABLE_ALL,
     viewForm:true,
     historyRequest: false
}

const PENDING_APPROVAL_USER_APPROVE = {
    ...DISABLE_ALL,
   
}

const REQUEST_FOR_MODIFICATION = {
    ...ENABLE_ALL,
    id: true,
}

const REQUEST_FOR_MODIFICATION_USER_APPROVE = {
    ...DISABLE_ALL,
     viewForm:true,
    
}

const APPROVED = {
    ...DISABLE_ALL,
     viewForm:true,
     historyRequest: false
}

const APPROVED_USER_APPROVED = {
    ...DISABLE_ALL,
     viewForm:true,
     
    
}

const CURRENT = {
    ...DISABLE_ALL,
     viewForm:true,
     historyRequest: false
}

const CURRENT_USER_APPROVE = {
    ...DISABLE_ALL,
     viewForm:true,
    
}

const FINISHED = {
    ...DISABLE_ALL,
     viewForm:true,
     historyRequest: false
}

const FINISHED_USER_APPROVED = {
    ...DISABLE_ALL,
     viewForm:true
}

export const DISABLE_STATUS: { [key: string]: any } = {
    [STATUS.INITIALIZATION]: INITIALIZATION,
    [STATUS.PENDING_APPROVAL]: PENDING_APPROVAL,
    [STATUS.PENDING_APPROVAL_USER_APPROVE]: PENDING_APPROVAL_USER_APPROVE,
    [STATUS.REQUEST_FOR_MODIFICATION]: REQUEST_FOR_MODIFICATION,
    [STATUS.REQUEST_FOR_MODIFICATION_USER_APPROVE]: REQUEST_FOR_MODIFICATION_USER_APPROVE,
    [STATUS.APPROVED]: APPROVED,
    [STATUS.APPROVED_USER_APPROVED]: APPROVED_USER_APPROVED,
    [STATUS.CURRENT]: CURRENT,
    [STATUS.CURRENT_USER_APPROVE]: CURRENT_USER_APPROVE,
    [STATUS.FINISHED]: FINISHED,
    [STATUS.FINISHED_USER_APPROVED]: FINISHED_USER_APPROVED,
    [STATUS.UNKNOWN]: DISABLE_ALL,
    [STATUS.EVALUATOR_ASSIGNED]: DISABLE_ALL,
    [STATUS.EVALUATOR_ASSIGNED_VIEW]: DISABLE_ALL
};
