import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EVALUATE_TAB } from "../../Constant";
import TabMenu from "../component/tabs/TabMenu";
import { getTabList } from "./const/EvaluateConst";

type Iprops = {
    activeTab: string
}

const Evaluate = ({activeTab}:Iprops) => {
  const [tabList, setTabList] = useState<any[]>([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    setTabList(getTabList());
  }, []);

  const handleChangeTab = (activeTab: string) => {
    if (activeTab === "0") {
      navigate("/evaluate/incomming")
    }
    if (activeTab === "1") {
      navigate("/evaluate/upcomming")
    }
    if (activeTab === "2") {
      navigate("/evaluate/review")
    }
  };

  return (
    <div className='wrapper-content'>
      <Row>
        <Col xs={12} className="my-5">
          <h3 className='spaces m-0 text-uppercase color-primary'>THỰC HIỆN ĐÁNH GIÁ 5S</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TabMenu
            danhsachTabs={tabList}
            propActiveTab={activeTab === EVALUATE_TAB.NOW ? "0" : (EVALUATE_TAB.UP_COMMING === activeTab ? "1" : "2")}
            handleChangeTab={handleChangeTab}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Evaluate;
