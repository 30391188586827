import { useCallback, useState } from "react";
import * as Yup from "yup";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextValidator from "../../component/input-field/TextValidator";
import { unstable_batchedUpdates } from "react-dom";
import { ICriteria } from "../models/SetOfCriteriaModels";

type TProps = {
  show: boolean;
  onClose: () => void;
  criteria: ICriteria;
  handleAddCriteria: (criteriaInfo: ICriteria) => void;
  handleUpdateCriteria: (criteriaInfo: ICriteria) => void;
  callback?: (shortNameParent?: string | null) => void;
};

const CriteriaAddNewDialog = (props: TProps) => {
  const { 
    show, 
    onClose, 
    criteria, 
    handleAddCriteria, 
    handleUpdateCriteria,
    callback
  } = props;

  const { lang } = useMultiLanguage();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    description: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const [nextCriteria, setNextCriteria] = useState(false)

  const handleSubmit = async (values: ICriteria,action:FormikHelpers<ICriteria>) => {
    criteria.name ? handleUpdateCriteria(values) : handleAddCriteria({ ...values });
    action.setFieldValue('name', '');
    action.setFieldValue('description', '');
    const currentShortName = values.shortName;
    const [major, minor] = currentShortName.split('.');
    const nextShortName = `${major}.${parseInt(minor) + 1}`;
    action.setFieldValue('shortName', nextShortName);
    !nextCriteria && onClose(); 
  };

  const formikData = useFormik({
    initialValues: criteria,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handlechange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    unstable_batchedUpdates(() => {
      const { value, name } = e.target;
      formikData.setFieldValue(name, value);
    });
  }, []);  

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      animation
      className="custom-modal category-dialog"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <span>Tiêu chí - {formikData.values?.shortName}</span>
          <span className="spaces pl-20 category-dialog-titel-status">
            {criteria.name ? "#Cập nhật" : "#Thêm mới"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formikData.handleSubmit}>
          <Row>
            <Col xs={12}>
              <TextValidator
                className="flex-row min-w-80"
                lable={"Tiêu chí"}
                readOnly={false}
                isRequired
                name="name"
                value={formikData.values?.name}
                type="text"
                onChange={handlechange}
                errors={formikData.errors?.name}
                touched={formikData.touched?.name}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                className="flex-row min-w-80 spaces mt-10"
                lable={"Mô tả"}
                readOnly={false}
                isRequired
                as="textarea"
                rows={3}
                name="description"
                value={formikData.values.description}
                type="text"
                onChange={handlechange}
                errors={formikData.errors?.description}
                touched={formikData.touched?.description}
              />
            </Col>
            <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
              {!criteria.name &&
                <Button
                  className="spaces button-primary py-4 fs-12 h-30"
                  onClick={() => setNextCriteria(true)}
                  type="submit"
                >
                  Lưu và tiếp
                </Button>
              }
              {criteria.name && (
                <Button
                  className="spaces button-primary py-4 fs-12 h-30"
                  type="submit"
                  onClick={() => setNextCriteria(false)}
                >
                  Cập nhật
                </Button>
              )}
              {!criteria.name && (
                <Button
                  className="spaces button-primary py-4 fs-12 h-30"
                  onClick={() => callback && callback(formikData.values?.shortNameParent)}
                >
                  Thêm danh mục
                </Button>
              )}
              <Button
                onClick={onClose}
                className="spaces button-primary-outline py-4 fs-12 h-30"
              >
                Hủy
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CriteriaAddNewDialog