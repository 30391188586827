import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import { heightAutocomplete, heightSelectAutocomplete } from "../../component/input-field/StyleComponent";
import SelectCustom from "../../component/select-custom";
import { getConfigTableButton } from "../config/ConfigTableAction";
import { TIME_TYPE } from "./InitialValue";

export const ColumnProgram = (props: any) => {
    const { handleEdit, handleDelete } = props
    return [
        {
            name: "STT",
            field: "stt",
            headerStyle: {
                minWidth: "40px",
                textAlign: "center"
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            )
        },
        {
            name: "Thao tác",
            field: "khoaBaoCao",
            headerStyle: {
                width: "80px",
                textAlign: "center"
            },
            render: (row: any) => {
                let CONFIG_BTN = getConfigTableButton(row.userCreate);
                return (
                    <GroupTableButton
                        elementAbility={CONFIG_BTN[row.statusCode]}
                        handleEdit={() => handleEdit(row)}
                        handleDelete={() => handleDelete(row)}
                        isIconEditOrAccess={false}
                    />
                )
            }
        },
        {
            name: "Mã CT",
            field: "code",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            }
        },
        {
            name: "Tên chương trình",
            field: "name",
            headerStyle: {
                minWidth: "350px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {
            name: "TG bắt đầu",
            field: "estimatedStartDate",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            }
        },
        {
            name: "TG kết thúc",
            field: "estimatedEndDate",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            }
        },
        {
            name: "Trạng thái",
            field: "statusName",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            }
        },
    ];
};

export const ColumnProgramChildren = (props: any) => {
    // const { handleOpenShowReport, handleOpenReportInput } = props
    const { planType } = props
    return [
        {
            name: "STT",
            field: "stt",
            headerStyle: {
                minWidth: "40px"
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            )
        },
        {
            name: "Hạn đánh giá",
            field: "evaluationTime",
            headerStyle: {
                minWidth: "100px"
            },
            isVisible: planType?.code !== TIME_TYPE.DAY
        },
        {
            name: "Khu vực đánh giá",
            field: "evaluationArea",
            headerStyle: {
                minWidth: "120px"
            },
        },
        {
            name: "Người chịu trách nhiệm",
            field: "responsiblePerson",
            headerStyle: {
                minWidth: "100px"
            },
        },
    ];
};

export const ColumnAssignEvaluation = ({ formik, disable, handleShowAreas, lstUserAction }: any) => {
    return [
        {
            name: "STT",
            field: "stt",
            headerStyle: {
                width: "20px",
                textAlign: "center"
            },
            cellStyle: {
                width: "20px",
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            )
        },
        {
            name: "Khu vực đánh giá",
            field: "areaName",
            headerStyle: {
                minWidth: "100px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
                
            },
            
            render: (row: any) => (
                <span
                    className="hyperlink"
                    onClick={
                        () => { handleShowAreas(row) }
                    }>
                    {row.areaName}
                </span>
            )
        },
        {
            name: "Cán bộ thực hiện",
            field: "empPerformId",
            headerStyle: {
                minWidth: "125px",
                maxWidth: "125px",
                textAlign: "center"
            },
            cellStyle: {
                minWidth: "125px",
                maxWidth: "125px"
            },
            render: (row: any, index: number) => (
                <SelectCustom
                    menuPlacement="auto"
                    options={lstUserAction || []}
                    styles={{... heightSelectAutocomplete("auto"), ...heightAutocomplete("32px")}}
                    valueField={'username'}
                    displayField={'displayName'}
                    optionsResponse='data'
                    name={`programImplDto[${index}].userPerform`}
                    handleChange={(value, _option:any, _actionMeta, name) => {
                        formik.setFieldValue(name, value);
                        formik.setFieldValue(`programImplDto[${index}].empPerformId`, _option?.empId);
                    }}
                    isDisabled={disable}
                    value={formik.values?.programImplDto?.[index]?.userPerform || null}
                    errors={formik.errors?.programImplDto?.[index]?.userPerform}
                    touched={formik.touched?.programImplDto?.[index]?.userPerform}
                />
            )
        },
        {
            name: "Người chịu trách nhiệm",
            field: "empOnusAreaName",
            headerStyle: {
                minWidth: "125px",
                maxWidth: "125px",
                textAlign: "center"
            },
            cellStyle: {
                minWidth: "125px",
                maxWidth: "125px"
            },
        },
    ];
};