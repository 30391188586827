import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { Col, Modal, Row, Form } from 'react-bootstrap'
import { columnNamesType } from '../../component/table-custom/TableCustom'
import TextValidator from '../../component/input-field/TextValidator'
import useMultiLanguage from '../../../hook/useMultiLanguage'
import { deleteImageEvaluate } from '../EvaluateServices'
import { ConfirmDialog } from '../../component/ConfirmDialog'
import { toast } from 'react-toastify'
import AppContext from '../../../AppContext'
import EvaluateRepeairDialog from './EveluateRepeairDialog'
import { StageSignature } from '../../constant'
import { EVALUATE_ACTION_TABLE } from '../config/configActionTable'
import { ColumnsEvalueteFormDetail } from '../const/EvaluateConst'
import { getImage, uploadImage } from '../../services'
import { handleError } from '../../utils/FunctionUtils'

type Props = {
  data: any[],
  setData: any,
  showSubRow?: boolean,
  handleChangeTableData: (value: any, rowIndex: number, name: string) => void,
  disabled?: boolean,
  programImplResultList?: any[],
  listTermAnswer?: any[],
  setProgramImplResultList?: any
  checkDisable?: any,
  stageCode?: string,
  columns?: any[],
  handleChangeTermAnswer?: (e: ChangeEvent<HTMLInputElement>, rowIndex: number, termAnswerIndex: number) => void
}

const EvaluateTable = ({
  data,
  showSubRow,
  handleChangeTableData,
  programImplResultList,
  listTermAnswer = [],
  stageCode = StageSignature.UNKNOWN,
  columns = [],
  handleChangeTermAnswer
}: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [itemList, setItemList] = useState<any>(data || []);
  const [imgSrc, setImgSrc] = useState<string>("");
  const imgRefs = useRef<any[]>([]);
  const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [imageApprove, setImageApprove] = useState<any>(false);
  const [rowTable, setRowTable] = useState<any>(null);
  const [rowIndex, setRowIndex] = useState<any>(null);

  const show = EVALUATE_ACTION_TABLE[stageCode];

  const lstImageTypes = [
    { type: "imageGood", title: "Hình ảnh thực hành tốt", titleAddBtn: "Thêm hình ảnh thực hành tốt", show: show?.imageGood, showRemove: show?.deleteImageGood },
    { type: "imageBad", title: "Hình ảnh cần cải tiến", titleAddBtn: "Thêm hình ảnh cần cải tiến", show: show?.imageBad, showRemove: show?.deleteImageBad },
    { type: "imageImprove", title: "Khắc phục", titleAddBtn: "Khắc phục", show: show?.actionImprove, showRemove: show?.deleteImageImporve }
  ];

  useEffect(() => {
    setItemList(data);
  }, [data]);

  const handleSetImageRefs = (ref: HTMLImageElement | null, index: number, type: string) => {
    imgRefs.current[index] = {
      ...imgRefs.current[index],
      [type]: ref
    }
  };

  const handleChangeImage = async (value: any, rowIndex: number, name: string) => {
    try {
      setPageLoading(true);
      if (value) {
        const formData = new FormData();
        formData.append("file", value);
        let { data } = await uploadImage(formData);
        handleChangeTableData(data.data, rowIndex, name);
      }
    } catch (error) {
      handleError(error)
    } finally {
      setPageLoading(false);
    }
  };

  const handleDelete = (rowIndex: number, name: string, rowData: any) => {
    setDeleteData({
      rowIndex,
      name,
      value: rowData[name],
      id: rowData?.id
    })
    setShowConfirmDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setPageLoading(true);
      deleteData.id && await deleteImageEvaluate({ [deleteData.name]: deleteData.value }, deleteData.id);
      handleChangeTableData(null, deleteData.rowIndex, deleteData.name);
      setShowConfirmDeletePopup(false);
    } catch (error) {
      toast.error(error as string);
    } finally {
      setPageLoading(false);
    }
  };

  const handleGetUrlImage = async (name: string) => {
    try {
      let imageDataURL = await getImage({ name });
      let url = URL.createObjectURL(imageDataURL.data);
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowImage = async (index: number, type: string) => {
    try {
      let url = imgRefs.current[index][type]?.src || "";
      setImgSrc(url as string);
      setShowImagePopup(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleLoadImage = async (image: any) => {
    try {
      const url = await handleGetUrlImage(image.id);
      image.src = url || '';
      image.loading = "";
      image.classList.remove("spaces");

      let action = image.parentNode.querySelector(".action-img");
      let loading = image.parentNode.querySelector(".spinner-border");
      action.classList.remove("d-none");
      loading.classList.add("d-none");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const options: IntersectionObserverInit = {
      rootMargin: '0px 0px 0px 0px',
      threshold: 0
    };

    const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
      try {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            const image = entry.target;
            await handleLoadImage(image);
            
            observer.unobserve(image);
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    const observer = new window.IntersectionObserver(handleIntersection, options);
    const images = document.querySelectorAll<HTMLImageElement>('img[loading="lazy"]');

    images.forEach(image => {
      observer.observe(image);
    });

    return () => {
      images.forEach(image => {
        observer.unobserve(image);
      });
    }
  }, [document.querySelectorAll<HTMLImageElement>('img[loading="lazy"]')]);

  const ColumnsEvaluate = () => {
    const columnsEvaluateNew: {
      name: string;
      field: string;
      render: (row: any, index: number) => JSX.Element | "";
    }[] = [...ColumnsEvalueteFormDetail({})]

    listTermAnswer.map((item: any, termAnswerIndex: number) => {
      columnsEvaluateNew.push({
        name: item?.sortWeight,
        field: "",
        render: (row: any, rowIndex: number) => (
          !row?.shortNameParent
            ?
            <></>
            :
            <Form.Check
              className="mt-2"
              type="radio"
              name={`checkbox_row_${row.id}_${rowIndex}`}
              disabled={!show?.checkAssess}
              value={item?.id}
              checked={row.termAnswerScore === item?.answerNumeric}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeTermAnswer && handleChangeTermAnswer(e, rowIndex, termAnswerIndex)}
            />
        )
      })
    });
    columnsEvaluateNew.sort((col1, col2) => {
      return Number(col1.name) - Number(col2.name);
    })
    return columnsEvaluateNew;
  };

  const renderColumns = (Array.isArray(columns) && columns.length) ? columns : ColumnsEvaluate();

  return (
    <div className={`table-responsive customs-collapse-row m-0 `}>
      <table
        className="table-row-dashed dataTable table w-100"
        id="kt_table_users"
      >
        <thead
          className="position-sticky top-0 z-index-1"
        >
          <tr className="text-header-table fw-600 fw-bolder gs-0 border">
            {renderColumns?.map((column: columnNamesType, index: number) => {
              return (
                <th
                  key={column?.field + index}
                  className="p-table bg-header-table text-center"
                  style={column?.headerStyle}
                >
                  <div>{column?.name}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody >
          {Boolean(itemList?.length) ?
            (
              itemList.map((row: any, index: number) => (
                <>
                  <tr
                    key={index}
                    className={`border-bottom border`}
                  >
                    {renderColumns?.map((column: columnNamesType, idx: number) => {
                      return (
                        <td
                          colSpan={column?.colSpan}
                          className={`td-vertical-center "bg-white" ${column?.action ? " action-cell fw-bold" : ""}`}
                          style={column?.cellStyle}
                          key={idx}
                        >
                          {column.render ? column.render(row, index, 0, itemList, null) : row[column?.field]}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className={(row?.hasSubRow && showSubRow) ? "" : "d-none"}>
                    <td colSpan={renderColumns.length} className="px-2">
                      <Row className="justify-content-space-between">
                        {lstImageTypes.map((item, imgIndex) => (
                          <>
                            {/*--- Đã có image ----*/}
                            {row[item.type] && item.type !== "imageImprove" && (
                              <Col
                                key={item.type + "-" + imgIndex}
                                className="flex-column flex-center text-center text-primary">
                                <div className="spaces mb-10 position-relative">
                                  <img
                                    ref={ref => handleSetImageRefs(ref, index, item.type)}
                                    loading='lazy'
                                    id={row[item.type]}
                                    src={row[item.type].url || ""}
                                    alt={item.titleAddBtn}
                                    className="rect-img spaces w-0 h-0"
                                  />
                                  <ActionImage
                                    showRemove={item.showRemove}
                                    handleShow={() => handleShowImage(index, item.type)}
                                    handleRemove={() => handleDelete(index, item.type, row)}
                                  />
                                  <div className="spinner-border" role="status">
                                    <span className="sr-only">
                                      Loading...
                                    </span>
                                  </div>
                                </div>
                                <div className='mb-2 mt-n3'>
                                  <em>{item.title}</em>
                                </div>
                              </Col>
                            )}
                            {/*--- Đã khắc phục ----*/}
                            {row["reasonText"] && item.type === "imageImprove" && (
                              <Col
                                key={item.type + "-" + imgIndex}
                                className="flex-column flex-center text-center text-primary">
                                <div
                                  className="cursor-pointer py-4"
                                  onClick={() => {
                                    setImageApprove(true);
                                    setRowTable(row);
                                    setRowIndex(index);
                                  }}
                                >
                                  <i className="fa-solid fa-eye pe-2"></i>
                                  Xem khắc phục
                                </div>
                              </Col>
                            )}
                            {/*--- Chưa có ảnh and chưa khắc phục ----*/}
                            {!row[item.type] && (
                              <>
                                {item.type !== "imageImprove" && !item.show && (
                                  <>
                                    {/* <div className='spaces mb-10'>
                                      <img
                                        src={"/media/images/no-image.jpg"}
                                        alt={"img-not-found"}
                                        className="rect-img"
                                      />
                                    </div>
                                    <div className='mb-2 mt-n3'>
                                      <em>{item.title}</em>
                                    </div> */}
                                  </>
                                )}
                                {item.type === "imageImprove" && item.show && !row["reasonText"] && row["imageBad"] && (
                                  <Col
                                    key={item.type + "-" + imgIndex}
                                    className="flex-column flex-center text-center text-primary">
                                    <div
                                      className={`cursor-pointer py-4`}
                                      onClick={() => {
                                        setImageApprove(true);
                                        setRowTable(row);
                                        setRowIndex(index);
                                      }}
                                    >
                                      <i className="fa-solid fa-screwdriver-wrench pe-2"></i>
                                      {item.titleAddBtn}
                                    </div>
                                  </Col>
                                )}
                                {item.type !== "imageImprove" && item.show && (
                                  <Col
                                    key={item.type + "-" + imgIndex}
                                    className="flex-column flex-center text-center text-primary">
                                    <label htmlFor={`choose-img-file-${item.type}-${index}`} className="cursor-pointer">
                                      <i className="bi bi-plus" />
                                      {item.titleAddBtn}
                                    </label>
                                    <input
                                      type="file"
                                      id={`choose-img-file-${item.type}-${index}`}
                                      className="d-none"
                                      onChange={(e) => handleChangeImage(
                                        e.target.files?.[0],
                                        index,
                                        item.type
                                      )}
                                    />
                                  </Col>
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </Row>
                      <TextValidator
                        name="feedback"
                        type="text"
                        className="w-100"
                        as="textarea"
                        placeholder="Nhận xét"
                        rows="1"
                        defaultValue={row?.feedback || ""}
                        disabled={show?.description}
                        onBlur={(e: ChangeEvent<HTMLInputElement>) => handleChangeTableData(e.target.value, index, "feedback")}
                      />
                    </td>
                  </tr>
                </>
              ))
            )
            :
            (
              <tr>
                <td colSpan={renderColumns.length} className="text-center border">
                  {lang("TABLE.DATA.EMPTY")}
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      {showImagePopup && (
        <PreviewImagePopup
          show={showImagePopup}
          hide={() => setShowImagePopup(false)}
          src={imgSrc}
        />
      )}
      {showConfirmDeletePopup && (
        <ConfirmDialog
          show={showConfirmDeletePopup}
          title="Xóa danh mục"
          message={`Bạn chắc chắn muốn xóa ảnh?`}
          yes="Xác nhận"
          cancel="Hủy"
          onYesClick={handleConfirmDelete}
          onCancelClick={() => setShowConfirmDeletePopup(false)}
        />
      )}
      {imageApprove && (
        <EvaluateRepeairDialog
          onClose={() => setImageApprove(false)}
          rowTable={rowTable}
          setImageApprove={setImageApprove}
          rowIndex={rowIndex}
          setShowImagePopup={setShowImagePopup}
          setImgSrc={setImgSrc}
          statusAction={show}
          programImplResultList={programImplResultList}
          data={itemList}
          setData={setItemList}
        />
      )}
    </div>
  )
}

export default EvaluateTable;


const ActionImage = ({ showRemove, handleShow, handleRemove }: {
  showRemove: boolean,
  handleShow: () => void,
  handleRemove: () => void
}) => {
  return (
    <div className='position-absolute top-0 left-0 w-100 h-100 flex-center bg-opacity-50 action-img d-none'>
      {showRemove && (
        <label>
          <i className="cursor-pointer px-4 fw-bold fs-1 bi bi-trash text-white bg-secondary rounded-2 py-1"
            onClick={handleRemove}
          />
        </label>
      )}
      <label>
        <i className="cursor-pointer px-4 fw-bold fs-1 bi bi-arrows-fullscreen text-white bg-secondary rounded-2 py-1"
          onClick={handleShow}
        />
      </label>
    </div>
  )
};

export const PreviewImagePopup = ({ show, hide, src }: { show: boolean, hide: () => void, src: string }) => {
  return (
    <Modal show={show} onHide={hide} size='lg' centered>
      <div className="w-100 h-100 position-relative bg-black bg-opacity-50"
      >
        <img id="preview-img" src={src || ""} alt="" className="w-100 h-100" />
      </div>
    </Modal>
  )
};