import React, { ComponentType, useEffect, useState } from 'react';
import { handleError } from '../modules/utils/FunctionUtils';
import { fetchInfoUserCurrent, fetchListColorConfig } from '../modules/auth/core/_requests';
import { KEY_LOCALSTORAGE } from '../modules/auth/core/_consts';
import { useAuth, UserModel, UserModelLogin } from '../modules/auth';

type ColorConfig = {
  code: string | null;
  id: number | null;
  name: string | null;
  statusName: string | null;
};

async function handleGetInfoUserEmployee(setCurrentUser: any): Promise<UserModel | UserModelLogin | null> {
  try {
    const response = await fetchInfoUserCurrent();
    const userData = response.data?.data || null;
    setCurrentUser(userData);
    return userData;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

async function handleGetColorConfig(): Promise<ColorConfig[]> {
  try {
    const response = await fetchListColorConfig();
    const colorData = response.data?.data || [];
    localStorage.setItem(KEY_LOCALSTORAGE.COLOR_CONFIG, JSON.stringify(colorData));
    return colorData;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

interface FivesInitProps {
  userInfo: UserModel | UserModelLogin | null;
  colorConfig: ColorConfig[] | null;
}

function FivesInit<P extends FivesInitProps>(FivesInitComponent: ComponentType<P>) {
  return function FivesInitWrapper(props: Omit<P, keyof FivesInitProps>) {
    const { auth, setCurrentUser } = useAuth();
    const [userInfo, setUserInfo] = useState<UserModel | UserModelLogin | null>(null);
    const [colorConfig, setColorConfig] = useState<ColorConfig[] | null>(null);

    useEffect(() => {
      const fetchApiData = async () => {
        try {
          const [fetchedUserInfo, fetchedColorConfig] = await Promise.all([
            handleGetInfoUserEmployee(setCurrentUser),
            handleGetColorConfig()
          ]);
          setUserInfo(fetchedUserInfo);
          setColorConfig(fetchedColorConfig);
        } catch (error) {
          console.error('Error:', error);
        }
      };

      auth && fetchApiData();
    }, [auth, setCurrentUser]);

    return (
      <FivesInitComponent
        {...(props as P)}
        userInfo={userInfo}
        colorConfig={colorConfig}
      />
    );
  };
}

export default FivesInit;
