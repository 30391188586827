import { createRef, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IScoreScale } from '../models/SetOfCriteriaModels';
import CriteriaTable from './CriteriaTable';
import { SetOfPointLevelColumns } from './SetOfCriteriaColumns';
import TableCustom from '../../component/table-custom/TableCustom';
import SetOfCriteriaContext from './SetOfCriteriaContext';
import useStateRef from '../../utils/useStateRef';

type TProps = {
  totalPoint: number,
  scoreScaleList: IScoreScale[];
  setScoreScaleList: React.Dispatch<React.SetStateAction<IScoreScale[]>>;
  handleAddScoreScale: () => void;
  hasRootArea: boolean;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const SetOfCriteriaDepartmentForm = ({
  totalPoint,
  scoreScaleList,
  hasRootArea,
  setScoreScaleList,
  handleAddScoreScale,
  errors,
  setErrors,
}: TProps) => {  

  const { formTableAbility } = useContext(SetOfCriteriaContext);
  const refTable = createRef<HTMLTableElement>();

  const handleChange = (name: string, value: number | string, rowIndex: number) => {
    const numericValue = Number(value);
    const isDescription = name === "description";
    const isValidNumber = !isNaN(numericValue) && numericValue >= 0;

    if (isDescription || isValidNumber) {
      let newErrors = errors;
      setScoreScaleList((scoreScaleListPrev: IScoreScale[]) => {
        const newList = scoreScaleListPrev.map((scoreScale: IScoreScale, index: number) => {
          if (index === rowIndex) {
            const error = handleCompareScoreFromTo(name, value, scoreScale);
            if (!error?.isRequiredScoreFrom || !error?.isRequiredScoreTo) {
              newErrors = {
                ...newErrors,
                [`scoreFrom-${index}`]: !error?.isRequiredScoreFrom ? "Bắt buộc nhập" : null,
                [`scoreTo-${index}`]: !error?.isRequiredScoreTo ? "Bắt buộc nhập" : null,
              }
            } else if (!error?.isCompare) {
              newErrors = {
                ...newErrors,
                [`scoreFrom-${index}`]: "Phải nhỏ hơn tổng điểm đến",
                [`scoreTo-${index}`]: "Phải lớn hơn tổng điểm từ",
              }
            } else {
              newErrors = {
                ...newErrors,
                [`scoreFrom-${index}`]: null,
                [`scoreTo-${index}`]: null,
              }
            }
            return {
              ...scoreScale,
              [name]: value,
            };
          }
          return scoreScale;
        });
        setErrors(newErrors);
        return newList;
      });
    }
  };

  const handleDelete = (deletedScoreScale: IScoreScale, index: number) => {
    setScoreScaleList(scoreScaleList.filter((_, i) => i !== index));
  };

  const handleCompareScoreFromTo = (name: string, value: number | string, data: IScoreScale,) => {
    let isRequiredScoreFrom = true;
    let isRequiredScoreTo = true;
    let isCompare = true;
    const scoreFrom = name === "scoreFrom" ? value.toString() : data.scoreFrom.toString();
    const scoreTo = name === "scoreTo" ? value.toString() : data.scoreTo.toString();
    if (!scoreFrom || !scoreTo) {
      isRequiredScoreFrom = !scoreFrom ? false : true;
      isRequiredScoreTo = !scoreTo ? false : true;
    } else {
      isCompare = Number(scoreFrom) < Number(scoreTo);
    }
    return {
      isRequiredScoreFrom,
      isRequiredScoreTo,
      isCompare,
    }
  };
  
  return (
    <Row className="w-100">
      <Col xs={12} className='table-custom'>
        <CriteriaTable 
          hasRootArea={hasRootArea}
        />
      </Col>
      <Col xs={12}>
        <div className="w-100">
          <div className="d-flex align-items-center spaces py-15 gap-20">
            <span className="spaces fs-20 fw-bold text-uppercase">Thang điểm tổng</span>
            <i className='spaces fs-17'>(Tổng điểm chuẩn {totalPoint})</i>
          </div>
          <div className='spaces sf-14'>
            <TableCustom
              id="SetOfPointLevelColumns"
              data={scoreScaleList}
              columns={SetOfPointLevelColumns({
                handleChange,
                handleDelete,
                formTableAbility,
                errors: errors
              })}
              buttonAdd={false}
              buttonExportExcel={false}
              notDelete={false}
              fixedColumnsCount={0}
              justFilter={true}
              updatePageData={() => { }}
              noPagination={true}
            />
            <div className="spaces py-5 fs-14 text-center color-blue-600 border border-top-dashed border-top-color-blue bg-gray-100">
              {!formTableAbility.table_point_ladder && (
                <div onClick={handleAddScoreScale} className='cursor-pointer spaces my-autu-0 w-140'>
                  + Thêm thang điểm
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SetOfCriteriaDepartmentForm;
