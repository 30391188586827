/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useLoading } from '../../../AppContext';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { Col } from '../../component/Grid';
import { heightSelectMutil } from '../../component/input-field/StyleComponent';
import TextValidator from '../../component/input-field/TextValidator';
import { ModalFormikCustom } from '../../component/modalFormik';
import SelectCustom from '../../component/select-custom';
import { handleError } from '../../utils/FunctionUtils';
import { listGender } from '../const/CategoryConst';
import { MemberModel } from '../model/CategoryModel';
import CheckboxField from './../../component/input-field/CheckboxField';
import {
    addMemberCategories,
    getAllZoneCategories,
    getOrganizations,
    getRoles,
    updateMemberCategories
} from '../services/CategoryServices';
import { useAuth } from '../../auth';

interface Props {
	show: boolean;
	onClose: () => void;
	updatePageData?: () => void;
	data: MemberModel;
}

const CategoryMemberDialogAdd: FC<Props> = (props) => {
	const {
		onClose,
		updatePageData,
		data
	} = props;
  const isUpdate = Boolean(data?.id);
  const isCreate = !isUpdate;
  const hasUser = true;

	const { lang } = useMultiLanguage();
	const { setPageLoading } = useLoading();
  const [createAccount, setCreatAccount]= useState(hasUser);
  const [isChangePassword, setisChangePassword]= useState<boolean>(false);
  const { currentUser } = useAuth();

  const validationSchema = Yup.object({
    code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^\W_]+$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    fullName: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^`~!@#$%^*]*$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    phone: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    birthYear: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable().min(1900, lang("VALIDATION.MIN_YEAR")).max(new Date().getFullYear(), lang("VALIDATION.INVALID_YEAR")),
    gender: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const validationFormUser = createAccount ? validationSchema.concat(
    Yup.object({
      username: Yup.string().when('createAccount', {
        is: () => createAccount,
        then: Yup.string().required(lang("VALIDATION.REQUIRE")).matches(/^[a-zA-Z0-9_]+$/, lang("Không chứa ký tự đặc biệt")).nullable(),
        otherwise: Yup.string().matches(/^[a-zA-Z0-9_]+$/, lang("Không chứa ký tự đặc biệt")).notRequired(),
      }),
      password: Yup.string().when(['isUpdate', 'isChangePassword'], {
        is: () => !isUpdate || isChangePassword,
        then: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
        otherwise: Yup.string().notRequired(),
      }),
      confirmPassword: Yup.string().when(['isUpdate', 'isChangePassword'], {
        is: () => !isUpdate || isChangePassword,
        then: Yup.string()
          .required('Bắt buộc nhập')
          .oneOf([Yup.ref('password'), null], "Chưa khớp với mật khẩu"),
        otherwise: Yup.string().notRequired(),
      }),
      roles: Yup.array().when('createAccount', {
        is: () => createAccount,
        then: Yup.array().min(1, "Chọn một vai trò").max(2, "Chỉ chọn tối đa hai vai trò").nullable(),
        otherwise: Yup.array().notRequired(),
      }),
      orgId: Yup.string().when('createAccount', {
        is: () => createAccount,
        then:  Yup.string().required("Bắt buộc chọn").nullable(),
        otherwise: Yup.array().notRequired(),
      }),
    })
  ) : validationSchema;

	const handleCombinedSubmit = async (values: any, { setFieldError }: FormikHelpers<FormikValues>) => {
		try {
			setPageLoading(true);
			const dataSubmit = {
				code: values.code,
				fullName: values.fullName,
				birthYear: values.birthYear,
				phone: values.phone,
				gender: values.gender,
				email: values.email,
				address: values.address,
        areas: values.areas,
        position: values.position,
        is5s: values.is5s,
        isChangePassword: isChangePassword,
        ...(createAccount && {
          username: values.username,
          roles: values.roles,
          authorities: values.authorities,
          orgId: values.orgId,
          password: values.password,
          confirmPassword: values.confirmPassword,
          active: values.active,
          isUser: createAccount
        }),
        password: (!isChangePassword && isUpdate) ? "12345678" : values.password,
        confirmPassword: (!isChangePassword && isUpdate) ? "12345678" : values.password,
      };

			values.id
				? await updateMemberCategories(values.id, dataSubmit)
				: await addMemberCategories(dataSubmit);

			toast.success(lang(values.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"));
			updatePageData && updatePageData();
			onClose();
		} catch (error) {
			toast.error(error as string);
			console.error(error);
			handleError(error, setFieldError);
		} finally {
			setPageLoading(false);
		}
	};

	return (
		<ModalFormikCustom
			show
			size='xl'
			handleClose={onClose}
			title={isUpdate ? "#Cập nhật" : "#Thêm mới" + " nhân viên"}
			FormikConfig={{
				initialValues: data,
        validationSchema: validationFormUser,
				onSubmit: handleCombinedSubmit,
			}}
		>
			{({ values, errors, touched, handleChange, setFieldValue }: FormikProps<FormikValues>) => {
        return ((
          <>
            <Row className='mb-2'>
              <Col xs={12}>
                <div className="fw-bold spaces fs-16 mb-8 text-primary">{"Thông tin cá nhân"}</div>
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"ID"}
                  disabled
                  name="id"
                  value={values.id}
                  type="text"
                />
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"Mã nhân viên"}
                  readOnly={false}
                  isRequired
                  name="code"
                  value={values.code}
                  type="text"
                  onChange={handleChange}
                  errors={errors?.code}
                  touched={touched?.code}
                />
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"Họ và tên"}
                  readOnly={false}
                  isRequired
                  name="fullName"
                  value={values.fullName}
                  type="text"
                  onChange={handleChange}
                  errors={errors?.fullName}
                  touched={touched?.fullName}
                />
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="flex-row min-w-80"
                  lable={"Năm sinh"}
                  readOnly={false}
                  isRequired
                  name="birthYear"
                  value={values.birthYear}
                  type="number"
                  onChange={handleChange}
                  errors={errors?.birthYear}
                  touched={touched?.birthYear}
                />
              </Col>
              <Col xs={2}>
                <SelectCustom
                  className="spaces z-index-8 width-100"
                  label={"Giới tính"}
                  options={listGender}
                  required
                  name="gender"
                  value={values.gender}
                  valueField={'code'}
                  displayField={'name'}
                  handleChange={(selectedOption) => {
                    setFieldValue("gender", selectedOption);
                  }}
                  errors={errors?.gender}
                  touched={touched?.gender}
                />
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="spaces z-index-8 width-100"
                  lable={"Số điện thoại"}
                  readOnly={false}
                  isRequired
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  type="text"
                  errors={errors?.phone}
                  touched={touched?.phone}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  className="spaces z-index-8 width-100"
                  lable={"Email"}
                  name="email"
                  value={values.email}
                  type="text"
                  onChange={handleChange}
                  errors={errors?.email}
                  touched={touched?.email}
                />

              </Col>
              <Col xs={6}>
                <TextValidator
                  className="spaces z-index-8 width-100"
                  lable={"Địa chỉ"}
                  name="address"
                  value={values.address}
                  type="text"
                  onChange={handleChange}
                  errors={errors?.address}
                  touched={touched?.address}
                />
              </Col>
              <Col xs={2}>
                <TextValidator
                  className="spaces z-index-8 width-100"
                  lable={"Chức vụ"}
                  name="position"
                  value={values.position}
                  type="text"
                  onChange={handleChange}
                  errors={errors?.position}
                  touched={touched?.position}
                />
              </Col>
              <Col xs={3} className="mb-3 mt-2 px-1">
                <CheckboxField
                  label="Là thành viên 5S"
                  name="is5s"
                  handleChange={(e: any) => { setFieldValue("is5s", e.target.checked) }}
                  value={values.is5s || false}
                  errors={errors?.is5s}
                  touched={touched?.is5s}
                />
              </Col>
              {values.id && (
                <Col xs={12}>
                  <TextValidator
                    className="spaces z-index-8 width-100"
                    lable={"Thuộc khu vực"}
                    name="position"
                    value={data?.areas?.join(", ")}
                    type="text"
                    as="textarea"
                    disabled={true}
                    rows={1}
                  />
                </Col>
              )}
              {!hasUser && (
                <Col xs={3} >
                  <div className='d-flex align-items-center spaces h-32'>
                    <CheckboxField
                      value={createAccount}
                      label='Tạo tài khoản'
                      name='creatAccount'
                      handleChange={() => setCreatAccount(prev => !prev)} />
                  </div>
                </Col>
              )}
            </Row>
            {
              createAccount && (
                <Row className='border border-secondary p-5 spaces rounded-6 pt-10' >
                  <Col xs={12}>
                    <div className='d-flex align-items-center spaces'>
                      <div className="fw-bold spaces fs-16 mb-8 text-primary">{"Thông tin tài khoản"}</div>
                      {isUpdate &&
                        <div className='d-flex align-items-center spaces h-32 ml-10'>
                          <Form.Check
                            name="isChangePassword"
                            type="switch"
                            id="isChangePassword"
                            checked={isChangePassword}
                            label="Thay đổi mật khẩu"
                            onChange={(e) => {
                              setisChangePassword(e.target.checked);
                              setFieldValue('isChangePassword', e.target.checked);
                            }}
                          />
                        </div>
                      }
                    </div>
                  </Col>
                  <Col xs={4}>
                    <TextValidator
                      className="spaces z-index-8 width-100"
                      lable={"Tên đăng nhập"}
                      disabled={isUpdate}
                      isRequired
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      type="text"
                      errors={errors?.username}
                      touched={touched?.username}
                    />
                  </Col>
                  <Col xs={4}>
                    <TextValidator
                      className="spaces z-index-8 width-100"
                      lable={"Mật khẩu"}
                      isRequired={isUpdate ? isChangePassword : hasUser}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      type="password"
                      errors={errors?.password}
                      touched={touched?.password}
                      disabled={isUpdate && !isChangePassword}
                    />
                  </Col>
                  <Col xs={4}>
                    <TextValidator
                      className="spaces z-index-8 width-100"
                      lable={"Nhập lại mật khẩu"}
                      isRequired={isUpdate ? isChangePassword : hasUser}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      type="password"
                      errors={errors?.confirmPassword}
                      touched={touched?.confirmPassword}
                      disabled={isUpdate && !isChangePassword}
                    />
                  </Col>
                  <Col xs={4}>
                    <SelectCustom
                      required
                      className="width-100"
                      displayField={'name'}
                      valueField='id'
                      label={"Đơn vị"}
                      optionsResponse="data"
                      service={getOrganizations}
                      params={{ "parentId": currentUser?.orgId || "" }}
                      styles={heightSelectMutil("auto", '30px')}
                      name="orgId"
                      value={values?.orgId}
                      handleChange={(value, _option: any, _actionMeta, name) => {
                        setFieldValue("authorities", _option?.authorities)
                        setFieldValue(name, value);
                      }}
                      errors={errors?.orgId}
                      touched={touched?.orgId}
                    />
                  </Col>
                  <Col xs={4}>
                    <SelectCustom
                      required
                      className="width-100"
                      displayField={'name'}
                      valueField='name'
                      label={"Vai trò"}
                      optionsResponse="content"
                      params={{ pageSize: 10, pageIndex: 1, keyword: '5S' }}
                      service={getRoles}
                      styles={heightSelectMutil("auto", '30px')}
                      isMulti
                      name="roles"
                      value={values.roles}
                      handleChange={(value, _option, _actionMeta, name) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors?.roles}
                      touched={touched?.roles}
                    />
                  </Col>
                  <Col xs={4}>
                    <div className='spaces h-24' />
                    <div className='d-flex align-items-center spaces h-32'>
                      <Form.Check
                        name="active"
                        type="switch"
                        id="active"
                        checked={values.active}
                        label="Kích hoạt tài khoản"
                        onChange={(e) => setFieldValue("active", e.target.checked)}
                      />
                    </div>
                  </Col>
                </Row>)
            }
          </>
        ))
      }}
    </ModalFormikCustom>
  );
};

export default CategoryMemberDialogAdd;

