import { useEffect, useState } from 'react';
import { ICriteria, ItermFormResDto } from '../models/SetOfCriteriaModels';
import SetOfCriteriaContext from './SetOfCriteriaContext';
import "../style.scss"
import TabMenu from '../../component/tabs/TabMenu';
import { tab } from '../../models/tabModels';
import SetOfCriteriaInfoTab from './SetOfCriteriaInfoTab';
import SetOfCriteriaAddTab from './SetOfCriteriaAddTab';
import { INFO_FORM_CRITERIA_ENABLE } from '../config/ConfigField';
import { STATUS_CODE_5S } from '../../../Constant';
import FloatingNotification from '../../component/FloatingNotification';

const SetOfCriteriaForm = () => {

  const [tabList, setTabList] = useState<tab[]>([])
  const [criteriaList, setCriteriaList] = useState<ICriteria[]>([]);
  const [formTableAbility, setFormTableAbility] = useState<any>(INFO_FORM_CRITERIA_ENABLE);
  const [empApprove, setEmpApprove] = useState<string | null>("");  
  const [evenKeyTabActive, setevenKeyTabActive] = useState<string>("0"); 
  const [maxPoint, setMaxPoint] = useState<number>(0);   
  const [termFormInfo, setTermFormInfo] = useState<ItermFormResDto>({});
  const [stageCodeInfo, setStageCodeInfo] = useState<string | null>(null)

  useEffect(() => {
    setTabList(getTabList())
  }, []);

  const getTabList = () => {
    const tabList = [
      {
        eventKey: "0",
        title: "Thông tin cơ bản",
        component: <SetOfCriteriaInfoTab
          setEmpApprove={setEmpApprove}
          switchTab={setevenKeyTabActive}
        />,
      },
      {
        eventKey: "1",
        title: "Thông tin tiêu chí",
        component: <SetOfCriteriaAddTab
          empApprove={empApprove}
        />,
      },
    ]
      return tabList
  };

  return (
    <SetOfCriteriaContext.Provider
      value={{
        criteriaList,
        setCriteriaList,
        setFormTableAbility,
        formTableAbility,
        setEmpApprove,
        maxPoint,
        setMaxPoint,
        setTermFormInfo,
        termFormInfo,
        stageCodeInfo,
        setStageCodeInfo
      }}
    >
      <div className='spaces wrapper-content pt-15'>
        <TabMenu
          propActiveTab={evenKeyTabActive}
          danhsachTabs={tabList}
          handleChangeTab={setevenKeyTabActive}
          vertical={false}
        />
      </div>
      {(termFormInfo.status === STATUS_CODE_5S.YEU_CAU_SUA_DOI && termFormInfo.textRefuse) &&
        <FloatingNotification
          content={termFormInfo.textRefuse}
        />
      }
    </SetOfCriteriaContext.Provider>
  );
};

export default SetOfCriteriaForm;
