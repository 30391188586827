import { ReactNode, useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import TextValidator from '../../component/input-field/TextValidator';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikHelpers, useFormik } from 'formik';
import { ISetOfCriteriaBasicInfo } from '../models/SetOfCriteriaModels';
import {
   addTermForm, 
   updateTermForm, 
   fetchDataTermForm, 
  } from '../services/SetOfCriteriaServices';
import { toast } from 'react-toastify';
import { MAX_LENGTH_ARRAY_CRITERIA_LADDER, initialBasicInfoValues } from '../const/SetOfCriteriaConst';
import { Col } from '../../component/Grid';
import Button from '../../component/button-custom';
import * as Yup from "yup";
import useMultiLanguage from '../../../hook/useMultiLanguage';
import InputDateCustom from '../../component/input-field/InputDateCustom';
import SelectCustom from '../../component/select-custom';
import { heightAutocomplete } from '../../component/input-field/StyleComponent';
import { getUsers } from '../../program/services/ProgramService';
import clsx from 'clsx';
import { useLoading } from '../../../AppContext';
import "../style.scss"
import { formatId, handleError } from '../../utils/FunctionUtils';
import { BASIC_FORM_CRITERIA, BASIC_FORM_INFO_ENABLE, INFO_FORM_CRITERIA, INFO_FORM_CRITERIA_ENABLE } from '../config/ConfigField';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from '../../../Constant';
import ButtonCustom from '../../component/button-custom';
import SetOfCriteriaContext from './SetOfCriteriaContext';
import "../style.scss";

interface SetOfCriteriaInfoTabProps {
  setEmpApprove: React.Dispatch<React.SetStateAction<string | null>>;
  switchTab: React.Dispatch<React.SetStateAction<string>>;
}

const SetOfCriteriaInfoTab: React.FC<SetOfCriteriaInfoTabProps> = ({
  setEmpApprove,
  switchTab
}) => {

  const {
    setMaxPoint,
    setTermFormInfo,
    termFormInfo,
    setFormTableAbility,
    setStageCodeInfo
  } = useContext(SetOfCriteriaContext);

    let navigate = useNavigate();
    let { id } = useParams();
    const { setPageLoading } = useLoading();
    const { lang } = useMultiLanguage();

    const [elementAbility, setElementAbility] = useState(BASIC_FORM_INFO_ENABLE);

    const validationSchemaBasicInfo = Yup.object().shape({
        termFormReqDto: Yup.object().shape({
        code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^\W_]+$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
        userApprove: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
        name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^`~!@#$%^*]*$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")) }).required(),
        lstTermAnswerDto: Yup.array().of(
            Yup.object().shape({
                answerNumeric: Yup.number().nullable().required("Yêu cầu nhập thông tin thang điểm").integer("Yêu cầu nhập điểm là số nguyên")
                .min(1, "Yêu cầu nhập điểm lớn hơn 0"),
                answerText: Yup.string().nullable().required("Yêu cầu nhập thông tin thang điểm"),
            })
        )
    });

    const isAddNew = Boolean(id === 'new');
    const hasCreate = Boolean(termFormInfo.id);
    const termFormId = termFormInfo.id;

    const handleSaveTermForm = async (values: ISetOfCriteriaBasicInfo, _actions: FormikHelpers<ISetOfCriteriaBasicInfo>) => {
      try {
        setPageLoading(true)
        const { data } = Boolean(values.id)
          ?
          await updateTermForm(values.id, { ...values })
          :
          await addTermForm(values);
        //set user approved
        setEmpApprove(data.data.termFormResDto.userApprove ? String(data?.data?.termFormResDto?.empApprove) : "");
        //set data form
        setTermFormInfo((perState) => ({
          ...data?.data.termFormResDto,
          stageRequestId: perState.stageRequestId,
        }))
        //toast message
        toast.success(`${values.id ? "Cập nhật" : "Thêm mới"} thành công`);
        //switchtab
        !hasCreate && switchTab("1");
      } catch (error) {
        handleError(error)
      } finally {
        setPageLoading(false)
      }
    };
  
    const formikTermForm = useFormik({
      initialValues: initialBasicInfoValues,
      validationSchema: validationSchemaBasicInfo,
      onSubmit: handleSaveTermForm,
    });    

    const values = formikTermForm.values;
    const errors = formikTermForm.errors;
    const touched = formikTermForm.touched;
    const handleChange = formikTermForm.handleChange;
    const setFieldValue = formikTermForm.setFieldValue;

    useEffect(() => {
      if (isAddNew && hasCreate) {
        handleGetDataTermForm(Number(termFormId));
        return
      }
      if (!isAddNew && Number(id)) {
        handleGetDataTermForm(Number(id || termFormId));
        return
      }
    }, [id]);

    useEffect(() => {
      setMaxPoint(values.lstTermAnswerDto?.[values.lstTermAnswerDto?.length -1]?.answerNumeric || 0);
    },[values.lstTermAnswerDto]);

    const handleGetDataTermForm = async (termFormId: number) => {
      try {
        setPageLoading(true);
        const { data } = await fetchDataTermForm(termFormId);
        const termFormData = data?.data || {};
        const termFormReqDto = termFormData.termFormResDto || {};
        const lstTermAnswer = termFormData.lstTermAnswer || [];
        //set values
        formikTermForm.setValues({
          id: termFormData.termFormResDto?.id || null,
          termFormReqDto,
          lstTermAnswerDto: lstTermAnswer
        });
        //set data form
        setTermFormInfo(termFormData.termFormResDto || {});
        setEmpApprove(termFormReqDto.userApprove ? String(termFormReqDto.userApprove) : "");
        setElementAbility(
          BASIC_FORM_CRITERIA[
            termFormData?.stageCode as keyof typeof BASIC_FORM_CRITERIA
          ] || BASIC_FORM_INFO_ENABLE
        );
        setFormTableAbility(
          INFO_FORM_CRITERIA[
            termFormData?.stageCode as keyof typeof INFO_FORM_CRITERIA
          ] || INFO_FORM_CRITERIA_ENABLE
        )
        setStageCodeInfo(termFormData?.stageCode)
      } catch (error) {
        handleError(error);
      } finally {
        setPageLoading(false);
      }
    };
    
    const handleCloseForm = () => {
        navigate(-1);
        return;
    };

    const addNewPointLadder = () => {
      const newAnswerList = [...values.lstTermAnswerDto].concat([
        {
          answerNumeric: 0,
          answerText: "",
        },
      ]);
      setFieldValue('lstTermAnswerDto', newAnswerList);
    };

    const handleRemovePointLadder = (index: number, itemToRemove: any) => {
      const newAnswerList = [...values.lstTermAnswerDto].filter(
        (answerItem, i) => i !== index
      );
      setFieldValue('lstTermAnswerDto', newAnswerList);
    };

    return (
      <div className='pt-4'>         
        <h3 className='spaces m-0 text-uppercase fs-20'>
          BIỂU MẪU
          <span
            className='spaces fs-17 ml-15 color-gunmetal italic fw-normal'>
            {termFormId ? `#${formatId(termFormId)}` : ""}
          </span>
        </h3>
        <form onSubmit={formikTermForm.handleSubmit}>
          <Row className='spaces mt-15 px-6'>
            <Col lg={4} xxl={1}>
              <TextValidator
                className="min-w-80"
                name="id"
                type="text"
                lable="ID"
                disabled
                onChange={handleChange}
                value={termFormId ? formatId(termFormId) : ''}
                disable={!hasCreate}
              />
            </Col>
            <Col lg={4} xxl={1}>
              <TextValidator
                className="min-w-80"
                name="termFormReqDto.code"
                type="text"
                lable="Mã biểu mẫu"
                isRequired
                value={values.termFormReqDto.code}
                errors={errors.termFormReqDto?.code}
                touched={touched.termFormReqDto?.code}
                onChange={handleChange}
                disabled={elementAbility.field_code}
              />
            </Col>
            <Col lg={4} xxl={3}>
              <TextValidator
                className="min-w-80"
                name="termFormReqDto.name"
                type="text"
                lable="Tên biểu mẫu"
                isRequired
                value={values.termFormReqDto.name}
                errors={errors.termFormReqDto?.name}
                touched={touched.termFormReqDto?.name}
                onChange={handleChange}
                disabled={elementAbility.field_name}
              />
            </Col>
            <Col lg={4} xxl={1}>
              <InputDateCustom
                label="Ngày tạo"
                value={values.termFormReqDto?.issuedDate || ""}
                setValue={(value: string) => setFieldValue("termFormReqDto.issuedDate", value)}
                disabled={elementAbility.field_start_date}
              />
            </Col>
            <Col lg={4} xxl={4}>
              <TextValidator
                className="min-w-80"
                name="termFormReqDto.note"
                type="text"
                lable="Ghi chú"
                value={values.termFormReqDto?.note}
                onChange={handleChange}
                disabled={elementAbility.field_note}
              />
            </Col>
            <Col lg={4} xxl={2}>
              <SelectCustom
                label='Người phê duyệt'
                menuPlacement="auto"
                required
                styles={heightAutocomplete("32px")}
                name="userApprove"
                valueField={'username'}
                displayField={'displayName'}
                optionsResponse='data'
                service={getUsers}
                value={values.termFormReqDto.userApprove}
                params={{ authorities: `5S.${AUTHORITIES_PERMISSIONS.FORM}.${AUTHORITIES_ABILITY.APPROVE}` }}
                handleChange={(value, _option, _actionMeta, name) => {
                  setEmpApprove(value ? String(value) : "");
                  setFieldValue("termFormReqDto.userApprove", value);
                }}
                isDisabled={elementAbility.field_userAprove}
                errors={errors.termFormReqDto?.userApprove}
                touched={touched.termFormReqDto?.userApprove}
              />
            </Col>
          </Row>
          <h4 className='spaces m-0 text-uppercase color-secondary mt-20 fs-20 mb-15'>Thang điểm đánh giá/Tiêu chí</h4>
          <Row className='spaces mt-5 mb-15 px-6 pb-5'>
            {values.lstTermAnswerDto?.map((item, index, arr) => (
              <>
                <Col lg={6} xxl={4}>
                    <div className='spaces d-flex align-items-center gap-1 mt-1'>
                        <div className='d-flex align-items-start'>
                            <TextValidator
                                className="spaces flex-row-label"
                                classNameInput="spaces max-w-80 mr-6 custom-input-answerNumeric"
                                lable={`Mức ${index + 1}`}
                                placeholder=" "
                                name={`lstTermAnswerDto[${index}].answerNumeric`}
                                type="number"
                                value={item?.answerNumeric}
                                onChange={handleChange}
                                errors={(errors.lstTermAnswerDto?.[index] as any)?.answerNumeric ? true : false}
                                touched={(touched.lstTermAnswerDto as any)?.[index]?.answerNumeric}
                                disabled={elementAbility.field_score_criteria}
                            />
                            <TextValidator
                                classNameInput="spaces min-w-240 mr-4"
                                name={`lstTermAnswerDto[${index}].answerText`}
                                type="text"
                                value={item?.answerText}
                                onChange={handleChange}
                                errors={(errors.lstTermAnswerDto?.[index] as any)?.answerText ? true : false}
                                touched={(touched.lstTermAnswerDto as any)?.[index]?.answerText}
                                disabled={elementAbility.field_des_criteria}

                            />
                        </div>
                        {
                           ( Array.isArray(values?.lstTermAnswerDto) && index === (values.lstTermAnswerDto.length - 1) && index > 1 && !elementAbility.btn_remove_criteria) &&
                            <i className={clsx("cursor-pointer bi bi-x-square-fill color-crimson-red spaces fs-20", {
                                "mb-6": Object.values((errors?.lstTermAnswerDto?.[index] as any) || {})?.length > 0 && ((touched.lstTermAnswerDto as any)?.[index]?.answerText ||
                                    (touched?.lstTermAnswerDto as any)?.[index]?.answerNumeric)
                            })}
                                onClick={() => handleRemovePointLadder(index, item)}
                            />
                        }
                    </div>
                    {
                        (((touched.lstTermAnswerDto as any)?.[index]?.answerText ||
                            (touched.lstTermAnswerDto as any)?.[index]?.answerNumeric) &&
                            errors.lstTermAnswerDto) &&
                        <div className='text-danger text-center spaces max-w-345'>
                            {Object.values((errors.lstTermAnswerDto?.[index] as any) || {})[0] as ReactNode}
                        </div>
                    }
                </Col>
                {(index === (arr.length - 1) && index < (MAX_LENGTH_ARRAY_CRITERIA_LADDER - 1) && !elementAbility.btn_add_criteria) && (
                  <Col lg={6} xl={4} className='spaces pb-20'>
                    <div className='spaces custom-button-plus max-w-80'>
                      <i className="cursor-pointer bi bi-plus color-primary spaces fs-16 font-normal lh-24"
                        onClick={() => addNewPointLadder()}
                      >
                        Thêm
                      </i>
                    </div>
                  </Col>
                )}
              </>
            ))}
            <Col xs={12} className={`d-flex justify-content-center`}>
              {
                !elementAbility?.btn_save_form_basic && <ButtonCustom
                  type='submit'
                  checkAuthority={{
                    permission: AUTHORITIES_PERMISSIONS.FORM,
                    ability: AUTHORITIES_ABILITY.ACTION,
                  }}
                >
                  {hasCreate ? 'Lưu' : 'Lưu và tiếp tục'}
                </ButtonCustom>
              }
              <Button
                className='button-primary-outline'
                onClick={handleCloseForm}
              >
                Hủy
              </Button>
            </Col>
          </Row>
        </form>         
      </div>
    );
};

export default SetOfCriteriaInfoTab;