import { StageSignature } from "../../constant";
import { FAKE_DATA_PROGRAM_CHILDREN } from "../FakeData";
import { iProgram } from "../models/ProgramModel";

export const INIT_FORM_DATA : iProgram = {
    id:"",
    code: "",
    name: "",
    estimatedEndDate: "",
    estimatedStartDate: "",
    empLeaderId: "",
    empLeaderName: "",
    empCreateId: "",
    type: "",
    termFormId: "",
    note: "",
    statusCode: "",
    textRefuse: null,
    textOpinions: null,
    stageCode: StageSignature.UNKNOWN,
    action: "SAVE",
    programImplDto:null,
    callback: function () { },
    userApprove: "",
};

export const TIME_TYPE = {
    EMERGENCY: "EMERGENCY",
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    QUARTER: "QUARTER",
    YEAR: "YEAR",
};

export const STATUS_REQUEST = {
    SAVE: "SAVE",
    SUBMIT_FOR_APPROVAL: "SUBMIT_FOR_APPROVAL",
    APPROVAL: "APPROVAL",
    REQUEST_FOR_REVISION: "REQUEST_FOR_REVISION",
    IMPLEMENTATION: "IMPLEMENTATION",
    FINISH: "FINISH"
}

export const LIST_PLAN_TYPE : any[] = [
    { code: TIME_TYPE.EMERGENCY, name: "Đột xuất" },
    { code: TIME_TYPE.DAY, name: "Hằng ngày" },
    { code: TIME_TYPE.WEEK, name: "Hằng tuần" },
    { code: TIME_TYPE.MONTH, name: "Theo tháng" },
    { code: TIME_TYPE.QUARTER, name: "Theo quý" },
    { code: TIME_TYPE.YEAR, name: "Theo năm" },
];

export const LIST_CRITERIA : any[] = [
    { code: 1, name: "Tiêu chí Q1_2024 HS1" },
    { code: 2, name: "Tiêu chí Q1_2024 HS2" },
];

export const LIST_DAY_OF_WEEK : any[] = [
    { value: "Thứ 2" },
    { value: "Thứ 3" },
    { value: "Thứ 4" },
    { value: "Thứ 5" },
    { value: "Thứ 6" },
    { value: "Thứ 7" },
    { value: "Chủ nhật" },
];

export const LIST_ASSIGN_EVALUATION : any[] = [
    { evaluationArea: "KV hành chính nhân sự", implementationStaff: null },
    { evaluationArea: "Khu vực R&D", implementationStaff: null },
    { evaluationArea: "Khu vực phòng Tài chính - Kế toán", implementationStaff: null },
    { evaluationArea: "Khu vực nhà ăn", implementationStaff: null },
    { evaluationArea: "Khu vực K1", implementationStaff: null },
]

export const LIST_ASSESSMENT_TEAM_LEADER: any[] = [
    { code: 1, name: "Nguyễn Ngọc Quyên" },
    { code: 2, name: "Phạm Xuân Thắng" },
];