import { FC } from "react";
import "./style.scss"
import {Col as Column, ColProps} from "react-bootstrap";

interface Iprops extends ColProps {};
const Col: FC<Iprops> = (props) => {
    let { className, children, ...rest } = props;
    return (
        <Column className={`mb-3 px-1 ${className}`} {...rest}>
            {children}
        </Column>
    );
};

export { Col };
