import { STATUS_CODE_5S } from "../../../Constant";

export const SCHEDULE_PROGRESS = {
    ELAPSED: 1,   // Đã trôi qua
    HAPPENING: 2, // Đang diễn ra
    UPCOMING: 3,  // Sắp tới
};

export const BG = {
    [STATUS_CODE_5S.CHUA_DANH_GIA]: "#eaeaea",
    [STATUS_CODE_5S.DANG_DANH_GIA]: "#c4edff",
    [STATUS_CODE_5S.DA_DANH_GIA]: "#bbe9c6",
}

export const TIME_TYPE_OPTIONS = [
  { name: "Tuần", value: "WEEK" },
  { name: "Tháng", value: "MONTH" },
  { name: "Quý", value: "QUARTER" },
  { name: "Năm", value: "YEAR" },
];

export const NUM_OF_ITEMS_EACH_SLIDE = 4;
