import { useState } from 'react'
import TableCustom from '../component/table-custom/TableCustom';
import { ColumnProgram } from './consts/Columns';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CREATE_PARAMETER } from '../constant';
import { deleteProgram, searchPrograms } from './services/ProgramService';
import { ConfirmDialog } from '../component/ConfirmDialog';
import InputSearch from '../component/input-field/InputSearch';
import { GeneralSearchParamsType } from '../utils/ParamsUtils';
import useMultiLanguage from '../../hook/useMultiLanguage';
import { toast } from 'react-toastify';
import ButtonCustom from '../component/button-custom';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from '../../Constant';
import { SearchBase, searchBaseDefaultValue } from '../utils/InterfaceUtils';
import { useAuth } from '../auth';
import { useLoading } from '../../AppContext';
import { checkColorByStatus } from '../utils/FunctionUtils';

type Props = {}

const Program = (props: Props) => {
  const navigate = useNavigate();
  const { lang } = useMultiLanguage();
  const { currentUser } = useAuth();
  const { setPageLoading } = useLoading();

  const [keyword, setKeyword] = useState<string>("");
  const [listProgram, setListProgram] = useState<any[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<SearchBase>({
    ...searchBaseDefaultValue,
    orgId: currentUser?.orgId,
  });

  const updatePageData = async (searchObj: GeneralSearchParamsType) => {
    try {
      setPageLoading(true);
      let { data } = await searchPrograms(searchObj);
      let newData = data.data.content;
      let pageable = data.data.pageable;
      setListProgram(newData);
      setTotalElements(pageable.totalElements);
      setNumberOfElements(pageable.pageNumber + 1);
    } catch (err) {
      console.error(err);
    } finally {
      setPageLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleEdit = (rowData: any) => {
    if (rowData?.id) {
      navigate(`${rowData?.id}`);
    }
  };

  const handleAprove = (rowData: any) => {
    if (rowData?.id) {
      navigate(`${rowData?.id}`);
    }
  };

  const handleDelete = (rowData: any) => {
    if (rowData?.id) {
      setSelectedId(rowData?.id);
      setOpenConfirmDialog(true);
    }
  };
  const handleConfirmDelete = async () => {
    try {
      if (selectedId !== CREATE_PARAMETER) {
        await deleteProgram(selectedId);
        setOpenConfirmDialog(false);
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        handleSearch();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSearch = () => {
    setSearchObject((preState) => ({ 
      ...preState,
      pageIndex: searchBaseDefaultValue.pageIndex,
      keyword 
    }));
  };

  const setRowColorClass = (row: any, rowId: string) => {
    checkColorByStatus(row?.statusCode, rowId);
    return "";
  };

  return (
    <div className='wrapper-content'>
      <Row>
        <Col xs="12" className='my-5'>
          <h3 className='spaces m-0 text-uppercase color-primary'>CHƯƠNG TRÌNH ĐÁNH GIÁ 5S</h3>
        </Col>
      </Row>
      <Row className='spaces pb-6'>
        <Col xs="9">
          <ButtonCustom
            onClick={() => navigate(CREATE_PARAMETER)}
            checkAuthority={{
                permission: AUTHORITIES_PERMISSIONS.FORM,
                ability: AUTHORITIES_ABILITY.ACTION,
            }}
          >
            Thêm mới
          </ButtonCustom>
        </Col>
        <Col xs="3">
          <InputSearch 
            className='spaces h-31' 
            handleChange={handleChange} 
            handleSearch={handleSearch} 
            placeholder="Tìm kiếm theo mã, tên kế hoạch" 
            isEnter 
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <TableCustom
            id="profile-1"
            height={"calc(100vh - 295px)"}
            data={listProgram || []}
            columns={ColumnProgram({
              handleEdit, handleDelete
            })}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            updatePageData={updatePageData}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            objectSearch={searchObject}
            setRowColorClass={setRowColorClass}
          />
        </Col>
      </Row>
      {openConfirmDialog &&
        <ConfirmDialog
          show={openConfirmDialog}
          onYesClick={handleConfirmDelete}
          onCancelClick={() => setOpenConfirmDialog(false)}
          yes="Có"
          cancel='Hủy'
        />
      }
    </div>
  )
}

export default Program;