import { FC } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import { Col } from './Grid'
import TextValidator from './input-field/TextValidator'
import { useFormik } from 'formik'
import * as yup from "yup";
import Button from './button-custom'
import { TYPE } from '../utils/Constant'
import InputDateCustom from './input-field/InputDateCustom'
import { generalDateValidation } from '../utils/ValidationSchema'

interface Props {
  show: boolean
  onSubmitForm: (values: any) => void
  onCancelClick: () => void
  title?: string
  label?: string
  typeInput?: string
}

const SubmitTextPopup: FC<Props> = (props) => {
  const {
    show,
    onSubmitForm,
    onCancelClick,
    title = "Gửi yêu cầu chỉnh sửa",
    label = "Nội dung chỉnh sửa",
    typeInput="text"
  } = props;

  const formik = useFormik({
    initialValues: {
      content: "",
    },
    validationSchema: yup.object({
        content: typeInput === "date" ? generalDateValidation().required("Không được bỏ trống").nullable() : yup.string().trim().required("Không được bỏ trống"),
    }),
    onSubmit: onSubmitForm,
  });

  const values = formik.values;
  const errors = formik.errors;
  const touched = formik.touched;

  return (
    <Modal
      show={show}
      centered
      animation
      className='custom-modal'
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header className="bg-pri p-3">
          <Modal.Title className="text-white fw-normal">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center p-4">
          <Row className='w-100'>
            <Col xs="12">
                {
                    typeInput === 'text' ? <TextValidator
                        lable={label}
                        name="content"
                        type='text'
                        value={values?.content || ""}
                        onChange={formik.handleChange}
                        isRequired
                        errors={errors?.content}
                        touched={touched?.content}
                        as="textarea"
                        rows={3}
                    /> : <>
                        <InputDateCustom
                            label={label}
                            type={typeInput}
                            value={values?.content || ""}
                            setValue={(value)=>formik.setFieldValue('content',value)}
                            isRequired
                            errors={errors?.content}
                            touched={touched?.content}
                        />
                    </>
                }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-3 center">
          <Button type='submit'> Gửi </Button>
          <Button color='secondary' onClick={onCancelClick}> Hủy </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export { SubmitTextPopup }
