import { SubmitTextPopup } from '../../component/SubmitTextPopup';
import { toast } from 'react-toastify';
import { Children, useState } from 'react';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import {
    BTN_APPROVE,
    BTN_CANCEL,
    BTN_DEPLOY,
    BTN_FINISH,
    BTN_SAVE,
    BTN_SEND_REQUEST,
    BTN_REQUEST_EDIT,
    AUTHORITIES_PERMISSIONS,
    BTN_SAVE_APPROVE,
    BTN_ASSIGNED
} from '../../../Constant';
import {
    awaitingAssignment,
    finishPrograms,
    implementationPrograms,
    updateApproveRequestPrograms,
    updateAssignAssessor,
    updateStageEditRequestPrograms,
    updateStageSendRequestPrograms
} from '../services/ProgramService';
import { useLoading } from '../../../AppContext';
import { PopupSendRequest } from '../../component/PopupSendRequest';
import { handleError } from '../../utils/FunctionUtils';
import ButtonCustom from '../../component/button-custom';
import { iProgram } from '../models/ProgramModel';
import { useNavigate } from 'react-router-dom';
import useMultiLanguage from '../../../hook/useMultiLanguage';

type Props = {
    lstButton: any[]
    handleClose: () => void
    setActionSubmit: (action: string) => void
    handleSubmit: () => void
    setCallback: (callback: () => void) => void;
    reload: () => void;
    programId: string
    formData: iProgram
    defaultValue?: string | null
}

interface ConfirmDialogState {
    show: boolean;
    message: string;
    action: () => void;
}

const GroupButton = ({
    handleClose,
    lstButton,
    handleSubmit,
    setCallback,
    reload,
    programId,
    formData,
    defaultValue = ""
}: Props) => {
    const { setPageLoading } = useLoading();
    const { lang } = useMultiLanguage();
    const navigate = useNavigate();
    const [openEditRequestPopup, setOpenEditRequestPopup] = useState<boolean>(false);
    const [openSendRequestPopup, setOpenSendRequestPopup] = useState<boolean>(false);
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogState>({
        show: false,
        message: "",
        action: () => { }
    });    

    const handleOpenConfirmDialog = (message: string, action: () => void) => {
        setConfirmDialog({
            show: true,
            message,
            action
        });
    };

    const validationTableSelect = () => {
        const _programImplDto = formData.programImplDto.map((item: any) => ({ ...item, empPerformId: "001" }));
        const isNotSelectedEnough = _programImplDto?.some((item: any) => !item.empPerformId);
        return isNotSelectedEnough;
    };

    const handleButtonClick = (action: string) => {
        switch (action) {
            case BTN_SAVE.action:
                setCallback(responseSave);
                handleSubmit();
                break;
            case BTN_CANCEL.action:
                handleClose();
                break;
            case BTN_SEND_REQUEST.action:
                setCallback(() => setOpenSendRequestPopup(true));
                handleSubmit();
                break;
            case BTN_SAVE_APPROVE.action:
                handleAssignAssessor();
                break;
            case BTN_APPROVE.action:
                if (validationTableSelect()) {
                    toast.warning("Phải chọn đủ người phân công đánh giá")
                } else {
                    handleOpenConfirmDialog("phê duyệt", () => handleApprove());
                }
                break;
            case BTN_REQUEST_EDIT.action:
                setOpenEditRequestPopup(true);
                break;
            case BTN_DEPLOY.action:
                handleOpenConfirmDialog("triển khai", handleImplementation)
                break;
            case BTN_FINISH.action:
                handleOpenConfirmDialog("kết thúc", handleFinish)
                break;
            case BTN_ASSIGNED.action:
                handleAssigned();
                break;
            default:
                break;
        }
    }; 

    const handleImplementation = async() => {
        try {
            await implementationPrograms(formData.id, formData);
            toast.success("Triển khai thành công");
            setPageLoading(false)
            navigate(-1);
        } catch (error) {
            handleError(error)
        }
    };

    const handleApprove = async() => {
        try {
            setPageLoading(true)
            await updateApproveRequestPrograms(formData.id, formData);
            toast.success("Phê duyệt thành công");
            setPageLoading(false)
            reload();
        } catch (error) {
            handleError(error)
            setPageLoading(false)
        }
    };

    const handleAssignAssessor = async() => {
        try {
            setPageLoading(true)
            await updateAssignAssessor(formData.id, formData);
            toast.success("Cập nhật thành công");
            setPageLoading(false)
            navigate(-1);
        } catch (error) {
            handleError(error)
            setPageLoading(false)
        }
    };

    const handleFinish = async() => {
        try {
            await finishPrograms(formData.id, formData);
            toast.success("Kết thúc thành công");
            setPageLoading(false)
            navigate(-1);
        } catch (error) {
            handleError(error)
        }
    };

    const handleSendRequest = async (data: any) => {
        try {
            setPageLoading(true);
            const sendRequestData = {
                programId: programId,
                stageRequestId: formData.stageRequestId,
                userApprove: data.userApprove,
                textOpinions: data.content
            }
            await updateStageSendRequestPrograms(sendRequestData);
            toast.success("Trình phê duyệt thành công")
            setPageLoading(false);
            handleClose();
        } catch (error) {
            handleError(error)
            setPageLoading(true);
        }
    };

    const handleRequestEdit = async (textRefuse: string) => {
        try {
            setPageLoading(true);
            let submitData = {
                programId,
                stageRequestId: formData.stageRequestId,
                textRefuse
            };
            await updateStageEditRequestPrograms(submitData);
            setOpenEditRequestPopup(false);
            toast.success("Gửi yêu cầu thành công");
            navigate("/approved");
        } catch (error) {
            toast.error(String(error));
        } finally {
            setPageLoading(false);
        }
    };

    const handleAssigned = async() => {
        try {
            setPageLoading(true)
            await awaitingAssignment(formData?.id, formData)
            toast.success("Đã phân công.");
            navigate("/program");
        } catch (error) {
            handleError(error)
        } finally {
            setPageLoading(false)
        }
    }
    
    const responseSave = () => {
        toast.success(lang(programId ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"));
        navigate(-1);
    };
    
    return (
        <>
            <div className='d-flex gap-1 justify-content-center align-items-center'>
                {Children.toArray(lstButton?.map((button => (
                    <ButtonCustom
                        name={button.name}
                        className={button.className}
                        onClick={() => { handleButtonClick(button.action) }}
                        checkAuthority={button.authority}
                    >
                        {button.text}
                    </ButtonCustom>
                ))))}
            </div>
            {openEditRequestPopup &&
                <SubmitTextPopup
                    show={openEditRequestPopup}
                    onSubmitForm={(value) => {
                        handleRequestEdit(value?.content || "")
                    }}
                    onCancelClick={() => setOpenEditRequestPopup(false)}
                />
            }
            {confirmDialog.show && (
                <ConfirmDialog
                    show={confirmDialog.show}
                    message={`Bạn có muốn ${confirmDialog.message} không?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={() => {
                        confirmDialog.action();
                        setConfirmDialog((preState) => ({ ...preState, show: false }));
                    }}
                    onCancelClick={() => setConfirmDialog((preState) => ({ ...preState, show: false }))}
                />
            )}
            {openSendRequestPopup &&
                <PopupSendRequest
                    show={openSendRequestPopup}
                    onSubmitForm={(data) => {
                        handleSendRequest(data)
                    }}
                    defaultValue={defaultValue}
                    onCancelClick={() => setOpenSendRequestPopup(false)}
                    permission={AUTHORITIES_PERMISSIONS.PROGRAM}
                />
            }
        </>
    );
};
export default GroupButton