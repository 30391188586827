import { StageSignature as STATUS } from "../../constant";

const HIDE = {
    display: false,
    disable: true
};

const SHOW_AND_ENABLE = {
    display: true,
    disable: false
};

const SHOW_AND_DISABLE = {
    display: true,
    disable: true
};

export const TABLE_STATUS: { [key: string]: any } = {
    [STATUS.INITIALIZATION]: HIDE,
    [STATUS.PENDING_APPROVAL]: HIDE,
    [STATUS.PENDING_APPROVAL_USER_APPROVE]: SHOW_AND_ENABLE,
    [STATUS.REQUEST_FOR_MODIFICATION]: HIDE,
    [STATUS.REQUEST_FOR_MODIFICATION_USER_APPROVE]: SHOW_AND_DISABLE,
    [STATUS.APPROVED]: HIDE,
    [STATUS.APPROVED_USER_APPROVED]: SHOW_AND_DISABLE,
    [STATUS.CURRENT]: HIDE,
    [STATUS.CURRENT_USER_APPROVE]: SHOW_AND_DISABLE,
    [STATUS.FINISHED]: HIDE,
    [STATUS.FINISHED_USER_APPROVED]: SHOW_AND_DISABLE,
    [STATUS.EVALUATOR_ASSIGNED]: SHOW_AND_ENABLE,
    [STATUS.EVALUATOR_ASSIGNED_VIEW]: SHOW_AND_DISABLE,
}