import { Col, Form, Row } from 'react-bootstrap';
import TextValidator from '../../component/input-field/TextValidator';
import CheckboxField from './../../component/input-field/CheckboxField';
import InputDateCustom from '../../component/input-field/InputDateCustom';
import SelectCustom from '../../component/select-custom';
import { listGender } from '../../constant';
import { useFormik } from 'formik';

type Props = {}

const AccountForm = (props: Props) => {

    return (
        <form>
            <div className='d-flex flex-column gap-12 spaces px-12'>
                <Row>
                    <div className='spaces color-primary fw-bold fs-16'>#Thêm mới tài khoản</div>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="fw-bold spaces fs-16 mb-8">{"Thông tin tài khoản"}</div>
                    </Col>
                    <Col xs={4}>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Tên tài khoản"}
                            isRequired
                            name="id"
                            type="text"
                        />
                    </Col>
                    <Col xs={4} className='p-0'>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Mật khẩu"}
                            isRequired
                            name="id"
                            type="text"
                        />
                    </Col>
                    <Col xs={4}>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Nhập lại mật khẩu"}
                            isRequired
                            name="id"
                            type="text"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <div className="fw-bold spaces fs-16 mb-8">{"Thông tin cá nhân"}</div>
                    </Col>
                    <Col xs={4}>
                        <SelectCustom
                            options={listGender}
                            className="spaces z-index-24 width-100"
                            valueField={'id'}
                            displayField={'name'}
                            label={"Họ và tên"}
                            optionsResponse="data"
                            name="areas"
                            required
                        />
                    </Col>
                    <Col xs={3} className='p-0'>
                        <InputDateCustom
                            className="flex-row min-w-80"
                            label={"Ngày sinh"}
                            type="text"
                            value=''
                            setValue={() => { { } }}
                        />
                    </Col>
                    <Col xs={5}>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Nơi sinh"}
                            name="id"
                            type="text"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <SelectCustom
                            options={listGender}
                            className="spaces z-index-23 width-100"
                            valueField={'id'}
                            displayField={'name'}
                            label={"Giới tính"}
                            optionsResponse="data"
                            name="areas"
                        />
                    </Col>
                    <Col className='ps-0'>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"CCCD/CMT"}
                            name="id"
                            type="text"
                        />
                    </Col>
                    <Col className='p-0'>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Email"}
                            name="id"
                            type="text"
                        />
                    </Col>
                    <Col>
                        <TextValidator
                            className="flex-row min-w-80"
                            lable={"Số điện thoại"}
                            name="id"
                            type="text"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} className='pe-0'>
                        <SelectCustom
                            options={[]}
                            className="spaces z-index-20 width-100"
                            valueField={'id'}
                            displayField={'name'}
                            label={"Đơn vị"}
                            optionsResponse="data"
                            name="areas"
                            isMulti
                        />
                    </Col>
                    <Col >
                        <SelectCustom
                            options={[]}
                            className="spaces z-index-20 width-100"
                            valueField={'id'}
                            displayField={'name'}
                            label={"Phòng ban"}
                            optionsResponse="data"
                            name="areas"
                            isMulti
                        />
                    </Col>
                </Row>
                <Row>
                    <div className="fw-bold spaces fs-16 pt-12">{"Vai Trò tài khoản"}</div>


                </Row>
                <Row className='spaces pb-16'>
                    <Col xs={3}>
                        <CheckboxField name='roles' label='ADMIN' value={true} handleChange={() => { }} />
                    </Col>
                    <Col xs={3}>
                        <CheckboxField name='roles' label='DATABASE' value={true} handleChange={() => { }} />
                    </Col>
                    <Col xs={3}>
                        <CheckboxField name='roles' label='DEMO' value={false} handleChange={() => { }} />
                    </Col>
                    <Col xs={3}>
                        <CheckboxField name='roles' label='OPERATOR' value={true} handleChange={() => { }} />
                    </Col>

                </Row>
                <Row>
                    <Col className='d-flex justify-content-end spaces gap-8'>
                        <Form.Check
                            name="active"
                            type="switch"
                            id="active"
                            label="Kích hoạt"
                        />
                        <Form.Check
                            name="changePassword"
                            type="switch"
                            id="changePassword"
                            label="Đổi mật khẩu"
                        />
                    </Col>
                </Row>
                <Row >
                    <Col className='d-flex justify-content-center'>
                        <button
                            className="spaces button-primary py-4 fs-12 h-30"
                            type="submit"
                        >
                            Lưu
                        </button>
                        <button
                            className="spaces button-primary-outline py-4 fs-12 h-30 ml-8"
                        >
                            Hủy
                        </button>
                    </Col>
                </Row>
                <Row>
                </Row>

            </div>

        </form>
    )
}

export default AccountForm