import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TableCustom from '../component/table-custom/TableCustom';
import { SetOfCriteriaColumns } from './components/SetOfCriteriaColumns';
import { ICriteria, ISetOfCriteria } from './models/SetOfCriteriaModels';
import { useNavigate } from 'react-router-dom';
import { deleteTermForm, searchCriteria } from './services/SetOfCriteriaServices';
import { ConfirmDialog } from '../component/ConfirmDialog';
import { toast } from 'react-toastify';
import { FAKE_DATA_ITEM_CRITERIA } from '../program/FakeData';
import InputSearch from '../component/input-field/InputSearch';
import useMultiLanguage from '../../hook/useMultiLanguage';
import { checkColorByStatus, handleError } from '../utils/FunctionUtils';
import ButtonCustom from '../component/button-custom';
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from '../../Constant';
import { SearchBase, searchBaseDefaultValue } from '../utils/InterfaceUtils';
import { useAuth } from '../auth';
import { useLoading } from '../../AppContext';

function SetOfCriteria() {
  let navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>("");
  const { lang } = useMultiLanguage();
  const { currentUser } = useAuth();
  const { setPageLoading } = useLoading();

  const [id, setId] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ISetOfCriteria[]>([]);
  const [, setTableDataItem] = useState<ICriteria[]>(FAKE_DATA_ITEM_CRITERIA as ICriteria[]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<SearchBase>({
    ...searchBaseDefaultValue,
    orgId: currentUser?.orgId,
  });

  const updateData = async (searchObj: any) => {
    try {
      setPageLoading(true)
      let { data } = await searchCriteria(searchObj);
      let newData = data.data.content;
      let pageable = data.data.pageable;
      setTableData(newData);
      setTotalPages(pageable.totalPages);
      setTotalElements(pageable.totalElements);
      setNumberOfElements(pageable.pageNumber);
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false)
    }
  };

  const handleOpenForm = () => {
    navigate('new');
  };

  const handleChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleDoubleClick = (row: ISetOfCriteria) => {
    setTableDataItem(row?.items?.listCriteria || []);
  };

  const handleDelete = (rowData: any) => {
    if (rowData?.id) {
      setId(rowData?.id);
      setOpenConfirmDialog(true);
    }
  };

  const handleView = (row: any) => {
    navigate(`view/${row?.id}`);
  };

  const handleEdit = (row: any) => {
    navigate(`${row?.id}`);
  };

  const handleDeleteCriteria = async () => {
    try {
      await deleteTermForm(id);
      setOpenConfirmDialog(false);
      toast.success(lang("TOAST.DELETE.SUCCESS"));
      handleSearch();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSearch = () => {
    setSearchObject((preState) => ({ 
      ...preState,
      pageIndex: searchBaseDefaultValue.pageIndex,
      keyword 
    }));
  };

  const setRowColorClass = (row: any, rowId: string) => {
    checkColorByStatus(row?.status, rowId);
    return "";
  };

  return (
    <div className='wrapper-content'>
      <Row>
        <Col xs="12" className='my-5'>
          <h3 className='spaces m-0 text-uppercase color-primary'>DANH SÁCH BIỂU MẪU</h3>
        </Col>
      </Row>
      <Row className='flex flex-space-between spaces pb-6'>
        <Col xs={9}>
          <ButtonCustom
            onClick={handleOpenForm}
            checkAuthority={{
              permission: AUTHORITIES_PERMISSIONS.FORM,
              ability: AUTHORITIES_ABILITY.ACTION,
            }}
          >
            Thêm mới
          </ButtonCustom>
        </Col>
        <Col xs={3}>
          <InputSearch
            className='spaces h-31'
            handleChange={handleChange}
            handleSearch={handleSearch}
            placeholder="Tìm kiếm theo mã, tên biểu mẫu"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='table-custom'>
          <TableCustom
            id="setOfCriteria"
            height={"calc(100vh - 295px)"}
            minHeight={230}
            data={tableData || []}
            columns={SetOfCriteriaColumns({
              handleEdit,
              handleDelete,
              handleView,
            })}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            updatePageData={updateData}
            objectSearch={searchObject}
            handleDoubleClick={handleDoubleClick}
            totalPages={totalPages}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            updateURLParams
            setRowColorClass={setRowColorClass}
          />
        </Col>
      </Row>
      {openConfirmDialog &&
        <ConfirmDialog
          show={openConfirmDialog}
          onYesClick={handleDeleteCriteria}
          onCancelClick={() => setOpenConfirmDialog(false)}
          yes="Có"
          cancel='Hủy'
        />
      }
    </div>
  );
}

export default SetOfCriteria;