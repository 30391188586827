import axios from "axios";
import { localStorageItem } from "../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_EVALUATE = API_PATH + "/program-impl-schedules";
const API_EVALUATE_PROGRAM_RESULT = API_PATH + "/program-result";


export const getEvaluates = (params: any) => {
    const url = API_EVALUATE + "/page";
    return axios.get(url, { params });
};

export const getEvaluateById = (id: string | undefined) => {
  const url = `${API_EVALUATE}/${id}`
    return axios.get(url);
};

export const getProgramImplSchedule = (params: any) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/get-result-by-term-area";
    return axios.get(url, { params });
};

export const getProgramImplScheduleNeedRemedy = (params: any) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/get-result-need-remedy";
    return axios.get(url, { params });
};

export const getProgramImplScheduleBeenRemedy = (params: any) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/get-result-has-been-remedy";
    return axios.get(url, { params });
};

export const getScoreScaleByTermAreaId = (id: number) => {
    const url = API_PATH + "/term-area/get-score-scale/" + id;
    return axios.get(url);
};

export const saveEvaluate = (data: any, id: number) => {
    const url = API_EVALUATE + "/save/" + id;
    return axios.put(url, data);
}

export const saveRemedy = (data: any, id: number) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/remedy/" + id;
    return axios.put(url, data);
}

export const saveFixRemedy = (id: number) => {
    const url = API_EVALUATE + /remedy/ + id;
    return axios.put(url);
}

export const completeEvaluate = (data: any, id: number) => {
    const url = API_EVALUATE + "/complete/" + id;
    return axios.put(url, data);
}

export const deleteImageEvaluate = (data: { [key: string]: string }, id: number) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/delete-image-and-name/" + id;
    return axios.put(url, data);
}

export const updateStageEditRequestEvaluate = (data: any) => {
    let url = API_PATH + "/program-impl-schedules/update-stage-edit-request";
    return axios.put(url, data);
}

export const updateStageSendRequestEvaluate = (data: any) => {
    let url = API_PATH + "/program-impl-schedules/update-stage-send-request";
    return axios.put(url, data);
}

export const updateApproveRequestEvaluate = (id: string, data: any) => {
    let url = API_PATH + "/program-impl-schedules/update-approve-request/" + id;
    return axios.put(url, data);
}

export const updateFixEvaluate = (id: string, data: any) => {
    let url = API_PATH + "/program-impl-schedules/update-approve-request/" + id;
    return axios.put(url, data);
}

export const updateAssignmentEvaluate = (id: string, data: any) => {
  let url = API_PATH + "/program-impl-schedules/assign-remedy/" + id;
  return axios.put(url, {}, {params: data});
};