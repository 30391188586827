import { AuthModel, ResponseModel } from './_models'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { CURRENT_HOST, KEY_LOCALSTORAGE, RESPONSE_STATUS_CODE } from './_consts';
import { headerConstant } from '../../../../_metronic/layout/components/header/header-menus/constant';
import { checkMenuByPermissions } from '../../utils/FunctionUtils';
import { localStorageItem } from '../../utils/LocalStorage';

const AUTH_LOCAL_STORAGE_KEY = KEY_LOCALSTORAGE.AUTH_LOCAL_STORAGE_KEY
const RESPONSE_MESSAGE_ERROR = {
  [RESPONSE_STATUS_CODE.UNAUTHORIZED]: 'Hết hạn đăng nhập',
  [RESPONSE_STATUS_CODE.FORBIDDEN]: 'Tài khoản không có quyền',
  [RESPONSE_STATUS_CODE.CONFLICT]: 'Trùng lặp bản ghi trong hệ thống',
  [RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR]: 'Đã có lỗi server',
  [RESPONSE_STATUS_CODE.BAD_REQUEST]: 'Đã có lỗi trong quá trình xử lý',
};

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth && (auth.expires_date_out < (new Date()).getTime())) return undefined;

    return auth;
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }
  try {
    const newDate = new Date();
    auth.expires_date_out = newDate.setSeconds(newDate.getSeconds() + auth.expires_in);
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const setSubMenu = () => {
  const checkedMenu = checkMenuByPermissions();
  //sau phân quyền
  // if (localStorageItem.get(headerConstant.AUTHORITIES)) {
    const selectSubMenu = JSON.stringify(checkedMenu?.filter(menu => menu?.name === headerConstant.DEFAULT_MODULE)[0].subMenu || [])
    localStorage.setItem(headerConstant.LIST_SUB_MENU, selectSubMenu);
  // }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(headerConstant.LIST_MENU)
    localStorage.removeItem(headerConstant.LIST_SUB_MENU)
    localStorage.removeItem(headerConstant.AUTHORITIES)
    localStorage.removeItem(KEY_LOCALSTORAGE.COLOR_CONFIG);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const handleRequest = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const { headers = {} } = requestConfig

  const auth = getAuth()
  if (auth && auth.access_token) {
    headers.Authorization = `Bearer ${auth.access_token}`
  }

  return requestConfig
}

const handleResponse = (responseConfig: AxiosResponse<ResponseModel>) => {
    const { data } = responseConfig;    
    if (checkCodeError(data)) {
      return Promise.reject(data);
    };
    if (data.code && data.code !== RESPONSE_STATUS_CODE.SUCCESS) {
      return Promise.reject(data)
    };
    return responseConfig;
}

const logoutAuth = () => {
  const auth = getAuth()
  localStorageItem.remove(KEY_LOCALSTORAGE.AUTH)
  localStorageItem.remove(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE)
  localStorageItem.remove(KEY_LOCALSTORAGE.TOKEN_EXPIRATION)
  localStorageItem.remove(KEY_LOCALSTORAGE.DEPARTMENT)
  localStorageItem.remove(KEY_LOCALSTORAGE.ROOM)
  localStorage.removeItem(KEY_LOCALSTORAGE.COLOR_CONFIG);
  if (auth) {
    window.location.href = `${localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"]}/${process.env.REACT_APP_SSO_LOGOUT_ENDPOINT}?id_token_hint=${auth?.id_token}&redirect_uri=${CURRENT_HOST}`;
  } else {
    window.location.href = `${localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"]}/${process.env.REACT_APP_SSO_LOGOUT_ENDPOINT}?redirect_uri=${CURRENT_HOST}`;
  }
}

const handleError = (error: AxiosError<ResponseModel>): Promise<AxiosError<ResponseModel>> => {
  const { isAxiosError, response } = error
  if (isAxiosError && response?.status) {
    
    if (response.status === RESPONSE_STATUS_CODE.UNAUTHORIZED) {
      toast.error(RESPONSE_MESSAGE_ERROR[RESPONSE_STATUS_CODE.UNAUTHORIZED]);
      setTimeout(logoutAuth, 2000);
    }

  }
  return Promise.reject(response?.data)
}

export function setupAxios(axios: any) {
  axios.defaults.timeout = 15000
  axios.defaults.headers.common = {
    Accept: 'application/json',
    "Accept-Language": 'vi',
  }
  axios.interceptors.request.use(handleRequest, handleError)
  axios.interceptors.response.use(handleResponse, handleError)
}

function checkCodeError(data: any) {
  switch (data?.code) {
    case RESPONSE_STATUS_CODE.INVALID_VALUE:
      Array.isArray(data?.data) ? data.data.forEach((error: any) => {
        toast.error(error?.message || data.message);
      }): toast.error(data.message);
      return true;
      default:
      return false;
  }
};

export { getAuth, setAuth, removeAuth, setSubMenu, logoutAuth, AUTH_LOCAL_STORAGE_KEY }