import React, { useState } from 'react';
import { Modal, Button, Row, Col, ModalHeader, ModalBody } from 'react-bootstrap';
import TableCustom from '../../../../app/modules/component/table-custom/TableCustom';
import { SearchBase } from '../../../../app/modules/utils/InterfaceUtils';
import { getScoreLogs } from '../../../../app/modules/services';
import { covertDateToString } from '../../../../app/modules/utils/FunctionUtils';
import moment from 'moment';


interface ScoreLogsProps {
    show: boolean;
    onClose: () => void;
}

const tabelColumn = (props: any) => {
    return [
        {
            name: "Ngày",
            field: "createdDate",
            headerStyle: {
                minWidth: "100px",
                textAlign: "center"
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{row?.createdDate ? moment(row.createdDate).format("DD/MM/YYYY") : ""}</span>
              )
        },
        {
            name: "Số điểm từ",
            field: "beforeScore",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            }
        },
        {
            name: "Số điểm được cộng",
            field: "afterScore",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            },
        },
        {
            name: "Tên chương trình",
            field: "programName",
            headerStyle: {
                minWidth: "300px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {
            name: "Tên khu vực đánh giá",
            field: "areaName",
            headerStyle: {
                minWidth: "200px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {
            name: "Tên người đanh giá",
            field: "empPerformName",
            headerStyle: {
                minWidth: "200px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
            }
        },
    ];
};

const ScoreLogs: React.FC<ScoreLogsProps> = ({
    show,
    onClose,
}) => {
    const [data, setData] = React.useState<any[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);

    const updatePageData = async (searchObj: SearchBase) => {
        try {
            let { data } = await getScoreLogs(searchObj);
            let newData = data.data.content;
            let pageable = data.data.pageable;
            setData(newData);
            setTotalElements(pageable.totalElements);
            setNumberOfElements(pageable.pageNumber + 1);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal
            show={show}
            size='xl'
            onHide={onClose}
            centered
        >
           <ModalHeader closeButton>
                <h3>Lịch sử thay đổi điểm</h3>
           </ModalHeader>
           <ModalBody>
                <Row>
                    <Col xs="12">
                        <TableCustom
                            height={"calc(100vh - 500px)"}
                            minHeight={"200px"}
                            data={data || []}
                            columns={tabelColumn({})}
                            buttonAdd={false}
                            buttonExportExcel={false}
                            notDelete={false}
                            justFilter={true}
                            updatePageData={updatePageData}
                            totalElements={totalElements}
                            numberOfElements={numberOfElements}
                        />
                    </Col>
                </Row>
           </ModalBody>
            <Modal.Footer className="p-3 center ">
                <button className="button-primary-outline" onClick={onClose}>
                    Đóng
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScoreLogs;