import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import TextValidator from "../../component/input-field/TextValidator";
import { Alert, Form, Row } from "react-bootstrap";
import { Col } from "../../component/Grid";
import InputDateCustom from "../../component/input-field/InputDateCustom";
import AppContext from "../../../AppContext";
import EvaluateTable from "./EvaluateTable";
import { DISABLE_STATUS } from "../const/DisableStatus";
import { StageSignature } from "../../constant";
import CustomIconButton from "../../component/CustomIconButton";
import EvaluateAnalysisDialog from "./EvaluateAnalysisDialog";

const InfoEvaluation = (props: any) => {
  const { 
    formikData, 
    handleChange,
    tableDataItem, 
    setTableDataItem, 
    listTermAnswer,
    totalTermAnswerScore, 
    setTotalTermAnswerScore, 
    programImplResultList,
    setProgramImplResultList,
    evaluatedList,
    scoreScale,
  } = props.data;

  const { setPageLoading } = useContext(AppContext);
  const [noteEvaluate, setNoteEvaluate] = useState<string>("");
  const [showSubRow, setShowSubRow] = useState<boolean>(false);
  const [showEvaluateAnalysisDialog, setShowEvaluateAnalysisDialog] = useState<boolean>(false);
  const disable = DISABLE_STATUS[formikData.values.stageCode];

  useEffect(() => {
    ShowNoteEvaluate();
  }, [listTermAnswer]);

  const ShowNoteEvaluate = () => {
    const conectCriteria: string[] = [];
    listTermAnswer.map((item: any) => {
      return conectCriteria.push(`${item?.answerNumeric}_${item?.answerText}`);
    })
    setNoteEvaluate(conectCriteria.join("; "));
  }

  const handleChangeTermAnswer = (e: ChangeEvent<HTMLInputElement>, rowIndex: number, termAnswerIndex: number) => {
    let newTableData = [...tableDataItem];
    let selectedValue = listTermAnswer[termAnswerIndex];
    newTableData[rowIndex] = {
      ...newTableData[rowIndex],
      termAnswerScore: selectedValue?.answerNumeric,
    };
    setTotalTermAnswerScore(newTableData.reduce((total, item) => total + item.termAnswerScore, 0));
    setTableDataItem(newTableData);
  };

  const handleChangeTableData = (value: any, rowIndex: number, name: string) => {
    let _data = [...tableDataItem];
    _data[rowIndex] = {
      ..._data[rowIndex],
      [name]: value
    };
    setTableDataItem(_data);
  };

  return (
    <Row>
      <Col className="flex" xs={2}>
        <TextValidator
          className="flex-grow-1"
          lable="ID"
          disabled={disable?.id}
          value={formikData.values.id}
        />
        <InputDateCustom
          className="ps-2 spaces min-w-150"
          label="Ngày đánh giá"
          value={formikData.values?.evaluateDate || ""}
          setValue={(value: string) => formikData.setFieldValue("evaluateDate", value)}
          errors={formikData.errors?.evaluateDate}
          touched={formikData.touched?.evaluateDate}
          isRequired
          disabled
        />
      </Col>
      <Col xs={3}>
        <TextValidator
          lable="Khu vực đánh giá"
          name="areaName"
          value={formikData.values?.areaName}
          onChange={handleChange}
          disabled={disable?.areaName}
        />
      </Col>
      <Col className="pe-4" xs={7}>
        <TextValidator
          lable="Ghi chú"
          name="note"
          value={formikData.values.note}
          onChange={handleChange}
          disabled={disable?.note}
        />
      </Col>

      <Row className='flex flex-space-between mt-5'>
        <Col xs="3">
          <Form.Check
            label="Hiển thị nhận xét và hình ảnh"
            type="checkbox"
            name="tree-node-radio"
            onChange={e => setShowSubRow(e.target.checked)}
          />
        </Col>
        <Col xs="9" className="text-end">
          <span>{"("}</span>
          <span className="color-red italic">Chú giải: </span>
          <span className="italic">
            {
              noteEvaluate
            }{")"}
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='table-custom'>
          <EvaluateTable
            data={tableDataItem || []}
            stageCode={formikData.values?.stageCode || StageSignature.UNKNOWN}
            setData={setTableDataItem}
            handleChangeTermAnswer={handleChangeTermAnswer}
            showSubRow={showSubRow}
            handleChangeTableData={handleChangeTableData}
            programImplResultList={programImplResultList} 
            setProgramImplResultList={setProgramImplResultList}
            listTermAnswer={listTermAnswer}
            checkDisable={disable}
          />
        </Col>
        <Col xs={12}>
            <Alert variant={evaluatedList.length === programImplResultList.length ? "success" : "warning"}>
              Đã đánh giá {evaluatedList.length + "/" + programImplResultList.length} mục
            </Alert>
        </Col>
      </Row>
      <Col xs={6} className="spaces pt-24">
        <h6 className="text-header-table">TỔNG ĐIỂM:&nbsp;
          <span className="fs-3 color-primary"> {`${totalTermAnswerScore || "0"} ${formikData.values?.resultText ? "/ " + formikData.values?.resultText : ""}`}</span>
          {
            disable?.analysis && <CustomIconButton
              tooltip="Xem ảnh sơ đồ"
              className="ms-3 fs-4 bi bi-clipboard-data color-primary"
              onClick={() => { setShowEvaluateAnalysisDialog(true) }}
            />
          }
        </h6>
      </Col>
      <Col xs={12} className="spaces pt-12">
        <TextValidator
          className="text-header-table"
          as="textarea"
          rows={3}
          lable="Kết luận"
          name="conclusion"
          value={formikData.values.conclusion}
          onChange={handleChange}
          disabled={disable?.conclusion}
        />
      </Col>
      
      {showEvaluateAnalysisDialog && <EvaluateAnalysisDialog
        show={showEvaluateAnalysisDialog}
        onClose={() => setShowEvaluateAnalysisDialog(false)}
        dataAnalysis={formikData?.values}
        scoreScale={scoreScale}
        listTermAnswer={listTermAnswer}
      />}
    </Row>
  );
};

export default InfoEvaluation;
