import { StageSignature } from "../../constant";

export const TABLE_ACTION_DEFAULT = {
    ADD: false,
    EDIT: true,
    DELETE: false,
};

export const CONFIG_TABLE_BUTTON = Object.fromEntries(
    Object.values(StageSignature).map((value: string) => {
        let configObject ={...TABLE_ACTION_DEFAULT};
        switch (value) {
            case StageSignature.INITIALIZATION:
                configObject = { ...TABLE_ACTION_DEFAULT, DELETE: true, ADD: true }
                break;
            case StageSignature.PENDING_APPROVAL:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
            case StageSignature.PENDING_APPROVAL_USER_APPROVE:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
            case StageSignature.REQUEST_FOR_MODIFICATION:
                configObject = { ...TABLE_ACTION_DEFAULT, DELETE: true, ADD: true }
                break;
            case StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
            case StageSignature.CURRENT:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
            case StageSignature.CURRENT_USER_APPROVE:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
            default:
                configObject = { ...TABLE_ACTION_DEFAULT }
                break;
        }
        return [value, configObject];
    })
);