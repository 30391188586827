import { BTN_APPROVE, BTN_CANCEL, BTN_SEND_REQUEST, BTN_REQUEST_EDIT } from "../../../Constant";
import { StageSignature } from "../../constant";

export const TERM_FORM_BUTTON = [
    BTN_SEND_REQUEST, BTN_REQUEST_EDIT, BTN_APPROVE, BTN_CANCEL
]

export const FORM_CRITERIA_BTN_INITAL = {
    btnSave: false,
    btnSendRequest: false,
    btnApprove: true,
    btnRequestEdit: true,
    btnCancel: false,
    iconButtonCopy: false,
}

export const FORM_ACTION = {
    VIEW: "view",
    APPROVE: "approve",
}

const INITIALIZATION = {
    ...FORM_CRITERIA_BTN_INITAL,
}
const PENDING_APPROVAL = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnSave: true,
    btnSendRequest: true,
    btnApprove: true,
    btnRequestEdit: true,
}
const PENDING_APPROVAL_USER_APPROVE = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnApprove: false,
    btnRequestEdit: false,
    btnSave: true,
    btnSendRequest: true,
}

const REQUEST_FOR_MODIFICATION = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnSave: false,
    btnSendRequest: false,
    btnApprove: true,
    btnRequestEdit: true,
}

const REQUEST_FOR_MODIFICATION_USER_APPROVE = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnApprove: true,
    btnRequestEdit: true,
    btnSave: false,
    btnSendRequest: true,
}

const CURRENT = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnSave: true,
    btnSendRequest: true,
    btnApprove: true,
    btnRequestEdit: true,
    iconButtonCopy: true
}
const CURRENT_USER_APPROVE = {
    ...FORM_CRITERIA_BTN_INITAL,
    btnSave: true,
    btnSendRequest: true,
    btnApprove: true,
    btnRequestEdit: true,
}

const VIEW_ACTION = {
    btnSave: true,
    btnSendRequest: true,
    btnApprove: true,
    btnRequestEdit: true,
    iconButtonCopy: false
}

const UNKNOWN = {
    btnSave: true,
    btnSendRequest: true,
    btnApprove: true,
    btnRequestEdit: true,
    iconButtonCopy: false
}

export const FORM_CRITERIA_BTN = {
    [StageSignature.INITIALIZATION]: INITIALIZATION,
    [StageSignature.PENDING_APPROVAL]: PENDING_APPROVAL,
    [StageSignature.PENDING_APPROVAL_USER_APPROVE]: PENDING_APPROVAL_USER_APPROVE,
    [StageSignature.REQUEST_FOR_MODIFICATION]: REQUEST_FOR_MODIFICATION,
    [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]: REQUEST_FOR_MODIFICATION_USER_APPROVE,
    [StageSignature.CURRENT]: CURRENT,
    [StageSignature.UNKNOWN]: UNKNOWN,
    [StageSignature.CURRENT_USER_APPROVE]: CURRENT_USER_APPROVE,
    [FORM_ACTION.VIEW]: VIEW_ACTION
}
