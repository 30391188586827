/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps, FormikValues } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useLoading } from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TextValidator from "../../component/input-field/TextValidator";
import { ModalFormikCustom } from "../../component/modalFormik";
import SelectCustom from "../../component/select-custom";
import { ZoneModel } from "../model/CategoryModel";
import {
  addZoneCategories,
  deleteImageArea,
  getOrganizations,
  getZoneCategoryById,
  updateZoneCategories
} from "../services/CategoryServices";
import { handleError } from "../../utils/FunctionUtils";
import { useAuth } from "../../auth";
import { getEmployees, getUsers } from "../../program/services/ProgramService";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from "../../../Constant";
import { CONFIG_BUTTON } from "../config/ConfigButton";
import { ActionImage } from "../../evaluate/component/EveluateRepeairDialog";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { PreviewImagePopup } from "../../evaluate/component/EvaluateTable";
import { getImage, uploadImage } from "../../services";
import { useLocation } from "react-router-dom";
import { heightSelectMutil } from "../../component/input-field/StyleComponent";

interface Props {
  show: boolean;
  onClose: () => void;
  updatePageData?: () => void;
  updateLstRootArea?: () => void;
  data: ZoneModel;
  disabledFieldList: any;
  isRoot?: boolean;
  rootAreaStatus?: string;
}

const CategoryZoneDialogAdd: FC<Props> = (props) => {
  const {
    onClose,
    updatePageData,
    updateLstRootArea = () => {},
    data,
    disabledFieldList,
    isRoot = false,
    rootAreaStatus = ""
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useLoading();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [imageUrls, setImageUrls] = useState<string>();
  const [nameImage, setNameImage] = useState<string | null>(data?.image);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState<boolean>(false);
  const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
  const [employees, setEmployees] = useState<any[]>([]);
  const [orgId, setOrgId] = useState<any[]>([]);
  const imgRefs = useRef<any>();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^\W_]+$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().matches(/^[^`~!@#$%^*]*$/, lang("VALIDATION.NO_SPECIAL_CHARACTERS")),
    empOnusArea: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    // ...(isRoot && {
    //   orgId: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
    // })
  });

  useEffect(() => {
    if (data?.id) {
      getUserZoneCategoryById(data.id);
    }
    getOrgIdByCurrentUser();
  }, [])

  const getUserZoneCategoryById = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getZoneCategoryById(id);
      setEmployees(data?.data?.employeeResDtoList.map((item: any) => item.id) || []);
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  }

  const getOrgIdByCurrentUser = async () => {
    const response = await getOrganizations({ "parentId": currentUser?.orgId || "" })
    const list = response.data.data;
    if (list.length) {
      setOrgId(list[0]?.id);
    }
  };

  const handleCombinedSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataSubmit = {
        code: values.code,
        name: values.name,
        parentAreaId: values.parentAreaId,
        empOnusArea: values.empOnusArea,
        note: values.note,
        active: values.active,
        orgId: orgId,
        userOnusArea: values.userOnusArea,
        image: nameImage,
        employees: employees
      };

      values.id
        ? await updateZoneCategories(values.id, dataSubmit)
        : await addZoneCategories(dataSubmit);

      toast.success(lang(values.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"))
      updatePageData?.();
      let isFromNotification = (location?.state as any)?.isFromNotification || null;
      !isFromNotification && updateLstRootArea && updateLstRootArea();
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleChangeImage = async (value: any) => {
    try {
      setPageLoading(true);
      if (value) {
        const formData = new FormData();
        formData.append("file", value);
        let { data } = await uploadImage(formData);
        setNameImage(data?.data)
        setImageUrls(URL.createObjectURL(value));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleShowImage = async (type: string) => {
    try {
      let url = imgRefs.current?.src || "";
      setImageUrls(url as string);
      setShowImagePopup(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setPageLoading(true);
      await deleteImageArea(nameImage as string, data?.id);
      setImageUrls("");
      setNameImage(null);
      setShowConfirmDeletePopup(false);
    } catch (error) {
      toast.error(error as string);
    } finally {
      setPageLoading(false);
    }
  }

  const handleGetUrlImage = async (name: string) => {
    try {
      let imageDataURL = await getImage({ name });
      let url = URL.createObjectURL(imageDataURL.data);
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadImage = async (image: any) => {
    try {
      if (image.id) {
        const url = await handleGetUrlImage(image.id);
        setImageUrls(url || '');
      }
      let action = image.parentNode.querySelector(".action-img");
      let loading = image.parentNode.querySelector(".spinner-border");
      action.classList.remove("d-none");
      loading.classList.add("d-none");
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    handleLoadImage(imgRefs.current);
  }, [imgRefs.current]);

  return (
    <ModalFormikCustom
      disableBtnSave={data?.id ? !CONFIG_BUTTON[rootAreaStatus as keyof typeof CONFIG_BUTTON]?.btnAddAreaSave : false}
      show
      size='xl'
      handleClose={onClose}
      title={(data?.id ? "#Cập nhật" : "#Thêm mới") + (isRoot ? " Layout" : " khu vực")}
      FormikConfig={{
        initialValues: data,
        validationSchema: validationSchema,
        onSubmit: handleCombinedSubmit,
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }: FormikProps<FormikValues>) => {

        return ((

          <Row>
            <Col xs={4} className="d-flex flex-column justify-content-center align-items-center ps-5">
              {disabledFieldList?.imageView ? (
                <label htmlFor="choose-img-file-imageImprove" className={`img-select ${imageUrls ? 'd-none' : ''}`}>
                  <i className="fa-solid fa-image cursor-pointer px-4 fw-bold fs-1 text-white bg-secondary rounded-2 py-3"></i>
                </label>
              ) :
                (
                  <span className={`img-select ${imageUrls ? 'd-none' : ''}`}>
                    <i className="fa-solid fa-image px-4 fw-bold fs-1 text-white bg-secondary rounded-2 py-3"></i>
                  </span>
                )}
              <input
                type="file"
                id="choose-img-file-imageImprove"
                className="d-none"
                value={""}
                onChange={(e) => handleChangeImage(e.target.files?.[0])}
              />
              <div className={`spaces position-relative img-not-select ${imageUrls ? '' : 'd-none'}`}>
                <img
                  ref={imgRefs}
                  loading='lazy'
                  id={nameImage || ""}
                  src={imageUrls || ""}
                  alt=""
                  className="rect-img-app w-100"
                />
                <ActionImage
                  showRemove={disabledFieldList?.imageView}
                  handleShow={() => handleShowImage("image")}
                  handleRemove={() => setShowConfirmDeletePopup(true)}
                />
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="mt-2">
                <em>Ảnh sơ đồ</em>
              </div>
            </Col>

            <Col xs={8}>
              <Row>
                <Col xs={3} className="mb-3 px-1">
                  <TextValidator
                    className="flex-row min-w-80"
                    lable={"ID"}
                    disabled={disabledFieldList?.id}
                    name="id"
                    type="text"
                    value={values?.id}
                  />
                </Col>
                <Col xs={3} className="mb-3 px-1">
                  <TextValidator
                    className="flex-row min-w-80"
                    lable={`Mã ${isRoot ? "khu vực" : "khu vực"}`}
                    readOnly={false}
                    isRequired
                    name="code"
                    value={values?.code}
                    type="text"
                    onChange={handleChange}
                    errors={errors.code}
                    touched={touched.code}
                    disabled={disabledFieldList?.code}
                  />
                </Col>
                <Col xs={6} className="mb-3 px-1">
                  <TextValidator
                    className="flex-row min-w-80"
                    lable={`Tên ${isRoot ? "khu vực" : "khu vực"}`}
                    readOnly={false}
                    isRequired
                    name="name"
                    value={values.name}
                    type="text"
                    onChange={handleChange}
                    errors={errors.name}
                    touched={touched.name}
                    disabled={disabledFieldList?.name}
                  />
                </Col>
                <Col xs={4} className="mb-3 px-1">
                  <SelectCustom
                    className="spaces z-index-8 width-100"
                    label={"Người chịu trách nhiệm"}
                    service={getUsers}
                    params={{ authorities: `5S.${AUTHORITIES_PERMISSIONS.FIX}.${AUTHORITIES_ABILITY.ACTION}` }}
                    optionsResponse='data'
                    required
                    valueField={'empId'}
                    displayField={'displayName'}
                    name="empOnusArea"
                    value={values?.empOnusArea}
                    handleChange={(value, _option: any, _actionMeta, name) => {
                      setFieldValue("userOnusArea", _option?.username)
                      setFieldValue(name, value);
                    }}
                    errors={errors.empOnusArea}
                    touched={touched.empOnusArea}
                    isDisabled={disabledFieldList?.empOnusArea}
                  />
                </Col>
                {!isRoot &&
                  (<Col xs={8} className="mb-3 px-1">
                    <TextValidator
                      className="flex-row min-w-80"
                      lable={"Thuộc khu vực"}
                      readOnly={false}
                      name="parentAreaName"
                      value={values?.parentAreaName || ""}
                      type="text"
                      disabled={disabledFieldList?.parentAreaName}
                    />
                  </Col>)
                }
                <Col xs={12} className="mb-3 px-1">
                  <TextValidator
                    className="flex-row min-w-80"
                    lable={"Ghi chú"}
                    readOnly={false}
                    name="note"
                    value={values?.note}
                    type="text"
                    as="textarea"
                    rows={4}
                    onChange={handleChange}
                    disabled={disabledFieldList?.note}
                  />
                </Col>
                {
                  !isRoot && (
                  <Col xs={12} className="mb-3 px-1">
                    <SelectCustom
                      className="width-100"
                      displayField={'displayName'}
                      valueField='empId'
                      params={{ authorities: `5S.${AUTHORITIES_PERMISSIONS.AREA}.${AUTHORITIES_ABILITY.EMPLOYEES_BY_REGION}` }}
                      label={"Nhân viên thuộc khu vực"}
                      optionsResponse="data"
                      options={[]}
                      service={getUsers}
                      styles={heightSelectMutil("auto", '30px')}
                      isMulti
                      name="employees"
                      value={employees}
                      handleChange={(value, _option, _actionMeta, name) => {
                        setEmployees([...(value as any[])]);
                      }}
                      errors={errors?.employees}
                      touched={touched?.employees}
                      isDisabled={disabledFieldList?.orgId}
                    />
                  </Col>
                  )
                }
              </Row>
            </Col>
            {showImagePopup && (
              <PreviewImagePopup
                show={showImagePopup}
                hide={() => setShowImagePopup(false)}
                src={imageUrls || ""}
              />
            )}
            {showConfirmDeletePopup && (
              <ConfirmDialog
                show={showConfirmDeletePopup}
                title="Xóa danh mục"
                message={`Bạn chắc chắn muốn xóa ảnh?`}
                yes="Xác nhận"
                cancel="Hủy"
                onYesClick={handleConfirmDelete}
                onCancelClick={() => setShowConfirmDeletePopup(false)}
              />
            )}
          </Row>
        ))
      }}

    </ModalFormikCustom>
  );
};

export default CategoryZoneDialogAdd;
