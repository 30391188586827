/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { useIntl } from 'react-intl';
import HeaderUserInfoModal from './HeaderUserInfoModal';
import { KEY_LOCALSTORAGE } from '../../../../app/modules/auth/core/_consts';
import HeaderChangePasswordModal from './HeaderChangePasswordModal';
const HeaderUserMenu: FC = () => {
  const [showInfoUserModal, setShowInfoUserModal] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const intl = useIntl();
  const { logout } = useAuth()
  const { currentUser } = useAuth();
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-5 mb-1'>
          <a className='menu-link px-5 py-2' onClick={() => setShowInfoUserModal(true)} >
            {intl.formatMessage({ id: 'USER.ACCOUNT.INFO' })}
          </a>
        </div>
        <div className='menu-item px-5 mb-1'>
          <a className='menu-link px-5 py-2' onClick={() => setShowChangePassword(true)} >
            {intl.formatMessage({ id: 'USER.CHANGEPASSWORD' })}
          </a>
        </div>
        <div className='menu-item px-5 mb-1'>
          <a onClick={logout} className='menu-link px-5 py-2'>
            {intl.formatMessage({ id: 'LOGOUT' })}
          </a>
        </div>
      </div>
      <HeaderUserInfoModal
        show={showInfoUserModal}
        onClose={() => setShowInfoUserModal(false)}
        currentUser={currentUser}
      />
      <HeaderChangePasswordModal
        show={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      />
    </>
  )
}

export {HeaderUserMenu}
