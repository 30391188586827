import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS, CHECK_APPROVE, EVALUATE_TAB, PERMISSIONS, PERMISSION_ABILITY } from "../Constant";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import SetOfCriteria from "../modules/set-of-criteria/SetOfCriteria";
import SetOfCriteriaForm from "../modules/set-of-criteria/components/SetOfCriteriaForm";
import Program from "../modules/program/Program";
import ProgramForm from "../modules/program/component/ProgramForm";
import Evaluate from "../modules/evaluate/Evaluate";
import FormEvaluation from "../modules/evaluate/component/FormEvaluation";
import SetOfCriteriaFormView from "../modules/set-of-criteria/components/SetOfCriteriaFormView";
import Dashboard from "../modules/dashboard/Dashboard";
import ManageAccounts from "../modules/manage-accounts/ManageAccounts";
import Systems from "../modules/systems/Systems";
import { FORM_ACTION } from "../modules/set-of-criteria/config/ConfigButton";
import Approval from "../modules/approval/Approval";
import CategoryMemberTab from "../modules/category/component/CategoryMemberTab";
import AreaCategoryTab from "../modules/category/component/AreaCategoryTab";
import AreaCategoryDetailInfo from "../modules/category/component/AreaCategoryDetailInfo";
import Repeair from "../modules/repeair/repeair";
import AllNotifications from "../../_metronic/partials/layout/header-menus/AllNotifications";
import { hasAuthority } from "../modules/utils/FunctionUtils";
import UnratedProgramStatistics from "../modules/statistical/UnratedProgramStatistics";
import ColorConfig from "../modules/config/color-config";
import RegionalScoreStatistics from "../modules/statistical/RegionalScoreStatistics";

interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
  propsComponent?:any
}

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ auth=AUTHORITIES_PERMISSIONS.DASHBOARD, ability=AUTHORITIES_ABILITY.VIEW, component: Component, redirect, propsComponent }) => {
    //khi có phân quyền sẽ check
    return hasAuthority(auth, ability) ? (<Component {...propsComponent} />) : (<Navigate to={redirect} />);
    // return true ? (<Component {...propsComponent} />) : (<Navigate to={redirect} />);
  };

  return (
    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path="auth/*" element={<Navigate to="/nursing-report" />} />
        <Route path="auth/*" element={<Navigate to="/set-of-criteria" />} /> */}
        {/* Pages */}
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path="dashboard" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.DASHBOARD} ability={AUTHORITIES_ABILITY.VIEW} component={Dashboard} redirect="/approve" />} />
        <Route path="set-of-criteria" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.FORM} ability={AUTHORITIES_ABILITY.SEARCH} component={SetOfCriteria} redirect="/evaluate/incomming" />} />
        <Route path="set-of-criteria/approval/:id" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.FORM} ability={AUTHORITIES_ABILITY.SEARCH} component={SetOfCriteriaFormView} propsComponent={{ action: FORM_ACTION.APPROVE }} redirect="/evaluate/incomming" />} />
        <Route path="set-of-criteria/view/:id" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.FORM} ability={AUTHORITIES_ABILITY.SEARCH} component={SetOfCriteriaFormView} propsComponent={{ action: FORM_ACTION.VIEW }} redirect="/evaluate/incomming" />} />
        <Route path="set-of-criteria/:id" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.FORM} ability={AUTHORITIES_ABILITY.SEARCH} component={SetOfCriteriaForm} redirect="/evaluate/incomming" />} />
        <Route path="areas" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.AREA} ability={AUTHORITIES_ABILITY.SEARCH} component={AreaCategoryTab} redirect="/employees" />} />
        <Route path="areas/:id" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.AREA} ability={AUTHORITIES_ABILITY.SEARCH} component={AreaCategoryDetailInfo} redirect="/employees" />} />
        <Route path="employees"
          element={
            <PrivateRoute 
              auth={AUTHORITIES_PERMISSIONS.EMPLOYEE}
              ability={AUTHORITIES_ABILITY.SEARCH}
              component={CategoryMemberTab}
              redirect="/employees" />
          }
        />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="program" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.PROGRAM} ability={AUTHORITIES_ABILITY.SEARCH} component={Program} redirect="/set-of-criteria" />} />
        <Route path="manage-accounts" element={<ManageAccounts />} />
        <Route path="program/:id" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.PROGRAM} ability={AUTHORITIES_ABILITY.SEARCH} component={ProgramForm} redirect="/set-of-criteria" />} />
        <Route path="evaluate/:id" element={<FormEvaluation></FormEvaluation>} />
        <Route path="approve" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.APPROVE} ability={AUTHORITIES_ABILITY.SEARCH} component={Approval} redirect="/program" propsComponent={{ checkApprove: CHECK_APPROVE.APPROVE }} />} />
        <Route path="approved" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.APPROVE} ability={AUTHORITIES_ABILITY.SEARCH} component={Approval} redirect="/program" propsComponent={{ checkApprove: CHECK_APPROVE.APPROVED }} />} />
        <Route path="evaluate/upcomming" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.EVALUATE} ability={AUTHORITIES_ABILITY.SEARCH} component={Evaluate} redirect="/repeair" propsComponent={{ activeTab: EVALUATE_TAB.UP_COMMING }} />} />
        <Route path="evaluate/incomming" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.EVALUATE} ability={AUTHORITIES_ABILITY.SEARCH} component={Evaluate} redirect="/repeair" propsComponent={{ activeTab: EVALUATE_TAB.NOW }} />} />
        <Route path="evaluate/review" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.EVALUATE} ability={AUTHORITIES_ABILITY.SEARCH} component={Evaluate} redirect="/repeair" propsComponent={{ activeTab: EVALUATE_TAB.REVIEW }} />} />
        <Route path="repeair" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.FIX} ability={AUTHORITIES_ABILITY.SEARCH} component={Repeair} redirect="/areas" />} />
        <Route path="notifications" element={<AllNotifications/>} />
        <Route path="statistic/unrated-program-statistics" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.STATISTICS} ability={AUTHORITIES_ABILITY.VIEW} component={UnratedProgramStatistics} redirect="/dashboard" />} />
        <Route path="statistic/regional-score-statistics" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.STATISTICS} ability={AUTHORITIES_ABILITY.VIEW} component={RegionalScoreStatistics} redirect="/dashboard" />} />
        <Route path="config/color-config" element={<PrivateRoute auth={AUTHORITIES_PERMISSIONS.DASHBOARD} ability={AUTHORITIES_ABILITY.CONFIG} component={ColorConfig} redirect="/dashboard" />} />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route path="systems" element={<PrivateRoute auth={PERMISSIONS.EMPLOYEE} ability={PERMISSION_ABILITY.VIEW} component={Systems} redirect="/systems" />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

