import axios from "axios";
import { GeneralSearchParamsType } from "../../utils/ParamsUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const ZONE_CATEGORY_API = PATH + "/areas";
const MEMBER_CATEGORY_API = PATH + "/employees";

export const getZoneCategories = (params: GeneralSearchParamsType) => {
    const url = ZONE_CATEGORY_API + "/search";
    return axios.get(url, {
        params
    });
};
export const getAllZoneCategories = () => {
    const url = ZONE_CATEGORY_API + "/all";
    return axios.get(url);
};
export const getAllParentZoneCategories = (params: any) => {
    const url = ZONE_CATEGORY_API + "/all/parents-current";
    return axios.get(url, { params: { pageSize: 100, ...params } });
};

export const addZoneCategories = (data: any) => {
    const url = ZONE_CATEGORY_API
    return axios.post(url, data)
}

export const updateZoneCategories = (id: string, data: any) => {
    const url = `${ZONE_CATEGORY_API}/${id}`
    return axios.put(url, data)
}

export const deleteZoneCategories = (id: string) => {
    const url = `${ZONE_CATEGORY_API}/${id}`
    return axios.delete(url)
}

export const getZoneCategoryById = (id: string) => {
    const url = `${ZONE_CATEGORY_API}/${id}`
    return axios.get(url)
}

export const getAllMemberCategories = () => {
    const url = MEMBER_CATEGORY_API + "/all";
    return axios.get(url);
};

export const getMemberCategories = (params: GeneralSearchParamsType) => {
    const url = MEMBER_CATEGORY_API + "/search"
    return axios.get(url, { params });
};

export const addMemberCategories = (data: any) => {
    const url = MEMBER_CATEGORY_API
    return axios.post(url, data);
}

export const updateMemberCategories = (id: string, data: any) => {
    const url = `${MEMBER_CATEGORY_API}/${id}`
    return axios.put(url, data);
}

export const deleteMemberCategories = (id: string) => {
    const url = `${MEMBER_CATEGORY_API}/${id}`
    return axios.delete(url);
}

export const getMemberById= (id: string) => {
    const url = `${MEMBER_CATEGORY_API}/${id}`
    return axios.get(url);
}

export const getMemberInfoById= (id: string) => {
    const url = `${MEMBER_CATEGORY_API}/info/${id}`
    return axios.get(url);
}

export const getStatus = () => {
    const url = ZONE_CATEGORY_API;
    return axios.get(url);
}

export const getAllZoneCategory = (rootId: string) => {
    const url = `${PATH}/areas/all/children/${rootId}`;
    return axios.get(url);
}

export const updateStageSendRequest = (data: any) => {
    const url = `${ZONE_CATEGORY_API}/update-stage-send-request`;
    return axios.put(url, data);
}

export const updateStageEditRequest = (data: any) => {
    const url = `${ZONE_CATEGORY_API}/update-stage-edit-request`;
    return axios.put(url, data);
}

export const updateApproveRequest = (data: any) => {
    const url = `${ZONE_CATEGORY_API}/update-approve-request`;
    return axios.put(url, data);
}

export const getOrganizations = (params: any) => {
    const url = `${process.env.REACT_APP_ADMIN_URL}/organizations/parent`;
    return axios.get(url, { params });
}
export const getRoles = (params: any) => {
    const url = `${process.env.REACT_APP_SSO_URL}/roles/search`;
    return axios.get(url, { params });
}
export const deleteImageArea = (image: string, id: any) => {
    const url = ZONE_CATEGORY_API + "/delete-image-and-name";
    return axios.put(url, null, {
        params: {
            id, image
        }
    });
}

export const fileUpload = (file: any) => {
    const url = MEMBER_CATEGORY_API + "/upload";
    let formData = new FormData();
    formData.append(`file`, file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.post(url, formData, config);
};