import { 
  AUTHORITIES_ABILITY, 
  AUTHORITIES_PERMISSIONS, 
} from "../../Constant"

export type TMenu = {
  title: string,
  to: string,
  name: string,
  permission: string,
  ability: string,
  subMenu: TSubMenu[]
}

export type TSubMenu = {
  title: string,
  to: string,
  hasBullet: boolean,
  icon: string
  permission: string,
  ability: string,
}

export const allMenu: TMenu[] = [
  {
    title: "Dashboard",
    to: "/dashboard",
    name: "dashboard",
    permission: AUTHORITIES_PERMISSIONS.DASHBOARD,
    ability: AUTHORITIES_ABILITY.VIEW,
    subMenu: [],
  },
  {
    title: "Phê duyệt",
    to: "/approve",
    name: "approve",
    permission: AUTHORITIES_PERMISSIONS.APPROVE,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Chương trình",
    to: "/program",
    name: "program",
    permission: AUTHORITIES_PERMISSIONS.PROGRAM,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Biểu mẫu",
    to: "/set-of-criteria",
    name: "set-of-criteria",
    permission: AUTHORITIES_PERMISSIONS.FORM,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Đánh giá",
    to: "/evaluate/incomming",
    name: "evaluate/incomming",
    permission: AUTHORITIES_PERMISSIONS.EVALUATE,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Khắc phục",
    to: "/repeair",
    name: "repeair",
    permission: AUTHORITIES_PERMISSIONS.FIX,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Layout",
    to: "/areas",
    name: "areas",
    permission: AUTHORITIES_PERMISSIONS.AREA,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Nhân viên",
    to: "/employees",
    name: "employees",
    permission: AUTHORITIES_PERMISSIONS.EMPLOYEE,
    ability: AUTHORITIES_ABILITY.SEARCH,
    subMenu: [],
  },
  {
    title: "Thống kê",
    to: "/statistic",
    name: "statistic",
    permission: AUTHORITIES_PERMISSIONS.STATISTICS,
    ability: AUTHORITIES_ABILITY.VIEW,
    subMenu: [
      {
        title: "Thống kê chương trình chưa đánh giá",
        to: "/statistic/unrated-program-statistics",
        permission: AUTHORITIES_PERMISSIONS.STATISTICS,
        ability: AUTHORITIES_ABILITY.VIEW,
        hasBullet: false,
        icon: ""
      },
      {
        title: "Thống kê điểm khu vực theo chương trình",
        to: "/statistic/regional-score-statistics",
        permission: AUTHORITIES_PERMISSIONS.STATISTICS,
        ability: AUTHORITIES_ABILITY.VIEW,
        hasBullet: false,
        icon: ""
      }
    ],
  },
  {
    title: "Cấu hình",
    to: "/config",
    name: "config",
    permission: AUTHORITIES_PERMISSIONS.DASHBOARD,
    ability: AUTHORITIES_ABILITY.CONFIG,
    subMenu: [
      {
        title: "Cấu hình màu",
        to: "/config/color-config",
        permission: AUTHORITIES_PERMISSIONS.DASHBOARD,
        ability: AUTHORITIES_ABILITY.CONFIG,
        hasBullet: false,
        icon: ""
      }
    ],
  },
];