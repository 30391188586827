/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useLoading } from '../../../AppContext';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { Col } from '../../component/Grid';
import { heightAutocomplete, heightSelectMutil } from '../../component/input-field/StyleComponent';
import TextValidator from '../../component/input-field/TextValidator';
import { ModalFormikCustom } from '../../component/modalFormik';
import SelectCustom from '../../component/select-custom';
import { handleError } from '../../utils/FunctionUtils';
import CheckboxField from './../../component/input-field/CheckboxField';
import { useAuth } from '../../auth';
import "../styles/Config.scss";
import { saveStatus } from '../services/ConfigServices';


interface Props {
  show: boolean;
  onClose: () => void;
  updatePageData?: () => void;
  data?: any;
  listStatus: any[];
}

const CategoryMemberDialogAdd: FC<Props> = (props) => {
  const {
    onClose,
    updatePageData,
    data,
    listStatus,
  } = props;
  const isUpdate = Boolean(data?.id);
  const isCreate = !isUpdate;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useLoading();

  const [color, setColor] = useState<string>('#000000');
  const [selectedStatus, setSelectedStatus] = useState<any>(null);

  useEffect(() => {
    if (data?.name) {
      setColor(data.name);
    };
    if (data?.id) {
      setSelectedStatus({
        code: data.code,
        name: data.statusName,
      })
    }
  }, [data]);

  const validationSchema = Yup.object({
    code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  const handleCombinedSubmit = async (values: any, { setFieldError }: FormikHelpers<FormikValues>) => {
    try {
      setPageLoading(true);
      const value: any = {
        id: data?.id,
        code: values.code,
        name: color,
        statusName: selectedStatus?.name,
      }
      await saveStatus(value);
      toast.success(lang(value?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"));
      updatePageData && updatePageData();
      onClose();
    } catch (error) {
      toast.error(error as string);
      console.error(error);
      handleError(error, setFieldError);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <ModalFormikCustom
      show
      size='sm'
      handleClose={onClose}
      title={isUpdate ? "#Cập nhật" : "#Thêm mới" + " cấu hình màu"}
      FormikConfig={{
        initialValues: {
          code: data?.code
        } || {
          code: null
        },
        validationSchema: validationSchema,
        onSubmit: handleCombinedSubmit,
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }: FormikProps<FormikValues>) => {
        return ((
          <>
            <Row className=''>
              <Col xs={9}>
                <SelectCustom
                  className="spaces width-100"
                  label={"Trạng thái"}
                  name="code"
                  required
                  valueField={'code'}
                  displayField={'name'}
                  options={listStatus}
                  value={values?.code || ""}
                  styles={{ ...heightAutocomplete("32px"), menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  handleChange={(value, _option, _actionMeta, name) => {
                    setSelectedStatus(_option); 
                    setFieldValue(name, value);
                  }}
                  errors={errors?.code}
                  touched={touched?.code}
                  isDisabled={data?.id}
                />
              </Col>
              <Col xs={3}>
                <Form.Group controlId="colorPicker" className="container-custom-color-picker">
                  <Form.Label>Màu<span className="color-red">&nbsp;*</span></Form.Label>
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={handleColorChange}
                    title="Chọn màu"
                    className='custom-color-picker'
                  />
                </Form.Group>
            </Col>
          </Row >
          </>
  ))
}}
    </ModalFormikCustom >
  );
};

export default CategoryMemberDialogAdd;

