
export interface SearchBase {
    id?: string,
    pageIndex: number,
    pageSize: number,
    orderByFilter?: string,
    keyword?: string,
    orgId?: string,
    personId?:string,
    departmentId?: string,
    userId?: string,
    userName?: string
  }

export const searchBaseDefaultValue: SearchBase = {
  pageIndex: 1,
  pageSize: 10,
  keyword: ''
}