import { useState } from 'react'
import InputSearch from '../../component/input-field/InputSearch'
import TableCustom from '../../component/table-custom/TableCustom'
import { ColumnApprove } from '../const/Columns'
import { fetchApproveds, fetchPendingApprovals } from '../services/ApprovalServices'
import { useNavigate } from 'react-router-dom'
import { ApprovalSignature } from '../../constant'
import { handleError } from '../../utils/FunctionUtils'
import { APPROVAL_TYPE } from '../const/ApprovalConst'

type Props = {
    type: string
}

const ApprovedTab = (props: Props) => {
    const { type } = props;
    const [data, setData] = useState<any[]>([]);
    const [keyword, setKeyword] = useState<string>("");
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);
    const [searchObject, setSearchObject] = useState<any>({
        pageIndex: 1,
        pageSize: 10,
        keyword: "",
    });
    let navigate = useNavigate();
    const isPendingApproval = type === APPROVAL_TYPE.PENDING_APPROVAL;

    const updatePageData = async (searchObj: any) => {
        try {
            const { data } = isPendingApproval
            ? 
            await fetchPendingApprovals({ ...searchObj }) 
            :  
            await fetchApproveds({ ...searchObj });
            let newData = data.data.content;
            let pageable = data.data.pageable;
            setData(newData);
            setTotalElements(pageable.totalElements);
            setNumberOfElements(pageable.pageNumber + 1);
        } catch (err) {
            handleError(err);
        }
    };

    const handleChange = (e: any) => {
        setKeyword(e.target.value);
    };

    const handleSearch = () => {
        setSearchObject({ 
            pageIndex: 1,
            pageSize: 10,
            keyword 
        });
    };

    const handleView = (row: any) => {
        const { requestTypeCode, areaId, programId, termFormId , programImplScheduleId} = row || {}
        switch (requestTypeCode) {
            case ApprovalSignature.AREA_REGISTER:
                areaId && navigate(`/areas/${areaId}`)
                break;
            case ApprovalSignature.PROGRAM_REGISTER:
                programId && navigate(`/program/${programId}`)
                break;
            case ApprovalSignature.EVALUATE:
                programImplScheduleId && navigate(`/evaluate/${programImplScheduleId}`)
                break;
            case ApprovalSignature.TERM_FORM_REGISTER:
                termFormId && navigate(`/set-of-criteria/approval/${termFormId}`)
                break;
            default:
                break;
        }
    };

    const handleApprove = (row: any) => {
        const { requestTypeCode, areaId, programId, termFormId , programImplScheduleId} = row || {}
        switch (requestTypeCode) {
            case ApprovalSignature.AREA_REGISTER:
                areaId && navigate(`/areas/${areaId}`)
                break;
            case ApprovalSignature.PROGRAM_REGISTER:
                programId && navigate(`/program/${programId}`)
                break;
            case ApprovalSignature.EVALUATE:
                programImplScheduleId && navigate(`/evaluate/${programImplScheduleId}`)
                break;
            case ApprovalSignature.TERM_FORM_REGISTER:
                termFormId && navigate(`/set-of-criteria/approval/${termFormId}`)
                break;
            default:
                break;
        }
    };

    return (
        <div className="category spaces mt-10">
            <div className="spaces d-flex flex-end mb-10">
                <div className='spaces min-w-400'>
                    <InputSearch
                        className='spaces h-31'
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        placeholder="Tìm kiếm nội dung yêu cầu"
                        isEnter
                    />
                </div>
            </div>
            <TableCustom
                data={data}
                height={"calc(100vh - 345px)"}
                columns={ColumnApprove({ handleView, handleApprove, type })}
                justFilter={true}
                updatePageData={updatePageData}
                noPagination={false}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                objectSearch={searchObject}
                headerClasses='text-center'
            />
        </div>
    )
}

export default ApprovedTab