import SVG from "react-inlinesvg";
import RolesTab from "../component/RolesTab";
import UsersTab from "../component/UsersTab";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";


export const getTabList = (thongTinTab?: any) => {
  const tabList = [
    {
      eventKey: "0",
      title: "Tài khoản",
      component: <UsersTab />,
    },
    {
      eventKey: "1",
      title: "Quyền",
      component: <RolesTab />,
    },
  ]
  return tabList
}

export const ColumnRoles = (props: any) => {
  const { handleEditRole, handleDeleteRole } = props;

  return [
    {
      name: "#",
      field: "",
      headerStyle: {
        minWidth: "40px",
        maxWidth: "50px",
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      ),
    },
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: "60px",
        maxWidth: "80px",
      },
      render: (row: any) => (
        <div>
          <span onClick={() => handleEditRole(row)} className="pointer">
            <SVG src={toAbsoluteUrl("/media/logos/edit.svg")} />
          </span>
          <span className="spaces ml-8 pointer" onClick={() => handleDeleteRole(row)}>
            <SVG src={toAbsoluteUrl("/media/logos/delete.svg")} />
          </span>
        </div>
      ),
    },
    {
      name: "Tên vai trò",
      field: "code",
      headerStyle: {
        minWidth: "100px",
        maxWidth: "120px",
      },
    },
    {
      name: "Mô tả",
      field: "name",
      headerStyle: {
        minWidth: "180px",
        maxWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
};

export const FAKE_TREE_DATA = [
{
  code: "0", name: "ADMIN", filter: [
    {
      code: "01", name: "AUTHORITY"
    },
    {
      code: "02", name: "DEPARTMENT"
    },
    {
      code: "03", name: "ROLE"
    },
  ]
},
{
  code: "1", name: "ORGANIZATION", filter: [
    {
      code: "11", name: "VIEW"
    }
  ]
}
];