import { ElementType, useRef, useState } from 'react'
import { useNotification } from '../../../../app/AppContext'
import TabMenu from '../../../../app/modules/component/tabs/TabMenu'
import { STATUS_READ_NOTI } from '../../../../app/modules/constant'
import { updateStatusNotifications } from '../../../../app/modules/services'
import { handleError } from '../../../../app/modules/utils/FunctionUtils'
import { INoti } from './HeaderNotificationsMenu'
import NotificationList from './NotificationList'
import NotificationListTab from './NotificationListTab'

type Props = {}

const AllNotifications = (props: Props) => {
    const { listNotificationsTab, setListNotificationsTab } = useNotification();
    const lstUnreadNotifications = listNotificationsTab?.filter((item: INoti) => item?.status === STATUS_READ_NOTI.UN_READ)

    const handleReadAllNotifications = async () => {
        try {
            await updateStatusNotifications(lstUnreadNotifications.map(item => item?.id));
            setListNotificationsTab((prev) => prev?.map((notification: INoti) => ({
                ...notification,
                status: STATUS_READ_NOTI.READ
            })));
        } catch (error) {
            handleError(error);
        }
    };

    const tabList = [
        {
            eventKey: "0",
            title: "Tất cả",
            component: <div className="spaces h-100vh-260 overflow-auto"><NotificationListTab listNotifications={listNotificationsTab} /></div>,
        },
        {
            eventKey: "1",
            title: "Chưa đọc",
            component: <div className="spaces h-100vh-260 overflow-auto"><NotificationListTab listNotifications={lstUnreadNotifications} /></div>,
        },
    ]
    return (
        <div className='wrapper-content spaces max-w-774 mx-auto'>
            <div className='d-flex justify-content-between'>
                <h2 className='fw-bold px-9 mt-10 py-4'>
                    Thông báo <span className='fs-7 opacity-75 ps-3'>{listNotificationsTab?.length} Bản ghi</span>
                </h2>
                <em className='d-inline-block color-primary cursor-pointer mt-10 py-4 me-2'
                    onClick={handleReadAllNotifications}
                >Đánh dấu tất cả là đã đọc</em>    
            </div>
            <TabMenu
                danhsachTabs={tabList}
            />

        </div>
    )
}

export default AllNotifications