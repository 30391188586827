/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "../../../AppContext";
import moment from "moment";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom from "../../component/table-custom/TableCustom";
import { GeneralSearchParamsType } from "../../utils/ParamsUtils";
import { SearchBase, searchBaseDefaultValue } from "../../utils/InterfaceUtils";
import { Col, Form, Row } from "react-bootstrap";
import SelectCustom from "../../component/select-custom";
import { LIST_PLAN_TYPE } from "../../program/consts/InitialValue";
import { heightAutocomplete } from "../../component/input-field/StyleComponent";
import { useFormik } from "formik";
import InputDateCustom from "../../component/input-field/InputDateCustom";
import ButtonCustom from "../../component/button-custom";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from "../../../Constant";
import { deleteStatus, getColorConfig, getListStatus } from "../services/ConfigServices";
import AddColorConfigDialog from "./AddColorConfigDialog";
import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import "../styles/Config.scss";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { handleError } from "../../utils/FunctionUtils";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

function ColorConfig() {
    const { lang } = useMultiLanguage();
    const { setPageLoading } = useLoading();


    const [data, setData] = useState<any[]>([]);
    const [listStatus, setListStatus] = useState<any[]>([]);
    const [listStatusAdd, setListStatusAdd] = useState<any[]>([]);
    const [openAddColorConfigDialog, setOpenAddColorConfigDialog] = useState<{open: boolean, data: any}>({
        open: false,
        data: null
    });
    const [showDialogDelete, setShowDialogDelete] = useState<{open: boolean, data: any}>({
        open: false,
        data: null
    });
    const [searchObject, setSearchObject] = useState<SearchBase>({  pageIndex: 1, pageSize: 1000 });

    useEffect(() => {
        getCategoryStatus();
    }, []);

    const getCategoryStatus = async (): Promise<void> => {
        try {
            const { data } = await getListStatus();
            setListStatus(data.data)
        } catch (error) {
            console.error("ERROR", error);
        }
    };

    const getData = async (isUpdate?: boolean): Promise<void> => {
        try {
            setPageLoading(true);
            const { data } = await getColorConfig();
            setData(data.data);
            isUpdate && updateData(data.data);
        } catch (error) {
            console.error("ERROR", error);
        } finally {
            setPageLoading(false);
        }
    };

    const updateData = async (list: any) => {
        const lsValue = JSON.stringify(list);
        localStorage.setItem(KEY_LOCALSTORAGE.COLOR_CONFIG, lsValue);
    };

    const handleEditColorConfig = (data: any) => {
        setOpenAddColorConfigDialog({
            open: true,
            data: data
        });
    };

    const handleDeleteColorConfig = (data: any) => {
        setShowDialogDelete({
            open: true,
            data: data
        });
    };

    const handleConfirmDelete = async (): Promise<void> => {
        try {
            setPageLoading(true);
            await deleteStatus(showDialogDelete.data?.id);
            toast.success(lang("TOAST.DELETE.SUCCESS"));
            setShowDialogDelete({
                open: false,
                data: null
            });
            getData(true);
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    };

    const handleFilterData = (statusCode: any) => {
        const data = JSON.parse(localStorage.getItem(KEY_LOCALSTORAGE.COLOR_CONFIG) || "");
        if (statusCode) {
            const newData = data.filter((item: any) => item?.code === statusCode);
            setData(newData);
        } else {
            setData(data);
        };
    };

    const handleClickAddDialog = () => {
        const newListStatus = listStatus.filter(item =>
            !data.some(filter => filter.code === item.code)
        );
        setListStatusAdd(newListStatus);
        setOpenAddColorConfigDialog({
            open: true,
            data: null
        });
    };

    return (
        <div className="category pt-4 bg-white area-category-edit-page wrapper-content">
            <div className="spaces space-between align-center mb-10">
                <div className="d-flex mt-22">
                    <ButtonCustom
                        className="spaces button-primary py-4 fs-12 h-30"
                        onClick={() => { handleClickAddDialog() }}
                        checkAuthority={{
                            permission: AUTHORITIES_PERMISSIONS.DASHBOARD,
                            ability: AUTHORITIES_ABILITY.CONFIG,
                        }}
                    >
                        Thêm cấu hình màu
                    </ButtonCustom>
                </div>
                <div className='spaces min-w-200'>
                    <SelectCustom
                        className="spaces width-100"
                        label={"Trạng thái"}
                        name="code"
                        valueField={'code'}
                        displayField={'name'}
                        options={listStatus}
                        styles={{ ...heightAutocomplete("32px"), menu: provided => ({ ...provided, zIndex: 9999 }) }}
                        handleChange={(value, _option, _actionMeta, name) => {
                            handleFilterData(value);
                        }}
                    />
                </div>
            </div>
            <TableCustom
                data={data}
                height={"calc(100vh - 285px)"}
                columns={ColumnMemberCategory({handleEditColorConfig, handleDeleteColorConfig})}
                updatePageData={getData}
                headerClasses='text-center'
                noPagination={true}
                objectSearch={searchObject}
            />
            {openAddColorConfigDialog.open &&
                <AddColorConfigDialog
                    show={openAddColorConfigDialog.open}
                    onClose={() => setOpenAddColorConfigDialog({
                        open: false,
                        data: null
                    })}
                    data={openAddColorConfigDialog.data}
                    listStatus={openAddColorConfigDialog.data ? listStatus : listStatusAdd}
                    updatePageData={() => getData(true)}
                />
            }
            {showDialogDelete.open && (
                <ConfirmDialog
                    show={showDialogDelete.open}
                    title="Xóa danh mục"
                    message={`Bạn chắc chắn muốn xóa cấu hình màu với trạng thái "${showDialogDelete.data?.statusName || ""}" ?`}
                    yes="Xác nhận"
                    cancel="Hủy"
                    onYesClick={handleConfirmDelete}
                    onCancelClick={() => setShowDialogDelete({
                        open: false,
                        data: null
                    })}
                />
            )}
        </div>
    );
}

const ColumnMemberCategory = (props: any) => {
    const { handleEditColorConfig, handleDeleteColorConfig } = props;
    return [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: "40px",
                maxWidth: "50px",
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            ),
        },
        {
            name: "TT",
            field: "",
            headerStyle: {
              width: "80px",
            },
            render: (row: any) => (
              <GroupTableButton 
                elementAbility={{"EDIT": true, "DELETE": true}}
                handleEdit={() => handleEditColorConfig(row)}
                handleDelete={() => handleDeleteColorConfig(row)}
                isIconEditOrAccess={false}
              />
            ),
          },
        {
            name: "Tên trạng thái",
            field: "statusName",
            headerStyle: {
                minWidth: "100px",
                maxWidth: "120px",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            name: "Mã màu",
            field: "name",
            headerStyle: {
                minWidth: "100px",
                maxWidth: "120px",
                textAlign: "center"
            },
            render: (row: any) => (
                <div className="container-input-color">
                    <Form.Control
                        type="color"
                        value={row.name}
                        disabled
                        title="Chọn màu"
                        className='custom-color-picker'
                    />

                </div>
            ),
        },
    ];
};

export default ColorConfig;