export const ENABLE_ALL = {
    id: false,
    code: false,
    name: false,
    empOnusArea: false,
    parentAreaName: false,
    note: false,
    orgId: false,
    imageView: false,
}

export const DEFAULT_DISABLE = {
    ...ENABLE_ALL,
    id: true,
    parentAreaName: true,
    imageView: true,
}

export const EDIT_DISABLE = {
    ...ENABLE_ALL,
    id: true,
    parentAreaName: true,
    code: true,
    imageView: true,
}

export const VIEW_DISABLE = {
    id: true,
    code: true,
    name: true,
    empOnusArea: true,
    parentAreaName: true,
    note: true,
    orgId: true,
    imageView: false,
}