import { 
    AUTHORITIES_ABILITY, 
    AUTHORITIES_PERMISSIONS, 
    BTN_CANCEL, 
    BTN_SAVE,
    BTN_SEND_REQUEST,
    BTN_APPROVE,
    BTN_REQUEST_EDIT,
    BTN_FIX
} from "../../../Constant";
import { StageSignature as STATUS_BUTTON } from "../../constant";

const addAuthority = (button: any, ability: string, permission?: string) => {
    return {
        ...button,
        authority: {
            permission: permission || AUTHORITIES_PERMISSIONS.EVALUATE,
            ability
        }
    };
};

export const EVALUATE_FORM_BUTTON_2 = [
    BTN_SAVE, 
    BTN_SEND_REQUEST, 
    BTN_APPROVE, 
    BTN_REQUEST_EDIT,
    BTN_FIX,
    BTN_CANCEL
]
const BTN_SAVE_AUTH = addAuthority(BTN_SAVE, AUTHORITIES_ABILITY.SEARCH);
const BTN_SEND_REQUEST_AUTH = addAuthority(BTN_SEND_REQUEST, AUTHORITIES_ABILITY.SEARCH);
const BTN_APPROVE_AUTH = addAuthority(BTN_APPROVE, AUTHORITIES_ABILITY.SEARCH);
const BTN_REQUEST_EDIT_AUTH = addAuthority(BTN_REQUEST_EDIT, AUTHORITIES_ABILITY.SEARCH);
const BTN_FIX_AUTH = addAuthority(BTN_FIX, AUTHORITIES_ABILITY.SEARCH, AUTHORITIES_PERMISSIONS.FIX);

export const EVALUATE_BUTTON: { [key: string]: any[] } = {
    [STATUS_BUTTON.NOT_EVALUATED]: [BTN_SEND_REQUEST_AUTH, BTN_SAVE_AUTH, BTN_CANCEL],
    [STATUS_BUTTON.UNDER_EVALUATION]: [BTN_SAVE_AUTH, BTN_SEND_REQUEST_AUTH, BTN_CANCEL ],
    [STATUS_BUTTON.PENDING_APPROVAL]: [BTN_CANCEL],
    [STATUS_BUTTON.PENDING_APPROVAL_USER_APPROVE]: [BTN_APPROVE_AUTH, BTN_REQUEST_EDIT_AUTH, BTN_CANCEL],
    [STATUS_BUTTON.REQUEST_FOR_MODIFICATION]: [BTN_SEND_REQUEST_AUTH, BTN_SAVE_AUTH, BTN_CANCEL],
    [STATUS_BUTTON.REQUEST_FOR_MODIFICATION_USER_APPROVE]: [BTN_CANCEL],
    [STATUS_BUTTON.EVALUATED]: [BTN_CANCEL],
    [STATUS_BUTTON.EVALUATED_USER_ONUS]: [BTN_FIX_AUTH, BTN_CANCEL],
    [STATUS_BUTTON.FIX_EVALUATED]: [BTN_CANCEL],
    [STATUS_BUTTON.UNKNOWN]: [BTN_CANCEL],
};
