import moment from "moment";
import GroupTableButton from "../../component/group-table-button/GroupTableButton";
import { APPROVAL_TYPE } from "./ApprovalConst";
import { hasAuthority } from "../../utils/FunctionUtils";
import { AUTHORITIES_ABILITY, AUTHORITIES_PERMISSIONS } from "../../../Constant";

export const ColumnApprove = (props: any) => {
    const { handleView, handleApprove, type } = props;

    return [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: "40px",
                maxWidth: "50px",
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            ),
        },
        ...(type === APPROVAL_TYPE.PENDING_APPROVAL ? [
        {
            name: "TT",
            field: "",
            headerStyle: {
                width: "80px",
                display: hasAuthority(AUTHORITIES_PERMISSIONS.APPROVE, AUTHORITIES_ABILITY.ACTION) ? "" : "none",
            },
            cellStyle: {
                display: hasAuthority(AUTHORITIES_PERMISSIONS.APPROVE, AUTHORITIES_ABILITY.ACTION) ? "" : "none",
            },
            render: (row: any) => (
                <GroupTableButton
                    {...(type === APPROVAL_TYPE.PENDING_APPROVAL && {
                        handleView: () => handleApprove(row)
                    })}
                    {...(type === APPROVAL_TYPE.APPROVED && {
                        handleView: () => handleView(row)
                    })}
                    elementAbility={{VIEW: "VIEW"}}
                />
            ),
        }
        ] : []),
        {
            name: "Loại đề xuất",
            field: "requestTypeName",
            headerStyle: {
                minWidth: "100px",
                maxWidth: "120px",
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any) => (
                <span className="hyperlink"
                    onClick={() => {
                        if (type === APPROVAL_TYPE.PENDING_APPROVAL) {
                            handleApprove && handleApprove(row);
                        } else {
                            handleView && handleView(row);
                        }
                    }}
                >{row?.requestTypeName}</span>
            )
        },
        {
            name: type === APPROVAL_TYPE.APPROVED ? "Ngày phê duyệt" : "Ngày gửi yêu cầu",
            field: "requestDate",
            headerStyle: {
                minWidth: "100px",
                maxWidth: "120px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (row: any, index: number) => (
                <span>{ type === APPROVAL_TYPE.APPROVED ?
                        row?.approvedDate ? moment(row.approvedDate).format("DD/MM/YYYY") : '' 
                        : 
                        row?.requestDate ? moment(row.requestDate).format("DD/MM/YYYY") : ''
                    }
                </span>
            ),
        },
        {
            name: "Nội dung yêu cầu",
            field: "textOpinions",
            headerStyle: {
                minWidth: "250px",
                maxWidth: "300px",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
        {
            name: "Người yêu cầu",
            field: "userCreate",
            headerStyle: {
                minWidth: "120px",
                maxWidth: "150px",
            },
            cellStyle: {
                textAlign: "left",
            },
        },
    ];
};