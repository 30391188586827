import { useNavigate } from 'react-router-dom'
import { useNotification } from '../../../../app/AppContext'
import { NOTIFICATION_CONFIG_CODE, STATUS_READ_NOTI, URL_ROUTES } from '../../../../app/modules/constant'
import { getDataNotifications, updateStatusNotifications } from '../../../../app/modules/services'
import { INoti } from './HeaderNotificationsMenu'
import { handleError } from '../../../../app/modules/utils/FunctionUtils'
import ScoreLogs from './ScoreLogs'
import { useCallback, useEffect, useRef, useState } from 'react'

type Props = {
    listNotifications: INoti[],
    onClose?: () => void
}

function NotificationListTab({ listNotifications, onClose }: Props) {

    const navigate = useNavigate()
    const { setListNotificationsTab } = useNotification()
    const [openScoreLogs, setOpenScoreLogs] = useState<boolean>(false);
    
    const loadNotifications = async () => {
        try {
            const res = await getDataNotifications({
                pageIndex: 1,
                pageSize: 999,
            });
            setListNotificationsTab(res?.data?.data);
        } catch (error) {
            console.error('Error loading notifications:', error);
        }
    };

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleViewNotifications = async (item: INoti) => {
        try {
            if (item?.status === STATUS_READ_NOTI.UN_READ) {
                await updateStatusNotifications([item?.id])
                setListNotificationsTab((prev) => prev?.map((notification: INoti) => ({
                    ...notification,
                    status: notification?.id === item.id ? STATUS_READ_NOTI.READ : notification?.status
                })))
            }
            if (item.configCode === NOTIFICATION_CONFIG_CODE.SCORE_CHANGE) {
                setOpenScoreLogs(true);
            } else {
                item?.url === URL_ROUTES.AREAS ?
                    navigate(`/${item?.url}`, { state: { isFromNotification: null, urlId: item?.idUrl } })
                    :
                    navigate(`/${item?.url}/${item?.idUrl}`, { state: { isFromNotification: true } });
            }
            onClose && onClose();
        } catch (error) {
            handleError(error)
        }
    };

    return (
        <>
            {Boolean(listNotifications?.length)
                    ?
                    <>
                        {listNotifications.map((item, index) => (
                            <div key={`alert${item?.id}`}
                                className={'d-flex flex-stack py-4 hover-gray px-8 cursor-pointer'}
                                onClick={() => {
                                    handleViewNotifications(item)
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    <div className='mb-0 me-2 hover-elevate-up'>
                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                                            {item?.title}
                                        </div>
                                        <div className={`fs-7 ${item?.status === STATUS_READ_NOTI.UN_READ ? "text-black" : "text-muted"}`}>
                                            {item?.content}
                                        </div>
                                        <div className={`fs-7 ${item?.status === STATUS_READ_NOTI.UN_READ ? "text-notifi" : "text-muted"}`}>
                                            {item?.sentTime} từ {item?.createdBy}
                                        </div>
                                    </div>
                                </div>

                                <span className='badge badge-light fs-8'></span>
                                {
                                    item?.status === STATUS_READ_NOTI.UN_READ && (
                                        <span className=''><i className="text-notifi fa-solid fa-circle fs-9" /></span>
                                    )
                                }
                            </div>
                        ))}
                    </> :
                    <div className='py-3 text-center'>
                        Không có thông báo.
                    </div>
            }
            {openScoreLogs && (
                <ScoreLogs
                    show={openScoreLogs}
                    onClose={() => setOpenScoreLogs(false)}
                />
            )}
        </>
    )
}

export default NotificationListTab