// true -> disabled/Hide  false -> enabled/show
import { StageSignature } from "../../constant"
import { FORM_ACTION } from "./ConfigButton"

const LIST_FIELD = [
  'field_id', 
  'field_code', 
  'field_name', 
  'field_start_date', 
  'field_note', 
  'field_userAprove', 
  'btn_save_form_basic', 
  'field_score_criteria', 
  'field_des_criteria', 
  'btn_add_criteria', 
  'btn_remove_criteria'
]

export const BASIC_FORM_INFO_DISABLED = Object.fromEntries(LIST_FIELD.map(key => [key, true]))

export const BASIC_FORM_INFO_ENABLE = Object.fromEntries(LIST_FIELD.map(key => [key, key === "field_start_date" ? true : false]))

export const INFO_FORM_CRITERIA_DISABLED = {
  table_criteria: true,
  table_point_ladder: true,
  btn_save_form_info: true,

}
export const INFO_FORM_CRITERIA_ENABLE = {
  table_criteria: false,
  table_point_ladder: false,
  btn_save_form_info: false,
}


export const INFO_FORM_CRITERIA = {
  [StageSignature.INITIALIZATION]: {
    ...INFO_FORM_CRITERIA_ENABLE
  },
  [StageSignature.REQUEST_FOR_MODIFICATION]: {
    ...INFO_FORM_CRITERIA_ENABLE
  },
  [StageSignature.PENDING_APPROVAL_USER_APPROVE]: {
    ...INFO_FORM_CRITERIA_DISABLED
  },
  [StageSignature.PENDING_APPROVAL]: {
    ...INFO_FORM_CRITERIA_DISABLED
  },
  [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]: {
    ...INFO_FORM_CRITERIA_DISABLED
  },
  [StageSignature.CURRENT]: {
    ...INFO_FORM_CRITERIA_DISABLED
  },
  [StageSignature.CURRENT_USER_APPROVE]: {
    ...INFO_FORM_CRITERIA_DISABLED
  },
    [FORM_ACTION.VIEW]: {
        ...INFO_FORM_CRITERIA_DISABLED
    }
}


export const BASIC_FORM_CRITERIA = {
  [StageSignature.INITIALIZATION]: {
    ...BASIC_FORM_INFO_ENABLE
  },
  [StageSignature.REQUEST_FOR_MODIFICATION]: {
    ...BASIC_FORM_INFO_ENABLE
  },
  [StageSignature.PENDING_APPROVAL_USER_APPROVE]: {
    ...BASIC_FORM_INFO_DISABLED
  },
  [StageSignature.PENDING_APPROVAL]: {
    ...BASIC_FORM_INFO_DISABLED
  },
  [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]: {
    ...BASIC_FORM_INFO_DISABLED
  },
  [StageSignature.CURRENT]: {
    ...BASIC_FORM_INFO_DISABLED
  },
  [StageSignature.CURRENT_USER_APPROVE]: {
    ...BASIC_FORM_INFO_DISABLED
  },
}