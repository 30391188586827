import { FC } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { Col } from './Grid';
import { useFormik } from 'formik';
import * as yup from "yup";
import Button from './button-custom';
import { heightAutocomplete } from './input-field/StyleComponent';
import SelectCustom from './select-custom';
import { getUsersByArea } from '../services';

interface Props {
    show: boolean;
    onSubmitForm: (values: any) => void;
    onCancelClick: () => void;
    defaultValue?: string | null
    areaId: string
}

const PopupAssignmentRequest: FC<Props> = ({ show, onSubmitForm, onCancelClick, defaultValue, areaId }) => {
    const formik = useFormik({
        initialValues: {
            userRemedy: defaultValue ||"",
        },
        validationSchema: yup.object({
            userRemedy: yup.string().trim().required("Không được bỏ trống").nullable(),
        }),
        onSubmit: onSubmitForm,
    });

    return (
        <Modal
            show={show}
            centered
            animation
            className='custom-modal'
        >
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header className="bg-pri p-3">
                    <Modal.Title className="text-white fw-normal">Phân công khắc phục</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center p-4">
                    <Row className='w-100'>
                        <Col xs="12">
                            <SelectCustom
                                required
                                label='Người phân công'
                                menuPlacement="auto"
                                service={getUsersByArea}
                                params={{ areaId }}
                                styles={heightAutocomplete("32px")}
                                valueField={'username'}
                                displayField={'fullName'}
                                optionsResponse='data'
                                handleChange={(selectedOption) => formik.setFieldValue("userRemedy", selectedOption)}
                                value={formik.values.userRemedy}
                                errors={formik.errors.userRemedy}
                                touched={formik.touched.userRemedy}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-3 center">
                    <Button type='submit'> Xác nhận </Button>
                    <Button color='secondary' onClick={onCancelClick}> Hủy </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export { PopupAssignmentRequest };
