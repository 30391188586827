import axios from "axios";
import { iProgram, iSearchProgramParams } from "../models/ProgramModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";


const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];

export const searchPrograms = (searchObject: iSearchProgramParams) => {
    const url = API_PATH + "/programs/search";
    return axios.get(url, { params: searchObject });
}

export const getAllProgramImpl = (searchObject: any) => {
    const url = API_PATH + "/program-impl-schedules/search/evaluate";
    return axios.get(url, { params: searchObject });
}

export const getProgramById = (id: any) => {
    let url = API_PATH + "/programs/" + id;
    return axios.get(url);
}
export const getListEmployee = (id: any) => {
    let url = API_PATH + "/employees/all";
    return axios.get(url);
}

export const createProgram = (data: iProgram) => {
    const url = API_PATH + "/programs";
    return axios.post(url, data);
}

export const updateProgram = (id: any, data: iProgram) => {
    let url = API_PATH + "/programs/" + id;
    return axios.put(url, data);
}

export const implementationPrograms = (id: any, data: iProgram) => {
    let url = API_PATH + "/programs/implementation/" + id;
    return axios.put(url, data);
}

export const finishPrograms = (id: any, data: iProgram) => {
    let url = API_PATH + "/programs/finish/" + id;
    return axios.put(url, data);
}

export const deleteProgram = (id: any) => {
    let url = API_PATH + "/programs/" + id;
    return axios.delete(url);
}

export const updateStageEditRequestPrograms = (data: any) => {
    let url = API_PATH + "/programs/update-stage-edit-request";
    return axios.put(url, data);
}

export const updateStageSendRequestPrograms = (data: any) => {
    let url = API_PATH + "/programs/update-stage-send-request";
    return axios.put(url, data);
}

export const updateApproveRequestPrograms = (id: any, data: iProgram) => {
    let url = API_PATH + "/programs/update-approve-request/" + id;
    return axios.put(url, data);
}

export const getEmployees = () => {
    let url = API_PATH + "/employees/all-by-current-organization";
    return axios.get(url);
}

export const getUsers = (params: any) => {
    let url = API_PATH + "/employees/all/user";
    return axios.get(url, { params });
}

export const getUsersIs5s = (params: any) => {
    let url = API_PATH + "/employees/all-by-5s";
    return axios.get(url, { params });
}

export const updateAssignAssessor = (id: any, data: iProgram) => {
    let url = API_PATH + "/programs/assign-assessor/" + id;
    return axios.put(url, data);
}

export const awaitingAssignment = (id: any, data: iProgram) => {
  let url = API_PATH + "/programs/awaiting-assignment/" + id;
  return axios.put(url, data);
};

export const copyProgram = (id: any) => {
    let url = API_PATH + "/programs/clone/" + id;
    return axios.get(url);
};