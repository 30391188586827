import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row, Col, Alert } from 'react-bootstrap';
import { ZoneModel } from '../../category/model/CategoryModel';
import { useLoading } from '../../../AppContext';
import { getAllZoneCategory } from '../../category/services/CategoryServices';
import { handleError } from '../../utils/FunctionUtils';
import CustomIconButton from '../../component/CustomIconButton';
import { getImage } from '../../services';
import { PreviewImagePopup } from '../../evaluate/component/EvaluateTable';
import { ActionImage } from '../../evaluate/component/EveluateRepeairDialog';

interface ProgramAreaDialogProps {
    show: boolean;
    onClose: () => void;
    areaDetail?: any;
}

const convertString = (inputString: string): string => {
    if (!inputString) {
        return "";
    }


    if (inputString.charAt(0) === '/') {
        inputString = inputString.substring(1);
    }

    const parts = inputString.split('/');

    const convertedString = parts.map(part => part.replace(':', '&#x276F;')).join(' > ');

    return convertedString;
};

const ProgramAreaDialog: React.FC<ProgramAreaDialogProps> = ({
    show,
    onClose,
    areaDetail
}) => {
    const { setPageLoading } = useLoading();
    const [areaInfo, setAreaInfo] = useState<any>();
    const [imageUrls, setImageUrls] = useState<string>("");
    const [openViewMapRequest, setOpenViewMapRequest] = useState<boolean>(false);
    const imgRefs = useRef<any>();

    useEffect(() => {
        getAreaCategoryList(areaDetail?.areaId);
    }, [areaDetail?.areaId])

    const getAreaCategoryList = async (rootId: string) => {
        try {
            setPageLoading(true);
            const { data } = await getAllZoneCategory(rootId);
            setAreaInfo(data?.data[0]);
            const url = await handleGetUrlImage(data?.data[0]?.image || '');
            setImageUrls(url || '');
        } catch (error) {
            handleError(error);
        } finally {
            setPageLoading(false);
        }
    };
    
    const handleGetUrlImage = async (name: string) => {
        try {
            let imageDataURL = await getImage({ name });
            let url = URL.createObjectURL(imageDataURL.data);
            return url;
        } catch (error) {
            console.error(error);
        }
    };

    const handleShowImage = async (type: string) => {
        try {
            let url = imgRefs.current?.src || "";
            setImageUrls(url as string);
            setOpenViewMapRequest(true);
        } catch (e) {
            console.error(e);
        }
    };

    const handleLoadImage = async (image: any) => {
        let action = image.parentNode.querySelector(".action-img");
        let loading = image.parentNode.querySelector(".spinner-border");
        action.classList.remove("d-none");
        loading.classList.add("d-none");
    };

    useEffect(() => {
        handleLoadImage(imgRefs.current);
    }, [imgRefs.current]);

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            animation
            className="custom-modal category-dialog"
            size="lg"
        >
            <Modal.Header closeButton className='spaces py-15'>
                <Modal.Title>
                    <span className='spaces m-0 text-uppercase color-primary'>Thông tin khu vực</span>
                    <span className="perform-evaluate-title-id fs-7">
                        {areaInfo?.path === "null" ? '' : convertString(areaInfo?.path)}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='spaces '>
                <div className='d-flex flex-column gap-12 spaces px-0 lh-xl'>
                    <Row>
                        <Col xs={4} className="d-flex flex-column justify-content-center align-items-center ps-5">
                            <span className={`img-select ${imageUrls ? 'd-none' : ''}`}>
                                <i className="fa-solid fa-image px-4 fw-bold fs-1 text-white bg-secondary rounded-2 py-3"></i>
                            </span>
                            <div className={`spaces position-relative img-not-select ${imageUrls ? '' : 'd-none'}`}>
                                <img
                                    ref={imgRefs}
                                    loading='lazy'
                                    id={areaInfo?.image || ""}
                                    src={imageUrls || ""}
                                    alt=""
                                    className="rect-img-app spaces h-220"
                                />
                                <ActionImage
                                    showRemove={false}
                                    handleShow={() => handleShowImage("image")}
                                    handleRemove={() => {}}
                                />
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <em>Ảnh sơ đồ</em>
                            </div>
                        </Col>
                        <Col xs={8} className='ps-5'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex gap-2 align-items-start'>
                                        <div className='spaces min-w-105'>Mã khu vực:</div>
                                        <div className='text-lable-input color-primary'>{areaInfo?.code === "null" ? '' : areaInfo?.code}</div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='d-flex gap-2 align-items-start'>
                                        <div className='spaces min-w-105'>Tên khu vực:</div>
                                        <div className='text-lable-input color-primary'>
                                            {areaInfo?.name === "null" ? '' : areaInfo?.name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='d-flex gap-2 align-items-start'>
                                        <div className='spaces min-w-105'>Chịu trách nhiệm:</div>
                                        <div className='text-lable-input color-primary'>
                                            {areaInfo?.employeeName === "null" ? '' : areaInfo?.employeeName}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='d-flex gap-2 align-items-start'>
                                        <div className='spaces min-w-105'>Thuộc khu vực:</div>
                                        <div className='text-lable-input color-primary'>
                                            {areaInfo?.parentAreaName === "null" ? '' : areaInfo?.parentAreaName}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='align-items-start pt-2'>
                                        <Alert variant="dark" className="spaces min-h-100">
                                            {areaInfo?.note === "null" ? '' : areaInfo?.note}
                                        </Alert>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    
                    <Col xs={12} className="justify-center spaces pt-16 mb-3 gap-10">
                        <Button
                            onClick={onClose}
                            className="spaces button-primary-outline py-4 fs-12 h-30"
                        >
                            Đóng
                        </Button>
                    </Col>

                    {openViewMapRequest && (
                        <PreviewImagePopup
                            show={openViewMapRequest}
                            hide={() => setOpenViewMapRequest(false)}
                            src={imageUrls || ""}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProgramAreaDialog;