import { StageSignature } from "../../constant";

const defaultAction = {
    imageGood: false,
    imageBad: false,
    actionImprove: false,
    deleteImageGood: false,
    deleteImageBad: false,
    deleteImageImporve: false,
    checkAssess: false,
    description: true,
    reasonText: true,
    fixedText: true,
    btnSave: true
}

export const EVALUATE_ACTION_TABLE: { [key: string]: { [key: string]: boolean } } = {
    [StageSignature.NOT_EVALUATED]: {
        ...defaultAction,
        imageGood: true,
        imageBad: true,
        deleteImageGood: true,
        deleteImageBad: true,
        description: false,
        checkAssess: true
    },
    [StageSignature.UNDER_EVALUATION]: {
        ...defaultAction,
        imageGood: true,
        imageBad: true,
        deleteImageGood: true,
        deleteImageBad: true,
        description: false,
        checkAssess: true
    },
    [StageSignature.PENDING_APPROVAL]: { ...defaultAction },
    [StageSignature.PENDING_APPROVAL_USER_APPROVE]: { ...defaultAction },
    [StageSignature.REQUEST_FOR_MODIFICATION]: {
        ...defaultAction,
        imageGood: true,
        imageBad: true,
        deleteImageGood: true,
        deleteImageBad: true,
        description: false,
        checkAssess: true
    },
    [StageSignature.REQUEST_FOR_MODIFICATION_USER_APPROVE]: { ...defaultAction },
    [StageSignature.EVALUATED]: { ...defaultAction },
    [StageSignature.EVALUATED_USER_ONUS]: { 
        ...defaultAction,
        actionImprove: true,
        deleteImageImporve: true,
        reasonText: false,
        fixedText: false,
        btnSave: false
    },
    [StageSignature.FIX_EVALUATED]: { ...defaultAction },
    [StageSignature.UNKNOWN]: { ...defaultAction },
};