
export const ColumnAccountTable = (props: any) => {
    const { handleEdit, handleDelete } = props
    return [
        {
            name: "#",
            field: "stt",
            headerStyle: {
                minWidth: "40px"
            },
            render: (row: any, index: number, numericalOrder: number) => (
                <span>{numericalOrder}</span>
            )
        },
        {
            name: "Thao tác",
            field: "khoaBaoCao",
            headerStyle: {
                minWidth: "100px",
                textAlign: "center"
            },
            render: (row: any) => {
                return (
                    <div className="d-flex justify-content-center">
                        <i className="cursor-pointer bi bi-pencil-fill fs-4 text-primary px-4 fw-bold"
                            onClick={() => handleEdit(row)}
                        />
                        <i className="cursor-pointer bi bi-trash fs-4 text-danger px-4 fw-bold"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                )
            }
        },
        {
            name: "Tên tài khoản",
            field: "nameAccount",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            },
        },
        {
            name: "Vai trò",
            field: "name",
            headerStyle: {
                minWidth: "300px",
                textAlign: "center"
            },
            cellStyle: {
                textAlign: "left"
            },
        },
        {
            name: "Trạng thái",
            field: "statusName",
            headerStyle: {
                minWidth: "150px",
                textAlign: "center"
            },
        },
    ];
};
