import { Alert, Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Col } from '../../component/Grid'
import { iProgram } from '../models/ProgramModel'

type Props = {
  dataForm: iProgram
}

const ProgramInfoTab = ({ dataForm }: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-105'>Mã chương trình:</div>
            <div className='text-lable-input color-primary'>{dataForm?.code}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-135'>Tên chương trình:</div>
            <div className='text-lable-input color-primary'>{dataForm?.name}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-90'>Loại kế hoạch:</div>
            <div className='text-lable-input color-primary'>{dataForm.typeName}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-105'>Tiêu chí:</div>
            <div className='text-lable-input color-primary'>{dataForm.termFormName}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-135'>Ngày bắt đầu:</div>
            <div className='text-lable-input color-primary'>{dataForm?.estimatedStartDate}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-90'>Ngày kết thúc:</div>
            <div className='text-lable-input color-primary'>{dataForm?.estimatedEndDate}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-105'>Trạng thái:</div>
            <div className='text-lable-input color-primary'>{dataForm?.statusName}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='d-flex gap-2 align-items-center'>
            <div className='spaces min-w-135'>Trưởng đoàn đánh giá:</div>
            <div className='text-lable-input color-primary'>{dataForm?.empLeaderName}</div>
          </div>
        </Col>
        
        <Col xs={12}>
          <Alert variant="dark" className="spaces min-h-46">
            {dataForm?.note}
          </Alert>
        </Col>
      </Row>
    </div>
  )
}

export default ProgramInfoTab