import React from 'react';

interface UsersTabProps {
    // Define the props for your component here
}

const UsersTab: React.FC<UsersTabProps> = () => { 

    return (
        <div className="roles spaces mt-24">
            Tab người dùng
        </div>
    );
};

export default UsersTab;