import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button } from "react-bootstrap";
import InputSearch from '../../component/input-field/InputSearch';
import TableCustom from '../../component/table-custom/TableCustom';
import { ColumnRoles, FAKE_TREE_DATA } from '../const/SystemConst';
import TextValidator from '../../component/input-field/TextValidator';
import SelectCustom from "../../component/select-custom";
import SelectTree from '../../component/SelectTree';


interface RolesTabProps {
    // Define the props for your component here
}

const RolesTab: React.FC<RolesTabProps> = () => {
    const [data, setData] = useState<any[]>([]);
    const [totalElements, setTotalElements] = useState(0);
    const [numberOfElements, setNumberOfElements] = useState(0);
    const [searchObject, setSearchObject] = useState("");
    const [treeSelect, setTreeSelect] = useState<any[]>([]);
    
    const handleSearch = () => {
        console.log("search")
    }

    const updateData = () => {

    }

    const handleEditRole = () => {

    }

    const handleDeleteRole = () => {

    }

    const getAllRoles = async() => {
        return []
    }

    const handleRow = () => {
        
    }

    const onClose = () => {

    }

    return (
        <div className="roles spaces mt-24">
            <Row>
                <Col xs="12" md="6" className='p-none'>
                    <div className='spaces max-w-220 mb-10'>
                        <InputSearch className='spaces h-31' handleChange={(e) => {}} handleSearch={handleSearch} placeholder="Nhấn vào đây" isEnter/>
                    </div>
                    <TableCustom
                        data={data}
                        height={"calc(100vh - 240px)"}
                        columns={ColumnRoles({ handleEditRole, handleDeleteRole })}
                        justFilter={true}
                        updatePageData={updateData}
                        noPagination={false}
                        totalElements={totalElements}
                        numberOfElements={numberOfElements}
                        objectSearch={searchObject}
                        headerClasses='text-center'
                    />
                </Col>
                <Col xs="12" md="6" className='pt-5 pt-md-0'>
                    <form action="" className='form-add-role'>
                        <div className="">
                            <h3 className='fw-bold role-left-title'>Thêm mới</h3>
                            <h3 className='fw-bold role-left-des'>Thông tin vai trò</h3>
                        </div>

                        <Row className='role-info'>
                            <Col xs={4} className="mb-3 px-1">
                                <TextValidator
                                    className="flex-row min-w-80"
                                    lable={"Tên vai trò"}
                                    isRequired
                                    name="id"
                                    type="text"
                                    value={""}
                                />
                            </Col>
                            <Col xs={4} className="mb-3 px-1">
                                <TextValidator
                                    className="flex-row min-w-80"
                                    lable={"Mô tả"}
                                    isRequired
                                    name="id"
                                    type="text"
                                    value={""}
                                />
                            </Col>
                            <Col xs={4} className="mb-3 px-1">
                                <SelectCustom
                                    className="spaces z-index-8 width-100"
                                    label={"Đơn vị"}
                                    service={getAllRoles}
                                    optionsResponse='data'
                                    valueField={'id'}
                                    displayField={'fullName'}
                                    name="employeeId"
                                    value={""}
                                    handleChange={() => {
                                        // setFieldValue(name, value);
                                    }}
                                    errors={""}
                                    touched={""}
                                />
                            </Col>
                        </Row>
                        <div className="">
                            <h3 className='fw-bold role-left-des'>Chức năng phân quyền</h3>
                        </div>
                        <SelectTree
                            codeCollapses={treeSelect}
                            handleChangeCollapsesCode={setTreeSelect}
                            idSelected={"id"}
                            handleChangeSelectId={() => { }}
                            selectTree={FAKE_TREE_DATA}
                            handleRow={handleRow}
                            className='role-func'
                        />
                        <Row>
                            <Col xs={12} className="justify-center spaces py-8 gap-10">
                                <Button 
                                    className="spaces button-primary py-4 fs-12 h-30" 
                                    type="submit"
                                > 
                                    Lưu 
                                </Button>
                                <Button
                                    onClick={onClose}
                                    className="spaces button-gray-outline py-4 fs-12 h-30"
                                >
                                    Hủy
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </div>
    );
};

export default RolesTab;