import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
    onClick: () => void
    tooltip?: string
    className?: string
    visible?: boolean
}

const CustomIconButton = ({ onClick, tooltip, className, visible = true }: Props) => {
    return (
        <>
            {visible && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{tooltip || ""}</Tooltip>}
                >
                    <i className={`cursor-pointer ${className || ""}`}
                        onClick={onClick}
                    ></i>
                </OverlayTrigger>
            )}
        </>
    )
}

export default CustomIconButton