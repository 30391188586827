import { 
    BTN_APPROVE, 
    BTN_CANCEL, 
    BTN_FINISH, 
    BTN_DEPLOY, 
    BTN_SAVE, 
    BTN_SEND_REQUEST, 
    BTN_REQUEST_EDIT, 
    AUTHORITIES_PERMISSIONS, 
    AUTHORITIES_ABILITY, 
    BTN_SAVE_APPROVE,
    BTN_ASSIGNED
} from "../../../Constant";
import { StageSignature as STATUS_BUTTON } from "../../constant";

const addAuthority = (button: any, ability: string) => {
    return {
        ...button,
        authority: {
            permission: AUTHORITIES_PERMISSIONS.PROGRAM,
            ability
        }
    };
};

const BTN_APPROVE_AUTHORITY = addAuthority(BTN_APPROVE, AUTHORITIES_ABILITY.APPROVE);

const BTN_FINISH_AUTHORITY = addAuthority(BTN_FINISH, AUTHORITIES_ABILITY.SECRETARY);

const BTN_DEPLOY_AUTHORITY = addAuthority(BTN_DEPLOY, AUTHORITIES_ABILITY.ASSIGNMENT);

const BTN_SAVE_AUTHORITY = addAuthority(BTN_SAVE, AUTHORITIES_ABILITY.ACTION);

const BTN_SEND_REQUEST_AUTHORITY = addAuthority(BTN_SEND_REQUEST, AUTHORITIES_ABILITY.REQUEST);

const BTN_REQUEST_EDIT_AUTHORITY = addAuthority(BTN_REQUEST_EDIT, AUTHORITIES_ABILITY.APPROVE);

const BTN_SAVE_ASSING_AUTHORITY = addAuthority(BTN_SAVE_APPROVE, AUTHORITIES_ABILITY.ASSIGNMENT);

const BTN_ASSIGNED_AUTHORITY = addAuthority(BTN_ASSIGNED, AUTHORITIES_ABILITY.ASSIGNMENT);

export const PROGRAM_BUTTON: { [key: string]: any[] } = {
    [STATUS_BUTTON.INITIALIZATION]: [BTN_SAVE_AUTHORITY, BTN_SEND_REQUEST_AUTHORITY, BTN_CANCEL],
    [STATUS_BUTTON.PENDING_APPROVAL]: [BTN_CANCEL],
    [STATUS_BUTTON.PENDING_APPROVAL_USER_APPROVE]: [BTN_SAVE_ASSING_AUTHORITY, BTN_REQUEST_EDIT_AUTHORITY, BTN_APPROVE_AUTHORITY, BTN_CANCEL],
    [STATUS_BUTTON.REQUEST_FOR_MODIFICATION]: [BTN_SAVE_AUTHORITY, BTN_SEND_REQUEST_AUTHORITY, BTN_CANCEL],
    [STATUS_BUTTON.REQUEST_FOR_MODIFICATION_USER_APPROVE]: [BTN_CANCEL],
    [STATUS_BUTTON.APPROVED]: [BTN_CANCEL],
    [STATUS_BUTTON.APPROVED_USER_APPROVED]: [BTN_DEPLOY_AUTHORITY, BTN_CANCEL],
    [STATUS_BUTTON.CURRENT]: [BTN_CANCEL],
    [STATUS_BUTTON.CURRENT_USER_APPROVE]: [BTN_FINISH_AUTHORITY, BTN_CANCEL],
    [STATUS_BUTTON.FINISHED]: [BTN_CANCEL],
    [STATUS_BUTTON.FINISHED_USER_APPROVED]: [BTN_CANCEL],
    [STATUS_BUTTON.UNKNOWN]: [BTN_CANCEL],
    [STATUS_BUTTON.EVALUATOR_ASSIGNED]: [BTN_ASSIGNED_AUTHORITY, BTN_CANCEL],
};
