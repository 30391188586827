import { OptionReactSelect } from "./models/models";

export const inputTypeList = {
    text: 'text',
    select: 'select',
    date: 'date',
    number: 'number',
    file: 'file',
    checkbox: 'checkbox'
};

export const TYPE_CATEGORY ={
    cap:1,
    bac:2,
    chucVuDang:3,
    chucVuDoan:4,
    chuyenNganh:5,
    phuCap:6,
    danToc: 7,
    chucDanh:8,
    phongBan: 9,
    nganHang: 10,
    binhChung: 11,
    chungChi:12,
    tonGiao:13,
    capBacQuanSu: 14,
    chucVuQuanSu: 15,
    hangThuongBenhBinh: 16,
    phongBenh: 17,
    viTriCongViec:28,
    noiDaoTao:31,
    donVi:100,
    nhomChungChi: 34,
    trinhDoDaoTao: 35,
    quanHeGiaDinh: 36,
    kyNangMem: 37,
    trinhDoNgoaiNgu: 38,
    trinhDoTinHoc: 39,
    trinhDoQuanLyNhaNuoc: 40,
    trinhDoLyLuan: 41,
    hinhThucKhenThuong: 42,
    danhHieu: 43,
    loaiThuTuc: 44,
    loaiDieuDong: 45,
};

export const rowPerPage = [1,5,10,20,30];

export const listGender: OptionReactSelect[] = [
    {
        value: 'M',
        name: 'Male'
    }, 
    {
        value: 'F',
        name: 'Female'
    }
];

export const regex = {
    phone: /^(?:\+84|0)(?:3|5|7|8|9)\d{8}$/,
}

export const CODE_GROUP_CERTIFICATE = {
    HANH_NGHE: "HN",
    NGOAI_NGU: "NN",
    TIN_HOC: "TH",
    KHAC: "OTHER"
}

export const CREATE_PARAMETER = "new";

export const PATH = {
    NEW: 'new',
}

export const StageSignature = {
    INITIALIZATION: "001",
    PENDING_APPROVAL: "002",
    PENDING_APPROVAL_USER_APPROVE: "003",
    CURRENT: "004",
    CURRENT_USER_APPROVE: "005",
    REQUEST_FOR_MODIFICATION: "006",
    REQUEST_FOR_MODIFICATION_USER_APPROVE: "007",
    APPROVED: "008",
    APPROVED_USER_APPROVED: "009",
    FINISHED: "010",
    FINISHED_USER_APPROVED: "011",
    UNKNOWN: "012",
    NOT_EVALUATED: "013",
    UNDER_EVALUATION: "014",
    EVALUATED: "015",
    EVALUATED_USER_ONUS:"016",
    FIX_EVALUATED:"017",
    EVALUATOR_ASSIGNED:"018",
    EVALUATOR_ASSIGNED_VIEW: "019",
} as const;

export const ApprovalSignature = {
    PROGRAM_REGISTER : "001",
    TERM_FORM_REGISTER : "002",
    AREA_REGISTER : "003",
    EVALUATE : "004",
}

export type StageSignatureType = typeof StageSignature[keyof typeof StageSignature];

export const STATUS_READ_NOTI = {
    READ: "014",
    UN_READ: "013"
}

export const NOTIFICATION_CONFIG_CODE = {
    PROGRAM_APPROVAL_REQUESTED: "001", // Yêu cầu phê duyệt chương trình
    APPROVED_PROGRAM: "002", // Đã phê duyệt chương trình
    DEPLOYED_PROGRAM: "003", // Triển khai chương trình
    PROGRAM_EDIT_REQUESTED: "004", // Yêu cầu chỉnh sửa chương trình
    TERM_FORM_APPROVAL_REQUESTED: "005", // Yêu cầu phê duyệt biểu mẫu
    APPROVED_TERM_FORM: "006", // Đã phê duyệt biểu mẫu
    TERM_FORM_EDIT_REQUESTED: "007", // Yêu cầu chỉnh sửa biểu mẫu
    AREA_APPROVAL_REQUESTED: "008", // Yêu cầu phê duyệt khu vực
    APPROVED_AREA: "009", // Đã phê duyệt khu vực
    AREA_EDIT_REQUESTED: "010", // Yêu cầu chỉnh sửa khu vực
    REMEDY_APPROVAL_REQUESTED: "011", // Yêu cầu phê duyệt đánh giá
    APPROVED_REMEDY: "012", // Đã phê duyệt đánh giá
    REMEDY_EDIT_REQUESTED: "013", // Yêu cầu chỉnh sửa đánh giá
    SCORE_CHANGE: "014", // Thay đổi điểm nhân viên
    ASSIGNMENT_FOR_CORRECTION: "016" // Phân công khắc phục
};

export const URL_ROUTES = {
    AREAS: "areas"
}