import { FC, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { tab } from "../../models/tabModels";

type TabMenuProps = {
    danhsachTabs: tab[];
    keyDanhSachTabs?: string;
    vertical?: boolean;
    propActiveTab?: string;
    handleChangeTab?: (activeTab:string) => void
}

export const TabMenu: FC<TabMenuProps> = (props) => {
    const { danhsachTabs, propActiveTab, handleChangeTab} = props;
    const [activeTab, setActiveTab] = useState<string>("0");
    const [tabs, setTabs] = useState<tab[]>([]);

  useEffect(() => {
    setTabs(danhsachTabs);
    if (propActiveTab !== undefined) {
      setActiveTab(propActiveTab);
    }
  }, [danhsachTabs, propActiveTab]);

  const handleTabSelect: (eventKey: string | null) => void = (eventKey) => {
    if (eventKey) {
      return handleChangeTab ? handleChangeTab(eventKey) : setActiveTab(eventKey);
    };
  }

    return (
      <Tabs
        className={`tabs nav nav-tabs customs-tabs ${props.vertical ? 'flex-column flex-nowrap' : ''}`}
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {tabs.map((item, index) => {
          return (
            <Tab
              className={`tab ${props.vertical ? 'flex-1' : ''}`}
              eventKey={index}
              key={item.eventKey}
              title={
                <div className="label">
                  <span>{item?.title}</span>
                </div>
              }
            >
              {activeTab === item.eventKey && item.component}
            </Tab>
          );
        })}
      </Tabs>
    );
}

export default TabMenu;
