import { memo, useCallback, useEffect, useState } from 'react';
import Select, { ActionMeta, mergeStyles } from 'react-select'
import { TYPE } from '../../utils/Constant';
import { SelectProps } from './models';
import "./style.scss";

const customStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        minHeight: '30px',
        borderColor: state.isFocused ? '#CED4DA' : '#ced4da',
        boxShadow: 'none',
        color: 'var(--kt-input-color)',
        '&:hover': {
            borderColor: state.isFocused ? null : '#ced4da'
        }
    }),
    valueContainer: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '30px',
        padding: '0 6px'
    }),
    indicatorsContainer: (baseStyles: any, state: any) => ({
        ...baseStyles,
        height: '30px'
    }),
    singleValue: (baseStyles: any, state: any) => ({
        ...baseStyles,
        color: 'var(--kt-input-color)',
        fontWeight:'500',
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
    }),
};

function SelectCustom(props: SelectProps) {
    const {
        name = '',
        options = [],
        label = '',
        errors = '',
        required = false,
        params = {},
        paramsChange,
        valueField = 'value',
        displayField = 'label',
        placeholder = 'Chọn...',
        noDataMessage = 'Không có dữ liệu',
        handleChange,
        className = '',
        touched = false,
        value,
        optionsResponse = 'content',
        isMulti,
        service,
        styles,
        filterOptions,
        isClearable = true,
        ...rest
    } = props;

    const [optionsData, setOptionsData] = useState<any[]>(options);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const combinedStyles = mergeStyles(customStyles, styles);

    useEffect(() => {
        service && fetchOptions();
    }, [paramsChange]);

    useEffect(() => {
        !service && setOptionsData(options);
    }, [options]);

    const fetchOptions = async() => {
        try {
            setIsLoading(true)
            setOptionsData([])
            if (service) {
                const response = await service(params);
                if (optionsResponse === 'content') {
                    return setOptionsData(response.data.data.content)
                }
                if (optionsResponse === 'data') {
                    return setOptionsData(response.data.data);
                }
            }
        } catch (error) {
            console.error(error); 
            setOptionsData([])
        } finally {
            setIsLoading(false)
        }
    };

    const getValue = useCallback(() => {
        if (!value) {
            return value;
        }
        if (isMulti && Array.isArray(value)) {
            const filteredValue = optionsData.filter((e: any) => {
                return value.includes(e[valueField])
            });
            return filteredValue;
        }
        if (typeof value === TYPE.OBJECT) {
            return value;
        }                
        return optionsData.find((option: any) => option[valueField] === value)
    }, [value, optionsData]);

    const getOptions = useCallback(() => {
        return filterOptions ? filterOptions(optionsData) : optionsData;
    }, [optionsData]);

    //Hàm xác định cách lấy giá trị từ mỗi phần tử trong mảng
    const getOptionValue = (option: any): string => option[valueField];
    
    //Hàm xác định cách lấy nhãn từ mỗi phần tử trong mảng
    const getOptionLabel = (option: any): string => option[displayField];

    //Thay đổi text mặc định khi Select không có bản ghi
    const noOptionsMessage = () => noDataMessage;    

    return (
        <div>
            <span className={`text-lable-input lable mb-1`}>
                {label}
                {required && <span className="color-red"> *</span>}
            </span>
            <Select
                {...rest}
                value={getValue()}
                isMulti={isMulti}
                styles={combinedStyles}
                className={`radius ${className}`}
                options={getOptions()}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                noOptionsMessage={noOptionsMessage}
                isLoading={isLoading}
                classNamePrefix='select'
                placeholder={<p className="custom-placeholder spaces m-0">{placeholder}</p>}
                isClearable={isClearable}
                onChange={(option: any | null, actionMeta: ActionMeta<unknown>) => {
                    let value: unknown = null;
                    //isMulti value 
                    if (Array.isArray(option)) {
                        value = option.map((option) => option[valueField])
                    }
                    //only one value
                    else if (typeof option === 'object' && option !== null) {
                        value = option?.[valueField] || null
                    }
                    handleChange && handleChange(value, option, actionMeta, name)
                }}
            />
            {errors && touched && <div className="error-message text-danger">{errors}</div>}
        </div>
    );
}

export default memo(SelectCustom);