import React, { useEffect, useState } from 'react';
import { Card, FormLabel } from 'react-bootstrap';
import { ZoneModel } from '../model/CategoryModel';

interface TreeItemProps {
    label: string;
    node: any;
    children?: React.ReactNode;
    handleSelect: (node: ZoneModel) => void;
    handleDelete?: () => void;
    handleAdd?: (e: any) => void;
    selectedId: string | number;
    isDisabledAction?: boolean;
}

interface TreeViewProps {
    data: ZoneModel[];
    handleGetAreaInfo: (areaInfo: ZoneModel) => void;
    selectedIdDefault: string | number;
    handleDelete?: () => void;
    handleAdd?: (area: ZoneModel, e: any) => void;
    isDisabled?: boolean;
}

const TreeItem: React.FC<TreeItemProps> = ({
    label,
    node,
    children,
    handleSelect,
    handleDelete,
    handleAdd,
    selectedId,
    isDisabledAction
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleToggle = () => {
        setIsOpen(isOpen => !isOpen);
    };

    return (
        <Card>
            <Card.Header
                onClick={() => handleSelect(node)}
                className={`spaces min-h-40 d-flex justify-content-start align-items-center cursor-pointer
                    ${selectedId === node?.id && "selected"}
                    ${node?.children && "has-children"}
                    pl-${30*node.level} pr-10
                `}
            >
                <FormLabel className='spaces mt-6 ms-2 cursor-pointer width-100'>
                    {(isOpen && node?.children) &&
                        <i className="bi bi-caret-down-fill spaces mr-10"
                            onClick={handleToggle} />
                    }
                    {!isOpen && node?.children &&
                        <i className="bi bi-caret-right-fill spaces mr-10"
                            onClick={handleToggle}
                        />
                    }
                    <span>{label}</span>
                    {isDisabledAction && (
                        <div className="float-end btn-actions">
                            <i className="bi bi-plus-square-fill fs-3 color-steel-blue " 
                                onClick={handleAdd} 
                            />
                            <i className="cursor-pointer px-4 fw-bold fs-3 bi bi-trash-fill text-danger"
                                onClick={handleDelete}
                            />
                        </div>
                    )}
                </FormLabel>
            </Card.Header>
            {isOpen && (
                <Card.Body className='p-0'>{children}</Card.Body>
            )}
        </Card>
    );
};

const TreeViewAreaCategory: React.FC<TreeViewProps> = ({
    data,
    handleGetAreaInfo,
    handleDelete,
    handleAdd,
    selectedIdDefault,
    isDisabled
}) => {
    const [selectedId, setSelectedId] = useState<string | number>("");

    const handleSelect = (node: ZoneModel) => {
        setSelectedId(node.id || "");
        handleGetAreaInfo(node);
    }

    useEffect(() => {
        setSelectedId(selectedIdDefault);
    }, [selectedIdDefault])

    const renderTree = (nodes: ZoneModel[] | undefined) => {
        return nodes?.map((node: ZoneModel) => (
            <TreeItem
                key={node.id}
                label={node.name || ""}
                node={node}
                selectedId={selectedId}
                handleSelect={() => handleSelect(node)}
                handleDelete={handleDelete}
                handleAdd={(e: any) => handleAdd?.(node, e)}
                isDisabledAction={isDisabled}
            >
                {node.children && renderTree(node.children)}
            </TreeItem>
        ));
    };

    return (
        <div className='treelist-container'>
            {data.length !== 0 && renderTree(data)}
        </div>
    );
};

export default TreeViewAreaCategory;