import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Carousel, Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { getAllEmployeeScore, getAllNumberProgram, getEvaluatorEmployee, getListInfoGoodDoings, getListInfoNeedImprovesDoings, getProgramImplSchedules, getTopFastestRemedy, getTopScoreArea } from './services/DashboardServices';
import { NUM_OF_ITEMS_EACH_SLIDE, SCHEDULE_PROGRESS, TIME_TYPE_OPTIONS } from './constants/DashboardConst';
import OngoingProgramsBox from './components/OngoingProgramsBox';
import { useLoading } from '../../AppContext';
import { TIME_TYPE } from '../program/consts/InitialValue';
import { useAuth } from '../auth';
import moment, { invalid } from 'moment';
import { getImage } from '../services';
import { SearchBase } from '../utils/InterfaceUtils';
import { PreviewImagePopup } from '../evaluate/component/EvaluateTable';
import { CardsWidget20 } from '../../../_metronic/partials/widgets';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import TextValidator from '../component/input-field/TextValidator';
import SelectCustom from '../component/select-custom';
import useStateRef from '../utils/useStateRef';

type Props = {}

interface AllNumberProgram {
    numberOfOngoingPrograms: number | null;
    numberOfPendingPrograms: number | null;
    numberOfReviewedRecords: number | null;
    numberOfResolvedRecords: number | null;
}

const Dashboard = (props: Props) => {
    const { setPageLoading } = useLoading();
    const { currentUser } = useAuth();

    const [listByDay, setListByDay] = useState<any[]>([]);
    const [listByWeek, setListByWeek] = useState<any[]>([]);
    const [listByMonth, setListByMonth] = useState<any[]>([]);
    const [listByQuarter, setListByQuarter] = useState<any[]>([]);
    const [listByYear, setListByYear] = useState<any[]>([]);
    const [listGoodDoings, setListGoodDoings] = useState<any[]>([]);
    const [listNeedImproves, setListNeedImproves] = useState<any[]>([]);
    const [arrImgSrcGoodDoings, setArrImgSrcGoodDoings] = useState<any[]>([]);
    const [arrImgSrcNeedImproveDoings, setArrImgSrcNeedImproveDoings] = useState<any[]>([]);
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>("");
    const [allNumberProgram, setAllNumberProgram] = useState<AllNumberProgram>();
    const [allEmployeeScore, setAllEmployeeScore] = useState<any[]>([]);
    const [paramsAllEmployeeScore, setParamsAllEmployeeScore] = useState<any>({
        timeType: TIME_TYPE.YEAR,
        date: String(new Date().getDate()).padStart(2, '0') + "/" + (String(new Date().getMonth() + 1).padStart(2, '0')) + '/' + new Date().getFullYear()
    });
    const [hiddenList, setHiddenList, hiddenListRef] = useStateRef<any>({
        listByDay: false,
        listByWeek: false,
        listByMonth: false,
        listByQuarter: false,
        listByYear: false,
    });
    const [evaluatorEmployee, setEvaluatorEmployee] = useState<any[]>([]);
    const [topScoreArea, setTopScoreArea] = useState<any[]>([]);
    const [topFastestRemedy, setTopFastestRemedy] = useState<any[]>([]);

    let unMount = useRef<boolean>(false)
    const searchParams: SearchBase = {
        pageIndex: 0,
        pageSize: 12,
        orgId: currentUser?.orgId
    };

    useEffect(() => {
        handleGetAllProgram()
        const fetchData = async () => {
            updatePageData();
            await fetchListGoodDoings();
            await fetchListNeedImproves();
        };
        fetchData();
        handleGetEvaluatorEmployee();
        handleGetTopScoreArea();
        handleGetTopFastestRemedy();
        return () => {
            unMount.current = true
        }
    }, []);

    useEffect(() => {
        handleGetAllEmployeeScore()
    }, [paramsAllEmployeeScore]);

    useEffect(() => {
        const fetchData = async () => {
            setArrImgSrcGoodDoings(await fetchImage(listGoodDoings));
        };
        fetchData();
    }, [listGoodDoings]);

    useEffect(() => {
        const fetchData = async () => {
            setArrImgSrcNeedImproveDoings(await fetchImage(listNeedImproves));
        };
        fetchData();
    }, [listNeedImproves]);

    const fetchImage = async (list: any[]) => {
        let arr: any[] = [];
        for (let i = 0; i < list.length; i++) {
            if (unMount.current) {
                return [];
            }
            const url = await handleGetUrlImage(list[i].image);
            arr.push(url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhsxbPm-8QyFLOZfm3Ez5GUqqTQ1TZsVD1rw&s');
        }
        return arr;
    };

    const updatePageData = () => {
        Object.values(TIME_TYPE).forEach(async (timeType: string) => {
            if (timeType !== TIME_TYPE.EMERGENCY) {
                await handleGetProgramImplSchedules(timeType, SCHEDULE_PROGRESS.HAPPENING, true);
            }
        });
    };

    const handleGetProgramImplSchedules = async (timeType: string, progress: number, isFisrtRun: boolean = false) => {
        try {
            setPageLoading(true);
            let { data } = await getProgramImplSchedules({
                timeType: timeType === TIME_TYPE.DAY ? `${TIME_TYPE.DAY},${TIME_TYPE.EMERGENCY}` : timeType,
                progress,
                pageIndex: 1,
                pageSize: 99,
                orgId: currentUser?.orgId,
            });
            let result: any[] = data.data.content;

            if (!result.length && isFisrtRun) {
                if (progress === SCHEDULE_PROGRESS.HAPPENING) {
                    handleGetProgramImplSchedules(timeType, SCHEDULE_PROGRESS.UPCOMING, true);
                } else {
                    checkHiddenListProgramImplSchedules(timeType);
                }
            };

            switch (timeType) {
                case TIME_TYPE.DAY:
                    setListByDay(result)
                    break;
                case TIME_TYPE.WEEK:
                    setListByWeek(result)
                    break;
                case TIME_TYPE.MONTH:
                    setListByMonth(result)
                    break;
                case TIME_TYPE.QUARTER:
                    setListByQuarter(result)
                    break;
                case TIME_TYPE.YEAR:
                    setListByYear(result)
                    break;
                default:
                    return;
            }
        } catch (err) {
            console.error(err)
        } finally {
            setPageLoading(false);
        }
    };

    const checkHiddenListProgramImplSchedules = (timeType: string) => {
        switch (timeType) {
            case TIME_TYPE.DAY:
                setHiddenList({
                    ...hiddenListRef.current,
                    listByDay: true,
                })
                break;
            case TIME_TYPE.WEEK:
                setHiddenList({
                    ...hiddenListRef.current,
                    listByWeek: true,
                })
                break;
            case TIME_TYPE.MONTH:
                setHiddenList({
                    ...hiddenListRef.current,
                    listByMonth: true,
                })
                break;
            case TIME_TYPE.QUARTER:
                setHiddenList({
                    ...hiddenListRef.current,
                    listByQuarter: true,
                })
                break;
            case TIME_TYPE.YEAR:
                setHiddenList({
                    ...hiddenListRef.current,
                    listByYear: true,
                })
                break;
            default:
                return;
        }
    };

    const fetchListGoodDoings = async () => {
        try {
            setPageLoading(true);
            let { data } = await getListInfoGoodDoings(searchParams);
            setListGoodDoings(data.data?.content || []);
        } catch (err) {
            console.error(err)
        } finally {
            setPageLoading(false);
        }
    };

    const fetchListNeedImproves = async () => {
        try {
            setPageLoading(true);
            let { data } = await getListInfoNeedImprovesDoings(searchParams);
            setListNeedImproves(data.data?.content || []);
        } catch (err) {
            console.error(err)
        } finally {
            setPageLoading(false);
        }
    }

    const handleGetUrlImage = async (name: string) => {
        try {
            let imageDataURL = await getImage({ name });
            let url = URL.createObjectURL(imageDataURL.data);
            return url;
        } catch (error) {
            console.error(error);
        }
    };

    const reduceRecipes = (acc: any, cur: any, index: any) => {
        const groupIndex = Math.floor(index / NUM_OF_ITEMS_EACH_SLIDE);
        if (!acc[groupIndex]) acc[groupIndex] = [];
        acc[groupIndex].push(cur);
        return acc;
    };

    const handleShowImage = async (url: string) => {
        try {
            setImgSrc(url as string);
            setShowImagePopup(true);
        } catch (e) {
            console.error(e);
        }
    };

    const handleGetAllProgram = async () => {
        try {
            setPageLoading(true);
            const { data } = await getAllNumberProgram({ orgId: currentUser?.orgId });
            setAllNumberProgram({ ...data.data })
        } catch (error) {
            console.error(error);
        } finally {
            setPageLoading(false);
        }
    }

    const handleGetAllEmployeeScore = async () => {
        try {
            setPageLoading(true);
            if (paramsAllEmployeeScore?.date && paramsAllEmployeeScore?.timeType) {
                const { data } = await getAllEmployeeScore(paramsAllEmployeeScore);
                setAllEmployeeScore(data?.data);
            };
        } catch (error) {
            console.log(error);
        } finally {
            setPageLoading(false)
        }
    };

    const handleGetEvaluatorEmployee = async () => {
        try {
            setPageLoading(true)
            const { data } = await getEvaluatorEmployee();
            setEvaluatorEmployee(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setPageLoading(false)
        }
    };

    const handleGetTopScoreArea = async () => {
        try {
            setPageLoading(true)
            const { data } = await getTopScoreArea();
            setTopScoreArea(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setPageLoading(false)
        }
    };

    const handleGetTopFastestRemedy = async () => {
        try {
            setPageLoading(true)
            const { data } = await getTopFastestRemedy();
            setTopFastestRemedy(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setPageLoading(false)
        }
    };

    return (
        <div className='mt-2 p-2'>
            <Row className='spaces bg-light mt-10 mx-2'>
                <Col xs="5" className='flex-grow-1 bg-white rounded h-100 overflow-hidden' >
                    <div className="d-flex justify-content-between align-items-center py-4">
                        <h3 className='mb-0'>Top các cải tiến nhanh</h3>
                    </div>
                    <ListGroup>
                        {topFastestRemedy?.map((item, index) => (
                            <ListGroup.Item
                                key={index}
                                action
                                variant={`${index % 2 === 0 ? 'light' : 'secondary'}`}
                            >
                                <div className="d-flex align-item-center justify-content-between align-items-center">
                                    <div className="d-flex w-100">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {item?.termAreaName || ''}
                                                </Tooltip>
                                            }
                                        >
                                            <div className="ms-3 w-100">
                                                <h5 className="mb-0 fw-normal cursor-pointer">
                                                    {item?.programName || ''}
                                                </h5>
                                                <div className="d-flex spaces min-w-90 me-2 text-muted">
                                                    Khu vực:{' '}
                                                    <div className="text-primary fw-500 text-ellipsis">
                                                        {item?.areaName || ''}
                                                    </div>
                                                </div>
                                                <div className="d-flex spaces min-w-90 me-2 text-muted">
                                                    Tiêu chí:{' '}

                                                    <div className="text-primary fw-500 text-ellipsis">
                                                        {item?.termAreaName || ''}
                                                    </div>

                                                </div>
                                                <div className="d-flex spaces min-w-90 me-2 text-muted">
                                                    Người khắc phục:{' '}
                                                    <div className="text-primary fw-500 text-ellipsis">
                                                        {item?.userRemedy || ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col xs="7">
                    <Row className='h-100'>
                        <Col xs="6">
                            <CardsWidget20
                                className='h-100'
                                description={{
                                    number: allNumberProgram?.numberOfOngoingPrograms || 0,
                                    des: 'Số lượng chương trình đang diễn ra'
                                }}
                                color='#17a2b8'
                            />
                        </Col>
                        <Col xs="6">
                            <CardsWidget20
                                className='h-100'
                                description={{
                                    number: allNumberProgram?.numberOfPendingPrograms || 0,
                                    des: 'Số lượng chương trình chờ triển khai'
                                }}
                                color='rgba(238, 136, 77, 1)'
                            />
                        </Col>
                        <Col xs="6" className='mt-3'>
                            <CardsWidget20
                                className='h-100'
                                description={{
                                    number: allNumberProgram?.numberOfReviewedRecords || 0,
                                    des: 'Số lượng bản ghi đã đánh giá'
                                }}
                                color='#28a745'
                            />
                        </Col>
                        <Col xs="6" className='mt-3'>
                            <CardsWidget20
                                className='h-100'
                                description={{
                                    number: allNumberProgram?.numberOfResolvedRecords || 0,
                                    des: 'Số lượng bản ghi đã khắc phục'
                                }}
                                color='#707070'
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='spaces bg-light mt-10 mx-2 mb-20 h-270'>
                <Col xs="4" className='flex-grow-1 bg-white rounded h-100 overflow-hidden' >
                    <div className="d-flex justify-content-between align-items-center py-4">
                        <h3 className='mb-0'>Thống kê điểm</h3>
                        <div className='d-flex'>
                            <SelectCustom
                                menuPlacement="auto"
                                className='min-w-100px'
                                options={TIME_TYPE_OPTIONS}
                                displayField='name'
                                valueField='value'
                                value={paramsAllEmployeeScore?.timeType}
                                handleChange={(item) => {
                                    setParamsAllEmployeeScore({
                                        ...paramsAllEmployeeScore,
                                        timeType: item
                                    });
                                }}
                            />
                            <TextValidator
                                className="ms-2 mt-1 min-w-100px"
                                readOnly={false}
                                isRequired
                                name="date"
                                value={moment(paramsAllEmployeeScore?.date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                                type="date"
                                onChange={(e: any) => {
                                    if (e.target.value) {
                                        setParamsAllEmployeeScore({
                                            ...paramsAllEmployeeScore,
                                            date: moment(e.target.value, 'YYYY-MM-DD', true).isValid() ? moment(e.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <ListGroup className='height-scroll'>
                        {
                            allEmployeeScore?.map((item, index) => (
                                <ListGroup.Item action variant={`${index % 2 === 0 ? "light" : "secondary"}`}>
                                    <div className="d-flex align-item-center justify-content-between align-items-center">
                                        <div className="d-flex">

                                            <div className="ms-3">
                                                <h5 className='mb-0 fw-normal cursor-pointer'>{item?.employeeName || ""}</h5>
                                                <div className='spaces min-w-90 me-2 text-muted'>Mã nhân viên: <span className='text-primary fw-500'>
                                                    {item?.employeeCode || ""}
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='spaces rounded-circle bg-primary w-40 h-40 flex-center text-white'>{item?.employeeScore || 0}</span>
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
                <Col xs="4" className='flex-grow-1 bg-white rounded h-100 overflow-hidden'>
                    <div className="d-flex align-items-center py-6">
                        <h3 className='mb-1'>Khu vực có điểm đánh giá cao nhất</h3>
                    </div>
                    <ListGroup className='height-scroll'>
                        {
                            topScoreArea?.map((item, index) => (
                                <ListGroup.Item action variant={`${index % 2 === 0 ? "light" : "secondary"}`}>
                                    <div className="d-flex align-item-center justify-content-between align-items-center">
                                        <div className="d-flex">

                                            <div className="ms-3">
                                                <h5 className='mb-0 fw-normal cursor-pointer'>{item?.name || ""}</h5>
                                                <div className='spaces min-w-90 me-2 text-muted'>Mã khu vực: <span className='text-primary fw-500'>
                                                    {item?.code || ""}
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='spaces rounded-circle bg-primary w-40 h-40 flex-center text-white'>{item?.totalScore || 0}</span>
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
                <Col xs="4" className='flex-grow-1 bg-white rounded h-100 overflow-hidden'>
                    <div className="d-flex align-items-center py-6">
                        <h3 className='mb-1'>Nhân viên đi đánh giá nhiều nhất</h3>
                    </div>
                    <ListGroup className='height-scroll'>
                        {
                            evaluatorEmployee?.map((item, index) => (
                                <ListGroup.Item action variant={`${index % 2 === 0 ? "light" : "secondary"}`}>
                                    <div className="d-flex align-item-center justify-content-between align-items-center">
                                        <div className="d-flex">

                                            <div className="ms-3">
                                                <h5 className='mb-0 fw-normal cursor-pointer'>{item?.employeeName || ""}</h5>
                                                <div className='spaces min-w-90 me-2 text-muted'>Mã nhân viên: <span className='text-primary fw-500'>
                                                    {item?.code || ""}
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='spaces rounded-circle bg-primary w-40 h-40 flex-center text-white'>{item?.evaluationCount || 0}</span>
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
            </Row>
            <Row className='spaces bg-light mt-10 mx-2'>
                <Col xs="12" className='spaces d-flex space-between align-center mb-15'>
                    <h4 className='spaces fw-bolder mb-0 fs-20'>Chương trình đang diễn ra</h4>
                    <div className='d-flex flex-end'>
                    </div>
                </Col>
                {!hiddenList.listByDay ?
                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className='position-relative'>
                        <OngoingProgramsBox
                            title='Hàng ngày'
                            timeType={TIME_TYPE.DAY}
                            data={listByDay}
                            updateData={handleGetProgramImplSchedules}
                        />
                    </Col>
                    :
                    <></>
                }
                {!hiddenList.listByWeek ?
                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className='position-relative'>
                        <OngoingProgramsBox
                            title='Hàng tuần'
                            timeType={TIME_TYPE.WEEK}
                            data={listByWeek}
                            updateData={handleGetProgramImplSchedules}
                        />
                    </Col>
                    :
                    <></>
                }
                {!hiddenList.listByMonth ?
                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className='position-relative'>
                        <OngoingProgramsBox
                            title='Hàng tháng'
                            timeType={TIME_TYPE.MONTH}
                            data={listByMonth}
                            updateData={handleGetProgramImplSchedules}
                        />
                    </Col>
                    :
                    <></>
                }
                {!hiddenList.listByQuarter ?
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='position-relative'>
                        <OngoingProgramsBox
                            title='Hàng quý'
                            timeType={TIME_TYPE.QUARTER}
                            data={listByQuarter}
                            updateData={handleGetProgramImplSchedules}
                        />
                    </Col>
                    :
                    <></>
                }
                {!hiddenList.listByYear ?
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='position-relative'>
                        <OngoingProgramsBox
                            title='Hàng năm'
                            timeType={TIME_TYPE.YEAR}
                            data={listByYear}
                            updateData={handleGetProgramImplSchedules}
                        />
                    </Col>
                    :
                    <></>
                }
            </Row>
            <Row className='spaces bg-light mt-10 mx-2 carousel-image-style'>
                <Col xs="12" className='spaces d-flex space-between align-center mb-20'>
                    <h4 className='spaces fw-bolder mb-0 fs-20'>Hình ảnh thực hành tốt</h4>
                    <div className='d-flex flex-end'>
                    </div>
                </Col>
                <Carousel
                    variant="dark"
                    touch={true}
                    indicators={false}
                >
                    {listGoodDoings.length === 0 && Array.from({ length: 4 }).map((item: any, index: number) => (
                        <Carousel.Item>
                            <Row className='flex'>
                                {Array.from({ length: 4 }).map((item: any) => (
                                    <Col xl={3}>
                                        <Card>
                                            <Card.Img variant="top" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhsxbPm-8QyFLOZfm3Ez5GUqqTQ1TZsVD1rw&s" />
                                            <Card.Body className='spaces px-35 py-10'>
                                                <Card.Text>
                                                    <div>
                                                        <h5 className='spaces mb-0 fw-normal fs-16 lh-28'>Khu vực nhà ăn</h5>
                                                        <div className='spaces flex mb-5'>
                                                            <i className="spaces bi bi-clock-fill me-2 fs-16 color-gray h-16"></i>
                                                            <div className='spaces body-normal-2 text-muted fs-12'>08:00 AM | Nguyễn Văn A</div>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                    {listGoodDoings.length > 0 && listGoodDoings.reduce(reduceRecipes, []).map((item: any, index: number) => (
                        <Carousel.Item>
                            <Row className='flex'>
                                {item.map((item: any, indexItem: number) => (
                                    <Col xl={3}>
                                        <Card key={indexItem} onClick={() => handleShowImage(arrImgSrcGoodDoings[index * NUM_OF_ITEMS_EACH_SLIDE + indexItem])}>
                                            <Card.Img variant="top" src={arrImgSrcGoodDoings[index * NUM_OF_ITEMS_EACH_SLIDE + indexItem]} style={{ objectFit: 'cover' }} height={250} />
                                            <Card.Body className='spaces px-35 py-10'>
                                                <Card.Text>
                                                    <div>
                                                        <h5 className='spaces mb-0 fw-bold fs-16 lh-28'>{item.employeeOnusName}</h5>
                                                        <div className='spaces flex mb-5'>
                                                            <i className="spaces bi bi-clock-fill me-2 fs-16 color-gray h-16"></i>
                                                            <div className='spaces body-normal-2 text-muted fs-12'>{moment(item.modifiedDate).format('DD/MM/YYYY HH:mm:ss')} | {item.areaName}</div>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>

            </Row>
            <Row className='spaces bg-light my-30 mx-2 carousel-image-style'>
                <Col xs="12" className='spaces d-flex space-between align-center mb-20'>
                    <h4 className='spaces fw-bolder mb-0 fs-20'>Hình ảnh cần cải tiến</h4>
                    <div className='d-flex flex-end'>
                    </div>
                </Col>
                <Carousel
                    variant="dark"
                    touch={true}
                    indicators={false}
                >
                    {listNeedImproves.length === 0 && Array.from({ length: 4 }).map((item: any, index: number) => (
                        <Carousel.Item>
                            <Row className='flex'>
                                {Array.from({ length: 4 }).map((item: any) => (
                                    <Col xl={3}>
                                        <Card>
                                            <Card.Img variant="top" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhsxbPm-8QyFLOZfm3Ez5GUqqTQ1TZsVD1rw&s" />
                                            <Card.Body className='spaces px-35 py-10'>
                                                <Card.Text>
                                                    <div>
                                                        <h5 className='spaces mb-0 fw-normal fs-16 lh-28'>Khu vực nhà ăn</h5>
                                                        <div className='spaces flex mb-5'>
                                                            <i className="spaces bi bi-clock-fill me-2 fs-16 color-gray h-16"></i>
                                                            <div className='spaces body-normal-2 text-muted fs-12'>08:00 AM | Nguyễn Văn A</div>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                    {listNeedImproves.length > 0 && listNeedImproves.reduce(reduceRecipes, []).map((item: any, index: number) => (
                        <Carousel.Item>
                            <Row className='flex'>
                                {item.map((item: any, indexItem: number) => (
                                    <Col xl={3}>
                                        <Card key={indexItem} onClick={() => handleShowImage(arrImgSrcNeedImproveDoings[index * NUM_OF_ITEMS_EACH_SLIDE + indexItem])}>
                                            <Card.Img variant="top" src={arrImgSrcNeedImproveDoings[index * NUM_OF_ITEMS_EACH_SLIDE + indexItem]} style={{ objectFit: 'cover' }} height={250} />
                                            <Card.Body className='spaces px-35 py-10'>
                                                <Card.Text>
                                                    <div>
                                                        <h5 className='spaces mb-0 fw-bold fs-16 lh-28'>{item.employeeOnusName}</h5>
                                                        <div className='spaces flex mb-5'>
                                                            <i className="spaces bi bi-clock-fill me-2 fs-16 color-gray h-16"></i>
                                                            <div className='spaces body-normal-2 text-muted fs-12'>{moment(item.modifiedDate).format('DD/MM/YYYY HH:mm:ss')} | {item.areaName}</div>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
            {showImagePopup && (
                <PreviewImagePopup
                    show={showImagePopup}
                    hide={() => setShowImagePopup(false)}
                    src={imgSrc}
                />
            )}
        </div>
    )
}

export default Dashboard;