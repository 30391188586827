import React, { Dispatch, SetStateAction, useContext } from "react";
import { INoti } from "../_metronic/partials";

type IAppContext = {
  pageLoading: boolean;
  setPageLoading: Dispatch<SetStateAction<boolean>>
  setListNotifications: Dispatch<SetStateAction<INoti[]>>
  listNotifications: INoti[]
  setListNotificationsTab: Dispatch<SetStateAction<INoti[]>>
  listNotificationsTab: INoti[]
}

const initValue: IAppContext = {
    listNotifications:[],
    pageLoading: false,
    setPageLoading: () => { },
    setListNotifications: () => { },
    listNotificationsTab: [],
    setListNotificationsTab: () => { }
}

const AppContext = React.createContext(initValue);

export const useLoading = () => {
  return useContext(AppContext);
}

export const useNotification = () => {
  return useContext(AppContext);
}

export default AppContext;