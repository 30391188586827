import axios from "axios";
import { SearchScheduleType } from "../models/DashboardModels";
import { SearchBase } from "../../utils/InterfaceUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";


const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"];
const API_EVALUATE = API_PATH + "/program-impl-schedules";
const API_EVALUATE_PROGRAM_RESULT = API_PATH + "/program-result";

export const getProgramImplSchedules = (params: SearchScheduleType) => {
    const url = API_EVALUATE + "/search";
    return axios.get(url, { params });
}

export const getListInfoGoodDoings = (params: SearchBase) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/info-good-images";
    return axios.get(url, { params });
}

export const getListInfoNeedImprovesDoings = (params: SearchBase) => {
    const url = API_EVALUATE_PROGRAM_RESULT + "/info-bad-images";
    return axios.get(url, { params });
}

export const getAllNumberProgram = (params: any) => {
    const url = API_PATH + "/dashboards/number-schedule-result";
    return axios.get(url, { params });
}

export const getAllEmployeeScore = (params: any) => {
    const url = API_PATH + "/dashboards/scores-by-period";
    return axios.get(url, { params });
}

export const getEvaluatorEmployee = () => {
    const url = API_PATH + "/employees/evaluator";
    return axios.get(url);
};

export const getTopScoreArea = () => {
    const url = API_PATH + "/areas/top-total-score";
    return axios.get(url);
};

export const getTopFastestRemedy = () => {
    const url = API_PATH + "/term-area/top-fastest-remedy";
    return axios.get(url);
};